import { linkToSession } from "../../links";

export function linkToShareModule(
  courseId?: string,
  sectionId?: string,
  contentId: string = ":contentId",
  moduleId: string = ":moduleId"
) {
  return `${linkToSession(
    courseId,
    sectionId
  )}/share/content/${contentId}/module/${moduleId}`;
}
