import { useEffect } from "react";
import { useSelector } from "react-redux";

import { pushGlobalValuesToDataLayer } from "seneca-common/features/analytics/gtm";
import { isSignedIn } from "seneca-common/features/user/state";

import initialisePlurallFeedbackTracker from "features/plurall/initialisePlurallFeedbackTracker";
import initialisePlurallHotjar from "features/plurall/initialisePlurallHotjar";

import useIsPlurallApp from "./useIsPlurallApp";

export default function usePlurallConfiguration() {
  const isPlurallApp = useIsPlurallApp();
  const signedIn = useSelector(isSignedIn);
  const shouldInitPlurallTracker = isPlurallApp && signedIn;

  useEffect(() => {
    if (!shouldInitPlurallTracker) return;

    const removePlurallTrackerListener = initialisePlurallFeedbackTracker();
    return removePlurallTrackerListener;
  }, [shouldInitPlurallTracker]);

  useEffect(() => {
    pushGlobalValuesToDataLayer({
      isPlurallApp
    });

    initialisePlurallHotjar();
  }, [isPlurallApp]);
}
