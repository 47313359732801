import { ModuleType as ModuleNames } from "seneca-common/features/module/module-types/types";

import { ModuleDeviceRequirements } from "features/module/types";

const MIN_MODULE_WIDTH_MOBILE = 300;

type AssembledTransformModuleAssets<T> = (arg0: T) => T;

export type ModuleComponentsType = {
  deviceRequirements: ModuleDeviceRequirements;
  transformModuleAssets: AssembledTransformModuleAssets<any> | null | undefined;
};

export default class ModuleComponentStaticInformation {
  _moduleName: ModuleNames;

  deviceRequirements: ModuleDeviceRequirements;
  transformModuleAssets: AssembledTransformModuleAssets<any> | null | undefined;

  constructor(
    moduleName: ModuleNames,
    { deviceRequirements, transformModuleAssets }: ModuleComponentsType
  ) {
    this._moduleName = moduleName;

    this.deviceRequirements = deviceRequirements;
    this.transformModuleAssets = transformModuleAssets;
  }

  getDeviceRequirements(): ModuleDeviceRequirements {
    return this.deviceRequirements;
  }

  getTransformModuleAssets():
    | AssembledTransformModuleAssets<any>
    | null
    | undefined {
    return this.transformModuleAssets;
  }

  isMobileModule(): boolean {
    const { desktopOnly, minModuleWidth } = this.deviceRequirements;
    return !desktopOnly && minModuleWidth === MIN_MODULE_WIDTH_MOBILE;
  }
}
