import logError from "seneca-common/utils/sentry/logError";

import { Flags, FlagsByType, FlattenedFlagsByType } from "./types";
import { getDevOverride } from "./utils";

const emptyFlattenedFlagsByType: FlattenedFlagsByType = {
  static: {},
  release: {},
  abTest: {}
};

export default function getAllFeatureFlags(flags?: FlagsByType) {
  if (!flags) {
    return emptyFlattenedFlagsByType;
  }

  try {
    return Object.keys(flags).reduce<FlattenedFlagsByType>((acc, key) => {
      return {
        ...acc,
        [key]: getFlagValues(flags[key as keyof FlagsByType])
      };
    }, emptyFlattenedFlagsByType);
  } catch (err) {
    logError(err, {
      message: "Unable to parse feature flags",
      extraInfo: { flags }
    });

    return emptyFlattenedFlagsByType;
  }
}

function getFlagValues(flags?: Flags) {
  if (!flags) {
    return {};
  }

  return Object.keys(flags).reduce<{ [key: string]: string | boolean }>(
    (obj, flagId) => {
      const { isOverridden, override } = getDevOverride(flagId);

      if (isOverridden) {
        obj[flagId] = override;
      } else {
        obj[flagId] = flags[flagId].value;
      }

      return obj;
    },
    {}
  );
}
