import { combineReducers } from "redux-immutable";

import AppStateSlice from "../models/AppStateSlice";
import pwaReducer from "./state/pwa";

export default combineReducers(
  {
    pwa: pwaReducer
  },
  () => new AppStateSlice() as any
);
