import { Record } from "immutable";

export type ApiStateType = {
  started: boolean;
  success: boolean;
  last?: string;
  error: boolean;
  errorMessage?: string;
};

export type OptionalApiStateType = Partial<ApiStateType>;

const defaultValues: ApiStateType = {
  started: false,
  success: false,
  last: undefined,
  error: false,
  errorMessage: undefined
};

export default class ApiState extends Record(defaultValues) {}
