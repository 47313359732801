import sortAscendingOrder from "seneca-common/utils/functions/sorting/sortAscendingOrder";

import SubscriptionProduct from "../../../models/state/subscription-products/subscription-product/SubscriptionProduct";
import { SubscriptionProducts } from "../../../models/state/subscription-products/SubscriptionProducts";
import * as subscriptionProductSelectors from "./subscription-product";

const { getPrice } = subscriptionProductSelectors;

export const getSubscriptionProduct = (
  state: SubscriptionProducts,
  subscriptionProductId: string
  // @ts-ignore
): SubscriptionProduct => state.get(subscriptionProductId);

export const getSubscriptionProductIds = (state: SubscriptionProducts) =>
  state.keySeq().toList();

export const getSortedAnnualSubscriptionProductIds = (
  state: SubscriptionProducts
) => {
  const annualSubscriptionProducts = state
    .sort((a: any, b: any) => sortAscendingOrder(getPrice(a), getPrice(b)))
    .filter(subscriptionProductSelectors.subscriptionIsBilledAnually);

  return getSubscriptionProductIds(annualSubscriptionProducts);
};

export const getSortedMonthlySubscriptionProductIds = (
  state: SubscriptionProducts
) => {
  const montlySubscriptionProducts = state
    .sort((a: any, b: any) => sortAscendingOrder(getPrice(a), getPrice(b)))
    .filter(subscriptionProductSelectors.subscriptionIsBilledMonthly);

  return getSubscriptionProductIds(montlySubscriptionProducts);
};

export const getSortedPromotedPeriodSubscriptionProductIds = (
  state: any,
  period: any
) => {
  const promotedProductsByPeriod = state
    .filter(
      (subscriptionProduct: any) =>
        subscriptionProductSelectors.isPromoted(subscriptionProduct) &&
        subscriptionProductSelectors.subscriptionMatchesPeriod(
          subscriptionProduct,
          period
        )
    )
    .sort((a: any, b: any) => sortAscendingOrder(getPrice(a), getPrice(b)));
  return getSubscriptionProductIds(promotedProductsByPeriod);
};

export const getSubscriptionTierName = (
  state: SubscriptionProducts,
  subscriptionProductId: string
): string => {
  const subscriptionProduct = getSubscriptionProduct(
    state,
    subscriptionProductId
  );
  // @ts-ignore
  return subscriptionProductSelectors.getTierName(subscriptionProduct);
};

export const getSubscriptionProductIconUrl = (
  state: SubscriptionProducts,
  subscriptionProductId: string
): string => {
  const subscriptionProduct = getSubscriptionProduct(
    state,
    subscriptionProductId
  );
  // @ts-ignore
  return subscriptionProductSelectors.getIconUrl(subscriptionProduct);
};

export const getSubscriptionProductCanBeUpgraded = (
  state: SubscriptionProducts,
  subscriptionProductId: string
): boolean => {
  const subscriptionProduct = getSubscriptionProduct(
    state,
    subscriptionProductId
  );

  // @ts-ignore
  return subscriptionProductSelectors.getCanBeUpgraded(subscriptionProduct);
};

export const getSubscriptionProductIsAddOn = (
  state: SubscriptionProducts,
  subscriptionProductId: string
) => {
  const subscriptionProduct = getSubscriptionProduct(
    state,
    subscriptionProductId
  );

  return subscriptionProductSelectors.isAddOn(subscriptionProduct);
};

export const getSubscriptionProductIsMisBooster = (
  state: SubscriptionProducts,
  subscriptionProductId: string
) => {
  const subscriptionProduct = getSubscriptionProduct(
    state,
    subscriptionProductId
  );

  return subscriptionProductSelectors.isMisBooster(subscriptionProduct);
};
