import actionTypes from "../action-types/resetPasswords";
import { ResetPasswordsStateType } from "../models/resetPasswordsState";

export function resetPasswordsSuccess() {
  return {
    type: actionTypes.RESET_PASSWORDS_SUCCESS
  };
}

export function resetPasswordsFailure(newPassword: string) {
  return {
    type: actionTypes.RESET_PASSWORDS_FAILURE,
    payload: {
      newPassword
    }
  };
}

export function passwordsResetSuccessReset() {
  return {
    type: actionTypes.SUCCESS_RESET
  };
}

export function updateResetPasswordsData(
  passwordsResetData: Partial<ResetPasswordsStateType>
) {
  return {
    type: actionTypes.RESET_PASSWORDS_UPDATE_DATA,
    payload: passwordsResetData
  };
}
