import { matchPath } from "react-router-dom";

import { Link } from "common/types/routing";

import { linkToClassroomCourse } from "../../links";

export function linkToCourseExamQuestionsHomepage(courseId?: string) {
  return `${linkToClassroomCourse(courseId)}/exam-questions`;
}

export function isInCourseExamQuestions(pathname: Link) {
  const stringpath =
    typeof pathname === "string" ? pathname : (pathname as any).pathname;

  if (!stringpath) {
    return false;
  }

  return matchPath(stringpath, {
    path: linkToCourseExamQuestionsHomepage(),
    exact: false
  });
}
