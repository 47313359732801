import {
  SchoolEntityEnrichedWithParentInfo,
  SchoolInfoI
} from "@seneca/schools-service-types";
import { userType } from "@seneca/user-service-client";
import { UserInfoI } from "@seneca/user-service-types";

import {
  fetchMySchoolInfo,
  fetchMyStudentSchools,
  fetchMyTeacherSchools,
  fetchSchoolInfo,
  fetchTeacherSchoolsWithParentInfo
} from "seneca-common/features/schools/service/client";
import { fetchMyInfo } from "seneca-common/features/user/service/client";

import { getStoredSelectedSchoolId } from "./persistence";
import { SchoolEntityType, SelectedSchool } from "./types";

export function defaultContext() {
  return {
    fetchMyInfo,
    fetchMyTeacherSchools,
    fetchTeacherSchoolsWithParentInfo,
    fetchMyStudentSchools,
    fetchSchoolInfo,
    fetchMySchoolInfo,
    getStoredSelectedSchoolId
  };
}

type Context = ReturnType<typeof defaultContext>;

export async function fetchSelectedSchool(
  {
    context,
    includeParentInfo
  }: { context: Context; includeParentInfo?: boolean } = {
    context: defaultContext(),
    includeParentInfo: false
  }
): Promise<SelectedSchool | null> {
  const userInfo = await context.fetchMyInfo();

  const isManaged = userInfo.managedBy;

  if (!isManaged) {
    return fetchSchoolPreference(context);
  }

  const managedSchools = await fetchUserManagedSchools(
    context,
    userInfo,
    includeParentInfo
  );

  if (!managedSchools.length) return null;

  const storedSchoolId = await context.getStoredSelectedSchoolId(
    userInfo.userId
  );

  return resolveSelectedSchool(
    context,
    managedSchools,
    userInfo.managedBy,
    storedSchoolId
  );
}

async function fetchUserManagedSchools(
  {
    fetchMyStudentSchools,
    fetchMyTeacherSchools,
    fetchTeacherSchoolsWithParentInfo
  }: Context,
  userInfo: UserInfoI,
  includeParentInfo?: boolean
): Promise<SchoolEntityType[]> {
  if (userInfo.type === userType.teacher) {
    return includeParentInfo
      ? fetchTeacherSchoolsWithParentInfo()
      : fetchMyTeacherSchools();
  }

  if (userInfo.type === userType.student) {
    return fetchMyStudentSchools();
  }

  return [];
}

async function fetchSchoolPreference({
  fetchMySchoolInfo
}: Context): Promise<SelectedSchool | null> {
  const schoolInfo = await fetchMySchoolInfo();

  if (!schoolInfo) return null;

  return {
    isManaged: false,
    schoolId: schoolInfo.schoolId,
    schoolInfo,
    allSchools: []
  };
}

async function resolveSelectedSchool(
  { fetchSchoolInfo }: Context,
  schools: SchoolEntityType[],
  userManagedBy: string | undefined,
  storedSchoolId: string | undefined
): Promise<SelectedSchool> {
  const selectedSchool =
    schools.find(({ schoolId }) => schoolId === storedSchoolId) || schools[0];
  const selectedSchoolId = selectedSchool.schoolId;

  const schoolInfo = await fetchSchoolInfo(selectedSchoolId);

  return {
    isManaged: true,
    schoolId: selectedSchoolId,
    externalSchoolId: selectedSchool.externalId ?? undefined,
    schoolEntity: selectedSchool,
    schoolInfo,
    allSchools: schools,
    userManagedBy
  };
}
