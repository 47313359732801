import { format } from "date-fns";
import localforage from "localforage";

import logError from "seneca-common/utils/sentry/logError";

const NUM_SESSIONS_COMPLETED_TODAY = "numOfSessionsCompletedToday";
export const NUM_SESSIONS_COMPLETED_ALL_TIME = "numOfSessionsCompletedAllTime";

type DailySessionsStats = {
  count: number;
  date: string;
};

function getTodaysDate() {
  return format(new Date(), "P").toString();
}

export async function incrementNumSessionsCompleted() {
  await incrementNumSessionsCompletedToday();
  incrementNumSessionsCompletedAllTime();
}

export async function incrementNumSessionsCompletedToday() {
  try {
    await localforage.ready();

    const completedToday = await localforage.getItem<DailySessionsStats>(
      NUM_SESSIONS_COMPLETED_TODAY
    );

    const todayStr = getTodaysDate();

    let toStore: DailySessionsStats = {
      date: todayStr,
      count: completedToday?.date === todayStr ? completedToday.count + 1 : 1
    };

    await localforage.setItem(NUM_SESSIONS_COMPLETED_TODAY, toStore);
  } catch (err: any) {
    logError(err);
  }
}

export async function getNumberOfSessionsCompletedToday() {
  try {
    await localforage.ready();

    const completedToday = await localforage.getItem<DailySessionsStats>(
      NUM_SESSIONS_COMPLETED_TODAY
    );

    return completedToday?.date === getTodaysDate() ? completedToday.count : 0;
  } catch (err: any) {
    logError(err);
    return 0;
  }
}

export function getNumberOfSessionsCompletedAllTime() {
  try {
    const value = window.localStorage.getItem(NUM_SESSIONS_COMPLETED_ALL_TIME);
    return value === null ? 0 : Number(value);
  } catch (error) {
    logError(error);
    return 0;
  }
}

export function incrementNumSessionsCompletedAllTime() {
  try {
    const sessionsCount = getNumberOfSessionsCompletedAllTime();
    window.localStorage.setItem(
      NUM_SESSIONS_COMPLETED_ALL_TIME,
      (sessionsCount + 1).toString()
    );
  } catch (error) {
    logError(error);
  }
}

export function resetNumSessionsCompletedAllTime() {
  try {
    window.localStorage.setItem(
      NUM_SESSIONS_COMPLETED_ALL_TIME,
      (0).toString()
    );
  } catch (error) {
    logError(error);
  }
}

export function getHasCompletedSessionsBefore() {
  const sessionsCount = getNumberOfSessionsCompletedAllTime();
  return sessionsCount > 1;
}
