import UiState from "features/ui-state/models/UiState";

export const getUIStateSlice = (state: any): UiState => state.get("uiState");

export const getModalOpen = (state: any): boolean =>
  getUIStateSlice(state).get("modalsOpenCount") > 0;

export const getMobileKeyboardOpen = (state: any): boolean =>
  getUIStateSlice(state).get("mobileKeyboardOpen");

export const getShowPlurallHelpCentreModalOpen = (state: any): boolean =>
  getUIStateSlice(state).get("showPlurallHelpCentreModal");
