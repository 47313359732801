import { History } from "history";
import { ReactNode } from "react";
import { Router } from "react-router-dom";
import { CompatRouter } from "react-router-dom-v5-compat";

import { LastLocationProvider } from "features/common/routing/with-last-location";

export default function RoutingProviders({
  children,
  history
}: {
  children: ReactNode;
  history: History;
}) {
  return (
    // @ts-ignore
    <Router history={history}>
      <CompatRouter>
        <LastLocationProvider>{children}</LastLocationProvider>
      </CompatRouter>
    </Router>
  );
}
