import { PushTopic } from "@seneca/notifications-service-types";

import {
  EmailIdSourceParamKey,
  NotificationType,
  PushNotificationsSourceParamKey
} from "../consts";

export function getAnalyticsDataFromQueryParams(
  searchParams: URLSearchParams
): {
  notificationType: NotificationType | null;
  pushSource?: PushTopic | null;
  emailId?: string | null;
} {
  const pushSource = searchParams.get(PushNotificationsSourceParamKey);
  const emailId = searchParams.get(EmailIdSourceParamKey);

  if (pushSource) {
    return {
      pushSource: pushSource as PushTopic,
      notificationType: NotificationType.PUSH
    };
  }

  if (emailId) {
    return {
      notificationType: NotificationType.EMAIL,
      emailId
    };
  }

  return {
    notificationType: null
  };
}
