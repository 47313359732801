import { Map, Record } from "immutable";

import { CrudBuilderState } from "seneca-common/utils/apiStates";

import subscriptionProductsMetaInitialState from "./state/meta";
import SubscriptionProduct from "./state/subscription-products/subscription-product/SubscriptionProduct";
import { SubscriptionProducts } from "./state/subscription-products/SubscriptionProducts";

export type SubscriptionProductsStateSliceType = {
  subscriptionProducts: SubscriptionProducts;
  meta: CrudBuilderState | Map<any, any>;
};

export type OptionalSubscriptionProductsStateSliceType =
  Partial<SubscriptionProductsStateSliceType>;

const defaultValues: SubscriptionProductsStateSliceType = {
  subscriptionProducts: Map<string, SubscriptionProduct>(),
  meta: subscriptionProductsMetaInitialState
};

export default class SubscriptionProductsStateSlice extends Record(
  defaultValues
) {}
