import { commonSession } from "seneca-common/features/common/action-types";

const actionTypes = {
  COMPLETE_SESSION: commonSession.COMPLETE_SESSION,
  SET_SECTION_IDS: "seneca/session/SET_SECTION_IDS",
  NEW_SESSION: "seneca/session/NEW_SESSION",
  ADD_SESSION_MODULE: "seneca/session/ADD_SESSION_MODULE",
  RECONCILE_SESSION_PROFICIENCIES:
    "seneca/session/RECONCILE_SESSION_PROFICIENCIES",
  SET_WON_MEMORY: "seneca/session/SET_WON_MEMORY",
  FOCUS_ITEM: "seneca/session/FOCUS_ITEM",
  FOCUS_LAST_ITEM: "seneca/session/FOCUS_LAST_ITEM",
  UPDATE_SESSION_XP: "seneca/session/UPDATE_SESSION_XP",
  SET_SESSION_TYPE: "seneca/session/SET_SESSION_TYPE",
  SET_SESSION_OPTIONS: "seneca/session/SET_SESSION_OPTIONS"
};

export default actionTypes;
