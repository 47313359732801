import { AllowedCallTypes } from "../../common/const";
import { CrudBuilderState } from "../../crudBuilder/models/CrudBuilderState";
import { processCallType } from "../utils";

export const generateStateKey = (callType: AllowedCallTypes): any => {
  const { callTypesLowerCase: stateKey } = processCallType(callType);
  return stateKey;
};

const { FETCH_ALL, FETCH, CREATE, UPDATE, DELETE } = AllowedCallTypes;

export const getFetchAllSlice = (state: CrudBuilderState) => {
  return state.get(generateStateKey(FETCH_ALL));
};

export const getFetchSlice = (state: CrudBuilderState) => {
  return state.get(generateStateKey(FETCH));
};

export const getDeleteSlice = (state: CrudBuilderState) => {
  return state.get(generateStateKey(DELETE));
};

export const getUpdateSlice = (state: CrudBuilderState) => {
  return state.get(generateStateKey(UPDATE));
};

export const getCreateSlice = (state: CrudBuilderState) => {
  return state.get(generateStateKey(CREATE));
};
