import actionTypes from "../action-types";
import { UserType } from "../model/UserType";

export function setUserType(type: UserType) {
  return {
    type: actionTypes.SET_TYPE,
    payload: type
  };
}

export function updateUserTypeBasedOnPremiumStatus({
  misSubscription,
  b2cSubscription
}: {
  misSubscription: boolean;
  b2cSubscription: boolean;
}) {
  return {
    type: actionTypes.UPDATE_TYPE,
    payload: {
      misSubscription,
      b2cSubscription
    }
  };
}
