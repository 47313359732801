export const browserNames = Object.freeze({
  FIREFOX: "firefox",
  CHROME: "chrome",
  CHROME_IOS: "crios",
  EDGE: "edge",
  IOS: "ios",
  SAFARI: "safari",
  ANDROID: "android",
  IE: "ie",
  OPERA: "opera"
});
