import { matchPath } from "react-router-dom";

import { Link } from "common/types/routing";

export function linkToClassroom() {
  return `/classroom`;
}

export function isInClassroom(pathname: Link) {
  const stringpath =
    typeof pathname === "string" ? pathname : (pathname as any).pathname;

  if (!stringpath) {
    return false;
  }

  return matchPath(stringpath, {
    path: linkToClassroom(),
    exact: false
  });
}
