import { AllowedCallTypes, CrudConfig } from "seneca-common/utils/apiStates";

export const PATH_TO_SUBSCRIPTIONS_STATE = "subscriptions";
export const baseSubscriptionsActionsPath = `seneca/${PATH_TO_SUBSCRIPTIONS_STATE}`;
export const FEATURE_NAME = PATH_TO_SUBSCRIPTIONS_STATE;
export const DEFAULT_CREATED_SUBSCRIPTION_ID = "newSubscriptionId";

export const subscriptionsCrudStateConfig: CrudConfig = {
  name: "subscriptions",
  path: `${baseSubscriptionsActionsPath}/meta`,
  idFieldName: "subscriptionId",
  callTypes: [
    AllowedCallTypes.FETCH_ALL,
    AllowedCallTypes.FETCH,
    AllowedCallTypes.CREATE,
    AllowedCallTypes.UPDATE
  ]
};
