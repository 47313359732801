import actionTypes from "../action-types/passwordReset";
import PasswordResetState from "../models/PasswordResetState";

export default function passwordResetState(
  passwordResetState: PasswordResetState = new PasswordResetState(),
  action: Record<string, any>
): PasswordResetState {
  switch (action.type) {
    case actionTypes.UPDATE_EMAIL:
      return passwordResetState.set("email", action.payload.email);
    default:
      return passwordResetState;
  }
}
