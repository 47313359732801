import { getFeatureItem } from "features/dev-tools/featureTogglesUtils";

const showDebugMessages =
  (window.env && window.env.pwa && window.env.pwa.showDebugMessages) ||
  getFeatureItem("enableServiceWorker");

export function debugLogString(msg: string) {
  if (showDebugMessages) {
    console.log(
      `%c${new Date().toLocaleTimeString()} Seneca service worker registration: %c${msg}`,
      "font-weight: bold; color: cornflowerblue;",
      "font-weight: unset; color: var(--neutral-high-content);"
    );
  }
}

export function debugLog(...args: any[]) {
  if (showDebugMessages) {
    console.log(...args);
  }
}

export function isServiceWorkerSupported() {
  return "serviceWorker" in navigator;
}
