import { analyticsCategories } from "../consts";
import { logGTMTrackingEvent } from "../googleTagManager";

export const analyticsReferredUserLandedOnSite = (
  referralCode: string,
  pathname: string
) => {
  logGTMTrackingEvent({
    category: analyticsCategories.REFERRAL,
    event: "seneca/referral/REFERRED_USER_LANDED_ON_SITE",
    payload: {
      referralCode,
      pathname
    }
  });
};

export const analyticsReferredUserSignIn = (referralCode: string) => {
  logGTMTrackingEvent({
    category: analyticsCategories.REFERRAL,
    event: "seneca/referral/REFERRED_USER_SIGN_IN",
    payload: {
      referralCode
    }
  });
};

export const analyticsReferredUserSignUp = (referralCode: string) => {
  logGTMTrackingEvent({
    category: analyticsCategories.REFERRAL,
    event: "seneca/referral/REFERRED_USER_SIGN_UP",
    payload: {
      referralCode
    }
  });
};
