import {
  analyticsReduxRegistry,
  DATA_LAYER_SCOPES
} from "seneca-common/features/analytics";
import { getUserType } from "seneca-common/features/userType";

import {
  areSoundEffectsEnabled,
  getModuleToolbarOpenByDefault,
  getPreferredThemeMode,
  getRegion,
  getTeacherRole,
  getUserAccountType,
  getUserDisplayName,
  getUserEmail,
  getUserFamilyName,
  getUserGivenName,
  getUserHasPickedDisplayName,
  getUserId,
  getUserManagedBy,
  getUserNickName,
  getUserPhoneNumber,
  getUserYearGroup,
  isFirstUserSession,
  isSignedIn,
  isSignedInAsGuest,
  userHasSignedInBefore
} from "../state";

analyticsReduxRegistry.registerGlobalSelectorsToDataLayerScope(
  DATA_LAYER_SCOPES.USER,
  {
    userId: getUserId,
    displayName: getUserDisplayName,
    hasPickedDisplayName: getUserHasPickedDisplayName,
    email: getUserEmail,
    firstName: getUserGivenName,
    lastName: getUserFamilyName,
    phoneNumber: getUserPhoneNumber,
    accountType: getUserAccountType,
    nickName: getUserNickName,
    isSignedIn: isSignedIn,
    isSignedInAsGuest: isSignedInAsGuest,
    userHasSignedInBeforeOnThisDevice: userHasSignedInBefore,
    isFirstUserSession: isFirstUserSession,
    region: getRegion,
    yearGroup: getUserYearGroup,
    teacherRole: getTeacherRole,
    managedBy: getUserManagedBy,
    soundEffectsEnabled: areSoundEffectsEnabled,
    preferredThemeMode: getPreferredThemeMode,
    userType: getUserType,
    moduleToolbarOpenByDefault: getModuleToolbarOpenByDefault
  }
);

analyticsReduxRegistry.registerUserPropertiesSelectorsToDataLayerScope({
  userId: getUserId,
  accountType: getUserAccountType,
  isSignedIn: isSignedIn,
  userHasSignedInBeforeOnThisDevice: userHasSignedInBefore,
  isFirstUserSession: isFirstUserSession,
  region: getRegion
});
