export const unsafeFieldsMatchExact = [
  "firstName",
  "lastName",
  "familyName",
  "givenName",
  "username",
  "school",
  "displayName",
  "nickname",
  "yearGroup", // fields no longer collected in the webapp - put here in case we start collecting again
  "learningDifficulties",
  "birthdate",
  "gender",
  "providerData",
  "externalSchoolId",
  "guardianEmail"
];
export const unsafeFieldsMatchFuzzy = [
  "password",
  "email",
  "postcode",
  "stripe",
  "phonenumber"
];

// Sentry payloads can't be > 100kb https://github.com/getsentry/raven-js/issues/339
// otherwise it silently fails - sentry doesn't report error at all
export const MAX_BREADCRUMB_SIZE_BYTES = 400;

export const MAX_NUMBER_OF_BREADCRUMBS = 75;
