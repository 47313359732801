// Use require to avoid circular dependency issue...
require("../analytics");

export { default as signInActionTypes } from "./action-types/signInState";
export {
  setUser,
  updateUserPersonalInformation,
  userInfoCreateActionFactory,
  userInfoFetchActionFactory,
  userInfoUpdateActionFactory
} from "./actions";
export {
  updatePasswordActionFactory,
  updatePasswordResetEmail
} from "./actions/passwordReset";
export {
  setUserPreferences,
  updateUserPreferences,
  userPreferencesFetchActionFactory,
  userPreferencesUpdateActionFactory
} from "./actions/preferences";
export {
  logCredentialsRefresh,
  refreshCredentialsError,
  setPromptToConnectProviderSSO,
  setUserHasSignedUpBefore,
  signInError,
  signInStart,
  signInSuccess,
  signInSuccessAsGuest,
  signOut,
  signOutError,
  signOutStart,
  signUpSuccess
} from "./actions/signInState";
export { PATH_TO_USER_STATE } from "./consts";
export { default as PasswordResetState } from "./models/PasswordResetState";
export type {
  OptionalPasswordResetStateType,
  PasswordResetStateType
} from "./models/PasswordResetState";
export { default as SignInState } from "./models/SignInState";
export type { OptionalSignInStateType } from "./models/SignInState";
export { default as User } from "./models/User";
export type { OptionalUserType, UserType } from "./models/User";
export { default as UserPreferences } from "./models/UserPreferences";
export type {
  OptionalUserPreferences,
  UserPreferencesType
} from "./models/UserPreferences";
export { default as UserStateSlice } from "./models/UserStateSlice";
export type {
  OptionalUserStateSliceType,
  UserStateSliceType
} from "./models/UserStateSlice";
export { default as reducer } from "./reducers";
export { default as getRegion } from "./selectors/getRegion";
export {
  getPasswordResetEmail,
  getPasswordResetMetaState,
  getPasswordResetSlice,
  getResubmitCodeError,
  getResubmitCodePending,
  getResubmitCodeSuccess
} from "./selectors/passwordReset";
export {
  areSoundEffectsEnabled,
  getAgeGroup,
  getAgeGroupPreferenceOption,
  getMapGradient,
  getModuleToolbarOpenByDefault,
  getNotifyWhenDataRequestReady,
  getPreferredStats,
  getPreferredTheme,
  getPreferredThemeMode,
  getRegion as getRegionPreference,
  getTypingPreference,
  getUserHasPickedDisplayName,
  getUserPreferences,
  smartLearningIsEnabled
} from "./selectors/preferences";
export {
  getUpdatePreferencesError,
  getUpdatePreferencesStarted
} from "./selectors/preferencesMeta";
export {
  credentialsRefreshHasErrored,
  getPromptToConnectProviderSSO,
  getSignInTime,
  getUserSignInState,
  isFirstUserSession,
  isSignedIn,
  isSignedInAsGuest,
  isSigningIn,
  isSigningOut,
  userHasSignedInBefore
} from "./selectors/signInState";
// selectors
export {
  getTeacherRole,
  getUserAccountType,
  getUserChosenSchool,
  getUserDetails,
  getUserDisplayName,
  getUserEmail,
  getUserFamilyName,
  getUserGivenName,
  getUserId,
  getUserLastVisitTime,
  getUserManagedBy,
  getUserMetaSlice,
  getUserNickName,
  getUserPhoneNumber,
  getUserRecoveryEmail,
  getUserRecoveryEmailConfirmed,
  getUserSlice,
  getUserYearGroup,
  isUserManaged
} from "./selectors/userInfo";
export {
  getUpdateInfoErrorMessage,
  getUpdateInfoStarted,
  getUpdateInfoSuccess,
  getUserInfoFetchFinished
} from "./selectors/userInfoMeta";
