import { AutoMarkType } from "@seneca/short-answer-service-consts";

import {
  QuestionDifficulty,
  QuestionType
} from "services/shortAnswerService/question-types/common";

import { pushEnrichedEventToDataLayerWithCleanUp } from "seneca-common/features/analytics/gtm";
import { examQuestionsAnalyticsNamespace } from "seneca-common/features/exam-questions/consts";

import { TrialModalSource } from "features/trials/consts";

export enum ExamQuestionsSource {
  courseSearch = "courseSearch",
  courseSidebar = "courseSidebar",
  sectionHomepage = "sectionHomepage",
  sectionHomepageRecommended = "sectionHomepageRecommended",
  endSessionAdScreen = "endSessionAdScreen",
  endSessionTryMore = "endSessionTryMore",
  endSessionAssignmentProgressScreen = "assignmentProgressScreen",
  retry = "retry",
  closedModalAtEndOfSession = "closedModalAtEndOfSession",
  assignmentTile = "assignmentTile",
  assignmentSidebar = "assignmentSidebar",
  studyCourseModal = "studyCourseModal",
  partOfAssignment = "partOfAssignment",
  selfStudy = "selfStudy",
  teacherMarkingModal = "teacherMarkingModal",
  teacherSpeedyMarking = "teacherSpeedyMarking",
  selectMode = "selectMode",
  miniMock = "miniMock",
  examQuestionEndSession = "examQuestionEndSession",
  courseHomepage = "courseHomepage",
  inviteParentsExamQuestions = "inviteParentsExamQuestions"
}

export function logClickStartExamQuestions() {
  return pushEnrichedEventToDataLayerWithCleanUp({
    event: `${examQuestionsAnalyticsNamespace}/CLICK_START`,
    payload: {}
  });
}

export type ExamQuestionAnalyticsContext = {
  questionId: string;
  assignmentId: string;
  difficulty?: QuestionDifficulty;
  submissionId: string;
  selfMarkedQuestion: boolean;
  maxMarkQuestion: number;
  source: ExamQuestionsSource;
  answerText?: string;
  autoMarkType?: AutoMarkType;
  questionType?: QuestionType;
};

export function logStartExamQuestionWithContext(
  examQuestionAnalyticsPayload: ExamQuestionAnalyticsContext
) {
  return pushEnrichedEventToDataLayerWithCleanUp({
    event: `${examQuestionsAnalyticsNamespace}/START`,
    payload: examQuestionAnalyticsPayload
  });
}

export function logSubmitExamQuestionWithContext(
  examQuestionAnalyticsPayload: ExamQuestionAnalyticsContext & {
    timeSpent?: number;
  }
) {
  return pushEnrichedEventToDataLayerWithCleanUp({
    event: `${examQuestionsAnalyticsNamespace}/SUBMIT`,
    payload: examQuestionAnalyticsPayload
  });
}

export function logStartExamQuestionDictation() {
  return pushEnrichedEventToDataLayerWithCleanUp({
    event: `${examQuestionsAnalyticsNamespace}/START_DICTATION`,
    payload: {}
  });
}

export function logSubmitFlaggedAnswerWithContext(
  payload: ExamQuestionAnalyticsContext & {
    autoMark?: number;
    selfMark?: number;
    timeSpent?: number;
  }
) {
  return pushEnrichedEventToDataLayerWithCleanUp({
    event: `${examQuestionsAnalyticsNamespace}/WRONG_MARK`,
    payload
  });
}

export function logMarkingErroredWithContext(
  payload: ExamQuestionAnalyticsContext
) {
  return pushEnrichedEventToDataLayerWithCleanUp({
    event: `${examQuestionsAnalyticsNamespace}/MARKING_ERRORED`,
    payload
  });
}

export function logExamQuestionUpdateSessionXp(payload: {
  questionId: string;
  sessionId: string;
  newXp: number;
  assignmentId?: string;
  source?: string;
  breakdown: {
    completionXp: number;
    markXp: number;
    assignmentXp: number;
    assignmentCompleteXp: number;
  };
}) {
  const { breakdown, ...restPayload } = payload;

  return pushEnrichedEventToDataLayerWithCleanUp({
    event: `${examQuestionsAnalyticsNamespace}/UPDATE_SESSION_XP`,
    payload: {
      ...breakdown,
      ...restPayload
    }
  });
}

export function logExamQuestionAutoMarkedWithContext(
  payload: ExamQuestionAnalyticsContext & {
    answerTimeCreated: string;
    autoMark?: number;
    autoMarkingTime: number;
    timeSpent?: number;
  }
) {
  return pushEnrichedEventToDataLayerWithCleanUp({
    event: `${examQuestionsAnalyticsNamespace}/AUTO_MARK`,
    payload
  });
}

export enum GatingType {
  maxQuestionsSelected = "maxQuestionsSelected",
  maxQuestionsStudied = "maxQuestionsStudied",
  hardQuestions = "hardQuestions",
  enableAutoMarking = "enableAutoMarking",
  wrongAnswers = "wrongAnswers",
  miniMockPapers = "miniMockPapers",
  aiMarking = "aiMarking",
  courseSearch = "courseSearch"
}

export function logExamQuestionGating(gatingType: GatingType) {
  return pushEnrichedEventToDataLayerWithCleanUp({
    event: `${examQuestionsAnalyticsNamespace}/GATING`,
    payload: {
      gatingType
    }
  });
}

export function logExamQuestionInviteParentsGatingShown(
  source: TrialModalSource,
  gatingType?: GatingType
) {
  return pushEnrichedEventToDataLayerWithCleanUp({
    event: `${examQuestionsAnalyticsNamespace}/INVITE_PARENTS_MODAL_SH`,
    payload: {
      source,
      gatingType
    }
  });
}

export function logExamQuestionInviteParentsGatingClosed(
  source: TrialModalSource,
  gatingType?: GatingType
) {
  return pushEnrichedEventToDataLayerWithCleanUp({
    event: `${examQuestionsAnalyticsNamespace}/INVITE_PARENTS_MODAL_CL`,
    payload: {
      source,
      gatingType
    }
  });
}

export function logClickRandomTopicsButton() {
  return pushEnrichedEventToDataLayerWithCleanUp({
    event: `${examQuestionsAnalyticsNamespace}/RANDOM_TOPICS`,
    payload: {}
  });
}

export function logReceiveExamQuestionsGift() {
  return pushEnrichedEventToDataLayerWithCleanUp({
    event: `${examQuestionsAnalyticsNamespace}/RECEIVE_GIFT`,
    payload: {}
  });
}
