import { useCallback } from "react";

import { openWindowSecurely } from "seneca-common/utils/routing";

import { isAndroid, isIOS } from "features/common/utils/functions/mobile";

import { PromptToUseAppBanner } from "./banner/PromptToUseAppBanner";
import { PromptToUseAppModal } from "./modal/PromptToUseAppModal";
import { Prompt, PromptProps } from "./types";
import {
  incrementTimesHasBeenInteractedWithLocally,
  logCtaClicked,
  logDismissed,
  setHasBeenInteractedWithInSession
} from "./utils";

type Props = {
  prompt: Prompt;
  setHasBeenInteractedWith: () => void;
};

export function PromptToUseApp({ prompt, setHasBeenInteractedWith }: Props) {
  const registerInteraction = useCallback(() => {
    setHasBeenInteractedWith();
    setHasBeenInteractedWithInSession();
    incrementTimesHasBeenInteractedWithLocally();
  }, [setHasBeenInteractedWith]);

  const handleDismiss = useCallback(() => {
    registerInteraction();
    logDismissed(prompt);
  }, [registerInteraction, prompt]);

  const handleCtaClick = useCallback(() => {
    registerInteraction();
    logCtaClicked(prompt);

    if (isIOS()) {
      openWindowSecurely("https://apps.apple.com/uk/app/seneca/id1631302657");
    } else if (isAndroid()) {
      openWindowSecurely(
        "https://play.google.com/store/apps/details?id=com.senecalearning.app"
      );
    }
  }, [registerInteraction, prompt]);

  const props: PromptProps = {
    handleDismiss,
    handleCtaClick
  };

  if (prompt === "modal") {
    return <PromptToUseAppModal {...props} />;
  }

  return <PromptToUseAppBanner {...props} />;
}
