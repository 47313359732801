import { ErrorHandler, throttledLogError } from "seneca-common/utils/apiStates";

import userServiceErrorTranslator from "./errorTranslator";

const userServiceErrorHandler: ErrorHandler = (error, { errorAction }) => {
  const errorMessage = userServiceErrorTranslator(error);
  throttledLogError(error);
  errorAction(errorMessage);
};

export default userServiceErrorHandler;
