// TODO: don't have react in seneca-common. Find some way to absract away
import { ComponentType } from "react";

import {
  ModuleConnector,
  ModuleType as ModuleNames
} from "seneca-common/features/module/module-types/types";

import ModuleComponents from "../models/ModuleComponents";
import ModuleBuilderNavigator from "./utils/ModuleBuilderNavigator";

export default class ModuleComponentBuilder<
  BackT,
  RootT
> extends ModuleBuilderNavigator<BackT, RootT> {
  _moduleName: ModuleNames;

  _Main: ComponentType<any> | null | undefined;
  _ControlBar: ComponentType<any> | null | undefined;
  _moduleConnector: ModuleConnector | null | undefined;

  constructor(moduleName: ModuleNames, back: any, root: any) {
    super(
      {
        moduleName,
        builderName: "ModuleComponentBuilder"
      },
      back,
      root
    );
    this._moduleName = moduleName;

    this._Main = null;
    this._ControlBar = null;
    this._moduleConnector = null;
  }

  main(Main: ComponentType<any>) {
    this._Main = Main;
    return this;
  }

  controlBar(ControlBar: ComponentType<any>) {
    this._ControlBar = ControlBar;
    return this;
  }

  moduleConnector(moduleConnector: ModuleConnector) {
    this._moduleConnector = moduleConnector;
    return this;
  }

  build(): ModuleComponents {
    !this._Main && this.throwMissingAttributeError("module component");
    !this._ControlBar &&
      this.throwMissingAttributeError("control bar component");
    !this._moduleConnector &&
      this.throwMissingAttributeError("module connector");

    return new ModuleComponents(this._moduleName, {
      Main: this._Main!,
      ControlBar: this._ControlBar!,
      moduleConnector: this._moduleConnector!
    });
  }
}
