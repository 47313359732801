import { matchPath } from "react-router-dom";

import { ROUTES } from "../../routing/routes";

import {
  makePasswordResetLink,
  makeSignInLink,
  makeSignUpLink
} from "./common-routes/links";

export const linkToSignIn = () => makeSignInLink("");

export const linkToSignUp = () => makeSignUpLink("");
export const linkToSignUpInvite = () => "/sign-up-invite";

export const linkToPasswordReset = () => makePasswordResetLink("");

export function getIsInParentSignUpPage(pathname: string) {
  return matchPath(pathname, {
    path: ROUTES.SIGN_UP_PARENT.path,
    exact: false
  });
}
