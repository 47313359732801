import { useSelector } from "react-redux";

/**
A convenience so you can do: 

const name = useSelectorWithArgs(getCourseName, courseId)

instead of: 

const name = useSelector(s => getCourseName(s, courseId))

 */

export default function useSelectorWithArgs<
  ArgumentsT extends ReadonlyArray<any>,
  ReturnT
>(
  selector: (...args: any) => ReturnT, // TODO: replace with SelectorWithArgs<ReturnT, ArgumentsT>,
  ...selectorArgs: ArgumentsT
): ReturnT {
  return useSelector(s => selector(s, ...selectorArgs));
}
