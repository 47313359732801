import { pushPageValuesToDataLayer } from "../../gtm";
import { Selector } from "../types";
import pushSelectorOutputsToDataLayer from "./pushSelectorOutputsToDataLayer";

export default function pushPageSelectorOutputsToDataLayer(
  dataLayerScope: string,
  selectors: Record<string, Selector>,
  lastSelectorOutputsCache: Record<string, any>
) {
  const selectorsArray = Object.keys(selectors).map(dataLayerValueName => ({
    dataLayerValueName,
    dataLayerScope,
    selector: selectors[dataLayerValueName]
  }));

  return pushSelectorOutputsToDataLayer({
    selectors: selectorsArray,
    pushValuesToDataLayer: pushPageValuesToDataLayer,
    lastSelectorOutputsCache
  });
}
