import { composeSelector } from "seneca-common/utils/selectors/compose-selectors";

import { getSubscriptionProductsStateSlice } from "./";
import { getMetaSlice } from "./state";
import * as meta from "./state/meta";

type State = any;

function getMetaState(state: State) {
  return getMetaSlice(getSubscriptionProductsStateSlice(state));
}

// fetch
export const subscriptionProductsAreFetching: (arg0: State) => boolean =
  composeSelector(getMetaState, meta.subscriptionProductsAreFetching);

export const subscriptionProductsFetchSucceeded: (arg0: State) => boolean =
  composeSelector(getMetaState, meta.subscriptionProductsFetchSucceeded);

export const subscriptionProductsFetchErrored: (arg0: State) => boolean =
  composeSelector(getMetaState, meta.subscriptionProductsFetchErrored);

export const subscriptionProductsFetchFinished: (arg0: State) => boolean =
  composeSelector(getMetaState, meta.subscriptionProductsFetchFinished);

export const subscriptionProductsNeedFetching: (arg0: State) => boolean =
  composeSelector(getMetaState, meta.subscriptionProductsNeedFetching);
