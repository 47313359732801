import makeErrorHandler from "services/subscriptionService/errorHandler";

import {
  fetchSubscriptionActionFactory,
  receiveSubscription
} from "seneca-common/features/subscriptions/state";

interface SubscriptionsClient {
  readonly updateSubscriptionStatusById: (id: string) => Promise<any>;
}

export const makeConfirmSubscriptionStatus = (
  getClient: () => SubscriptionsClient
) => {
  return fetchSubscriptionActionFactory({
    requestFunction: (id: string) =>
      getClient().updateSubscriptionStatusById(id),
    storeAction: receiveSubscription,
    errorHandler: makeErrorHandler()
  });
};
