import { Dispatch, GetState } from "common/types";

import makeErrorHandler from "services/subscriptionService/errorHandler";

import { fetchAllSubscriptionsActionFactory } from "seneca-common/features/subscriptions/state/actions/meta";
import { receiveSubscriptions } from "seneca-common/features/subscriptions/state/index";
import { subscriptionsNeedsFetching } from "seneca-common/features/subscriptions/state/selectors/state/subscriptions/meta";
import { isSignedIn } from "seneca-common/features/user/state";

interface SubscriptionsClient {
  readonly fetchMySubscription: () => Promise<any[]>;
}

// For testing purposes we're able to inject a mock client
export const makeFetchMySubscriptions = (
  getClient: () => SubscriptionsClient
) => {
  return fetchAllSubscriptionsActionFactory({
    requestFunction: () => getClient().fetchMySubscription(),
    storeAction: receiveSubscriptions,
    errorHandler: makeErrorHandler()
  });
};

export const makeFetchMySubscriptionsIfNotFetched = (
  getClient: () => SubscriptionsClient
) => {
  const fetchSubscriptions = makeFetchMySubscriptions(getClient);

  return () => async (dispatch: Dispatch, getState: GetState) => {
    const state = getState();

    if (isSignedIn(state) && subscriptionsNeedsFetching(state)) {
      // @ts-ignore
      dispatch(fetchSubscriptions());
    }
  };
};
