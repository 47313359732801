import actionTypes from "../action-types/learningMode";
import { LearningMode } from "../consts";

export function setLearningMode(mode: LearningMode) {
  return {
    type: actionTypes.SET_LEARNING_MODE,
    payload: {
      mode
    }
  };
}

export function setLearningModeMeta(payload: {
  assignmentId: string | null | undefined;
  source: string | null | undefined;
}) {
  return {
    type: actionTypes.SET_LEARNING_MODE_META,
    payload
  };
}
