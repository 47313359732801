import { lazy, ReactNode, Suspense } from "react";

import loaderWithRetry from "seneca-common/utils/loaderWithRetry";

import MinVersionParamHandler from "features/app/update/MinVersionParamHandler/MinVersionParamHandler";
import LocalStorageBlockedProvider from "features/localStorageBlocked/LocalStorageBlockedProvider";
import PWAMobileProvider from "features/pwa/mobile/PWAMobileProvider";

const VersionUpdateHandler = lazy(() =>
  loaderWithRetry(
    () =>
      import(
        /* webpackChunkName: "VersionUpdateHandler" */ "features/app/components/VersionUpdateHandler"
      )
  )
);

export default function AppEnvironmentProvider({
  children
}: {
  children: ReactNode;
}) {
  return (
    <LocalStorageBlockedProvider>
      <PWAMobileProvider>
        <MinVersionParamHandler>
          <>
            <Suspense>
              <VersionUpdateHandler />
            </Suspense>
            {children}
          </>
        </MinVersionParamHandler>
      </PWAMobileProvider>
    </LocalStorageBlockedProvider>
  );
}
