import { combineReducers } from "redux-immutable";

import TeacherStateSlice from "../models/TeacherStateSlice";
import resetPasswords from "./resetPasswordsState";

const teacherReducer = combineReducers(
  {
    resetPasswords
  },
  () => new TeacherStateSlice() as any
);

export default teacherReducer;
