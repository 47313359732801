import { useMemo } from "react";

import { Selector, SelectorFactory } from "./types";

/**
  Used to help make a memoised selector.
 */

export default function useMakeSelector<
  ArgumentsT extends ReadonlyArray<any>,
  ReturnT
>(
  makeSelector: SelectorFactory<ArgumentsT, ReturnT>,
  ...dependencies: ArgumentsT
): Selector<ReturnT> {
  //eslint-disable-next-line
  return useMemo(() => makeSelector(...dependencies), dependencies);
}
