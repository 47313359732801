import { combineReducers } from "redux-immutable";

import SubscriptionProductsStateSlice from "../models/SubscriptionProductsStateSlice";
import subscriptionProductsMetaReducer from "./state/meta";
import subscriptionProducts from "./state/subscription-products";

const subscriptionProductsReducer = combineReducers(
  {
    subscriptionProducts,
    meta: subscriptionProductsMetaReducer
  },
  () => new SubscriptionProductsStateSlice() as any
);

export default subscriptionProductsReducer;
