export default function removeUndefinedKeys<T extends Record<string, any>>(
  obj: T
): Partial<T> {
  if (obj && typeof obj === "object" && !Array.isArray(obj)) {
    const mutate = { ...obj };
    Object.entries(mutate).forEach(([key, val]) => {
      if (val === undefined) {
        delete mutate[key];
      }
    });
    return mutate;
  }

  return obj;
}
