import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { isMobile } from "features/common/utils/functions/mobile";

import { setUpdateToastHidden } from "../state";

export default function useHideUpdateToastWhileMounted(condition = true) {
  const dispatch = useDispatch();

  useEffect(() => {
    if (condition) {
      dispatch(setUpdateToastHidden(true));

      return () => {
        dispatch(setUpdateToastHidden(false));
      };
    }
  }, [dispatch, condition]);
}

export function useHideUpdateToastOnMobile() {
  useHideUpdateToastWhileMounted(isMobile());
}
