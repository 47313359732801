import classnames from "classnames";
import * as React from "react";

import styles from "./CroppedImageBackground.module.scss";

type Props = {
  imageUrl: string;
  withMask?: boolean;
  cropImageOnRight?: boolean;
  className: string;
  height?: number;
  width?: number;
};

const CLIP_RIGHT_ID = "clipRight";
const CLIP_LEFT_ID = "clipLeft";

export default function CroppedImageBackground({
  imageUrl,
  withMask,
  cropImageOnRight,
  className,
  height = 100,
  width = 100
}: Props) {
  const clipPath = `url(#${cropImageOnRight ? CLIP_RIGHT_ID : CLIP_LEFT_ID})`;
  return (
    <svg
      className={classnames(styles.wrapper, className)}
      viewBox={`0 0 ${width} ${height}`}
      preserveAspectRatio="xMidYMid slice"
    >
      <image
        clipPath={clipPath}
        xlinkHref={imageUrl}
        preserveAspectRatio="xMinYMid slice"
        width={width}
        height={height}
      />
      {withMask && (
        <rect
          clipPath={clipPath}
          width={width}
          height={height}
          preserveAspectRatio="none"
          className={styles.imageMask}
        />
      )}
    </svg>
  );
}

export function CroppedImageBackgroundDefs() {
  return (
    <svg className={styles.svg}>
      <clipPath id={CLIP_LEFT_ID} clipPathUnits="objectBoundingBox">
        <polygon points="0.47 0, 1 0, 1 1, 0 1" />
      </clipPath>
      <clipPath id={CLIP_RIGHT_ID} clipPathUnits="objectBoundingBox">
        <polygon points="0,0 0.54,0 1,1 0,1" />
      </clipPath>
    </svg>
  );
}
