import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import { makeLinkToSignIn } from "features/sign-up/global-sign-up-modal/links";

export default function useResumeSession(initialized: boolean): boolean {
  const [signInAttemptStarted, setSignInAttemptStarted] = useState(false);
  const [signInAttemptFinished, setSignInAttemptFinished] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const redirectToSignInPage = useCallback(() => {
    history.replace(
      makeLinkToSignIn("", {
        redirectAuthenticatedUserToLocation: location as any
      })
    );
  }, [history, location]);

  useEffect(() => {
    async function signIn() {
      const { resumeSession } = await import("../actions/auth/resumeSession");
      setSignInAttemptStarted(true);
      await dispatch(
        resumeSession({
          onSessionError: redirectToSignInPage
        })
      );
      setSignInAttemptFinished(true);
    }

    if (initialized && !signInAttemptStarted) {
      signIn();
    }
  }, [dispatch, initialized, redirectToSignInPage, signInAttemptStarted]);

  return signInAttemptFinished;
}
