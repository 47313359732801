import {
  Box,
  Center,
  Inline,
  Stack,
  Text,
  useForegroundVariantColor,
  useTheme
} from "@seneca/senekit";

import CopyWithTooltip from "features/common/components/CopyWithTooltip/CopyWithTooltip";

import { DIAGNOSIS_CODE } from "../utils";

export default function CopyDiagnosisCode() {
  const background = useForegroundVariantColor("1");
  const { "neutral-low-content": borderColor } = useTheme();

  return (
    <Stack space="xlarge">
      <Text size="small" weight="bold">
        Share this code and a description of what happened with customer support
        to help them identify what's going wrong:
      </Text>
      <Center>
        <Box
          width={["100%", "35%"]}
          borderRadius="small"
          background={background}
          borderWidth="xxxsmall"
          borderStyle="solid"
          borderColor={borderColor}
        >
          <Center>
            <Inline alignItems="center" space="medium">
              <Text weight="bold" dataNoTranslate>
                {DIAGNOSIS_CODE}{" "}
              </Text>
              <CopyWithTooltip copyValue={DIAGNOSIS_CODE} />
            </Inline>
          </Center>
        </Box>
      </Center>
    </Stack>
  );
}
