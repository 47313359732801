import React, {
  ReactComponentElement,
  Suspense,
  useCallback,
  useEffect,
  useState
} from "react";
import { useLocation } from "react-router";

import { persistDevToolsEnabled, shouldLoadDevTools } from "./utils";

type Props = {
  children: ReactComponentElement<any, any> | ReactComponentElement<any, any>[];
};

const DevTools = React.lazy(
  () =>
    import(
      /* webpackChunkName: "dev-tools" */
      "./DevTools"
    )
);

export default function DevToolsProvider({ children }: Props) {
  const [key, setKey] = useState(0);
  const tick = useCallback(() => {
    setKey(v => v + 1);
  }, []);

  const { search } = useLocation();

  useEffect(() => {
    persistDevToolsEnabled(search);
  }, [search]);

  return (
    <>
      <React.Fragment key={key}>{children}</React.Fragment>
      {shouldLoadDevTools() ? (
        <Suspense fallback={null}>
          <DevTools tick={tick} />
        </Suspense>
      ) : null}
    </>
  );
}
