import actionTypes from "../action-types/resetPasswords";
import ResetPasswordsState from "../models/resetPasswordsState";

export default function resetPasswordsState(
  resetPasswords: ResetPasswordsState = new ResetPasswordsState(),
  action: Record<string, any>
) {
  switch (action.type) {
    case actionTypes.RESET_PASSWORDS_SUCCESS:
      return resetPasswords.merge({
        success: true,
        newPassword: ""
      });
    case actionTypes.RESET_PASSWORDS_FAILURE:
      return resetPasswords.merge({
        success: false,
        newPassword: action.payload.newPassword
      });
    case actionTypes.SUCCESS_RESET:
      return resetPasswords.set("success", null);
    case actionTypes.RESET_PASSWORDS_UPDATE_DATA:
      return resetPasswords.merge(action.payload);
    default:
      return resetPasswords;
  }
}
