import { Action, Reducer } from "common/types/redux";

import { updateUserTypeBasedOnPremiumStatus } from "seneca-common/features/userType/actions";
import { UserType } from "seneca-common/features/userType/model/UserType";

import actionTypes from "../action-types";
import Subscription, {
  OptionalSubscriptionType
} from "../models/subscriptions/subscription/Subscription";
import {
  hasStripeSubscription,
  isLive,
  isManaged
} from "../selectors/state/subscriptions/subscription";

export function userTypeReducerWrapper(userTypeReducer: Reducer<UserType>) {
  return (state: UserType, action: Action<any>) => {
    switch (action.type) {
      case actionTypes.RECEIVE_SUBSCRIPTION: {
        const subscription = new Subscription(action.payload);

        const misSubscription = isManaged(subscription) && isLive(subscription);
        const b2cSubscription =
          hasStripeSubscription(subscription) && isLive(subscription);

        return userTypeReducer(
          state,
          updateUserTypeBasedOnPremiumStatus({
            misSubscription,
            b2cSubscription
          })
        );
      }
      case actionTypes.RECEIVE_SUBSCRIPTIONS:
        const subscriptions: Subscription[] = action.payload.map(
          (sub: OptionalSubscriptionType) => new Subscription(sub)
        );

        const misSubscription = !!subscriptions.find(
          s => isManaged(s) && isLive(s)
        );
        const b2cSubscription = !!subscriptions.find(
          s => hasStripeSubscription(s) && isLive(s)
        );

        return userTypeReducer(
          state,
          updateUserTypeBasedOnPremiumStatus({
            misSubscription,
            b2cSubscription
          })
        );

      default:
        return userTypeReducer(state, action);
    }
  };
}
