import { Action, GetState } from "common/types";

import { AWAIT_CONDITION } from "./actions";
import { PendingCondition } from "./types";

export function awaitConditionMiddleware({ getState }: { getState: GetState }) {
  let pending: PendingCondition[] = [];

  function checkPending() {
    const state = getState();

    pending = pending.reduce((newPending, item) => {
      const { condition, promise } = item;

      if (condition(state)) {
        promise.resolve();
      } else {
        newPending.push(item);
      }

      return newPending;
    }, [] as PendingCondition[]);
  }

  return (next: any) => (action: Action<any, string>) => {
    if (action.type === AWAIT_CONDITION) {
      pending.push(action.payload);
      checkPending();
    } else {
      const result = next(action);
      checkPending();
      return result;
    }
  };
}
