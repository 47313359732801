export const LANGUAGE_CODES = Object.freeze({
  EN: "en",
  ES: "es",
  ES_CO: "es-CO",
  ES_MX: "es-MX",
  FR: "fr",
  PT_BR: "pt-BR",
  VI: "vi",
  DE: "de",
  AT: "de-AT",
  IT: "it"
});

export type LanguageCode = keyof typeof LANGUAGE_CODES;
export type LanguageCodeLower = (typeof LANGUAGE_CODES)[LanguageCode];

export const LANGUAGE_NAMES: {
  [key in LanguageCode]: string;
} = {
  EN: "English",
  ES: "Spanish",
  ES_CO: "Spanish (Colombia)",
  ES_MX: "Spanish (Mexico)",
  FR: "French",
  PT_BR: "Portuguese (Brazil)",
  VI: "Vietnamese",
  DE: "German",
  AT: "German (Austria)",
  IT: "Italian"
};
