import { useSelector } from "react-redux";

import { SelectorFactory } from "./types";
import useMakeSelector from "./useMakeSelector";

/**
Used to call make and call a memoised selector given condition is true: 

e.g. 
const sectionIds = useMemoSelectorOnCondition(courseFetched, makeGetCourseSectionIds, courseId)
 */

export default function useMemoSelectorOnCondition<
  ArgumentsT extends ReadonlyArray<any>,
  ReturnT
>(
  condition: any,
  makeSelector: SelectorFactory<ArgumentsT, ReturnT>,
  ...dependencies: ArgumentsT
): ReturnT | null {
  const selector = useMakeSelector(makeSelector, ...dependencies);
  return useSelector(s => (condition ? selector(s) : null));
}
