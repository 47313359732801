import {
  makeLinkToPasswordReset,
  makeLinkToSignIn,
  makeLinkToSignUp,
  Options as SignUpLinkOptions
} from "features/sign-up/global-sign-up-modal/links";

import { linkToCourseHomepage } from "./links";

// Sign Up
export function linkToCourseHomePageSignUp(
  courseId?: string,
  options?: SignUpLinkOptions
) {
  return makeLinkToSignUp(linkToCourseHomepage(courseId), options);
}

export function linkToCourseHomePageSignIn(
  courseId?: string,
  options?: SignUpLinkOptions
) {
  return makeLinkToSignIn(linkToCourseHomepage(courseId), options);
}

export function linkToCourseHomePagePasswordReset(
  courseId?: string,
  options?: SignUpLinkOptions
) {
  return makeLinkToPasswordReset(linkToCourseHomepage(courseId), options);
}
