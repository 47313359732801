import { themes } from "@seneca/senekit";

import {
  PREFERRED_THEME_MODE_KEY,
  PREFERS_DARK_MODE,
  ThemeModePreference
} from "./consts";

export const getSplashScreenBackgroundColor = () => {
  const SPLASH_SCREEN_LIGHT_BG = themes.free.colors.light["background-base"];
  const SPLASH_SCREEN_DARK_BG = themes.free.colors.dark["background-base"];

  switch (getThemeModePreferenceFromLocalStorage()) {
    case ThemeModePreference.LIGHT:
      return SPLASH_SCREEN_LIGHT_BG;
    case ThemeModePreference.DARK:
      return SPLASH_SCREEN_DARK_BG;
    case ThemeModePreference.SYSTEM:
      return USER_AGENT_PREFERENCE === ThemeModePreference.DARK
        ? SPLASH_SCREEN_DARK_BG
        : SPLASH_SCREEN_LIGHT_BG;
  }
};

const USER_AGENT_PREFERENCE = window.matchMedia(PREFERS_DARK_MODE).matches
  ? ThemeModePreference.DARK
  : ThemeModePreference.LIGHT;

export const getThemeModePreferenceFromLocalStorage = () => {
  try {
    const preferredThemeMode = localStorage.getItem(
      PREFERRED_THEME_MODE_KEY
    ) as ThemeModePreference;
    return preferredThemeMode ?? ThemeModePreference.SYSTEM;
  } catch (err) {
    return ThemeModePreference.SYSTEM;
  }
};

export const setThemeModePreferenceInLocalStorage = (
  preference: ThemeModePreference
) => {
  try {
    localStorage.setItem(PREFERRED_THEME_MODE_KEY, `${preference}`);
  } catch (err) {}
};

export const themeModePreferenceSetInLocalStorage = () => {
  try {
    const preferredThemeMode = localStorage.getItem(PREFERRED_THEME_MODE_KEY);
    return preferredThemeMode !== null;
  } catch (err) {
    return false;
  }
};
