const DATA_LAYER_SCOPES = Object.freeze({
  USER: "user",
  SCHOOL: "school",
  CLASSROOM: "classroom",
  SESSION: "session",
  TEACHER: "teacher",
  TEACHER_CLASS: "teacherClass",
  SCHOOL_ANALYTICS: "schoolAnalytics",
  TEACHER_ASSIGNMENT: "teacherAssignment",
  TEACHER_COURSE_DISCOVERY: "teacherCourseDiscovery",
  SUBSCRIPTION: "subscription",
  STUDENT_STATS: "studentStats",
  COURSE_DISCOVERY: "courseDiscovery",
  WRONG_ANSWERS_TRIAL: "wrongAnswersTrial",
  PARENT: "parent",
  SETTINGS: "settings",
  WHAT_IS_PREMIUM: "whatIsPremium",
  NOTIFICATIONS: "notifications"
});

export default DATA_LAYER_SCOPES;

export type DataLayerScope =
  (typeof DATA_LAYER_SCOPES)[keyof typeof DATA_LAYER_SCOPES];
