// Add categories of topbar here
type TopbarType = "syncExpiry" | "promptToUseAppBanner";

export type TopbarVisibilityState = {
  [key in TopbarType]: boolean;
};

export enum TopbarActionType {
  UPDATE_TOPBAR_VISIBILITY = "UPDATE_TOPBAR_VISIBILITY"
}

export type TopbarAction = {
  type: TopbarActionType;
  payload: {
    show: boolean;
    type: TopbarType;
  };
};

export function topbarReducer(
  state: TopbarVisibilityState,
  action: TopbarAction
) {
  const { type, payload } = action;

  switch (type) {
    case TopbarActionType.UPDATE_TOPBAR_VISIBILITY: {
      const { type, show } = payload;
      return {
        ...state,
        [type]: show
      };
    }
    default:
      return state;
  }
}

export const initialTopbarState: TopbarVisibilityState = {
  syncExpiry: true,
  promptToUseAppBanner: false
};
