import { Record } from "immutable";

export type PWAStateType = {
  configReady: boolean;
  newVersionAvailable: boolean;
  updating: boolean;
  promptHidden: boolean;
  promptDismissed: boolean;
  warningDismissed: boolean;
  lastSeenPrompt: null | number;
  lastSeenReady: boolean;
  canShowPrompt: boolean;
  canShowWarning: boolean;
  shouldForceUpdate: boolean;
  showingWarning: boolean;
  toastVisibilityTimeoutMS: number;
};

export default class PWAState extends Record({
  configReady: false,
  newVersionAvailable: false,
  updating: false,
  promptHidden: false,
  promptDismissed: false,
  warningDismissed: false,
  lastSeenPrompt: null,
  lastSeenReady: false,
  canShowPrompt: false,
  canShowWarning: false,
  shouldForceUpdate: false,
  showingWarning: false,
  toastVisibilityTimeoutMS: 0
}) {}
