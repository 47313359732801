import logError from "seneca-common/utils/sentry/logError";

export const LOCAL_STORAGE_KEY = "lastSkippedCompleteTeacherAccountModal";

const SECONDS_IN_A_DAY = 86400;
const SET_ROLE_MODAL_TIMEOUT = 1000 * SECONDS_IN_A_DAY;

export function setHasSkippedCompleteTeacherAccount(now: number) {
  try {
    window.localStorage &&
      window.localStorage.setItem(LOCAL_STORAGE_KEY, now as unknown as string);
  } catch (err: any) {
    logError(err, {
      message: "Could not set hasSkippedCompleteTeacherAccount on localStorage"
    });
  }
}

export function clearHasSkippedCompleteTeacherAccount() {
  try {
    window.localStorage && window.localStorage.removeItem(LOCAL_STORAGE_KEY);
  } catch (err: any) {
    logError(err, {
      message:
        "Could not remove hasSkippedCompleteTeacherAccount from localStorage"
    });
  }
}

export function getLastSkippedCompleteTeacherAccount(): boolean {
  try {
    return (window.localStorage &&
      window.localStorage.getItem(LOCAL_STORAGE_KEY)) as unknown as boolean;
  } catch (err: any) {
    logError(err, {
      message:
        "Could not get hasSkippedCompleteTeacherAccount from localStorage"
    });
  }

  return false;
}

export function getHasSkippedCompleteTeacherAccountModalRecently(): boolean {
  try {
    const lastSkipped =
      window.localStorage && window.localStorage.getItem(LOCAL_STORAGE_KEY);
    return (lastSkipped &&
      Date.now() - Number(lastSkipped) <
        SET_ROLE_MODAL_TIMEOUT) as unknown as boolean;
  } catch (err: any) {
    logError(err, {
      message:
        "Could not get lastSkippedCompleteTeacherAccount from localStorage"
    });
  }

  return false;
}
