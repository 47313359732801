export default class Navigator<BackT = void, RootT = void> {
  a: this;
  are: this;
  and: this;
  but: this;
  code: this;
  for: this;
  has: this;
  is: this;
  provide: this;
  theResponse: this;
  that: this;
  the: this;
  with: this;

  back: BackT extends void ? this : BackT;
  also: BackT extends void ? this : BackT;
  then: BackT extends void ? this : BackT;

  root: RootT extends void ? this : RootT;
  finally: RootT extends void ? this : RootT;

  constructor(back?: BackT, root?: RootT) {
    this.back = (back || this) as any;
    this.also = this.back;
    this.then = this.back;

    this.root = (root || this) as any;
    this.finally = this.root;

    this.a = this;
    this.are = this;
    this.and = this;
    this.but = this;
    this.code = this;
    this.for = this;
    this.has = this;
    this.is = this;
    this.provide = this;
    this.theResponse = this;
    this.that = this;
    this.the = this;
    this.with = this;
  }
}
