const actionTypes = {
  OPEN_SIDEBAR: "seneca/classroom/OPEN_SIDEBAR",
  CLOSE_SIDEBAR: "seneca/classroom/CLOSE_SIDEBAR",
  EXPAND_ASSIGNMENT: "seneca/classroom/EXPAND_ASSIGNMENT",
  COLLAPSE_ASSIGNMENT: "seneca/classroom/COLLAPSE_ASSIGNMENT",
  EXPAND_SECTION: "seneca/classroom/EXPAND_SECTION",
  EXPAND_SECTIONS: "seneca/classroom/EXPAND_SECTIONS",
  COLLAPSE_SECTION: "seneca/classroom/COLLAPSE_SECTION",
  SET_ACTIVE_TAB: "seneca/classroom/SET_ACTIVE_TAB"
};

export default actionTypes;
