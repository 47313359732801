import { combineReducers } from "redux-immutable";

import SubscriptionsStateSlice from "../models/SubscriptionsStateSlice";
import subscriptionsMetaReducer from "./state/meta";
import payment from "./state/payment";
import subscriptions from "./state/subscriptions";

const subscriptionsReducer = combineReducers(
  {
    subscriptions,
    payment,
    meta: subscriptionsMetaReducer
  },
  () => new SubscriptionsStateSlice() as any
);

export default subscriptionsReducer;
