import { Client } from "@seneca/client-library";

import { ClassLeaderboard } from "./types";

export default class ClassLeaderboardsServiceClient extends Client {
  async fetchLeaderboardForStudent(classId: string) {
    const url = `${this.url}/api/class-leaderboards/student?classId=${classId}`;
    const expectedResponses = [200];

    const response = await this.requestMaker.makeRequest(
      url,
      { method: "GET" },
      expectedResponses
    );

    return response.json as ClassLeaderboard;
  }
}
