import { Record } from "immutable";

import { SchoolEntityTypes } from "@seneca/schools-service-types";

export type ResetPasswordsStateType = {
  success?: boolean | null;
  userIds: string[];
  userType: SchoolEntityTypes;
  schoolId: string;
  externalSchoolId?: string;
  newPassword: string;
};

export const defaultResetPasswordsValues: ResetPasswordsStateType = {
  userIds: [],
  userType: SchoolEntityTypes.Student,
  schoolId: "",
  success: null,
  newPassword: ""
};

export default class ResetPasswordsState extends Record(
  defaultResetPasswordsValues
) {}
