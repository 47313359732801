import { Action } from "common/types";

import propsToJS from "seneca-common/utils/functions/immutable/props-to-js";
import logError from "seneca-common/utils/sentry/logError";

import {
  clearActionPayloadFromDataLayer,
  pushEventToDataLayer,
  pushLastActionPayloadToDataLayer
} from "../gtm";
import analyticsRegistry from "./AnalyticsRegistry";

export const createAnalytics =
  (store: any) => (next: any) => (action: Action<any>) => {
    next(action);

    try {
      const eventGenerator = analyticsRegistry.getEventGenerator(action.type);

      if (!eventGenerator) {
        return;
      }

      const jsPayload = action.payload && propsToJS(action.payload);
      pushLastActionPayloadToDataLayer(jsPayload);

      pushEventToDataLayer({
        event: eventGenerator.eventName || action.type
      });

      // Clear up the data layer after the event has been published so it doesn't get picked up
      // accidentally by later events/tags.
      //
      // It does not matter that this happens synchronously after we pushEventToDataLayer
      // because GTM will observe the state of the data layer when the event was added to the
      // processing queue.
      clearActionPayloadFromDataLayer(action.payload);
    } catch (error: any) {
      logError(error, {
        message: "Error sending analytics event via redux middleware",
        fingerprint: ["Analytics", "redux middleware"]
      });
    }
  };
