import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { UpdateReason } from "../reasons";

type UpdateAppModalStateType = {
  reason: string | null;
};

const initialState: UpdateAppModalStateType = {
  reason: null
};

const updateAppModalSlice = createSlice({
  name: "updateAppModal",
  initialState,
  reducers: {
    showUpdateAppModal(state, action: PayloadAction<UpdateReason>) {
      state.reason = action.payload;
    },
    resetUpdateAppModal(state) {
      state.reason = null;
    }
  }
});

export const getUpdateAppModalState = (state: any) =>
  state.get(updateAppModalSlice.name) as UpdateAppModalStateType;

export const { showUpdateAppModal, resetUpdateAppModal } =
  updateAppModalSlice.actions;

export default updateAppModalSlice;
