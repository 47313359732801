import {
  pushGlobalSelectorOutputsToDataLayer,
  pushUserPropertiesSelectorOutputsToDataLayer
} from "./pushValuesToDataLayer";
import {
  EventGenerators,
  RegisteredSelectorsT,
  SelectorDetails,
  SelectorsDetails,
  Store
} from "./types";

type EventGeneratorArgs = {
  triggerReduxActionType: string;
  gtmEventName?: string; // if the event name should be different from the action name
};

/**

TODO: add documentation

 */
export class AnalyticsRegistry {
  store: Store | undefined;

  eventGenerators: EventGenerators = {};
  selectors: SelectorsDetails = [];
  userPropertiesSelectors: SelectorsDetails = [];

  _selectorOutputCache = {};
  _userPropertiesOutputCache = {};

  registerStore(store: Store) {
    this.store = store;

    store.subscribe(this._runSelectorsAndUpdateDataLayer.bind(this));
    store.subscribe(
      this._runUserPropertiesSelectorsAndUpdateDataLayer.bind(this)
    );
  }

  /**
   * This method can be used to register a group of selectors whos output will be put into
   * a scope in the data layer. This basically allows us to add a bit of structure to the
   * data layer so it's not just one big flat object.
   *
   * E.g.
   * const GTMDataLayer = {
   *    [dataLayerScope (e.g. user)]: {
   *       ...output of your selectors will go here
   *    }
   * }
   */
  registerGlobalSelectorsToDataLayerScope(
    dataLayerScope: string,
    selectors: RegisteredSelectorsT
  ) {
    Object.keys(selectors).forEach(dataLayerValueName => {
      this.registerGlobalSelectorToUpdateDataLayer({
        dataLayerValueName,
        dataLayerScope,
        selector: selectors[dataLayerValueName]
      });
    });
  }

  registerUserPropertiesSelectorsToDataLayerScope(
    selectors: RegisteredSelectorsT
  ) {
    Object.keys(selectors).forEach(dataLayerValueName => {
      this.registerUserPropertySelectorToUpdateDataLayer({
        dataLayerValueName,
        selector: selectors[dataLayerValueName]
      });
    });
  }

  /**
   * This method is used to register 'global' selectors i.e. selectors that return information
   * that is relevant globally across the app (e.g. userEmail or userTotalStudyTime). This is
   * not to be used for selectors that return information that is only relevant on a given page
   * (e.g. courseName in the classroom), it is the responsibility of the page in question to add
   * that information to the dataLayer via other means when it is relevant and remove it when it
   * is no longer relevant (i.e. probably on unmount).
   *
   * Based on that the selectors here can only take state as an argument. If you need to pass another
   * argument chances are that selector is not 'global'. E.g. in the classroom you'd get the `courseId`
   * value from the URL which changes as you navigate around the app.
   */
  registerGlobalSelectorToUpdateDataLayer({
    dataLayerValueName,
    dataLayerScope,
    selector
  }: SelectorDetails) {
    const selectorAlreadyBeenRegisteredForSameValueNameAndScope =
      this.selectors.filter(
        s =>
          s.dataLayerScope === dataLayerScope &&
          s.dataLayerValueName === dataLayerValueName
      ).length > 0;

    if (selectorAlreadyBeenRegisteredForSameValueNameAndScope) {
      throw new Error(
        `A selector has already been registered at path ${
          dataLayerScope ? dataLayerScope + "." : ""
        }${dataLayerValueName}, please pick a new variable name or set the value in a new scope.`
      );
    }

    this.selectors.push({
      dataLayerValueName,
      dataLayerScope,
      selector
    });

    this._runSelectorsAndUpdateDataLayer();
  }

  registerUserPropertySelectorToUpdateDataLayer({
    dataLayerValueName,
    selector
  }: SelectorDetails) {
    const selectorAlreadyBeenRegisteredForSameValueNameAndScope =
      this.userPropertiesSelectors.filter(
        s => s.dataLayerValueName === dataLayerValueName
      ).length > 0;

    if (selectorAlreadyBeenRegisteredForSameValueNameAndScope) {
      throw new Error(
        `A selector has already been registered for the user property ${dataLayerValueName}.`
      );
    }

    this.userPropertiesSelectors.push({
      dataLayerValueName,
      selector
    });

    this._runUserPropertiesSelectorsAndUpdateDataLayer();
  }

  getEventGenerator(actionType: string) {
    return this.eventGenerators[actionType];
  }

  registerEventGenerator({
    triggerReduxActionType,
    gtmEventName
  }: EventGeneratorArgs) {
    if (this.eventGenerators[triggerReduxActionType]) {
      throw new Error(
        `An event generator has already been registered for event: ${triggerReduxActionType} with ${JSON.stringify(
          this.eventGenerators[triggerReduxActionType]
        )}`
      );
    }

    this.eventGenerators[triggerReduxActionType] = {
      eventName: gtmEventName
    };
  }

  registerEventGenerators(
    triggerReduxActionTypes: (
      | string
      | {
          triggerReduxActionType: string;
          gtmEventName: string;
        }
    )[]
  ) {
    triggerReduxActionTypes.forEach(actionDetails => {
      if (typeof actionDetails === "string") {
        this.registerEventGenerator({
          triggerReduxActionType: actionDetails
        });
      } else {
        this.registerEventGenerator({
          triggerReduxActionType: actionDetails.triggerReduxActionType,
          gtmEventName: actionDetails.gtmEventName
        });
      }
    });
  }

  _runSelectorsAndUpdateDataLayer() {
    if (this.store) {
      pushGlobalSelectorOutputsToDataLayer(
        this.selectors,
        this._selectorOutputCache
      )(this.store.getState());
    }
  }

  _runUserPropertiesSelectorsAndUpdateDataLayer() {
    if (this.store) {
      pushUserPropertiesSelectorOutputsToDataLayer(
        this.userPropertiesSelectors,
        this._userPropertiesOutputCache
      )(this.store.getState());
    }
  }
}

const analyticsRegistry = new AnalyticsRegistry();

export default analyticsRegistry;
