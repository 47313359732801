import { Record } from "immutable";

import { ActionTypes, JsToImmutableTransform } from "../types";
import ErrorTransformingObjectToImmutableError from "./errors/ErrorTransformingObjectToImmutableError";

export default function makeRecordReducer<JS, I extends Record<any>>(
  actionTypes: ActionTypes,
  jsToImmutableTransform: JsToImmutableTransform<JS, I>,
  RecordBuilder: I
) {
  const transformItem = (item: JS): I => {
    try {
      return jsToImmutableTransform(item);
    } catch (err: any) {
      throw new ErrorTransformingObjectToImmutableError(err, item);
    }
  };

  return (
    // @ts-ignore
    stored: I = new RecordBuilder(),
    action: { [key: string]: any } = {}
  ) => {
    // @ts-ignore
    switch (action!.type) {
      case actionTypes.RECEIVE_ONE:
        // @ts-ignore
        const item = action.payload;
        return transformItem(item);

      case actionTypes.UPDATE:
        // @ts-ignore
        return stored.mergeDeep(action.payload);

      default:
        return stored;
    }
  };
}
