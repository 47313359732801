import * as Sentry from "@sentry/react";
import { Integration } from "@sentry/types";
import getConfig from "config";
import { History } from "history";

import { MAX_NUMBER_OF_BREADCRUMBS } from "../../utils/sentry/consts";
import { applyCustomSampling } from "./applyCustomSampling";
import { getBrowserTracingIntegration } from "./performance/browserTracing/browserTracingIntegration";
import { customTracesSampler } from "./performance/browserTracing/tracesSampler";
import { customTracingConfig } from "./performance/customTracing/customTracingConfig";

let sentryConfig: Sentry.BrowserOptions;

export default function getSentryConfig(history?: History) {
  if (!sentryConfig) {
    const config = getConfig();

    const devEnv = "development";
    const dsn = process.env.NODE_ENV === devEnv ? "" : config.sentry.dsn;

    const integrations: Integration[] = [
      new Sentry.Integrations.Breadcrumbs({ console: false })
    ];

    if (!!history) {
      const browserTracingIntegration = getBrowserTracingIntegration(history);
      integrations.push(browserTracingIntegration);
    }

    // https://docs.sentry.io/clients/javascript/tips/#decluttering-sentry
    // https://github.com/getsentry/sentry-javascript/issues/1631
    sentryConfig = {
      dsn,
      environment: config.sentry.environment,
      release: `seneca-web@${process.env.REACT_APP_VERSION}`,
      maxBreadcrumbs: MAX_NUMBER_OF_BREADCRUMBS,
      ignoreErrors: [
        // Random plugins/extensions
        "top.GLOBALS",
        // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error. html
        "originalCreateNotification",
        "canvas.contentDocument",
        "MyApp_RemoveAllHighlights",
        "http://tt.epicplay.com",
        "Can't find variable: ZiteReader",
        "jigsaw is not defined",
        "ComboSearch is not defined",
        "http://loading.retry.widdit.com/",
        "atomicFindClose",
        // Facebook borked
        "fb_xd_fragment",
        // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
        // reduce this. (thanks @acdha)
        // See http://stackoverflow.com/questions/4113268
        "bmi_SafeAddOnload",
        "EBCallBackMessageReceived",
        // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
        "conduitPage",
        // Error generated by a bug in auto-fill library from browser
        // https://github.com/getsentry/sentry/issues/5267
        /Blocked a frame with origin/,
        "ResizeObserver loop limit exceeded" // See https://stackoverflow.com/a/50387233/7680360  &  https://github.com/WICG/ResizeObserver/issues/38#issuecomment-616445073
      ],
      denyUrls: [
        // Facebook flakiness
        /graph\.facebook\.com/i,
        // Facebook blocked
        /connect\.facebook\.net\/en_US\/all\.js/i,
        // Woopra flakiness
        /eatdifferent\.com\.woopra-ns\.com/i,
        /static\.woopra\.com\/js\/woopra\.js/i,
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        // Other plugins
        /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
        /webappstoolbarba\.texthelp\.com\//i,
        /metrics\.itunes\.apple\.com\.edgesuite\.net\//i
      ],
      beforeSend: (event, hint) => applyCustomSampling(event, hint),
      integrations,
      tracesSampler: samplingContext => {
        const transactionName = samplingContext?.transactionContext?.name;
        if (config.sentry?.tracesSampleRate > 0) {
          if (customTracingConfig[transactionName]) {
            return customTracingConfig[transactionName].sampleRate;
          }
          return customTracesSampler(samplingContext.transactionContext);
        }
        return false;
      }
    };
  }
  return sentryConfig;
}
