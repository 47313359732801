import { combineReducers } from "redux-immutable";

import { AllowedCallTypes, CrudConfig } from "../../common/const";
import { processCallType } from "../../common/utils";
import makeCreateStateReducer from "../../createStates/reducers/createState";
import makeDeleteStateReducer from "../../deleteStates/reducers/deleteState";
import makeFetchAllStateReducer from "../../fetchAllStates/reducers/fetchAllState";
import makeFetchStateReducer from "../../fetchStates/reducers/fetchState";
import makeUpdateStateReducer from "../../updateStates/reducers/updateState";
import { consumerRequiresCallType } from "../utils";

const makeCrudStateReducer = (config: CrudConfig) => {
  const { callTypes, ...reducerConfig } = config;

  let returnReducerObject = {};

  const { FETCH_ALL, FETCH, CREATE, UPDATE, DELETE } = AllowedCallTypes;

  addCallTypeReducerIfNecessary(FETCH_ALL, makeFetchAllStateReducer);
  addCallTypeReducerIfNecessary(FETCH, makeFetchStateReducer);
  addCallTypeReducerIfNecessary(CREATE, makeCreateStateReducer);
  addCallTypeReducerIfNecessary(UPDATE, makeUpdateStateReducer);
  addCallTypeReducerIfNecessary(DELETE, makeDeleteStateReducer);

  function addCallTypeReducerIfNecessary(
    callType: AllowedCallTypes,
    reducerBuilder: (arg0?: any) => any
  ) {
    if (consumerRequiresCallType(callType, config)) {
      const reducer = reducerBuilder(reducerConfig);
      const { callTypesLowerCase: stateKey } = processCallType(callType);

      returnReducerObject = { ...returnReducerObject, [stateKey]: reducer };
    }
  }

  return combineReducers(returnReducerObject);
};

export default makeCrudStateReducer;
