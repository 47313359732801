import { useSelector } from "react-redux";

import { SelectorFactory } from "./types";
import useMakeSelector from "./useMakeSelector";

/**
Used to call make and call a memoised selector: 

e.g. 
const sectionIds = useMemoSelector(makeGetCourseSectionIds, courseId)
 */

export default function useMemoSelector<
  ArgumentsT extends ReadonlyArray<any>,
  ReturnT
>(
  makeSelector: SelectorFactory<ArgumentsT, ReturnT>,
  ...dependencies: ArgumentsT
): ReturnT {
  const selector = useMakeSelector(makeSelector, ...dependencies);
  return useSelector(selector);
}
