import { getSubscriptionsStateSlice } from "seneca-common/features/subscriptions/state/selectors/index";
import { getMetaSlice } from "seneca-common/features/subscriptions/state/selectors/state/index";
import * as meta from "seneca-common/features/subscriptions/state/selectors/state/subscriptions/meta/index";
import { composeSelector } from "seneca-common/utils/selectors/compose-selectors";

type State = any;

function getMetaState(state: State): any {
  return getMetaSlice(getSubscriptionsStateSlice(state));
}

// fetch
export const subscriptionsAreFetching: (state: State) => boolean =
  composeSelector(getMetaState, meta.subscriptionsAreFetching);

export const subscriptionsFetchSucceeded: (state: State) => boolean =
  composeSelector(getMetaState, meta.subscriptionsFetchSucceeded);

export const subscriptionsFetchErrored: (state: State) => boolean =
  composeSelector(getMetaState, meta.subscriptionsFetchErrored);

export const subscriptionsFetchFinished: (state: State) => boolean =
  composeSelector(getMetaState, meta.subscriptionsFetchFinished);

export const subscriptionsNeedsFetching: (state: State) => boolean =
  composeSelector(getMetaState, meta.subscriptionsNeedsFetching);

export const subscriptionIsFetching: (state: State, id: string) => boolean =
  composeSelector(getMetaState, meta.subscriptionIsFetching);

export const subscriptionFetchSucceeded: (state: State, id: string) => boolean =
  composeSelector(getMetaState, meta.subscriptionFetchSucceeded);

export const subscriptionFetchErrored: (state: State, id: string) => boolean =
  composeSelector(getMetaState, meta.subscriptionFetchErrored);

export const subscriptionFetchFinished: (state: State, id: string) => boolean =
  composeSelector(getMetaState, meta.subscriptionFetchFinished);

// update
export const subscriptionIsUpdating: (state: State, id: string) => boolean =
  composeSelector(getMetaState, meta.subscriptionIsUpdating);

export const subscriptionUpdateSucceeded: (
  state: State,
  id: string
) => boolean = composeSelector(getMetaState, meta.subscriptionUpdateSucceeded);

export const subscriptionUpdateErrored: (state: State, id: string) => boolean =
  composeSelector(getMetaState, meta.subscriptionUpdateErrored);

// @ts-ignore
export const subscriptionUpdateErrorMessage: (
  state: State,
  id: string
) => string = composeSelector(
  getMetaState,
  meta.subscriptionUpdateErrorMessage
);

export const subscriptionUpdateFinished: (state: State, id: string) => boolean =
  composeSelector(getMetaState, meta.subscriptionUpdateFinished);

// create
export const subscriptionIsBeingCreated: (state: State, id: string) => boolean =
  composeSelector(getMetaState, meta.subscriptionIsBeingCreated);

export const createSubscriptionSucceeded: (
  state: State,
  id: string
) => boolean = composeSelector(getMetaState, meta.createSubscriptionSucceeded);

export const createSubscriptionErrored: (state: State, id: string) => boolean =
  composeSelector(getMetaState, meta.createSubscriptionErrored);

export const createSubscriptionFinished: (state: State, id: string) => boolean =
  composeSelector(getMetaState, meta.createSubscriptionFinished);

// @ts-ignore
export const createSubscriptionErrorMessage: (
  state: State,
  id: string
) => string = composeSelector(
  getMetaState,
  meta.createSubscriptionErrorMessage
);
