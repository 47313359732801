import { ModuleType as ModuleNames } from "seneca-common/features/module/module-types/types";

import ModuleStaticInformation from "../models/ModuleStaticInformation";
import ModuleBuilderNavigator from "./utils/ModuleBuilderNavigator";

export default class ModuleStaticInformationBuilder<
  BackT,
  RootT
> extends ModuleBuilderNavigator<BackT, RootT> {
  _moduleName: ModuleNames;

  _displayName: string | null | undefined;
  _testOnly: boolean;
  _readOnly: boolean;
  _supportsAmeliaExplainMyAnswer: boolean;

  constructor(moduleName: ModuleNames, back: any, root: any) {
    super(
      {
        moduleName,
        builderName: "ModuleStaticInformationBuilder"
      },
      back,
      root
    );
    this._moduleName = moduleName;
    this._displayName = null;
    this._testOnly = false;
    this._readOnly = false;
    this._supportsAmeliaExplainMyAnswer = false;
  }

  displayName(displayName: string) {
    this._displayName = displayName;
    return this;
  }

  testOnly() {
    if (this._readOnly) {
      throw new Error(
        `You cannot set ${this._moduleName} to be testOnly, it's already set as readOnly`
      );
    }

    this._testOnly = true;
    return this;
  }

  readOnly() {
    if (this._testOnly) {
      throw new Error(
        `You cannot set ${this._moduleName} to be readOnly, it's already set as testOnly`
      );
    }

    this._readOnly = true;
    return this;
  }

  supportsAmeliaExplainMyAnswer() {
    this._supportsAmeliaExplainMyAnswer = true;
    return this;
  }

  build(): ModuleStaticInformation {
    !this._displayName && this.throwMissingAttributeError("displayName");

    return new ModuleStaticInformation(this._moduleName, {
      displayName: this._displayName!,
      testOnly: this._testOnly,
      readOnly: this._readOnly,
      supportsAmeliaExplainMyAnswer: this._supportsAmeliaExplainMyAnswer
    });
  }
}
