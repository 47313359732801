import { Action, Reducer } from "common/types/redux";

import {
  receiveSubscriptionProducts,
  SubscriptionProductsStateSlice
} from "seneca-common/features/subscriptions/features/subscription-products/state/index";
import actionTypes from "seneca-common/features/subscriptions/state/action-types";
import {
  ReceivedSubscriptionProductType,
  ReceivedSubscriptionType
} from "seneca-common/features/subscriptions/state/types";

export default function subscriptionProductsReducerWrapper(
  subscriptionProductsReducer: Reducer<SubscriptionProductsStateSlice>
) {
  // @ts-ignore
  return (state?: SubscriptionProductsStateSlice, action: Action<any>) => {
    switch (action.type) {
      case actionTypes.RECEIVE_SUBSCRIPTIONS: {
        const receivedSubscriptions = action.payload;

        const receivedProducts = receivedSubscriptions.reduce(
          (
            acc: ReceivedSubscriptionProductType[],
            sub: ReceivedSubscriptionType
          ) => {
            const upgradeProducts =
              sub.updateOnPaymentSuccess?.subscriptionProducts;

            if (upgradeProducts) {
              acc.push(...upgradeProducts);
            }

            return [...acc, ...sub.subscriptionProducts];
          },
          []
        );

        return subscriptionProductsReducer(
          state,
          receiveSubscriptionProducts(receivedProducts)
        );
      }

      default:
        return subscriptionProductsReducer(state, action);
    }
  };
}
