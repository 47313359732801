import { allValuesAreTheSame } from "../../allValuesAreTheSame";
import { isEqual } from "../../isEqual";
import shuffle from "./shuffle";

export const shuffleAndAvoidOriginalOrder = <T>(values: T[]): T[] => {
  if (allValuesAreTheSame(values)) {
    return values;
  }

  let newValues = [...values];

  while (isEqual(newValues, values)) {
    newValues = shuffle(newValues);
  }

  return newValues;
};
