import { Map } from "immutable";

import { isIn } from "seneca-common/utils/functions/index";

import actionTypes from "../action-types";
import sessionMessage from "./sessionMessage";

const sessionMessages = (sessionMessages = Map(), action: any) => {
  switch (action.type) {
    case actionTypes.NEW_SESSION_MESSAGE:
      return sessionMessages.setIn(
        [action.payload.sessionId, action.payload.sessionMessageId],
        action.payload.sessionMessage
      );

    default: {
      if (isIn(action.type, actionTypes)) {
        return sessionMessages.updateIn(
          [action.payload.sessionId, action.payload.sessionMessageId],
          // @ts-ignore
          module => sessionMessage(module, action)
        );
      } else {
        return sessionMessages;
      }
    }
  }
};

export default sessionMessages;
