import { ActionTypes, FeatureName } from "../types";

export default function makeActionsTypes(
  featureName: FeatureName
): ActionTypes {
  return {
    RECEIVE_ONE: `seneca/${featureName}/RECEIVE_ONE`,
    RECEIVE_MANY: `seneca/${featureName}/RECEIVE_MANY`,
    UPDATE: `seneca/${featureName}/UPDATE`,
    DELETE: `seneca/${featureName}/DELETE`
  };
}
