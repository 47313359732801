import { CustomerIdDoesNotExistForUserInDBError } from "@seneca/subscriptions-service-client";

import makeErrorHandler from "services/subscriptionService/errorHandler";

import {
  updateSubscription,
  updateSubscriptionActionFactory
} from "seneca-common/features/subscriptions/state";
import MissingArgumentError from "seneca-common/utils/errors/MissingArgumentError";

interface SubscriptionsClient {
  readonly updatePaymentMethodById: (
    subscriptionId: string,
    options: {
      stripePaymentMethodId: string;
      cardHolderEmail?: string;
    }
  ) => Promise<any>;
}

function errorTranslator(error: Error) {
  if (error instanceof CustomerIdDoesNotExistForUserInDBError) {
    return "You cannot update payment details if you didn't purchase a personal subscription";
  }

  return "Unknown error occurred";
}

// For testing purposes we're able to inject a mock client
const makeUpdatePaymentMethodFactory = (
  getClient: () => SubscriptionsClient
) => {
  return updateSubscriptionActionFactory({
    requestFunction: (id, options) =>
      getClient().updatePaymentMethodById(id, options),
    storeAction: (subscription: any) => {
      return updateSubscription(subscription);
    },
    errorHandler: makeErrorHandler(errorTranslator)
  });
};

export const makeUpdatePaymentMethod = (
  getClient: () => SubscriptionsClient
) => {
  const updatePaymentMethod = makeUpdatePaymentMethodFactory(getClient);

  return (
    subscriptionId: string,
    stripePaymentMethodId: string,
    cardHolderEmail?: string
  ) => {
    if (!stripePaymentMethodId) {
      throw new MissingArgumentError("stripePaymentMethodId");
    }
    if (!subscriptionId) {
      throw new MissingArgumentError("subscriptionId");
    }

    // @ts-ignore
    return updatePaymentMethod(
      [
        subscriptionId,
        {
          paymentMethodId: stripePaymentMethodId,
          cardHolderEmail
        }
      ],
      subscriptionId
    );
  };
};
