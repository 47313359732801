import { lazy } from "react";

import loaderWithRetry from "seneca-common/utils/loaderWithRetry";

import { useFeatureFlag } from "features/feature-flags";

const DeveloperCommandMenu = lazy(() =>
  loaderWithRetry(
    () =>
      import(
        /* webpackChunkName: "developer-command-menu" */ "features/command-menu/developer/DeveloperCommandMenu"
      )
  )
);

export function CommandMenu() {
  const enableCommandMenu = useFeatureFlag("command-menu");
  if (!enableCommandMenu) {
    return null;
  }
  return <DeveloperCommandMenu />;
}
