import { Record } from "immutable";

export type UiStateType = {
  modalsOpenCount: number;
  mobileKeyboardOpen: boolean;
  smartLearningFreeTrialActive: boolean;
  showPlurallHelpCentreModal: boolean;
};

export default class UiState extends Record({
  modalsOpenCount: 0,
  mobileKeyboardOpen: false,
  smartLearningFreeTrialActive: false,
  showPlurallHelpCentreModal: false
}) {}
