import { makeCrudStateSelectors } from "seneca-common/utils/apiStates";

import { userInfoCrudStateConfig } from "../consts";
import { getUserMetaSlice } from "./userInfo";

const getUserInfoMetaState = (state: any) => getUserMetaSlice(state).userInfo;

const updateInfoMetaSelectors = makeCrudStateSelectors(
  getUserInfoMetaState,
  userInfoCrudStateConfig
).update;

const fetchInfoMetaSelectors = makeCrudStateSelectors(
  getUserInfoMetaState,
  userInfoCrudStateConfig
).fetchAll;

export const getUpdateInfoStarted = (state: any, fieldName: string): boolean =>
  updateInfoMetaSelectors.getStarted(state, fieldName);

export const getUpdateInfoSuccess = (state: any, fieldName: string): boolean =>
  updateInfoMetaSelectors.getSuccess(state, fieldName);

export const getUpdateInfoErrorMessage = (
  state: any,
  fieldName: string
): string | null | undefined =>
  updateInfoMetaSelectors.getErrorMessage(state, fieldName);

export const getUserInfoFetchFinished = (state: any): boolean =>
  fetchInfoMetaSelectors.getSuccess(state);
