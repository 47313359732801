import { useEffect } from "react";
import { useSelector } from "react-redux";

import { getClient } from "services";

import { isSignedIn } from "seneca-common/features/user/state";

import { isNativeAppiOS } from "features/pwa/mobile/ios/utils/isNativeAppiOS";

export default function useSavePushNotificationToken() {
  const signedIn = useSelector(isSignedIn);
  const isiOSApp = isNativeAppiOS();

  useEffect(() => {
    async function savePushToken(e: CustomEventInit) {
      const { getPushType } = await import("services/firebase/messaging/utils");
      const token = e.detail;

      if (token && signedIn) {
        const type = getPushType();
        const client = getClient("notificationsService");

        await client.savePushNotificationsToken({ type, token });
      }
    }

    if (isiOSApp) {
      window.addEventListener("push-notification-token", savePushToken);
      return () => {
        window.removeEventListener("push-notification-token", savePushToken);
      };
    }
  }, [signedIn, isiOSApp]);
}
