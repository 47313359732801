import {
  CanBeTested,
  GenerateContent,
  GenerateInitialModuleState,
  GenerateInitialUserAnswer,
  GetModuleAssets,
  MarkAnswer,
  ModuleType as ModuleNames
} from "seneca-common/features/module/module-types/types";

import ModuleLogic from "../models/ModuleLogic";
import ModuleBuilderNavigator from "./utils/ModuleBuilderNavigator";

export default class ModuleLogicBuilder<
  BackT,
  RootT
> extends ModuleBuilderNavigator<BackT, RootT> {
  _moduleName: ModuleNames;

  _markAnswer: MarkAnswer | null | undefined;
  _generateContent: GenerateContent | null | undefined;
  _canBeTested: CanBeTested | null | undefined;
  _getModuleAssets: GetModuleAssets | null | undefined;
  _generateInitialUserAnswer: GenerateInitialUserAnswer | null | undefined;
  _generateInitialModuleState: GenerateInitialModuleState | null | undefined;
  _throwMissingLogicError: ((error?: string) => any) | undefined;

  constructor(moduleName: ModuleNames, back: any, root: any) {
    super(
      {
        moduleName,
        builderName: "ModuleLogicBuilder"
      },
      back,
      root
    );
    this._moduleName = moduleName;

    this._markAnswer = null;
    this._generateContent = null;
    this._canBeTested = null;
    this._getModuleAssets = null;
    this._generateInitialUserAnswer = null;
    this._generateInitialModuleState = null;
  }

  markAnswer(markAnswer: MarkAnswer) {
    this._markAnswer = markAnswer;
    return this;
  }

  generateContent(generateContent: GenerateContent) {
    this._generateContent = generateContent;
    return this;
  }

  canBeTested(canBeTested: CanBeTested) {
    this._canBeTested = canBeTested;
    return this;
  }

  getModuleAssets(getModuleAssets: GetModuleAssets) {
    this._getModuleAssets = getModuleAssets;
    return this;
  }

  generateInitialUserAnswer(
    generateInitialUserAnswer: GenerateInitialUserAnswer
  ) {
    this._generateInitialUserAnswer = generateInitialUserAnswer;
    return this;
  }

  generateInitialModuleState(
    generateInitialModuleState: GenerateInitialModuleState
  ) {
    this._generateInitialModuleState = generateInitialModuleState;
    return this;
  }

  build(): ModuleLogic {
    !this._markAnswer && this._throwMissingLogicError!("markAnswer");
    !this._generateContent && this._throwMissingLogicError!("generateContent");

    return new ModuleLogic(this._moduleName, {
      markAnswer: this._markAnswer!,
      generateContent: this._generateContent!,
      canBeTested: this._canBeTested,
      getModuleAssets: this._getModuleAssets,
      generateInitialUserAnswer: this._generateInitialUserAnswer,
      generateInitialModuleState: this._generateInitialModuleState
    });
  }
}
