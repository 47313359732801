import {
  getUpdateInfoErrorMessage,
  getUpdateInfoStarted,
  getUpdateInfoSuccess,
  getUpdatePreferencesError,
  getUpdatePreferencesStarted
} from "seneca-common/features/user/state";

import { preferenceUpdateOperations } from "../consts/preferences";
import { userInfoUpdateOperations } from "../consts/userInfo";

export const updateAccountTypeHasStarted = (state: any): boolean =>
  getUpdateInfoStarted(state, userInfoUpdateOperations.ACCOUNT_TYPE);

export const updateAccountTypeDidSucceed = (state: any): boolean =>
  getUpdateInfoSuccess(state, userInfoUpdateOperations.ACCOUNT_TYPE);

export const getUpdateAccountTypeErrorMessage = (
  state: any
): string | null | undefined =>
  getUpdateInfoErrorMessage(state, userInfoUpdateOperations.ACCOUNT_TYPE);

export const pickDisplayNameStarted = (state: any): boolean =>
  getUpdatePreferencesStarted(
    state,
    preferenceUpdateOperations.HAS_PICKED_DISPLAY_NAME
  );

export const pickDisplayNameError = (state: any): boolean =>
  getUpdatePreferencesError(
    state,
    preferenceUpdateOperations.HAS_PICKED_DISPLAY_NAME
  );
