import {
  Box,
  Button,
  ButtonFill,
  Column,
  Columns,
  Icon,
  IconName,
  Text,
  useTheme
} from "@seneca/senekit";
import { KeyColorRole } from "@seneca/senekit/out/styles/colors/types";

type Props = {
  iconName: IconName;
  iconTone: KeyColorRole;
  showIconBorder?: boolean;
  label: string;
  buttonText: string;
  buttonFill: ButtonFill;
  onButtonClick: () => void;
};

export function OptionRow({
  iconName,
  iconTone,
  showIconBorder,
  label,
  buttonText,
  buttonFill,
  onButtonClick
}: Props) {
  const theme = useTheme();

  return (
    <Columns space="12" alignY="center">
      <Column width="content">
        <Box
          width="42px"
          height="42px"
          borderStyle={showIconBorder ? "solid" : "none"}
          borderWidth="xxxsmall"
          borderColor={theme[`${iconTone}-high-content`]}
          borderRadius="large"
          padding="small"
        >
          <Icon
            iconName={iconName}
            accessibilityLabel={null}
            size="fill"
            tone={iconTone}
          />
        </Box>
      </Column>
      <Column>
        <Text>{label}</Text>
      </Column>
      <Column width={["1/2", "1/3"]}>
        <Button onClick={onButtonClick} fill={buttonFill} width="100%">
          {buttonText}
        </Button>
      </Column>
    </Columns>
  );
}
