import {
  CanBeTested,
  GenerateContent,
  GenerateInitialModuleState,
  GenerateInitialUserAnswer,
  GetModuleAssets,
  MarkAnswer,
  ModuleType as ModuleNames
} from "seneca-common/features/module/module-types/types";

export type ModuleLogicType = {
  markAnswer: MarkAnswer;
  generateContent: GenerateContent;
  canBeTested: CanBeTested | null | undefined;
  getModuleAssets: GetModuleAssets | null | undefined;
  generateInitialUserAnswer: GenerateInitialUserAnswer | null | undefined;
  generateInitialModuleState: GenerateInitialModuleState | null | undefined;
};

export default class ModuleLogic {
  _moduleName: ModuleNames;

  markAnswer: MarkAnswer;
  generateContent: GenerateContent;
  canBeTested: CanBeTested | null | undefined;
  getModuleAssets: GetModuleAssets | null | undefined;
  generateInitialUserAnswer: GenerateInitialUserAnswer | null | undefined;
  generateInitialModuleState: GenerateInitialModuleState | null | undefined;

  constructor(
    moduleName: ModuleNames,
    {
      markAnswer,
      generateContent,
      getModuleAssets,
      canBeTested,
      generateInitialUserAnswer,
      generateInitialModuleState
    }: ModuleLogicType
  ) {
    this._moduleName = moduleName;

    this.markAnswer = markAnswer;
    this.generateContent = generateContent;
    this.canBeTested = canBeTested;
    this.getModuleAssets = getModuleAssets;
    this.generateInitialUserAnswer = generateInitialUserAnswer;
    this.generateInitialModuleState = generateInitialModuleState;
  }

  getMarkAnswer(): MarkAnswer {
    return this.markAnswer;
  }

  getGenerateContent(): GenerateContent {
    return this.generateContent;
  }

  getCanBeTested(): CanBeTested | null | undefined {
    return this.canBeTested;
  }

  getGetModuleAssets(): GetModuleAssets | null | undefined {
    return this.getModuleAssets;
  }

  getGenerateInitialUserAnswer(): GenerateInitialUserAnswer | null | undefined {
    return this.generateInitialUserAnswer;
  }

  getGenerateInitialModuleState():
    | GenerateInitialModuleState
    | null
    | undefined {
    return this.generateInitialModuleState;
  }
}
