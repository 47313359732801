import { Services } from "./types";

export default class ServiceClientRegistry {
  static _instance: ServiceClientRegistry;
  services: Services = {};
  config: Record<string, any> | undefined;
  requestMaker: Record<string, any> | undefined;

  static getInstance() {
    if (!this._instance) {
      this._instance = new ServiceClientRegistry();
    }

    return this._instance;
  }

  registerConfig(config: Record<string, any>) {
    this.config = config;
    return this;
  }

  registerClient<ServiceKey extends keyof Services>(
    key: ServiceKey,
    ServiceClientClass: any,
    options?: Record<string, any>
  ) {
    const serviceConfig = this.getServiceConfig(key);

    this.services[key] = new ServiceClientClass(fetch, {
      ...serviceConfig,
      ...options
    });

    return this;
  }

  getServiceConfig<ServiceKey extends keyof Services>(key: ServiceKey) {
    if (this.services[key]) {
      throw new Error(
        `Error registering '${key}' client. This client has already been registered`
      );
    }

    if (!this.config) {
      throw new Error(
        "Unable to register client due to missing config. You need to registerConfig before registering any clients"
      );
    }

    const serviceConfig = this.config.services[key];

    if (!serviceConfig) {
      throw new Error(
        `Unable to find config for service: '${key}'. Please check your config file.`
      );
    }

    return serviceConfig;
  }

  getClient<ServiceKey extends keyof Services>(key: ServiceKey) {
    const client = this.services[key];

    if (!client) {
      throw new Error(
        `Unable to get ${key} client as it has not been registered yet.`
      );
    }

    return client!;
  }
}

export function getClient<ServiceKey extends keyof Services>(key: ServiceKey) {
  return ServiceClientRegistry.getInstance().getClient(key);
}
