import type { AuthCredential } from "firebase/auth";

export default class AccountExistsWithDifferentCredential extends Error {
  credential: AuthCredential;
  email: string;
  code: string | null | undefined;

  constructor({
    credential,
    email,
    code
  }: {
    credential: AuthCredential;
    email: string;
    code?: string;
  }) {
    super(`Account exists with different credential`);

    this.credential = credential;
    this.email = email;
    this.code = code;
  }
}
