import {
  isAndroid as isAndroidVar,
  isIOS as isIOSVar,
  isMobileOnly as isMobileOnlyVar,
  isMobile as isMobileVar,
  isTablet as isTabletVar
  // @ts-ignore
} from "react-device-detect";

const isTablet = (): boolean => isTabletVar;

const isMobile = (): boolean => isMobileVar;

const isMobileOnly = (): boolean => isMobileOnlyVar;

const isNotMobile = (): boolean => !isMobileVar;

const isAndroid = (): boolean => isAndroidVar;

const isIOS = (): boolean => isIOSVar;

const isTouchIOS = () => {
  // `isIOS` doesn't return true on iPad, and in iOS 13 onwards, userAgent contains `Mac` instead of `iPad`
  //  The first `isIOS` acts as a fallback, and the `.includes("Mac")` checks if it is an iOS device,
  // `"ontouchend" in document` will check if it is touchscreen
  return (
    (isIOS() || navigator.userAgent.includes("Mac")) && "ontouchend" in document
  );
};

const getMobileDeviceType = () => {
  if (isAndroid()) {
    return "android";
  }

  if (isIOS()) {
    return "iOS";
  }

  return null;
};

export {
  isMobile,
  isTablet,
  isMobileOnly,
  isAndroid,
  isIOS,
  isTouchIOS,
  isNotMobile,
  getMobileDeviceType
};
