import { List, Record } from "immutable";

type SidebarUIType = {
  open: boolean;
  expandedSectionIds: List<string>;
  expandedAssignmentId?: string;
  activeTab?: number;
};

export type OptionalSidebarUIType = Partial<SidebarUIType>;

const defaultSidebarUI: SidebarUIType = {
  open: false,
  expandedSectionIds: List(),
  expandedAssignmentId: undefined,
  activeTab: undefined
};

export default class SidebarUI extends Record(defaultSidebarUI) {}
