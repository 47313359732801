import {
  getSubscriptionProduct,
  SubscriptionProductType
} from "seneca-common/features/subscriptions/features/subscription-products/state";
import {
  getSubscriptions,
  hasLiveSubscription
} from "seneca-common/features/subscriptions/state";
import {
  getRegion,
  getUserAccountType,
  isUserManaged
} from "seneca-common/features/user/state";

import { getAllFeatureFlags } from "features/feature-flags";

import { getProductId } from "../../features/subscriptions/state/selectors/state/subscriptions/subscription";
import { recursivelyFilterFieldsForSentry } from "./utils";

export default function getTags(state: any) {
  let subscriptionInfoForSentry = {};
  const subscriptions = getSubscriptions(state);

  const productIds = subscriptions
    .map(sub => getProductId(sub))
    .toList()
    .toJS() as string[];

  const uniqueProductIds = [...new Set(productIds)];

  if (uniqueProductIds.length > 0) {
    const tiers = uniqueProductIds
      .map(productId => {
        const subscriptionProduct = getSubscriptionProduct(
          state,
          productId!
        ).toJS() as SubscriptionProductType;
        const { productDescription } = subscriptionProduct;

        return productDescription?.tierName;
      })
      .join(",");
    subscriptionInfoForSentry = {
      tierName: tiers,
      subscriptionProductIds: uniqueProductIds.join(",")
    };
  }

  const { static: staticFlags, abTest, release } = getAllFeatureFlags();

  return recursivelyFilterFieldsForSentry({
    region: getRegion(state),
    accountType: getUserAccountType(state),
    premium: hasLiveSubscription(state),
    isUserManaged: isUserManaged(state),
    ...subscriptionInfoForSentry,
    ...abTest,
    ...staticFlags,
    ...release
  });
}
