import { matchPath } from "react-router-dom";

import { Link } from "common/types/routing";

import { addValueToLinkState } from "seneca-common/utils/routing";

import { ExamQuestionsSource } from "features/exam-questions/analytics";

import { linkToClassroomSection } from "../../links";

export function linkToSectionExamQuestions(
  courseId?: string,
  sectionId?: string
) {
  return `${linkToClassroomSection(courseId, sectionId)}/exam-questions`;
}

export function isInSectionExamQuestions(pathname: Link) {
  const stringpath =
    typeof pathname === "string" ? pathname : (pathname as any).pathname;

  if (!stringpath) {
    return false;
  }

  return matchPath(stringpath, {
    path: linkToSectionExamQuestions(),
    exact: false
  });
}

export function linkToSectionExamQuestionsSession(
  courseId?: string,
  sectionId?: string
) {
  return `${linkToSectionExamQuestions(courseId, sectionId)}/session`;
}

// use this whenever linking to a section Exam Questions session

export const makeLinkToSectionExamQuestionsSessionWithState = ({
  courseId,
  sectionId,
  source,
  sessionId,
  isRetrySession,
  selectedQuestionIds
}: {
  courseId: string;
  sectionId: string;
  source: ExamQuestionsSource;
  sessionId?: string;
  isRetrySession?: boolean;
  selectedQuestionIds?: string[];
}) =>
  addValueToLinkState(linkToSectionExamQuestionsSession(courseId, sectionId), {
    source,
    sessionId,
    isRetrySession,
    selectedQuestionIds
  });
