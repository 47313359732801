import { FlattenedFlags } from "./types";

export function getDevOverride(id: string) {
  try {
    // dev override, try catching so that these can't crash
    // the app for users with local storage disabled
    const item = localStorage.getItem(id);

    if (item !== null) {
      return {
        isOverridden: true,
        override: item === "true" || item === "false" ? JSON.parse(item) : item
      };
    }
  } catch (err: any) {}

  return {
    isOverridden: false,
    override: null
  };
}

export function setDevOverride(key: string, value: any) {
  window.localStorage.setItem(key, value);
}

export function removeDevOverride(key: string) {
  window.localStorage.removeItem(key);
}

export function setDevOverridesFromSearchParams(
  flags: FlattenedFlags,
  searchParams: string
) {
  const searchParamsObject = Object.fromEntries(
    new URLSearchParams(searchParams).entries()
  );
  const flagsFromSearchParams = Object.keys(searchParamsObject).reduce(
    (acc, key) => {
      const value = searchParamsObject[key];
      const isValid = isValidFlagValue(flags, key);

      if (!isValid) {
        return acc;
      }

      return {
        ...acc,
        [key]: isValidStaticFlagValue(value) ? JSON.parse(value) : value
      };
    },
    {}
  );

  Object.keys(flagsFromSearchParams).forEach(key => {
    setDevOverride(key, (flagsFromSearchParams as any)[key]);
  });

  return { ...flags, ...flagsFromSearchParams };
}

function isValidFlagValue(flags: FlattenedFlags, key: string) {
  return Object.keys(flags).includes(key);
}

function isValidStaticFlagValue(value: string) {
  return value === "true" || value === "false";
}
