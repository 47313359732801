import actionTypes from "../action-types/learningMode";
import LearningMode from "../models/learningMode/LearningMode";

export default function learningModeReducer(
  learningMode: LearningMode = new LearningMode(),
  action: any
) {
  switch (action.type) {
    case actionTypes.SET_LEARNING_MODE:
      return learningMode.set("mode", action.payload.mode);

    case actionTypes.SET_LEARNING_MODE_META:
      const { assignmentId, source } = action.payload;
      return learningMode.merge({
        assignmentId,
        source
      });

    default:
      return learningMode;
  }
}
