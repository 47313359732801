import { makeActionsAndActionCreators } from "../../common/actions/actionCreator";
import { AllowedCallTypes, MakeActionCreator } from "../../common/const";

const makeFetchStateActionsAndActionCreators = ({
  name,
  path = "seneca/",
  idFieldName
}: MakeActionCreator) => {
  const { actionTypes, actionCreators } = makeActionsAndActionCreators({
    name,
    path,
    idFieldName,
    callType: AllowedCallTypes.FETCH
  });
  return {
    fetchActionTypes: actionTypes,
    fetchActionCreators: actionCreators
  };
};

export default makeFetchStateActionsAndActionCreators;
