import getConfig from "config";
import TagManager from "react-gtm-module";

export default function initializeGoogleTagManager() {
  const config = getConfig();

  const gtmId = config.googleTagManager.id;
  const gtmAuth = config.googleTagManager.auth;
  const gtmPreview = config.googleTagManager.preview;

  if (
    // process.env.NODE_ENV === "development" &&
    !gtmId ||
    !gtmAuth ||
    !gtmPreview
  ) {
    console.log(
      `%c @Seneca: No REACT_APP_GOOGLE_TAG_MANAGER_ID, REACT_APP_GOOGLE_TAG_MANAGER_AUTH or 
      REACT_APP_GOOGLE_TAG_MANAGER_PREVIEW env var found, not logging anything to google tag manager.`,
      "color: #989848"
    );
    return;
  }

  if (process.env.NODE_ENV === "test") {
    return;
  }

  // Exclude edge environments
  if (process.env.NODE_ENV === "production" && !gtmId) {
    return;
  }

  const options = {
    gtmId,
    auth: gtmAuth,
    preview: gtmPreview
  };

  TagManager.initialize(options);
}
