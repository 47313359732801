import sortAscendingOrder from "../sortAscendingOrder";

export default function sortStringAscendingOrder(
  x: string | null | undefined,
  y: string | null | undefined
): number {
  if (!x) return -1;
  if (!y) return 1;

  const a = String(x).toLowerCase();
  const b = String(y).toLowerCase();

  return a < b ? -1 : a > b ? 1 : sortAscendingOrder(x, y);
}
