import { createSearchParams, Filters } from "features/course-discovery/utils";

import { linkToDashboard } from "../../links";

export const makeLinkToStartCourseScreen = (
  basePath: string,
  courseId: string = ":courseId"
): string => `${basePath}/start/${courseId}`;

export function linkToDashboardStartCourseScreen(courseId?: string) {
  return makeLinkToStartCourseScreen(linkToDashboard(), courseId);
}

export function linkToCoursesPage() {
  return `${linkToDashboard()}/courses`;
}

export function linkToYourCoursesPage() {
  return `${linkToCoursesPage()}/your-courses`;
}

export function linkToYourCoursesNewPage() {
  return `${linkToYourCoursesPage()}/new`;
}

export function linkToYourCoursesSuggestedPage() {
  return `${linkToYourCoursesPage()}/suggested`;
}

export const linkToYourCoursesPageStartCourseScreen = (courseId?: string) =>
  makeLinkToStartCourseScreen(linkToYourCoursesPage(), courseId);

export const linkToYourCoursesPageNewCourseStartCourseScreen = (
  courseId?: string
) => makeLinkToStartCourseScreen(linkToYourCoursesNewPage(), courseId);

export const linkToYourCoursesPageSuggestedCourseStartCourseScreen = (
  courseId?: string
) => makeLinkToStartCourseScreen(linkToYourCoursesSuggestedPage(), courseId);

export const linkToAddCoursesPageStartCourseScreen = (courseId?: string) =>
  makeLinkToStartCourseScreen(linkToAddCoursesPage(), courseId);

export function linkToNewCoursesPage() {
  return `${linkToCoursesPage()}/new-courses`;
}

export const linkToAddCoursesPage = () => `${linkToCoursesPage()}/add`;

export const linkToArchiveCourseModal = (courseId: string = ":courseId") =>
  `${linkToYourCoursesPage()}/archive-course/${courseId}`;

export const linkToPersonalisedSuggestionsPage = () =>
  `${linkToCoursesPage()}/personalised-suggestions`;

export const linkToAddCoursesWithQueryParams = (
  filters: Filters,
  text?: string
): string => {
  return `${linkToAddCoursesPage()}?${createSearchParams(filters, text)}`;
};

export const makeLinkToStartLearningJourney = (baseLink: string) => {
  const linkToStartCourseScreen = (courseId: string = ":courseId") =>
    makeLinkToStartCourseScreen(baseLink, courseId);

  return linkToStartCourseScreen;
};
