import { Dispatch } from "common/types/redux";

import logError from "seneca-common/utils/sentry/logError";

import { getAppVersion } from "features/common/utils/functions/app";

import {
  appVersionIsUpdating,
  newAppVersionIsAvailable,
  newPWAConfigReceived
} from "../state";
import {
  getForceUpdateForVersionsFromConfig,
  getShowWarningForVersionsFromConfig,
  getToastVisibilityTimeout
} from "./config";
import { PwaConfig } from "./types";

export { newAppVersionIsAvailable, appVersionIsUpdating };

export function setInitialPwaConfig() {
  return receivePwaConfig({
    showWarningForVersions: getShowWarningForVersionsFromConfig(),
    forceUpdateForVersions: getForceUpdateForVersionsFromConfig(),
    toastVisibilityTimeoutMS: getToastVisibilityTimeout()
  });
}

export function receivePwaConfig({
  showWarningForVersions,
  forceUpdateForVersions,
  toastVisibilityTimeoutMS
}: PwaConfig) {
  return function (dispatch: Dispatch) {
    const currentVersion = getAppVersion();
    dispatch(
      newPWAConfigReceived({
        showWarning: findVersionMatch(currentVersion!, showWarningForVersions),
        forceUpdate: findVersionMatch(currentVersion!, forceUpdateForVersions),
        toastVisibilityTimeoutMS
      })
    );
  };
}

function findVersionMatch(currentVersion: string, rules: string[] = []) {
  try {
    return window.Seneca.versions.findMatch(
      currentVersion,
      removeVFromVersions(rules)
    );
  } catch (error: any) {
    logError(error, {
      message: "Error parsing PWA config"
    });
    return false;
  }
}

function removeVFromVersions(versions?: string[]) {
  return versions
    ? versions.map(v => v.replace("v", "").replace("V", ""))
    : null;
}
