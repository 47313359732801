import moment from "moment";

import actionTypes from "../action-types/signInState";

export function signInStart() {
  return {
    type: actionTypes.SIGN_IN_START
  };
}

export function signOutStart() {
  return {
    type: actionTypes.SIGN_OUT_START
  };
}

export function signInSuccess(userId?: string) {
  return {
    type: actionTypes.SIGN_IN_SUCCESS,
    payload: {
      signInTime: moment().format(),
      userId
    }
  };
}

export function signUpSuccess() {
  return {
    type: actionTypes.SIGN_UP_SUCCESS,
    payload: {
      signInTime: moment().format()
    }
  };
}

export function signInSuccessAsGuest() {
  return {
    type: actionTypes.SIGN_IN_SUCCESS_AS_GUEST,
    payload: {
      signInTime: moment().format()
    }
  };
}

export function signInError() {
  return {
    type: actionTypes.SIGN_IN_ERROR
  };
}

export function signOut() {
  return {
    type: actionTypes.SIGN_OUT_SUCCESS
  };
}

export function signOutError() {
  return {
    type: actionTypes.SIGN_OUT_ERROR
  };
}

export const setUserHasSignedUpBefore = () => ({
  type: actionTypes.SET_USER_HAS_SIGNED_UP_BEFORE
});

export const logCredentialsRefresh = ({
  expiration
}: {
  expiration: string;
}) => {
  return {
    type: actionTypes.REFRESH_CREDENTIALS,
    payload: {
      refreshedAt: moment().format(),
      expiration
    }
  };
};

export const refreshCredentialsError = () => ({
  type: actionTypes.REFRESH_CREDENTIALS_ERROR
});

export const setPromptToConnectProviderSSO = (value?: boolean) => {
  return {
    type: actionTypes.SET_PROMPT_TO_CONNECT_PROVIDER_SSO,
    payload: value
  };
};
