import { DEFAULT_REGION, regions } from "common/static/regions";

import {
  getRegionFromSessionStorage,
  setRegionInSessionStorage
} from "services/region";

import { getRegionFromIp } from "seneca-common/features/geolocation/service";
import { getRegion } from "seneca-common/features/user/state";

import isGoStudentLearningApp from "features/goStudentLearning/utils/isGoStudentLearningApp";
import { isNativeAppiOSAndHidePremium } from "features/pwa/mobile/ios/utils/isNativeAppiOS";

export { DEFAULT_REGION, getRegion };

export const getRegionFromSessionStorageOrIpAndCache = async () => {
  const regionFromSessionStorage = getRegionFromSessionStorage();

  if (regionFromSessionStorage) {
    const language = getLanguageFromRegion(regionFromSessionStorage);
    language && window.Localize.setLanguage(language);
    return regionFromSessionStorage;
  }

  const regionFromIp = await getRegionFromIp();
  const region = regionFromIp || DEFAULT_REGION;

  setRegionInSessionStorage(region);
  const language = getLanguageFromRegion(region);
  language && window.Localize.setLanguage(language);

  return region;
};

export const getLanguageFromRegion = (region: string): string => {
  return regions[region]?.languageCode;
};

export const getLocaleFromRegion = (region: string): string => {
  return regions[region]?.locale;
};

export const getHelpLinkRegionCode = (state: any): string => {
  const userRegion = getRegion(state) || DEFAULT_REGION;
  return regions[userRegion]?.helpLinkCode;
};

export const getShowPremiumAds = (state: any): boolean => {
  const userRegion = getRegion(state) || DEFAULT_REGION;
  const hideAdsOniOS = isNativeAppiOSAndHidePremium();
  const isGoStudentLearning = isGoStudentLearningApp();

  return (
    !!regions[userRegion]?.showPremiumAds &&
    !hideAdsOniOS &&
    !isGoStudentLearning
  );
};

export const getShowNewFeatures = (state: any): boolean => {
  const userRegion = getRegion(state) || DEFAULT_REGION;
  return !!regions[userRegion]?.showNewFeatures;
};

export const getAuthenticateWithPlurall = (state: any): boolean => {
  const userRegion = getRegion(state) || DEFAULT_REGION;
  return !!regions[userRegion]?.authenticateWithPlurall;
};

export const getShowIntercomInRegion = (state: any): boolean => {
  const userRegion = getRegion(state) || DEFAULT_REGION;
  return !!regions[userRegion]?.showIntercom;
};

export const getShowShareToRemind = (state: any): boolean => {
  const userRegion = getRegion(state) || DEFAULT_REGION;
  return !!regions[userRegion]?.showShareToRemind;
};

export const getCollectTeacherRole = (state: any): boolean => {
  const isGoStudentLearning = isGoStudentLearningApp();
  const userRegion = getRegion(state) || DEFAULT_REGION;

  return !isGoStudentLearning && !!regions[userRegion]?.collectUserSchool;
};

export const getIncludeYearGroup = (state: any): boolean => {
  const userRegion = getRegion(state) || DEFAULT_REGION;
  return !!regions[userRegion]?.collectUserYearGroup;
};

export const getIncludePhoneNumber = (state: any): boolean => {
  const userRegion = getRegion(state) || DEFAULT_REGION;
  return !!regions[userRegion]?.collectUserPhoneNumber;
};

export const getUserLanguageCode = (state: any): string => {
  const userRegion = getRegion(state) || DEFAULT_REGION;
  return getLanguageFromRegion(userRegion);
};

export const getTeacherResourcesNoTranslate = (state: any): boolean => {
  const userRegion = getRegion(state) || DEFAULT_REGION;
  return !!regions[userRegion]?.teacherResourcesDataNoTranslate;
};

export const getLocale = (state: any): string => {
  const userRegion = getRegion(state) || DEFAULT_REGION;
  return getLocaleFromRegion(userRegion);
};

export const getCollectUserSchool = (state: any): boolean => {
  const isGoStudentLearning = isGoStudentLearningApp();
  const userRegion = getRegion(state) || DEFAULT_REGION;

  return !isGoStudentLearning && !!regions[userRegion]?.collectUserSchool;
};

export function isSchoolAnalyticsEnabled(state: any) {
  const userRegion = getRegion(state) || DEFAULT_REGION;
  return !!regions[userRegion]?.enableSchoolAnalytics;
}

export function isWholeSchoolReportEnabled(state: any) {
  const userRegion = getRegion(state) || DEFAULT_REGION;
  return !!regions[userRegion]?.enableWholeSchoolReport;
}

export function chatBotEnabledForRegion(state: any) {
  const userRegion = getRegion(state) || DEFAULT_REGION;
  return !!regions[userRegion]?.enableChatBot;
}

export const getShowCramModeForRegion = (state: any): boolean => {
  const userRegion = getRegion(state) || DEFAULT_REGION;
  return !!regions[userRegion]?.showCramMode;
};
