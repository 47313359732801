import { Client } from "@seneca/client-library";

export default class DiscordServiceClient extends Client {
  async fetchActivationCode() {
    const url = `${this.url}/api/activation-code/me`;
    const expectedResponses = [200];

    const response = await this.requestMaker.makeRequest<any>(
      url,
      { method: "GET" },
      expectedResponses
    );

    return response.json;
  }
}
