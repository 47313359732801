import { useEffect } from "react";

import { DATA_LAYER_SCOPES } from "seneca-common/features/analytics";
import { pushGlobalValuesToDataLayer } from "seneca-common/features/analytics/gtm";

import { analyticsLogUserClickedOnEmailNotification } from "../";
import { NotificationType } from "../consts";

export default function useEmailNotificationsAnalytics({
  notificationType,
  emailId
}: {
  notificationType: NotificationType | null;
  emailId?: string | null;
}) {
  useEffect(() => {
    if (emailId && notificationType === NotificationType.EMAIL) {
      analyticsLogUserClickedOnEmailNotification(emailId);
      pushGlobalValuesToDataLayer({
        [DATA_LAYER_SCOPES.NOTIFICATIONS]: { emailId }
      });
    }
  }, [emailId, notificationType]);
}
