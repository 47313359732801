import {
  DocumentExport,
  GetTextPreview,
  ModuleType as ModuleNames,
  PollyPodcastExport
} from "seneca-common/features/module/module-types/types";

import ModuleExportTransformations from "../models/ModuleExportTransformations";
import ModuleBuilderNavigator from "./utils/ModuleBuilderNavigator";

export default class ModuleExportTransformationsBuilder<
  BackT,
  RootT
> extends ModuleBuilderNavigator<BackT, RootT> {
  _moduleName: ModuleNames;

  _getTextPreview: GetTextPreview | null | undefined;
  _pollyPodcastExport: PollyPodcastExport | undefined;
  _documentExport: DocumentExport | undefined;

  constructor(moduleName: ModuleNames, back: any, root: any) {
    super(
      {
        moduleName,
        builderName: "ModuleExportTransformationsBuilder"
      },
      back,
      root
    );
    this._moduleName = moduleName;

    this._getTextPreview = null;
  }

  getTextPreview(getTextPreview: GetTextPreview) {
    this._getTextPreview = getTextPreview;
    return this;
  }

  pollyPodcastExport(pollyPodcastExport: PollyPodcastExport) {
    this._pollyPodcastExport = pollyPodcastExport;
    return this;
  }

  documentExport(documentExport: DocumentExport) {
    this._documentExport = documentExport;
    return this;
  }

  build(): ModuleExportTransformations {
    return new ModuleExportTransformations(this._moduleName, {
      getTextPreview: this._getTextPreview!,
      pollyPodcastExport: this._pollyPodcastExport!,
      documentExport: this._documentExport!
    });
  }
}
