import { useSelector } from "react-redux";

import { makeBox } from "@seneca/senekit";
import { Height, ResponsiveProp, Width } from "@seneca/senekit/out/types";

import { hasLiveSubscription } from "seneca-common/features/subscriptions/state";

import { ReactComponent as errorMascotPremium } from "../assets/error-mascot-premium.svg";
import { ReactComponent as errorMascot } from "../assets/error-mascot.svg";

const ErrorImg = makeBox(errorMascot);
const ErrorImgPremium = makeBox(errorMascotPremium);

const maxWidth: ResponsiveProp<Width> = ["350px", "auto"];
const maxHeight: ResponsiveProp<Height> = ["230px", "auto"];

export default function ErrorImage() {
  const hasPremium = useSelector(hasLiveSubscription);
  if (hasPremium) {
    return <ErrorImgPremium maxWidth={maxWidth} maxHeight={maxHeight} />;
  }
  return <ErrorImg maxWidth={maxWidth} maxHeight={maxHeight} />;
}
