import { useContext, useRef } from "react";

import LastLocationContext from "./context";

export default function useLastLocationOnMount() {
  const lastLocation = useContext(LastLocationContext);
  const lastLocationRef = useRef(lastLocation);

  return lastLocationRef.current;
}
