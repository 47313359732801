import { unsafeFieldsMatchExact, unsafeFieldsMatchFuzzy } from "./consts";

const unsafeFieldsMatchRegex = unsafeFieldsMatchFuzzy.map(
  field => new RegExp(field, "i")
);
export type AppStateJS = Record<string, any>;

export function filterBreadcrumbs({ type }: AppStateJS) {
  return !!type;
}

export function recursivelyFilterFieldsForSentry(obj: AppStateJS): any {
  return Object.keys(obj).reduce((acc, key) => {
    const value = obj[key];

    if (isUnsafe(key)) {
      return { ...acc, [key]: "***" };
    }

    if (value instanceof Object) {
      return { ...acc, [key]: recursivelyFilterFieldsForSentry(value) };
    }

    return { ...acc, [key]: value };
  }, {});
}

export function isUnsafe(value: string) {
  return (
    unsafeFieldsMatchExact.includes(value) ||
    unsafeFieldsMatchRegex.some(regex => regex.test(value))
  );
}
