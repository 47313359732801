import { getAppName } from "features/common/utils/functions/app";

import { validateString } from "./validation/validateString";

export const makeMinStringLengthValidator =
  (limit: number = 300) =>
  (value: string | null | undefined): string | null | undefined => {
    if (!value) return validateString(value);
    return value.length <= limit
      ? `Text needs to be at least ${limit} characters`
      : undefined;
  };

export const makeMaxStringLengthValidator =
  (limit: number = 1000) =>
  (value: string | null | undefined): string | null | undefined => {
    if (!value) return validateString(value);
    return value.length > limit
      ? `Text needs to be ${limit} characters or less`
      : undefined;
  };

export const validateEmailLoosely = (
  value: string | null | undefined
): string | null | undefined => {
  // This regEx works in the following way:
  // ^                                      - match string start
  // [a-zA-Z0-9.!#$%&’'*+/=?^_`{|}~-]+      - match any number of alphanumeric + selected non-alphanumeric chars
  // @                                      - match the @ sign
  // [a-zA-Z0-9-]+                          - match any number of alphanumeric chars + a dash
  // (?:\.[a-zA-Z0-9-]+)*                   - non-capturing group - match the . followed by alphanumeric chars + a dash 0 times or more
  // $                                      - match string end

  const regEx =
    /^[a-zA-Z0-9.!#$%&’'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  const evaluated = value && value.match(regEx);
  if (evaluated) return undefined;
  return "Please enter a valid email";
};

export const validateEmailStrictly = (
  email: string | null | undefined
): string | null | undefined => {
  // This regEx works in the following way:
  // ^                                      - match string start
  // (?!\.)                                 - negative look ahead for . - email can't start with .
  // (?!.*@\.)                              - negative look ahead for @. - email can't contain @.
  // (?!.*\.@)                              - negative look ahead for .@ - email can't contain .@
  // (?!.*?\.\.)                            - negative look ahead for .. - email can't contain multiple neighbouring full stops
  // [a-zA-Z0-9.!#$%&’'*+/=?^_`{|}~-]{1,64} - match between 1 and 64 alphanumeric + selected non-alphanumeric chars
  // @                                      - match the @ sign
  // [a-zA-Z0-9-.]{1,63}                    - match between 1 and 63 alphanumeric chars, dash or full stop
  // \.                                     - match the .
  // [a-zA-Z0-9]{2,24}                      - match between 2 and 24 alphanumeric chars
  // $                                      - match string end

  const regEx =
    /^(?!\.)(?!.*@\.)(?!.*\.@)(?!.*?\.\.)[a-zA-Z0-9.!#$%&’'*+/=?^_`{|}~-]{1,64}@[a-zA-Z0-9-.]{1,63}\.[a-zA-Z0-9]{2,24}$/;

  const evaluated = email && email.match(regEx);
  if (evaluated) return undefined;
  return "Please enter a valid email";
};

export const validateUrl = (
  url: string | null | undefined
): string | null | undefined => {
  const regEx =
    /^(?:http(s)?:\/\/)[\w.-]+(?:.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/;

  const evaluated = url && url.match(regEx);
  if (evaluated) return undefined;
  return "The url is invalid";
};

export const changeEmailFormValidateEmail = (
  newEmail: string | null | undefined,
  currentEmail: string
) => {
  if (newEmail === currentEmail) {
    return "Please enter a new email";
  }

  return validateEmailStrictly(newEmail);
};

export const isNumeric = (value: any): boolean =>
  !isNaN(value - parseFloat(value));

export const containsOnlyDigits = (str: string): boolean =>
  str.split("").every(isNumeric);

const validatePhoneLength = (value: string) => {
  //  These length limits come from https://en.wikipedia.org/wiki/E.164
  const lowerLengthLimit = 6;
  const upperLengthLimit = 16;
  if (value.length < lowerLengthLimit)
    return `Should be at least ${lowerLengthLimit} characters long`;
  if (value.length > upperLengthLimit)
    return `Should be ${upperLengthLimit} characters long or less`;
};

export const validatePhoneNumber = (
  phoneNumber: string
): string | null | undefined => {
  if (!phoneNumber) return "Please enter a value";

  if (!containsOnlyDigits(phoneNumber.slice(1)))
    return "Should consist of numbers only";
  return validatePhoneLength(phoneNumber);
};

export const validatePhoneNumberLoosely = (phoneNumber: string) => {
  if (!phoneNumber) return "Please enter a value";

  if (!/^[0-9+ ]*$/.test(phoneNumber))
    return "Should contain numbers, spaces and + only";

  return validatePhoneLength(phoneNumber);
};

export const validatePhoneNumberWithoutCountryCode = (phoneNumber: string) => {
  if (!phoneNumber) return "Please enter a value";

  if (!containsOnlyDigits(phoneNumber)) return "Should consist of numbers only";

  return validatePhoneLength(phoneNumber);
};

export const validateStringField = (fieldName: string) => (input: string) => {
  if (validateString(input)) {
    return `Please enter a ${fieldName}.`;
  }

  return undefined;
};

export const validateDropdownField = (fieldName: string) => (input: string) => {
  if (input === undefined) {
    return `Please select a ${fieldName}.`;
  }

  return undefined;
};

export const validateClassCode = (
  str: string,
  message: string = "Sorry, that class code isn't valid. Please check with your teacher."
): string | null | undefined => {
  if (validateString(str)) {
    return "Get a class code from your teacher";
  }

  if (!/^[A-z0-9\-_]+$/.test(str)) {
    return message;
  }

  return undefined;
};

export const validateConsent = (value: boolean) => {
  const appName = getAppName();
  if (!value) return `You must have consent to use ${appName}`;
  return undefined;
};
