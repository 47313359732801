import uuid from "seneca-common/utils/functions/uuid";
import logError from "seneca-common/utils/sentry/logError";

const AuthCorrelationIdKey = "AuthCorrelationId";

export default function manageAuthCorrelationId() {
  try {
    const get = () => window.localStorage.getItem(AuthCorrelationIdKey);

    const create = (id: string = uuid()) => {
      window.localStorage.setItem(AuthCorrelationIdKey, id);
      return id;
    };

    const remove = () => window.localStorage.removeItem(AuthCorrelationIdKey);

    return { get, create, remove };
  } catch (error) {
    logError(error, { message: "Error on manageAuthCorrelationId util" });
  }
}
