import { Record as ImmutableRecord, Map } from "immutable";

import TryingToUpdateBeforeReceivingError from "seneca-common/utils/state/build-redux-bundles/reducer-builder/errors/TryingToUpdateBeforeReceivingError";

import {
  ActionTypes,
  GetIdFromJSObject,
  JsToImmutableTransform
} from "../types";
import ErrorTransformingObjectToImmutableError from "./errors/ErrorTransformingObjectToImmutableError";
import NoIdFoundOnJSObjectError from "./errors/NoIdFoundOnJSObjectError";

export default function makeReducer<JS, I extends ImmutableRecord<any>>(
  actionTypes: ActionTypes,
  getIdFromJsObject: GetIdFromJSObject<JS>,
  jsToImmutableTransform: JsToImmutableTransform<JS, I>
) {
  const getId = (item: JS): string => {
    const id = getIdFromJsObject(item);

    if (!id) {
      throw new NoIdFoundOnJSObjectError(item);
    }

    return id;
  };

  const transformItem = (item: JS): I => {
    try {
      return jsToImmutableTransform(item);
    } catch (err: any) {
      throw new ErrorTransformingObjectToImmutableError(err, item);
    }
  };

  const getUpdatedItem = (storedMap: Map<string, I>, newItem: JS): I => {
    const storedItem = storedMap.get(getId(newItem));
    if (!storedItem)
      throw new TryingToUpdateBeforeReceivingError(getId(newItem));
    const storedItemJS = storedItem.toJS();
    return transformItem({ ...storedItemJS, ...newItem });
  };

  return (stored: any = Map<string, I>(), action: Record<string, any> = {}) => {
    const item = action.payload;
    switch (action.type) {
      case actionTypes.RECEIVE_ONE:
        return stored.set(getId(item), transformItem(item));
      case actionTypes.UPDATE:
        return stored.setIn([getId(item)], getUpdatedItem(stored, item));

      case actionTypes.RECEIVE_MANY:
        return stored.withMutations((mutableStored: any) =>
          action.payload.reduce(
            (acc: any, item: any) => acc.set(getId(item), transformItem(item)),
            mutableStored
          )
        );

      case actionTypes.DELETE:
        return stored.delete(item);

      default:
        return stored;
    }
  };
}
