import {
  createSubscription,
  createSubscriptionActionFactory
} from "seneca-common/features/subscriptions/state";
import { DEFAULT_CREATED_SUBSCRIPTION_ID } from "seneca-common/features/subscriptions/state/consts";
import MissingArgumentsError from "seneca-common/utils/errors/MissingArgumentsError";

interface SubscriptionsClient {
  readonly createSubscription: (arg0: {
    stripePaymentMethodId: string;
    subscriptionProductId: string;
    cardHolderEmail?: string;
  }) => Promise<any>;
}

// For testing purposes we're able to inject a mock client
const makeCreateSubscriptionFactory = (getClient: () => SubscriptionsClient) =>
  createSubscriptionActionFactory({
    requestFunction: body => getClient().createSubscription(body),
    storeAction: createSubscription
  });

export const makeCreateSubscription = (
  getClient: () => SubscriptionsClient
) => {
  const createSubscription = makeCreateSubscriptionFactory(getClient);

  return ({
    stripePaymentMethodId,
    subscriptionProductId,
    cardHolderEmail,
    addonProductIds
  }: {
    stripePaymentMethodId: string;
    subscriptionProductId: string;
    cardHolderEmail?: string;
    addonProductIds: string[] | undefined;
  }) => {
    const missingParams: string[] = [];
    !stripePaymentMethodId && missingParams.push("stripePaymentMethodId");
    !subscriptionProductId && missingParams.push("subscriptionProductId");

    if (missingParams.length > 0)
      throw new MissingArgumentsError(missingParams);

    // @ts-ignore
    return createSubscription(
      [
        {
          paymentMethodId: stripePaymentMethodId,
          subscriptionProductId,
          cardHolderEmail,
          addonProductIds
        }
      ],
      DEFAULT_CREATED_SUBSCRIPTION_ID
    );
  };
};
