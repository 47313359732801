import actionTypes from "../action-types/signInState";
import SignInState from "../models/SignInState";

function getSignInSuccessData() {
  return {
    signingIn: false,
    signedIn: true,
    signedInAsGuest: false,
    error: false,
    hasSignedInBefore: true
  };
}

export default function signInState(
  signInState: SignInState = new SignInState(),
  action: Record<string, any>
): SignInState {
  switch (action.type) {
    case actionTypes.SET_SIGN_IN:
      return signInState.set("signedIn", true);

    case actionTypes.SIGN_IN_START:
      return signInState.set("signingIn", true);

    case actionTypes.SIGN_IN_SUCCESS:
      return signInState.merge({
        signInTime: action.payload.signInTime,
        ...getSignInSuccessData()
      });

    case actionTypes.SIGN_UP_SUCCESS:
      return signInState.merge({
        ...getSignInSuccessData(),
        signInTime: action.payload.signInTime,
        isFirstSession: true
      });

    case actionTypes.SIGN_IN_SUCCESS_AS_GUEST:
      return signInState.merge({
        signingIn: false,
        signedIn: false,
        signedInAsGuest: true,
        signInAsGuestTime: action.payload.signInTime,
        error: false
      });

    case actionTypes.SIGN_IN_ERROR:
      return signInState.merge({
        signingIn: false,
        signedIn: false,
        error: true
      });

    case actionTypes.SIGN_OUT_START:
      return signInState.set("signingOut", true);

    case actionTypes.SIGN_OUT_ERROR:
      return signInState.set("signingOut", false);

    case actionTypes.SET_USER_HAS_SIGNED_UP_BEFORE:
      return signInState.set("hasSignedInBefore", true);

    case actionTypes.REFRESH_CREDENTIALS:
      return signInState.update("refreshHistory", refreshHistory =>
        refreshHistory.push(action.payload)
      );

    case actionTypes.REFRESH_CREDENTIALS_ERROR:
      return signInState.set("refreshCredentialsError", true);

    case actionTypes.SET_PROMPT_TO_CONNECT_PROVIDER_SSO:
      return signInState.set("promptToConnectProviderSSO", action.payload);

    default:
      return signInState;
  }
}
