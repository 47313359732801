export const DELAY_DURATIONS = Object.freeze({
  SUBMIT_SCORE: 900,
  DEFAULT_SESSION_SCROLL_DELAY: 500,
  SESSION_DEBOUNCE_DELAY: 50,
  SESSION_AUTO_SCROLL_DELAY: 50
});

// Probability of getting a no memory message, rather than promo one
export const PROBABILITY_OF_NO_MEMORY_MESSAGE = 0.25;

// Winning memories
export const N_SESSIONS_BEFORE_GUARANTEED_MEMORY = 1;
export const MAX_CHANCE_OF_WINNING_MEMORY = 0.4;
export const MIN_CHANCE_OF_WINNING_MEMORY = 0.05;

// Add Course Page
//make a multiple of 2 and 3 to render nicely on page
export const NUMBER_OF_CARDS_TO_INITIALLY_DISPLAY = 42;
export const NUMBER_OF_CARDS_INCREMENT = 42;

// Map
export const MAP_UNLOCK_TARGETS = {
  MEMORIES: 3,
  SESSION_MODULES_STUDIED: 50,
  COURSES_ENROLLED: 3
};

export const MAX_SESSION_DURATION = 1187000; // 19m47s in ms
export const MAX_MODULE_DURATION = 180000; // 3m in ms

export const NUMBERS_TO_WORDS = {
  "0": ["zero", "nought", "zéro", "null", "kein", "keine", "keins"],
  "1": ["one", "uno", "un", "um", "eins", "ein", "eine"],
  "2": ["two", "dos", "deux", "dois", "zwei", "zwo"],
  "3": ["three", "tres", "trois", "três", "drei"],
  "4": ["four", "cuatro", "quatre", "quatro", "vier"],
  "5": ["five", "cinco", "cinq", "cinco", "fünf"],
  "6": ["six", "seis", "six", "seis", "sechs"],
  "7": ["seven", "siete", "sept", "sete", "sieben"],
  "8": ["eight", "ocho", "huit", "oito", "acht"],
  "9": ["nine", "nueve", "neuf", "nove", "neun"],
  "10": ["ten", "diez", "dix", "dez", "zehn"],
  "11": ["eleven", "once", "onze", "onze", "elf"],
  "12": ["twelve", "doce", "douze", "doze", "zwölf"],
  "13": ["thirteen", "trece", "treize", "treze", "dreizehn"],
  "14": ["fourteen", "catorce", "quatorze", "quatorze", "vierzehn"],
  "15": ["fifteen", "quince", "quinze", "quinze", "fünfzehn"],
  "16": ["sixteen", "dieciséis", "seize", "dezesseis", "sechzehn"],
  "17": ["seventeen", "diecisiete", "dix-sept", "dezessete", "siebzehn"],
  "18": ["eighteen", "dieciocho", "dix-huit", "dezoito", "achtzehn"],
  "19": ["nineteen", "diecinueve", "dix-neuf", "dezenove", "neunzehn"],
  "20": ["twenty", "veinte", "vingt", "vinte", "zwanzig"],
  "30": ["thirty", "treinta", "trente", "trinta", "dreißig"],
  "40": ["forty", "cuarenta", "quarante", "quarenta", "vierzig"],
  "50": ["fifty", "cincuenta", "cinquante", "cinquenta", "fünfzig", "fuffzig"],
  "60": ["sixty", "sesenta", "soixante", "sessenta", "sechzig"],
  "70": ["seventy", "setenta", "soixante-dix", "setenta", "siebzig"],
  "80": ["eighty", "ochenta", "quatre-vingts", "oitenta", "achtzig"],
  "90": ["ninety", "noventa", "quatre-vingt-dix", "noventa", "neunzig"],
  "100": ["hundred", "cien", "cent", "cem", "hundert"]
};

export const ALL_COURSES_QUERY_SIZE = 2000;
