import { composeSelectors } from "seneca-common/utils/selectors/compose-selectors";

import { AllowedCallTypes, ApiStates, CrudConfig } from "../const";
import {
  didError,
  didSucceed,
  getErrorMessage,
  getLastFetchedTime,
  hasFinished,
  hasStarted,
  notSucceededOrStarted
} from "./apiStates";
import { generateStateKey } from "./crudStates";

function makeGetCallTypeSlice(callType: AllowedCallTypes) {
  return function (state: ApiStates) {
    return state.get(generateStateKey(callType));
  };
}

export function makeApiStateSelectors(crudConfig: CrudConfig) {
  const callTypes = crudConfig.callTypes || Object.values(AllowedCallTypes);
  return callTypes.reduce((selectors, callType) => {
    const getApiState = makeGetCallTypeSlice(callType);
    // @ts-ignore
    selectors[callType] = composeSelectors(getApiState, {
      hasStarted,
      didSucceed,
      didError,
      hasFinished,
      notSucceededOrStarted,
      getErrorMessage,
      getLastFetchedTime
    });
    return selectors;
  }, {});
}

export default function makeApiStatesSelectorsFactory(crudConfig: CrudConfig) {
  return function (apiStatesSelector: (arg0: any) => ApiStates) {
    const selectors = makeApiStateSelectors(crudConfig) as any;
    return Object.keys(selectors).reduce((composedSelectors, callType) => {
      return {
        ...composedSelectors,
        [callType]: composeSelectors(apiStatesSelector, selectors[callType])
      };
    }, {});
  };
}
