export enum AppVariants {
  plurall = "plurall",
  goStudentLearning = "goStudentLearning",
  seneca = "seneca"
}

export enum GoStudentLearningAppVariants {
  goStudent = "goStudent",
  studienkreis = "studienkreis"
}

export type AppVariant = keyof typeof AppVariants;

export type GoStudentLearningAppVariant =
  keyof typeof GoStudentLearningAppVariants;
