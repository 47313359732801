import { Map } from "immutable";

import { isIn } from "seneca-common/utils/functions/index";

import sessionModuleActionTypes from "../action-types/sessionModule";
import sessionModule from "./sessionModule";

const sessionModules = (sessionModules = Map<string, any>(), action: any) => {
  switch (action.type) {
    case sessionModuleActionTypes.NEW_SESSION_MODULE:
      return sessionModules.setIn(
        [action.payload.sessionId, action.payload.sessionModuleId],
        sessionModule(undefined, action)
      );

    default: {
      if (isIn(action.type, sessionModuleActionTypes)) {
        return sessionModules.updateIn(
          [action.payload.sessionId, action.payload.sessionModuleId],
          // @ts-ignore
          module => sessionModule(module, action)
        );
      } else {
        return sessionModules;
      }
    }
  }
};

export default sessionModules;
