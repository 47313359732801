import { Map, Record } from "immutable";

export type SessionModuleType<Content, Answer> = {
  contentId: string | undefined;
  contentModuleId: string | undefined;
  moduleType: string | undefined;
  textToSpeechDisabled: boolean | undefined;
  completed: boolean;
  testingActive: boolean;
  content: Content;
  userAnswer?: Answer;
  score?: number;
  submitted: boolean;
  moduleState: Map<string, any>;
  gaveUp: boolean;
  timeStarted: string | undefined;
  timeFinished?: string | null;
};

export type OptionalSessionModuleType<C, A> = Partial<SessionModuleType<C, A>>;

const defaultValues: SessionModuleType<any, any> = {
  contentId: undefined,
  contentModuleId: undefined,
  completed: false,
  moduleType: undefined,
  textToSpeechDisabled: undefined,
  testingActive: false,
  content: undefined,
  userAnswer: undefined,
  score: undefined,
  submitted: false,
  moduleState: Map<string, any>(),
  gaveUp: false,
  timeStarted: undefined,
  timeFinished: null
};

export default class SessionModule extends Record(defaultValues) {}
