import { List, Record } from "immutable";

import Product from "./product/Product";
import ProductDescription from "./productDescription/ProductDescription";

export const SUBSCRIPTION_PRODUCT_PAYMENT_FREQUENCIES = Object.freeze({
  annually: "annually",
  monthly: "monthly",
  seasonal: "seasonal"
});

export type SubscriptionProductPaymentFrequencies =
  keyof typeof SUBSCRIPTION_PRODUCT_PAYMENT_FREQUENCIES;

export type SubscriptionProductType = {
  id: string | undefined;
  description: string | undefined;
  price: number | undefined;
  paymentFrequency: SubscriptionProductPaymentFrequencies | undefined;
  promoted: boolean | undefined;
  product: Product | undefined;
  productDescription: ProductDescription | undefined;
  canBeUpgraded: boolean | undefined;
  isAddon: boolean | undefined;
  misSubscriptionBooster: boolean | undefined;
  addonProductIds: List<string>;
};

export type OptionalSubscriptionProductType = Partial<SubscriptionProductType>;

const defaultValues: SubscriptionProductType = {
  id: undefined,
  description: undefined,
  price: undefined,
  paymentFrequency: undefined,
  promoted: undefined,
  product: new Product(),
  productDescription: new ProductDescription(),
  canBeUpgraded: undefined,
  isAddon: undefined,
  misSubscriptionBooster: undefined,
  addonProductIds: List()
};

export default class SubscriptionProduct extends Record(defaultValues) {}
