import { ComponentType } from "react";

import { ModuleType as ModuleNames } from "seneca-common/features/module/module-types/types";

import { ProcessContentSource } from "features/course-creation/module/module-types/types";

export type ModuleFormType = {
  Form: ComponentType<any>;
  processContentSource: ProcessContentSource;
};

export default class ModuleForm {
  _moduleName: ModuleNames;

  Form: ComponentType<any>;
  processContentSource: ProcessContentSource;

  constructor(
    moduleName: ModuleNames,
    { Form, processContentSource }: ModuleFormType
  ) {
    this._moduleName = moduleName;

    this.Form = Form;
    this.processContentSource = processContentSource;
  }

  getForm(): ComponentType<any> {
    return this.Form;
  }

  getProcessContentSource(): ProcessContentSource {
    return this.processContentSource;
  }
}
