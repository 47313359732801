import { useConfig } from "config";

import { useFeatureFlag } from "features/feature-flags";

/**
 * Note: In most cases on the student platform, you don't want to use this hook because it leads to unmaintainable code due to multiple app variants (Seneca, Plurall, GoStudentLearning).
 * Instead, create your own hook using `useAppVariant` hook (check existing usages of that hook for examples).
 *
 * Once GoStudent Learning variant becomes available on teacher and parent platforms, this hook can be entirely replaced by `useAppVariant`.
 */
export default function useIsPlurallApp() {
  const { config } = useConfig();
  const isPlurallApp = (config && config.isPlurallApp) as boolean;

  return !!useFeatureFlag("isPlurallApp") || isPlurallApp;
}
