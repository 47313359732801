import Meta from "../../../models/meta/Meta";

export function getCourseId(state: Meta) {
  return state.courseId;
}

export function getShareTopicModalOpen(state: Meta): boolean {
  return state.shareTopicModalOpen;
}

export function getLastCourseSearchTerm(state: Meta): string {
  return state.lastCourseSearchTerm;
}
