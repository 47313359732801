import { createBrowserHistory } from "history";
import { lazy, Suspense, useEffect, useState } from "react";

import registerClients from "services/registerClients";

import {
  initializeGoogleTagManager,
  pushGlobalValuesToDataLayer,
  pushUserPropertiesToDataLayer
} from "seneca-common/features/analytics/gtm";
import { initialiseLocalize } from "seneca-common/features/localisation";
import useEffectOnMount from "seneca-common/utils/hooks/useEffectOnMount";
import loaderWithRetry from "seneca-common/utils/loaderWithRetry";

import lazyWithPreload from "features/app/components/lazyWithPreload";
import ResumeSession from "features/app/components/ResumeSession";
import { CommandMenu } from "features/command-menu/CommandMenu";
import GeneralAppError, {
  SimpleAppError
} from "features/common/components/AppErrors";
import { ErrorBoundary } from "features/common/components/ErrorHandler";
import { PromoContextProvider } from "features/common/components/PromoTemplate/PromoProvider";
import {
  getMobileDeviceType,
  isMobile
} from "features/common/utils/functions/mobile";
import DevToolsProvider from "features/dev-tools/Provider";
import { LocalisationProvider } from "features/localisation";

import { useConfig } from "./config";
import populateCypressGlobals from "./cypressGlobals";
import AppEnvironmentProvider from "./providers/AppEnvironmentProvider";
import AppStateProviders from "./providers/AppStateProviders";
import LayoutProviders from "./providers/LayoutProvider";
import NotificationsProvider from "./providers/NotificationsProvider";
import RoutingProviders from "./providers/RoutingProvider";
import getStore from "./store";

const OfflineScreen = lazy(() =>
  loaderWithRetry(
    () =>
      import(/* webpackChunkName: "offline-screen" */ "features/offline-screen")
  )
);

const Routes = lazyWithPreload(
  () => import(/* webpackChunkName: "routes" */ "./Routes")
);

Routes.preload();

export default function App() {
  return (
    <ErrorBoundary
      errorBoundaryName="OuterApp"
      fallbackRender={() => <SimpleAppError />}
    >
      <AppProviders>
        <Suspense>
          <Routes />
          <CommandMenu />
        </Suspense>
      </AppProviders>
    </ErrorBoundary>
  );
}

function AppProviders({ children }: { children: React.ReactElement }) {
  const [history] = useState(() => createBrowserHistory());
  const { config, configError } = useConfig();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (!initialized) {
      initialiseLocalize();
      populateCypressGlobals();
      initializeGoogleTagManager();
      const store = getStore(history);
      registerClients(config, store);
      setInitialized(true);
    }
  }, [initialized, config, history]);

  useEffectOnMount(() => {
    pushGlobalValuesToDataLayer({
      appVersion: process.env.REACT_APP_VERSION,
      isMobile: isMobile(),
      mobileDeviceType: getMobileDeviceType()
    });
    pushUserPropertiesToDataLayer({
      appVersion: process.env.REACT_APP_VERSION
    });
  });

  if (configError)
    return (
      <Suspense>
        <OfflineScreen />
      </Suspense>
    );

  return (
    <RoutingProviders history={history}>
      <AppStateProviders history={history}>
        <LayoutProviders>
          <ResumeSession initialized={initialized}>
            <NotificationsProvider>
              <DevToolsProvider>
                <AppEnvironmentProvider>
                  <LocalisationProvider>
                    <PromoContextProvider>
                      <ErrorBoundary
                        errorBoundaryName="App"
                        fallbackRender={({ resetErrorBoundary }) => (
                          <GeneralAppError
                            resetErrorBoundary={resetErrorBoundary}
                          />
                        )}
                      >
                        {children}
                      </ErrorBoundary>
                    </PromoContextProvider>
                  </LocalisationProvider>
                </AppEnvironmentProvider>
              </DevToolsProvider>
            </NotificationsProvider>
          </ResumeSession>
        </LayoutProviders>
      </AppStateProviders>
    </RoutingProviders>
  );
}
