import { Box, Heading } from "@seneca/senekit";

import ErrorImage from "./ErrorImage";

export default function AppErrorWrapper({
  heading = "Oops, something went wrong",
  children
}: {
  heading?: string;
  children?: React.ReactNode;
}) {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
    >
      <ErrorImage />
      <Heading level="2" align="center">
        {heading}
      </Heading>
      {children}
    </Box>
  );
}
