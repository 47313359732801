import { isIOS } from "features/common/utils/functions/mobile";
import { getFeatureFlag } from "features/feature-flags";

function isAppLoadedFromWebView() {
  return (
    navigator.userAgent.includes("SenecaWebView") &&
    navigator.userAgent.includes("iOS")
  );
}

export function isNativeAppiOS() {
  const iosWebViewFlag = getFeatureFlag("iosWebView");
  return (isAppLoadedFromWebView() && isIOS()) || !!iosWebViewFlag;
}

export function isNativeAppiOSAndHidePremium() {
  const shouldAdvertisePremium = getFeatureFlag("advertisePremiumiOS");
  return isNativeAppiOS() && !shouldAdvertisePremium;
}
