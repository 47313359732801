import { regionCodesAndRedirects } from "common/static/regions";

import { getClient } from "services";

import logError from "seneca-common/utils/sentry/logError";

export const getRegionFromIp = async () => {
  try {
    const ipInfo = await getClient("geolocationService").fetchIpInfo();
    const regionFromIp =
      ipInfo.countryCode as keyof typeof regionCodesAndRedirects;
    return regionCodesAndRedirects[regionFromIp];
  } catch (err: any) {
    logError(err);
    return null;
  }
};
