import { makeCrudStateActionFactories } from "seneca-common/utils/apiStates";

import actionTypes from "../action-types";
import { userInfoCrudStateConfig } from "../consts";
import { UserType } from "../models/User";

export function setUser(user: UserType) {
  return {
    type: actionTypes.SET_USER,
    payload: {
      user
    }
  };
}

export const updateUserPersonalInformation = (updates: Partial<UserType>) => ({
  type: actionTypes.UPDATE_USER_PERSONAL_INFO,
  payload: updates
});

const {
  fetchAllActionFactory: userInfoFetchActionFactory,
  createActionFactory: userInfoCreateActionFactory,
  updateActionFactory: userInfoUpdateActionFactory
} = makeCrudStateActionFactories(userInfoCrudStateConfig);

export {
  userInfoFetchActionFactory,
  userInfoCreateActionFactory,
  userInfoUpdateActionFactory
};
