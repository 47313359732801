import { PushType } from "@seneca/notifications-service-types";

import { isAndroid, isIOS } from "features/common/utils/functions/mobile";

import { PreferencesType } from "../types";

export default function getNotificationPreferenceTypeServiceName(
  type: PreferencesType
) {
  return type === "push" ? getPushPropName() : "email";
}

function getPushPropName(): PushType {
  if (isIOS()) return "push-ios";
  if (isAndroid()) return "push-android";
  return "push-web";
}
