import { combineReducers } from "redux-immutable";

import actionTypes from "../action-types";
import User from "../models/User";
import UserStateSlice from "../models/UserStateSlice";
import meta from "./meta";
import passwordReset from "./passwordResetState";
import preferences from "./preferences";
import signInState from "./signInState";

function user(user: User = new User(), action: Record<string, any>): User {
  switch (action.type) {
    case actionTypes.SET_USER:
      return user.merge(
        new User({
          yearGroup: action.payload.user.currentYearGroup,
          ...action.payload.user
        })
      );

    case actionTypes.UPDATE_USER_PERSONAL_INFO:
      return user.merge(action.payload);

    default:
      return user;
  }
}

const userReducer = combineReducers(
  {
    user,
    signInState,
    passwordReset,
    preferences,
    meta
  },
  () => new UserStateSlice() as any
);

export default userReducer;
