import {
  cancelSubscription,
  updateSubscriptionActionFactory
} from "seneca-common/features/subscriptions/state";

interface SubscriptionsClient {
  readonly cancelSubscriptionById: (id: string) => Promise<void>;
}

export const makeCancelSubscription = (
  getClient: () => SubscriptionsClient
) => {
  return updateSubscriptionActionFactory({
    requestFunction: (subscriptionId: string) =>
      getClient().cancelSubscriptionById(subscriptionId),
    storeAction: cancelSubscription // will be called on success
  });
};
