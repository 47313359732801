import { pushUserPropertiesToDataLayer } from "../../gtm";
import { SelectorsDetails } from "../types";
import pushSelectorOutputsToDataLayer from "./pushSelectorOutputsToDataLayer";

export default function pushUserPropertiesSelectorOutputsToDataLayer(
  selectors: SelectorsDetails,
  lastSelectorOutputsCache: Record<string, any>
) {
  return pushSelectorOutputsToDataLayer({
    selectors,
    pushValuesToDataLayer: pushUserPropertiesToDataLayer,
    lastSelectorOutputsCache,
    triggerUpdateUserPropertiesEvent: true
  });
}
