import { ModuleType as ModuleNames } from "seneca-common/features/module/module-types/types";

export type ModuleStaticInformationType = {
  displayName: string;
  testOnly: boolean;
  readOnly: boolean;
  supportsAmeliaExplainMyAnswer: boolean;
};

export default class ModuleStaticInformation {
  _moduleName: ModuleNames;

  displayName: string;
  testOnly: boolean;
  readOnly: boolean;
  supportsAmeliaExplainMyAnswer: boolean;

  constructor(
    moduleName: ModuleNames,
    {
      displayName,
      testOnly,
      readOnly,
      supportsAmeliaExplainMyAnswer
    }: ModuleStaticInformationType
  ) {
    this._moduleName = moduleName;

    this.displayName = displayName;
    this.testOnly = testOnly;
    this.readOnly = readOnly;
    this.supportsAmeliaExplainMyAnswer = supportsAmeliaExplainMyAnswer;
  }

  getDiplayName(): string {
    return this.displayName;
  }

  isTestOnly(): boolean {
    return this.testOnly;
  }

  isReadOnly(): boolean {
    return this.readOnly;
  }

  hasAmeliaExplainMyAnswerSupport(): boolean {
    return this.supportsAmeliaExplainMyAnswer;
  }
}
