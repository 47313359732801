import { List, Record } from "immutable";

import SmartLearningSuggestionReason from "seneca-common/features/smart-learning/models/SuggestionReason";

import SessionItem from "./SessionItem";
import SessionOptions from "./SessionOptions";
import { SessionType } from "./SessionType";
import SessionXp from "./SessionXp";

export type SessionModelType = {
  id: string | null;
  startTime: string | null;
  endTime: string | null;
  startingProficiency: number | null;
  startingCourseProficiency: number | null;
  startingBestScore: number | null | undefined;
  endingProficiency: number | null | undefined;
  endingCourseProficiency: number | null | undefined;
  endingCourseScore: number | null | undefined;
  sessionScore: number | null | undefined;
  completed: boolean;
  courseId?: string;
  sectionIds: List<string>;
  contentIds: List<string>;
  sessionType: SessionType | null;
  items: List<SessionItem>;
  moduleIdsWhitelist: List<string>;
  wonMemory: boolean;
  smartLearningSuggestionReason: SmartLearningSuggestionReason | undefined;
  focusedItemId: string | null;
  completesAssignment: boolean | null;
  xp?: SessionXp;
  options?: SessionOptions;
};

export type OptionalSessionType = Partial<SessionModelType>;

const defaultSession: SessionModelType = {
  id: null,
  startTime: null,
  endTime: null,
  startingProficiency: null,
  startingCourseProficiency: null,
  startingBestScore: null,
  endingProficiency: null,
  endingCourseProficiency: null,
  endingCourseScore: null,
  sessionScore: null,
  completed: false,
  courseId: undefined,
  sectionIds: List(),
  contentIds: List(),
  sessionType: null,
  items: List(),
  moduleIdsWhitelist: List(),
  wonMemory: false,
  smartLearningSuggestionReason: undefined,
  focusedItemId: null,
  completesAssignment: null,
  xp: undefined,
  options: new SessionOptions()
};

export default class Session extends Record(defaultSession) {}
