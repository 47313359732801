import AppErrorWrapper from "./components/AppErrorWrapper";

export default function PageNotFoundError({
  children
}: {
  children?: React.ReactNode;
}) {
  return (
    <AppErrorWrapper heading="Sorry, looks like this page does not exist!">
      {children}
    </AppErrorWrapper>
  );
}
