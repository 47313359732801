export const PushNotificationsSourceParamKey = "pushNotificationSource";
export const EmailIdSourceParamKey = "emailId";

export enum NotificationType {
  PUSH = "push",
  EMAIL = "email"
}

export enum NotificationStateKeys {
  PUSH_SOURCE = "pushNotificationSource",
  TYPE = "notificationType",
  EMAIL_ID = "emailId"
}
