const actionTypes = {
  SET_SIGN_IN: "seneca/user/signIn/SET_SIGN_IN",
  SIGN_OUT_START: "seneca/user/signIn/SIGN_OUT_START",
  SIGN_OUT_SUCCESS: "seneca/user/signIn/SIGN_OUT_SUCCESS",
  RESET_STORE: "seneca/user/signIn/RESET_STORE",
  SIGN_IN_START: "seneca/user/signIn/SIGN_IN_START",
  SIGN_IN_SUCCESS: "seneca/user/signIn/SIGN_IN_SUCCESS",
  SIGN_UP_SUCCESS: "seneca/user/signIn/SIGN_UP_SUCCESS",
  SIGN_IN_SUCCESS_AS_GUEST: "seneca/user/signIn/SIGN_IN_SUCCESS_AS_GUEST",
  SIGN_IN_ERROR: "seneca/user/signIn/SIGN_IN_ERROR",
  SIGN_OUT_ERROR: "seneca/user/signIn/SIGN_OUT_ERROR",
  SET_USER_HAS_SIGNED_UP_BEFORE: "seneca/user/SET_USER_HAS_SIGNED_UP_BEFORE",
  REFRESH_CREDENTIALS: "seneca/user/signIn/REFRESH_CREDENTIALS",
  REFRESH_CREDENTIALS_ERROR: "seneca/user/signIn/REFRESH_CREDENTIALS_ERROR",
  SET_PROMPT_TO_CONNECT_PROVIDER_SSO:
    "seneca/user/signIn/SET_PROMPT_TO_CONNECT_PROVIDER_SSO"
};

export default actionTypes;
