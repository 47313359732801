import { ActionCreatorConfig } from "../const";
import { processCallType, processName } from "../utils";

const startActionKey = ({ callType, name }: ActionCreatorConfig): string => {
  const { callTypeCaps } = processCallType(callType);
  const { capsName } = processName(name);
  return `${callTypeCaps}_${capsName}_START`;
};

const successActionKey = ({ callType, name }: ActionCreatorConfig): string => {
  const { callTypeCaps } = processCallType(callType);
  const { capsName } = processName(name);
  return `${callTypeCaps}_${capsName}_SUCCESS`;
};

const errorActionKey = ({ callType, name }: ActionCreatorConfig): string => {
  const { callTypeCaps } = processCallType(callType);
  const { capsName } = processName(name);
  return `${callTypeCaps}_${capsName}_ERROR`;
};

const resetActionKey = ({ callType, name }: ActionCreatorConfig): string => {
  const { callTypeCaps } = processCallType(callType);
  const { capsName } = processName(name);
  return `${callTypeCaps}_${capsName}_RESET`;
};

export { startActionKey, successActionKey, errorActionKey, resetActionKey };
