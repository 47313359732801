import { actionTypes } from "features/ui-state/actions";
import UiState from "features/ui-state/models/UiState";

export default function uiStateReducer(
  uiState: UiState = new UiState(),
  action: any
) {
  switch (action.type) {
    case actionTypes.SET_MODAL_OPEN:
      return uiState.update(
        "modalsOpenCount",
        modalsOpenCount => modalsOpenCount + 1
      );

    case actionTypes.SET_MODAL_CLOSED:
      return uiState.update("modalsOpenCount", modalsOpenCount =>
        Math.max(0, modalsOpenCount - 1)
      );

    case actionTypes.SET_MOBILE_KEYBOARD_OPEN:
      return uiState.set("mobileKeyboardOpen", true);

    case actionTypes.SET_MOBILE_KEYBOARD_CLOSED:
      return uiState.set("mobileKeyboardOpen", false);

    case actionTypes.SET_SMART_LEARNING_TRIAL_ACTIVE:
      return uiState.set("smartLearningFreeTrialActive", true);

    case actionTypes.SET_SMART_LEARNING_TRIAL_INACTIVE:
      return uiState.set("smartLearningFreeTrialActive", false);

    case actionTypes.SET_PLURALL_HELP_CENTRE_MODAL_OPEN:
      return uiState.set("showPlurallHelpCentreModal", true);

    case actionTypes.SET_PLURALL_HELP_CENTRE_MODAL_CLOSED:
      return uiState.set("showPlurallHelpCentreModal", false);

    default:
      return uiState;
  }
}
