import useEmailNotificationsAnalytics from "./hooks/useEmailNotificationsAnalytics";
import useGetNotificationAnalyticsFromQueryParams from "./hooks/useGetNotificationAnalyticsFromQueryParams";
import usePushNotificationsAnalytics from "./hooks/usePushNotificationsAnalytics";

type Props = {
  children?: React.ReactElement;
};

export default function NotificationsAnalytics({ children }: Props) {
  const { pushSource, notificationType, emailId } =
    useGetNotificationAnalyticsFromQueryParams();

  useEmailNotificationsAnalytics({ notificationType, emailId });
  usePushNotificationsAnalytics({ notificationType, pushSource });

  return null;
}
