import { logMessage as default_logMessage } from "seneca-common/utils/sentry/logError";

import { pushEventToDataLayer } from "../../gtm";
import { SelectorsDetails } from "../types";

const UPDATE_USER_PROPERTIES_EVENT = "seneca/gtm/updateUserProperties";

type PushValuesToDataLayer = (arg0: Record<string, any>) => void;

let errorLog: any[] = [];
type PushSelectorOutputsToDataLayerArgs = {
  selectors: SelectorsDetails;
  pushValuesToDataLayer: PushValuesToDataLayer;
  lastSelectorOutputsCache: Record<string, any>;
  logErrorMessage?: typeof default_logMessage;
  triggerUpdateUserPropertiesEvent?: boolean;
};

export default function pushSelectorOutputsToDataLayer({
  selectors,
  pushValuesToDataLayer,
  lastSelectorOutputsCache,
  logErrorMessage = default_logMessage,
  triggerUpdateUserPropertiesEvent
}: PushSelectorOutputsToDataLayerArgs) {
  if (!lastSelectorOutputsCache) {
    throw new Error(
      `You need to pass an lastSelectorOutputsCache to pushSelectorOutputsToDataLayer, this is used to store the last outputs of the selectors so that we can only push changed values to the data layer. Make sure this cache remains the same across all calls/renders!`
    );
  }

  return (state: any) => {
    const changesToPushToTheDataLayer = {};

    selectors.forEach(({ dataLayerValueName, dataLayerScope, selector }) => {
      try {
        const value = selector(state);

        if (dataLayerScope) {
          addScopedValueToChangesIfNecessary(
            changesToPushToTheDataLayer,
            value,
            dataLayerScope,
            dataLayerValueName,
            lastSelectorOutputsCache
          );
        } else {
          addNoneScopedValueToChangesIfNecessary(
            changesToPushToTheDataLayer,
            value,
            dataLayerValueName,
            lastSelectorOutputsCache
          );
        }
      } catch (err: any) {
        errorLog.push({
          dataLayerValueName,
          dataLayerScope,
          err
        });
      }
    });

    logErrorIfNecessary(logErrorMessage);

    if (Object.keys(changesToPushToTheDataLayer).length > 0) {
      pushValuesToDataLayer(changesToPushToTheDataLayer);

      if (triggerUpdateUserPropertiesEvent) {
        pushEventToDataLayer({
          event: UPDATE_USER_PROPERTIES_EVENT
        });
      }
    }
  };
}

function addScopedValueToChangesIfNecessary(
  changesToPushToTheDataLayerObj: Record<string, any>,
  value: any,
  dataLayerScope: string,
  dataLayerValueName: string,
  lastSelectorOutputsCache: Record<string, any>
) {
  const lastValue = lastSelectorOutputsCache[dataLayerScope]
    ? lastSelectorOutputsCache[dataLayerScope][dataLayerValueName]
    : undefined;

  if (value !== lastValue) {
    setNestedKey(
      lastSelectorOutputsCache,
      dataLayerScope,
      dataLayerValueName,
      value
    );
    setNestedKey(
      changesToPushToTheDataLayerObj,
      dataLayerScope,
      dataLayerValueName,
      value
    );
  }
}

function addNoneScopedValueToChangesIfNecessary(
  changesToPushToTheDataLayerObj: Record<string, any>,
  value: any,
  dataLayerValueName: string,
  lastSelectorOutputsCache: any
) {
  if (value !== lastSelectorOutputsCache[dataLayerValueName]) {
    lastSelectorOutputsCache[dataLayerValueName] = value;
    changesToPushToTheDataLayerObj[dataLayerValueName] = value;
  }
}

function setNestedKey(
  obj: Record<string, any>,
  key1: string,
  key2: string,
  value: any
) {
  if (!obj[key1]) {
    obj[key1] = {};
  }

  obj[key1][key2] = value;
}

let sentryErrorCount = 0;

function logErrorIfNecessary(logErrorMessage: typeof default_logMessage) {
  // These function run on every store update so we want to avoid the case where
  // a single user can spam our error logging.
  if (sentryErrorCount < 10 && errorLog.length > 0) {
    sentryErrorCount++;

    const extraInfo = {};

    errorLog.forEach(({ dataLayerValueName, dataLayerScope, err }) => {
      const dataKey = dataLayerScope
        ? `${dataLayerScope}.${dataLayerValueName}`
        : dataLayerValueName;

      (extraInfo as any)[`Selector error for: ${dataKey}`] = err.message;
    });

    logErrorMessage("Error adding selector output to the analytics dataLayer", {
      fingerprint: ["Analytics"],
      extraInfo
    });
  }

  errorLog = [];
}
