import actionTypes from "../action-types/sessionUI";

export function setScrolledUp() {
  return {
    type: actionTypes.SET_SCROLLED_UP
  };
}

export function setScrolledDown() {
  return {
    type: actionTypes.SET_SCROLLED_DOWN
  };
}

export function setFocusedQuestionId(questionId: string) {
  return {
    type: actionTypes.SET_FOCUSED_QUESTION_ID,
    payload: { questionId }
  };
}
