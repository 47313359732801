import { Box, Heading, MobileBottomSheet, Stack } from "@seneca/senekit";

import { ModalPortal } from "features/common/modals/components/ModalNodeContext";

import { PromptProps } from "../types";
import { OptionRow } from "./components/OptionRow";

export function PromptToUseAppModal({
  handleDismiss,
  handleCtaClick
}: PromptProps) {
  return (
    <ModalPortal>
      <Box position="fixed" top="0" left="0" width="100%" height="100%">
        <MobileBottomSheet>
          <Stack space="24">
            <Heading level="5">Seneca is better on the app!</Heading>
            <Stack space="xlarge">
              <OptionRow
                iconName="seneca"
                iconTone="primary"
                showIconBorder
                label="Seneca"
                buttonText="Use app"
                buttonFill="filled"
                onButtonClick={handleCtaClick}
              />
              <OptionRow
                iconName="globe"
                iconTone="neutral"
                label="Browser"
                buttonText="Continue"
                buttonFill="outlined"
                onButtonClick={handleDismiss}
              />
            </Stack>
          </Stack>
        </MobileBottomSheet>
      </Box>
    </ModalPortal>
  );
}
