import { Client } from "@seneca/client-library";

import { UnionToIntersection } from "common/types/utils";

import { AnyAnswerI } from "./answer-types/AnyAnswer";
import ExamQuestionBadInputError from "./errors/ExamQuestionBadInputError";
import ExamQuestionNotFoundError from "./errors/ExamQuestionNotFoundError";
import { QuestionType } from "./question-types/common";
import { AnyQuestionI } from "./question-types/Question";
import {
  EQCourseSections,
  EQEnrichedCourseSections
} from "./question-types/QuestionCourseSections";
import {
  AnswerMark,
  CourseSectionItemI,
  LinkableSection,
  SubmitAnswerI,
  UpdateAnswerI
} from "./types";

export default class ShortAnswerServiceClient extends Client {
  async batchFetchQuestions(questionIds: string[]) {
    if (questionIds.length === 0) {
      return [];
    }

    const url = `${this.url}/api/questions/batch/fetch`;
    const expectedResponses = [200, 404, 400];

    const response = await this.requestMaker.makeRequest(
      url,
      {
        method: "POST",
        body: JSON.stringify({ questionIds })
      },
      expectedResponses
    );

    return (response.json as any).items as AnyQuestionI[];
  }

  async fetchQuestion(questionId: string) {
    const url = `${this.url}/api/questions/${questionId}`;
    const expectedResponses = [200, 404, 400];

    const response = await this.requestMaker.makeRequest(
      url,
      {
        method: "GET"
      },
      expectedResponses
    );

    if (response.status === 404) {
      throw new ExamQuestionNotFoundError();
    }
    if (response.status === 400) {
      throw new ExamQuestionBadInputError();
    }

    return response.json as AnyQuestionI;
  }

  async submitAnswer({ questionId, ...payload }: SubmitAnswerI) {
    const url = `${this.url}/api/answers/${questionId}`;
    const expectedResponses = [200, 404, 400];

    const response = await this.requestMaker.makeRequest(
      url,
      {
        method: "POST",
        body: JSON.stringify(payload)
      },
      expectedResponses
    );

    if (response.status === 404) {
      throw new ExamQuestionNotFoundError();
    }
    if (response.status === 400) {
      throw new ExamQuestionBadInputError();
    }

    return response.json as AnyAnswerI;
  }

  async updateAnswer({ questionId, ...payload }: UpdateAnswerI) {
    const url = `${this.url}/api/answers/${questionId}`;
    const expectedResponses = [200, 404, 400];

    const response = await this.requestMaker.makeRequest(
      url,
      {
        method: "PATCH",
        body: JSON.stringify(payload)
      },
      expectedResponses
    );

    if (response.status === 404) {
      throw new ExamQuestionNotFoundError();
    }
    if (response.status === 400) {
      throw new ExamQuestionBadInputError();
    }

    return response.json as AnyAnswerI;
  }

  async queryUserAnswers(courseId?: string) {
    const params = [];

    if (courseId) {
      params.push(`courseId=${courseId}`);
    }

    const baseUrl = `${this.url}/api/answers`;
    const url = params.length > 0 ? `${baseUrl}?${params.join("&")}` : baseUrl;

    const expectedResponses = [200];

    const response = await this.requestMaker.makeRequest(
      url,
      {
        method: "GET"
      },
      expectedResponses
    );

    return response.json as any;
  }

  private async queryQuestions(
    { courseId, sectionId }: { courseId?: string | null; sectionId?: string },
    enrich: boolean = false
  ) {
    const params = [];

    if (courseId) {
      params.push(`courseId=${courseId}`);
    }
    if (sectionId) {
      params.push(`sectionId=${sectionId}`);
    }
    if (enrich) {
      params.push(`enrich=question`);
    }

    params.push(`questionTypes=${Object.values(QuestionType).join(",")}`);

    const baseUrl = `${this.url}/api/questions/query`;
    const url = params.length > 0 ? `${baseUrl}?${params.join("&")}` : baseUrl;

    const expectedResponses = [200];

    const response = await this.requestMaker.makeRequest(
      url,
      {
        method: "GET"
      },
      expectedResponses
    );

    return (response.json as any)?.items;
  }

  queryCourseSections(args: { courseId?: string; sectionId?: string }) {
    return this.queryQuestions(args) as Promise<EQCourseSections[]>;
  }

  queryEnrichedCourseSections(args: {
    courseId?: string | null;
    sectionId?: string;
  }) {
    return this.queryQuestions(args, true) as Promise<
      EQEnrichedCourseSections[]
    >;
  }

  async createQuestion(payload: Partial<AnyQuestionI>) {
    const url = `${this.url}/api/questions`;
    const expectedResponses = [201];

    const response = await this.requestMaker.makeRequest(
      url,
      {
        method: "POST",
        body: JSON.stringify(payload)
      },
      expectedResponses
    );

    if ((response.json as any).reason) {
      throw Error((response.json as any).reason);
    }

    return response.json as AnyQuestionI;
  }

  async updateQuestion(
    questionId: string,
    questionToUpdate: Partial<UnionToIntersection<AnyQuestionI>>
  ) {
    const {
      questionId: _,
      difficulty,
      lastTrainedAt,
      modelAccuracy,
      ...payload
    } = questionToUpdate;

    const url = `${this.url}/api/questions/${questionId}`;
    const expectedResponses = [200, 400];
    const response = await this.requestMaker.makeRequest(
      url,
      {
        method: "PATCH",
        body: JSON.stringify(payload)
      },
      expectedResponses
    );

    if ((response.json as any).reason) {
      throw Error((response.json as any).reason);
    }

    return response.json as AnyQuestionI;
  }

  async deleteQuestion(questionId: string) {
    const url = `${this.url}/api/questions/${questionId}`;
    const expectedResponses = [204];

    await this.requestMaker.makeRequest(
      url,
      {
        method: "DELETE"
      },
      expectedResponses
    );
  }

  async linkSections(questionId: string, payload: LinkableSection[]) {
    const url = `${this.url}/api/questions/${questionId}/sections`;
    const expectedResponses = [200];

    const response = await this.requestMaker.makeRequest(
      url,
      {
        method: "POST",
        body: JSON.stringify({ sections: payload })
      },
      expectedResponses
    );

    return (response as any).json.items as CourseSectionItemI[];
  }

  async unlinkSections(questionId: string, payload: LinkableSection[]) {
    const url = `${this.url}/api/questions/${questionId}/sections`;
    const expectedResponses = [200];

    await this.requestMaker.makeRequest(
      url,
      {
        method: "DELETE",
        body: JSON.stringify({ sections: payload })
      },
      expectedResponses
    );
  }

  async uploadAnswers({
    questionId,
    marks,
    skipTraining
  }: {
    questionId: string;
    marks: AnswerMark[];
    skipTraining: boolean;
  }) {
    const url = `${this.url}/api/answers/${questionId}/marks`;
    const expectedResponses = [200, 400];

    const response = await this.requestMaker.makeRequest(
      url,
      {
        method: "POST",
        body: JSON.stringify({ answerMarks: marks, skipTraining })
      },
      expectedResponses
    );

    if (response.status === 400) {
      const reason =
        (response as any)?.json?.reason || "Unknown error occurred";
      if (reason === "AnswersNotInQuestion") {
        throw new Error(
          "Some answers dont exist. Check if answers were uploaded to the wrong question"
        );
      }
      throw new Error(reason);
    }
  }
}
