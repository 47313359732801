import { Record } from "immutable";

export enum SUBSCRIPTION_TIERS {
  supreme = "supreme",
  examBoost = "examBoost",
  aiAssistant = "aiAssistant",
  tutoring = "tutoring",
  guarantee = "guarantee"
}

export type ProductDescriptionType = {
  shortDisplayName: string | undefined;
  longDisplayName?: string | undefined;
  tierName: SUBSCRIPTION_TIERS | undefined;
  iconUrl: string | undefined;
};

export type OptionalProductDescriptionType = Partial<ProductDescriptionType>;

const defaultValues: ProductDescriptionType = {
  shortDisplayName: undefined,
  longDisplayName: undefined,
  tierName: undefined,
  iconUrl: undefined
};

export default class ProductDescription extends Record(defaultValues) {}
