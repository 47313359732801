import { PATH_TO_SUBSCRIPTIONS_STATE } from "seneca-common/features/subscriptions/state/consts";
import Subscription from "seneca-common/features/subscriptions/state/models/subscriptions/subscription/Subscription";
import SubscriptionsStateSlice from "seneca-common/features/subscriptions/state/models/SubscriptionsStateSlice";
import { composeSelector } from "seneca-common/utils/selectors/compose-selectors";

import { getPaymentSlice, getSubscriptionsSlice } from "./state";
import * as subscriptions from "./state/subscriptions";
import * as payment from "./state/subscriptions/payment";
import * as subscription from "./state/subscriptions/subscription";

type State = any;

export function getSubscriptionsStateSlice(
  state: State
): SubscriptionsStateSlice {
  return state.get(PATH_TO_SUBSCRIPTIONS_STATE);
}

export const getSubscriptions = (state: State) =>
  getSubscriptionsSlice(getSubscriptionsStateSlice(state));

export const getPayment = (state: State) =>
  getPaymentSlice(getSubscriptionsStateSlice(state));

export const getSortedSubscriptionIds = (state: State) => {
  const subscriptions = getSubscriptions(state);
  return subscriptions
    .valueSeq()
    .sort(a => (!!subscription.isManaged(a) ? 1 : -1))
    .map(s => subscription.getSubscriptionId(s))
    .toList();
};

export const getSubscription: (
  state: State,
  subscriptionId: string
) => Subscription = composeSelector(
  getSubscriptions,
  subscriptions.getSubscription
);

export function subscriptionExists(state: State, subscriptionId: string) {
  const subscriptionsSlice = getSubscriptions(state);
  return subscriptionsSlice.has(subscriptionId);
}

export const userHasSubscription = (state: State) =>
  !!getSubscriptions(state).size;

export const hasLiveSubscription = (state: State): boolean =>
  getSubscriptions(state).some(sub => subscription.isLive(sub));

export const hasActiveSubscription = (state: State): boolean =>
  getSubscriptions(state).some(sub => subscription.isActive(sub));

export const canGetMISUpgradeSubscription = (state: State): boolean =>
  hasLiveManagedSubscription(state) &&
  !hasLiveNonManagedSubscription(state) &&
  !hasSubscriptionWithFailedPayment(state) &&
  !hasSubscriptionWithPendingAuthentication(state);

export const hasLiveManagedSubscription = (state: State): boolean =>
  getSubscriptions(state).some(
    sub => subscription.isManaged(sub) && subscription.isLive(sub)
  );

export const hasTransferableSubscription = (state: State): boolean =>
  getSubscriptions(state).some(sub => subscription.canBeTransferred(sub));

export const getLiveManagedSubscriptionId = (
  state: State
): string | undefined => {
  const managedSub = getSubscriptions(state).find(
    sub => subscription.isManaged(sub) && subscription.isLive(sub)
  );
  return managedSub && subscription.getSubscriptionId(managedSub);
};

export const getLiveStripeSubscriptionId = (state: State) => {
  const stripeSubscription = getSubscriptions(state).find(
    sub => subscription.hasStripeSubscription(sub) && subscription.isLive(sub)
  );
  return stripeSubscription
    ? subscription.getSubscriptionId(stripeSubscription)
    : undefined;
};

export const hasLiveNonManagedSubscription = (state: State): boolean =>
  getSubscriptions(state).some(
    sub => !subscription.isManaged(sub) && subscription.isLive(sub)
  );

export const getLiveManagedSubscriptionProductId = (
  state: State
): string | undefined => {
  const managedSub = getSubscriptions(state).find(
    sub => subscription.isManaged(sub) && subscription.isLive(sub)
  );
  return managedSub && subscription.getProductId(managedSub);
};

export const hasSubscriptionWithFailedPayment = (state: State): boolean =>
  getSubscriptions(state).some(sub => subscription.paymentFailed(sub));

export const getSubscriptionIdWithFailedPayment = (state: State) => {
  const subs = getSubscriptions(state).find(sub =>
    subscription.paymentFailed(sub)
  );
  return subs ? subscription.getSubscriptionId(subs) : undefined;
};

export const hasSubscriptionWithPendingAuthentication = (
  state: State
): boolean =>
  getSubscriptions(state).some(sub =>
    subscription.paymentRequiresAuthentication(sub)
  );

export const getSubscriptionIdWithPendingAuthentication = (state: State) => {
  const subs = getSubscriptions(state).find(sub =>
    subscription.paymentRequiresAuthentication(sub)
  );
  return subs ? subscription.getSubscriptionId(subs) : undefined;
};

export const hasManagedSubscription = (state: State): boolean =>
  getSubscriptions(state).some(sub => subscription.isManaged(sub));

export const hasSingleStripeSubscription = (state: State): boolean => {
  const subscriptions = getSubscriptions(state);
  const stripeSubs = subscriptions.filter(
    s => subscription.hasStripeSubscription(s) && subscription.isLive(s)
  );

  return stripeSubs.size === 1;
};

export function premiumHasExpired(state: any) {
  const hasSubscription = hasLiveSubscription(state);
  if (hasSubscription) return false;
  const subscriptions = getSubscriptions(state);
  return !!subscriptions.some(s => subscription.hasExpired(s));
}

export const getCancelledSubscriptionId = (state: State) => {
  const cancelledSubscription = getSubscriptions(state).find(sub =>
    subscription.isCancelled(sub)
  );
  return cancelledSubscription
    ? subscription.getSubscriptionId(cancelledSubscription)
    : null;
};

export const getSubscriptionProductId = (
  state: State,
  subscriptionId: string
) => subscription.getProductId(getSubscription(state, subscriptionId));

// subscription status
export const getSubscriptionStatus = (state: State, subscriptionId: string) =>
  subscription.getStatus(getSubscription(state, subscriptionId));

export const subscriptionPaymentSucceeded = (
  state: State,
  subscriptionId: string
) => subscription.paymentSucceeded(getSubscription(state, subscriptionId));

export const subscriptionPaymentFailed = (
  state: State,
  subscriptionId: string
) => subscription.paymentFailed(getSubscription(state, subscriptionId));

export const subscriptionPaymentRequiresAuthentication = (
  state: State,
  subscriptionId: string
) =>
  subscription.paymentRequiresAuthentication(
    getSubscription(state, subscriptionId)
  );

export const subscriptionIsActive = (state: State, subscriptionId: string) =>
  subscription.isActive(getSubscription(state, subscriptionId));

export const subscriptionIsManaged = (state: State, subscriptionId: string) =>
  subscription.isManaged(getSubscription(state, subscriptionId));

export const hasStripeSubscription = (state: State, subscriptionId: string) =>
  subscription.hasStripeSubscription(getSubscription(state, subscriptionId));

export const subscriptionIsCancelled = (state: State, subscriptionId: string) =>
  subscription.isCancelled(getSubscription(state, subscriptionId));

export const subscriptionCanBeRestarted = (
  state: State,
  subscriptionId: string
) => subscription.canBeRestarted(getSubscription(state, subscriptionId));

export function subscriptionThatRequiresAction(
  state: State,
  subscriptionId: string
) {
  return (
    userHasSubscription(state) &&
    subscription.requiresAction(getSubscription(state, subscriptionId))
  );
}

export const canCancelSubscription = (state: State, subscriptionId: string) =>
  subscriptionIsActive(state, subscriptionId) &&
  !subscriptionHasExpired(state, subscriptionId) &&
  hasStripeSubscription(state, subscriptionId) &&
  !subscriptionThatRequiresAction(state, subscriptionId);

export const canRestartSubscription = (state: State, subscriptionId: string) =>
  subscriptionIsCancelled(state, subscriptionId) &&
  !subscriptionHasExpired(state, subscriptionId) &&
  subscriptionCanBeRestarted(state, subscriptionId);

export const canRenewSubscription = (state: State, subscriptionId: string) =>
  subscriptionHasExpired(state, subscriptionId);

// payment
export const getSubscriptionPaymentError = (state: State) =>
  payment.paymentError(getPayment(state));

export const secureCustomerAuthenticationIsOngoing = (state: State) =>
  payment.secureCustomerAuthenticationIsOngoing(getPayment(state));

export const paymentMethodCreationIsOngoing = (state: State) =>
  payment.paymentMethodCreationIsOngoing(getPayment(state));

export const stripeInteractionIsOngoing = (state: State) =>
  secureCustomerAuthenticationIsOngoing(state) ||
  paymentMethodCreationIsOngoing(state);

export const canUpdatePaymentMethod = (state: State, subscriptionId: string) =>
  hasStripeSubscription(state, subscriptionId);

// subscription expiry
export const getSubscriptionExpiryDate = (
  state: State,
  subscriptionId: string
) => subscription.getExpiryDate(getSubscription(state, subscriptionId));

export const subscriptionHasExpired = (state: State, subscriptionId: string) =>
  subscription.hasExpired(getSubscription(state, subscriptionId));

export const isSubscriptionLive = (
  state: State,
  subscriptionId: string
): boolean => {
  return (
    userHasSubscription(state) &&
    subscription.isLive(getSubscription(state, subscriptionId))
  );
};

export const getSubscriptionInitialPurchaseDate = (
  state: State,
  subscriptionId: string
) => {
  return subscription.getInitialPurchaseDate(
    getSubscription(state, subscriptionId)
  );
};

export const getSubscriptionRenewalPurchaseDate = (
  state: State,
  subscriptionId: string
) => {
  return subscription.getRenewalPurchaseDate(
    getSubscription(state, subscriptionId)
  );
};

export const userHasExpiredSubscription = (
  state: State,
  subscriptionId: string
): boolean => {
  return (
    userHasSubscription(state) && subscriptionHasExpired(state, subscriptionId)
  );
};

export const hasSubscriptionCardDetails = (
  state: State,
  subscriptionId: string
) => subscription.hasCardDetails(getSubscription(state, subscriptionId));

export const getSubscriptionCardBrand = (
  state: State,
  subscriptionId: string
) =>
  hasSubscriptionCardDetails(state, subscriptionId) &&
  subscription.getCardBrand(getSubscription(state, subscriptionId));

export const getSubscriptionCardLast4Digits = (
  state: State,
  subscriptionId: string
) =>
  hasSubscriptionCardDetails(state, subscriptionId) &&
  subscription.getCardLast4Digits(getSubscription(state, subscriptionId));

// upgrades

export const getIsUpgrading = (state: State, subscriptionId: string) =>
  subscription.getIsUpgrading(getSubscription(state, subscriptionId));

export const getUpgradeSubscriptionProductId = (
  state: State,
  subscriptionId: string
) =>
  subscription.getUpgradeSubscriptionProductId(
    getSubscription(state, subscriptionId)
  );

export const getUpgradeProratedPrice = (state: State, subscriptionId: string) =>
  subscription.getUpgradeProratedPrice(getSubscription(state, subscriptionId));

export const getAddonProductIds = (state: State, subscriptionId: string) =>
  subscription.getAddonProductIds(getSubscription(state, subscriptionId));

// Transferring

export const canSubscriptionBeTransferred = (
  state: State,
  subscriptionId: string
) => subscription.canBeTransferred(getSubscription(state, subscriptionId));
