import { linkToHelpSection, makeLinkToHelpSectionArticle } from "common/links";

// Temporary replacement till translations are done
export function linkToParentFaqs(locale: string) {
  return `${linkToHelpSection(locale)}/collections/1434432-parent-faqs`;
}

export const linkToParentInviteChildrenHelp =
  makeLinkToHelpSectionArticle("3990859");

export const linkToParentWhatIsSeneca = makeLinkToHelpSectionArticle("2483292");

export {
  linkToParentDashboard,
  linkToParentWhatIsPremium,
  isInParentPlatform,
  linkToMonitoringPage,
  linkToSettings,
  linkToPersonalInformation,
  linkToPasswordUpdate,
  linkToPremiumSettings,
  linkToCancelSubscription,
  linkToTransferPremium,
  linkToChangeAccountType,
  SUBSCRIPTION_JOURNEY_SOURCES,
  makeLinkToParentWhatIsPremium,
  linkToAssignmentSummaryRoute
} from "./routes/links";
