export enum LeaderboardCategory {
  LOCAL_AUTHORITY = "localAuthority",
  LOCAL_AUTHORITY_PRIVATE_SCHOOL = "localAuthority,privateSchool",
  MAT = "mat"
}

export type SchoolInfo = {
  schoolId: string;
  localAuthority?: string;
  name: string;
  town?: string;
  region: string;
  mat?: boolean;
  synced?: boolean;
};

export type SchoolLeaderboardRow = SchoolInfo & {
  rank: number;
  score: number;
  me?: true;
};

export type SchoolLeaderboard = {
  id: string;
  category: LeaderboardCategory;
  startTime: string;
  endTime: string;
  rows: {
    leaders: SchoolLeaderboardRow[];
    others: SchoolLeaderboardRow[];
  };
};
