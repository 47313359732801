import { Dispatch } from "common/types";

import {
  signInSuccessAsGuest,
  updateUserPreferences as updateUserPreferencesLocally
} from "seneca-common/features/user/state";

import { persistChangedUserPreferences } from "../../actions/preferences";

export function signInAsGuest(region: string | null | undefined) {
  return function (dispatch: Dispatch) {
    region &&
      dispatch(
        updateUserPreferencesLocally({
          region
        })
      );
    dispatch(signInSuccessAsGuest());
  };
}

export function reconcileData() {
  return async function (dispatch: Dispatch) {
    const { reconcileCourseEnrolments } = await import(
      "seneca-common/features/enrollments/features/course-enrollments/service/actions"
    );
    const { reconcileMemories } = await import(
      "seneca-common/features/memories/service"
    );
    const { reconcileStudyStats } = await import(
      "seneca-common/features/session/actions/reconcile"
    );
    await Promise.all([
      dispatch(persistChangedUserPreferences()),
      dispatch(reconcileStudyStats()),
      dispatch(reconcileMemories()),
      dispatch(reconcileCourseEnrolments())
    ]);
  };
}
