import { Location } from "history";
import { matchPath } from "react-router-dom";

import { makeLinkStateGetterAndSetter } from "seneca-common/utils/routing";

import { linkToClassroom } from "../../links";

export function linkToClassroomCourse(courseId: string = ":courseId") {
  return `${linkToClassroom()}/course/${courseId}`;
}

export function getCourseIdIfInClassroom(location: Location) {
  const match = matchPath(location.pathname, {
    path: linkToClassroomCourse()
  }) as any;

  return match ? match.params.courseId : undefined;
}

// TODO: delete as legacy code
export const {
  set: addRedirectedFromInvalidSubscriptionForCourseState,
  get: getCourseIdFromInvalidSubscriptionForCourseState,
  has: redirectedFromInvalidSubscriptionForCourse
} = makeLinkStateGetterAndSetter<string>("invalidSubscriptionForCourse");

export const {
  set: addRedirectedFromMissingCourseState,
  has: redirectedFromMissingCourse
} = makeLinkStateGetterAndSetter<string>("missingCourse");
