import {
  SESSION_TYPES,
  SessionType
} from "seneca-common/features/session/models/SessionType";

export const PATH_TO_STATE = "classroom";

export enum LearningMode {
  default = "default",
  assignments = "assignments",
  wrongAnswers = "wrongAnswers",
  smartLearning = "smartLearning",
  quiz = "quiz"
}

export const modeToSessionTypeMap = Object.freeze({
  default: null,
  assignments: SESSION_TYPES.adaptive,
  smartLearning: SESSION_TYPES.smartLearning,
  wrongAnswers: SESSION_TYPES.wrongAnswers,
  quiz: SESSION_TYPES.quiz
});

export type { SessionType };
