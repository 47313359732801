import featureToggles from "features/dev-tools/featureToggles";

const key = "feature-toggles";

// update featureToggles with what's in localStorage
try {
  Object.assign(featureToggles, JSON.parse(window.localStorage.getItem(key)!));
} catch (error: any) {
  window.localStorage.removeItem(key);
}

const persist = () =>
  window.localStorage.setItem(key, JSON.stringify(featureToggles));

function enable(name: string) {
  (featureToggles as any)[name] = true;
  persist();
}

function disable(name: string) {
  (featureToggles as any)[name] = false;
  persist();
}

function getFeatureItem(item: string) {
  try {
    const data = JSON.parse(window.localStorage.getItem(key)!);
    return data[item];
  } catch {
    return null;
  }
}

export default featureToggles;
export { enable, disable, getFeatureItem };
