import {
  AndroidReferrer,
  AndroidTWAParamKey
} from "features/pwa/mobile/android/utils/consts";

export default function isAndroidTWA() {
  try {
    const item = localStorage.getItem(AndroidTWAParamKey);
    const storeItem = item ? Boolean(item) : false;

    const referrer = document.referrer.includes(AndroidReferrer);

    return storeItem || referrer;
  } catch (error) {
    return false;
  }
}
