import { composeSelectors } from "seneca-common/utils/selectors/compose-selectors";

import { LearningMode as LearningModeT } from "../../../consts";
import LearningMode from "../../../models/learningMode/LearningMode";
import * as modesSelectors from "./mode";

export function getLearningMode(state: LearningMode): LearningModeT {
  return state.mode;
}

export function getAssignmentId(
  state: LearningMode
): string | null | undefined {
  return state.assignmentId;
}

export function getSource(state: LearningMode): string | null | undefined {
  return state.source;
}

export const {
  isAssignmentsMode,
  isWrongAnswersMode,
  isSmartLearningMode,
  isDefaultMode
} = composeSelectors(getLearningMode, modesSelectors);
