import {
  DocumentExport,
  GetTextPreview,
  ModuleType as ModuleNames,
  PollyPodcastExport
} from "seneca-common/features/module/module-types/types";

export type ModuleTransformationsType = {
  getTextPreview: GetTextPreview;
  pollyPodcastExport: PollyPodcastExport;
  documentExport: DocumentExport;
};

export default class ModuleExportTransformations {
  _moduleName: ModuleNames;

  _getTextPreview: GetTextPreview;
  _pollyPodcastExport: PollyPodcastExport;
  _documentExport: DocumentExport;

  constructor(
    moduleName: ModuleNames,
    {
      getTextPreview,
      pollyPodcastExport,
      documentExport
    }: ModuleTransformationsType
  ) {
    this._moduleName = moduleName;

    this._getTextPreview = getTextPreview;
    this._pollyPodcastExport = pollyPodcastExport;
    this._documentExport = documentExport;
  }

  getGetTextPreview(): GetTextPreview {
    return this._getTextPreview;
  }

  getPollyPodcastExport(): PollyPodcastExport {
    return this._pollyPodcastExport;
  }

  getDocumentExport(): DocumentExport {
    return this._documentExport;
  }
}
