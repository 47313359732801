import { Client } from "@seneca/client-library";

export default class ChallengeServiceClient extends Client {
  async fetchSchoolChallenges(schoolName: string) {
    const url = `${this.url}/api/challenges/schools/${schoolName}`;
    const expectedResponses = [200];

    const response = await this.requestMaker.makeRequest(
      url,
      {
        method: "GET"
      },
      expectedResponses
    );

    return response.json;
  }
}
