import { lazy, ReactNode, Suspense } from "react";

import loaderWithRetry from "seneca-common/utils/loaderWithRetry";

import useIsGoStudentLearningApp from "features/goStudentLearning/hooks/useIsGoStudentLearningApp";
import { isNativeAppiOS } from "features/pwa/mobile/ios/utils/isNativeAppiOS";
import useHasUserSignedUpBefore from "features/user/hooks/useHasUserSignedUpBefore";
import useResumeSession from "features/user/hooks/useResumeSession";

type Props = {
  initialized: boolean;
  children: ReactNode;
};

const IOSAuth = lazy(() =>
  loaderWithRetry(
    () => import(/* webpackChunkName: "iOS-auth-chunk" */ "./IOSAuth")
  )
);

const GoStudentLearningAuth = lazy(() =>
  loaderWithRetry(
    () =>
      import(
        /* webpackChunkName: "go-student-learning-auth-chunk" */ "./GoStudentLearningAuth"
      )
  )
);

const AuthListeners = lazy(() =>
  loaderWithRetry(
    () =>
      import(/* webpackChunkName: "auth-listeners-chunk" */ "./AuthListeners")
  )
);

const HideableSplashScreen = lazy(() =>
  loaderWithRetry(
    () =>
      import(
        /* webpackChunkName: "hideable-splash-screen" */ "features/common/loadingScreens/components/SplashScreen/components/HideableSplashScreen"
      )
  )
);

const UserImpersonationAlert = lazy(() =>
  loaderWithRetry(
    () =>
      import(
        /* webpackChunkName: "user-impersonation-alert" */ "features/app/user-impersonation/UserImpersonationAlert"
      )
  )
);

export default function ResumeSession({ initialized, children }: Props) {
  const signInAttemptFinished = useResumeSession(initialized);
  const checkedIfSignedUpBefore = useHasUserSignedUpBefore();

  const isiOS = isNativeAppiOS();
  const isGoStudentLearningApp = useIsGoStudentLearningApp();

  return (
    <>
      <Suspense>
        {isiOS ? <IOSAuth /> : null}
        {isGoStudentLearningApp ? <GoStudentLearningAuth /> : null}
        <AuthListeners initialized={initialized} />
        <HideableSplashScreen hide={signInAttemptFinished} />
      </Suspense>
      {signInAttemptFinished && checkedIfSignedUpBefore ? children : null}
      <UserImpersonationAlert />
    </>
  );
}
