// Randomly shuffles an array in-place using Fisher-Yates algorithm
// http://stackoverflow.com/a/12646864
export default function shuffle<T>(
  arr: T[],
  random: () => number = Math.random
): T[] {
  const shuffled: T[] = [...arr];

  for (var i = shuffled.length - 1; i > 0; i--) {
    var j = Math.floor(random() * (i + 1));
    const temp = shuffled[i];
    shuffled[i] = shuffled[j];
    shuffled[j] = temp;
  }

  return shuffled;
}
