import { Record } from "immutable";

import {
  CrudBuilderState,
  makeCrudBuilderInitialState
} from "seneca-common/utils/apiStates";

import {
  passwordResetCrudStateConfig,
  userInfoCrudStateConfig,
  userPreferenceCrudStateConfig
} from "../../consts";

export type UserMetaType = {
  passwordReset: CrudBuilderState;
  userInfo: CrudBuilderState;
  preferences: CrudBuilderState;
};

export default class UserMeta extends Record({
  passwordReset: makeCrudBuilderInitialState(passwordResetCrudStateConfig),
  userInfo: makeCrudBuilderInitialState(userInfoCrudStateConfig),
  preferences: makeCrudBuilderInitialState(userPreferenceCrudStateConfig)
}) {}
