import { Client } from "@seneca/client-library";

export type FeatureFlagsResponse = {
  static?: { [key: string]: { value: boolean } };
  release?: { [key: string]: { value: boolean } };
  abTest?: { [key: string]: { value: string; variants: string[] } };
};

export default class FeatureFlagsServiceClient extends Client {
  async fetchFeatureFlags() {
    const url = `${this.url}/api/flags`;
    const expectedResponses = [200];

    const response = await this.requestMaker.makeRequest<FeatureFlagsResponse>(
      url,
      { method: "GET" },
      expectedResponses
    );

    return response.json;
  }
}
