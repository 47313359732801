export default function initialisePlurallFeedbackTracker() {
  window.addEventListener("load", initPlurallTracker);
  return () => window.removeEventListener("load", initPlurallTracker);
}

function initPlurallTracker() {
  window.PLURALL_TRACKER = new window.PlurallTracker({
    environment:
      process.env.NODE_ENV === "production" ? "production" : "staging",
    clientId: "adapta",
    plurallFeedback: 1001
  });
}
