import React, { ReactComponentElement } from "react";
import { useSelector } from "react-redux";

import { getModalOpen } from "features/ui-state/selectors";

import "./ModalBodyScrollLocker.scss";

type Props = {
  children: ReactComponentElement<any, any> | ReactComponentElement<any, any>[];
};

export default function ModalBodyScrollLocker({ children }: Props) {
  const modalOpen = useSelector(getModalOpen);
  React.useEffect(() => {
    if (modalOpen) {
      addBodyScrollLock();
    } else {
      removeBodyScrollLock();
    }
  }, [modalOpen]);

  return children;
}

const CLASS_NAME = "ModalBodyScrollLocker__lock-scroll";

export const removeBodyScrollLock = () => {
  document.body && document.body.classList.remove(CLASS_NAME);
};

export const addBodyScrollLock = () => {
  document.body && document.body.classList.add(CLASS_NAME);
};
