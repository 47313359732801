import { createContext, useContext } from "react";

export type PushNotificationsContextState =
  | {
      ready: false;
    }
  | {
      ready: true;
      firebaseMessagingIsSupported: boolean;
    };

export const defaultContextValue: PushNotificationsContextState = {
  ready: false
};

export const PushNotificationsContext =
  createContext<PushNotificationsContextState>(defaultContextValue);

export function usePushNotificationsContext() {
  return useContext(PushNotificationsContext);
}
