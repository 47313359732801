export default class NoIdFoundOnJSObjectError extends Error {
  jsObject: any;

  constructor(jsObject: any) {
    super(
      `Could not find an id from an object using the getIdFromJsObject function provided`
    );
    this.jsObject = jsObject;
  }
}
