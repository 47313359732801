import { ComponentType, lazy } from "react";

type LazyLoadFactory = () => Promise<{
  default: ComponentType<any>;
}>;

type ComponentWithPreload = ComponentType<any> & {
  preload: LazyLoadFactory;
};

export default function lazyWithPreload(
  factory: LazyLoadFactory
): ComponentWithPreload {
  const Component = lazy(factory);
  (Component as unknown as ComponentWithPreload).preload = factory;
  return Component as unknown as ComponentWithPreload;
}
