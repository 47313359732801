import { Buffer } from "buffer";

import { Client } from "@seneca/client-library";
import { GeneratePdfResponse } from "@seneca/pdf-service-types";

export default class PdfServiceClient extends Client {
  async fetchSectionNotesPdfUrl(courseId: string, sectionId: string) {
    const url = `${this.url}/api/courses/${courseId}/sections/${sectionId}/notes/signed-url`;

    const response = await this.requestMaker.makeRequest(url, {
      method: "GET"
    });

    return response.json as { url: string; expiryLengthMs: number };
  }

  async fetchSectionNotesPdf(url: string) {
    const response = await this.requestMaker.makeRequest(url, {
      method: "GET"
    });

    return Buffer.from(response.text!, "base64");
  }

  async generateQuizPdf(quizId: string) {
    const expectedResponses = [200, 422];

    const url = `${this.url}/api/quizzes/${quizId}`;

    const response = await this.requestMaker.makeRequest<GeneratePdfResponse>(
      url,
      { method: "GET" },
      expectedResponses
    );

    if (response.status === 502) throw new Error("Internal server error");

    if (response.status !== 200) {
      throw new Error("Failed to generate quiz pdf");
    }

    return Buffer.from(response.text!, "base64");
  }
}
