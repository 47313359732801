import { ProviderSSO } from "services/firebase/auth/types";

import { pushEnrichedEventToDataLayerWithCleanUp } from "seneca-common/features/analytics";

export const analyticsLogConnectSSOProvider = (providerId: ProviderSSO) => {
  pushEnrichedEventToDataLayerWithCleanUp({
    event: "seneca/user/SSO_CONNECT_PROVIDER",
    payload: {
      providerId
    }
  });
};

export const analyticsLogDisconnectSSOProvider = (providerId: ProviderSSO) => {
  pushEnrichedEventToDataLayerWithCleanUp({
    event: "seneca/user/SSO_DISCONNECT_PROVIDER",
    payload: {
      providerId
    }
  });
};
