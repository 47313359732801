import { useCallback, useMemo } from "react";
import {
  createSearchParams,
  NavigateOptions,
  useSearchParams
} from "react-router-dom-v5-compat";

import {
  InSearchParams,
  InStateParams,
  OutSearchParams,
  Route
} from "../common";

interface TypedNavigateOptions<T> extends NavigateOptions {
  state?: T;
  preserveUntyped?: boolean;
}

function useTypedSearchParams<
  TPath extends string,
  TPathTypes,
  TSearchTypes,
  THash extends string[],
  TStateTypes
>(
  route: Route<TPath, TPathTypes, TSearchTypes, THash, TStateTypes>,
  typedDefaultInit?: InSearchParams<TSearchTypes>
): [
  OutSearchParams<TSearchTypes>,
  (
    searchParams:
      | InSearchParams<TSearchTypes>
      | ((
          prevParams: OutSearchParams<TSearchTypes>
        ) => InSearchParams<TSearchTypes>),
    navigateOptions?: TypedNavigateOptions<InStateParams<TStateTypes>>
  ) => void
] {
  const defaultInit = useMemo(
    () =>
      typedDefaultInit
        ? route.getPlainSearchParams(typedDefaultInit)
        : undefined,
    [route, typedDefaultInit]
  );

  const [searchParams, setSearchParams] = useSearchParams(defaultInit);

  const typedSearchParams = useMemo(
    () => route.getTypedSearchParams(searchParams),
    [route, searchParams]
  );

  const setTypedSearchParams = useCallback(
    (
      params:
        | InSearchParams<TSearchTypes>
        | ((
            prevParams: OutSearchParams<TSearchTypes>
          ) => InSearchParams<TSearchTypes>),
      {
        state,
        preserveUntyped,
        ...restNavigateOptions
      }: TypedNavigateOptions<InStateParams<TStateTypes>> = {}
    ) => {
      setSearchParams(
        ((prevParams: any) => {
          const nextParams = createSearchParams(
            route.getPlainSearchParams(
              typeof params === "function"
                ? params(route.getTypedSearchParams(prevParams))
                : params
            )
          );

          if (preserveUntyped)
            appendSearchParams(
              nextParams,
              route.getUntypedSearchParams(prevParams)
            );

          return nextParams;
        }) as unknown as any,
        {
          ...(state ? { state: route.buildState(state) } : {}),
          ...restNavigateOptions
        }
      );
    },
    [route, setSearchParams]
  );

  return [typedSearchParams, setTypedSearchParams];
}

function appendSearchParams(target: URLSearchParams, source: URLSearchParams) {
  for (const [key, val] of source.entries()) {
    target.append(key, val);
  }

  return target;
}

export { useTypedSearchParams };

export type { TypedNavigateOptions };
