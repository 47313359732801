import { Record } from "immutable";

import makeActionsTypes from "./action-types-builder";
import makeActions from "./actions-builder";
import { mapReducerBuilder, recordReducerBuilder } from "./reducer-builder";
import {
  ReduxMapModuleBuilderConfig,
  ReduxRecordModuleBuilderConfig
} from "./types";

export const buildReduxMapBundle = <JS, I extends Record<any>>({
  featureName,
  getIdFromJsObject,
  jsToImmutableTransform
}: ReduxMapModuleBuilderConfig<JS, I>) => {
  const actionTypes = makeActionsTypes(featureName);

  return {
    actionTypes,
    actions: makeActions(actionTypes),
    reducer: mapReducerBuilder(
      actionTypes,
      getIdFromJsObject,
      jsToImmutableTransform
    )
  };
};

export const buildReduxRecordBundle = <JS, I extends Record<any>>({
  featureName,
  jsToImmutableTransform,
  RecordBuilder
}: ReduxRecordModuleBuilderConfig<JS, I>) => {
  const actionTypes = makeActionsTypes(featureName);

  return {
    actionTypes,
    actions: makeActions(actionTypes),
    reducer: recordReducerBuilder(
      actionTypes,
      jsToImmutableTransform,
      RecordBuilder
    )
  };
};
