import { AppVariants } from "common/types/appVariant";

import useIsGoStudentLearningApp from "features/goStudentLearning/hooks/useIsGoStudentLearningApp";
import { useIsPlurallApp } from "features/plurall";

export default function useAppVariant() {
  const isPlurallApp = useIsPlurallApp();
  const isGoStudentLearningApp = useIsGoStudentLearningApp();

  if (isPlurallApp) return AppVariants.plurall;
  if (isGoStudentLearningApp) return AppVariants.goStudentLearning;
  return AppVariants.seneca;
}
