import { LearningMode } from "../state/consts";

const MODE_KEY = "mode";

export const withLearningModeQueryParams = (
  link: string,
  mode: LearningMode
) => {
  const [baseLink, searchParams] = link.split("?");
  const params = new URLSearchParams(searchParams);
  params.append(MODE_KEY, mode);

  return `${baseLink}?${params.toString()}`;
};

export const getLearningModeFromParams = (
  params: string
): LearningMode | null => {
  // @ts-ignore
  return new URLSearchParams(params).get(MODE_KEY);
};
