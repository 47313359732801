import { helpEmailByVariant } from "common/links";
import { AppVariants } from "common/types/appVariant";

import useAppVariant from "./useAppVariant";
import useGoStudentLearningAppVariant from "./useGoStudentLearningAppVariant";

export default function useHelpEmailByVariant() {
  const appVariant = useAppVariant();
  const goStudentLearningAppVariant = useGoStudentLearningAppVariant();

  switch (appVariant) {
    case AppVariants.plurall:
      return helpEmailByVariant[AppVariants.plurall];

    case AppVariants.goStudentLearning:
      return helpEmailByVariant[AppVariants.goStudentLearning][
        goStudentLearningAppVariant
      ];

    default:
      return helpEmailByVariant[AppVariants.seneca];
  }
}
