import { makeActionsAndActionCreators } from "../../common/actions/actionCreator";
import { AllowedCallTypes, MakeActionCreator } from "../../common/const";

const makeCreateStateActionsAndActionCreators = ({
  name,
  path = "seneca/",
  idFieldName
}: MakeActionCreator) => {
  const { actionTypes, actionCreators } = makeActionsAndActionCreators({
    name,
    path,
    idFieldName,
    callType: AllowedCallTypes.CREATE
  });
  return {
    createActionTypes: actionTypes,
    createActionCreators: actionCreators
  };
};

export default makeCreateStateActionsAndActionCreators;
