import { Record } from "immutable";

type MetaType = {
  courseId?: string;
  shareTopicModalOpen: boolean;
  lastCourseSearchTerm: string;
};

export type OptionalMetaType = Partial<MetaType>;

const defaultMeta: MetaType = {
  courseId: undefined,
  shareTopicModalOpen: false,
  lastCourseSearchTerm: ""
};

export default class Meta extends Record(defaultMeta) {}
