import getMessageFromError from "seneca-common/utils/functions/services/getMessageFromError";

export default function errorTranslator(error: Error): string {
  try {
    if (typeof error === "string") return error;

    return getMessageFromError(error) || "An unknown error has occurred.";
  } catch (err: any) {
    return "An unknown error has occurred.";
  }
}
