import { List } from "immutable";

import Subscription from "seneca-common/features/subscriptions/state/models/subscriptions/subscription/Subscription";
import UpdateOnPaymentSuccess from "seneca-common/features/subscriptions/state/models/subscriptions/subscription/UpdateOnPaymentSuccess";
import { ReceivedSubscriptionType } from "seneca-common/features/subscriptions/state/types";
import { buildReduxMapBundle } from "seneca-common/utils/state/build-redux-bundles";

import { FEATURE_NAME } from "./consts";

export const { actionTypes, actions, reducer } = buildReduxMapBundle({
  featureName: FEATURE_NAME,
  getIdFromJsObject: (subscription: ReceivedSubscriptionType) =>
    subscription.subscriptionId,
  jsToImmutableTransform: ({
    subscriptionProducts,
    addonProductIds,
    updateOnPaymentSuccess,
    ...subscription
  }: ReceivedSubscriptionType) => {
    return new Subscription({
      ...subscription,
      addonProductIds: List(addonProductIds),
      updateOnPaymentSuccess: updateOnPaymentSuccess
        ? new UpdateOnPaymentSuccess({ ...updateOnPaymentSuccess })
        : undefined
    });
  }
});
