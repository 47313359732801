import { Record } from "immutable";

import UserMeta from "./meta";
import PasswordResetState from "./PasswordResetState";
import SignInState from "./SignInState";
import User from "./User";
import UserPreferences from "./UserPreferences";

export type UserStateSliceType = {
  user: User;
  signInState: SignInState;
  passwordResetState: PasswordResetState;
  preferences: UserPreferences;
  meta: UserMeta;
};

export type OptionalUserStateSliceType = Partial<UserStateSliceType>;

export default class UserStateSlice extends Record({
  user: new User(),
  signInState: new SignInState(),
  passwordReset: new PasswordResetState(),
  preferences: new UserPreferences(),
  meta: new UserMeta()
}) {}
