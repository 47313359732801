import React from "react";
import { createGlobalStyle } from "styled-components";

import { Box, ThemeInMode, useBackgroundColor } from "@seneca/senekit";

import { useIsPlurallApp } from "features/plurall";
import useSetStatusBarBackgroundColoriOS from "features/pwa/mobile/ios/hooks/useSetStatusBarBackgroundColoriOS";

type GlobalStyleProps = {
  backgroundColor: string;
  isPlurallApp: boolean;
  theme?: ThemeInMode;
};

// Plurall tracker css injects white background color into body tag so need to overwrite it with !important
// Also injects border box box-sizing so adding this to overwrite some of the time

const GlobalStyle = createGlobalStyle`
  body {
    background: ${(props: GlobalStyleProps) =>
      `${props.backgroundColor} ${props.isPlurallApp ? "!important" : ""}`};
  }

  *, ::before, ::after {
    box-sizing: ${(props: GlobalStyleProps) =>
      props.isPlurallApp ? "initial" : ""};
  }
`;

type Props = {
  children: React.ReactNode;
};

export default function AppBackground({ children }: Props) {
  const backgroundColor = useBackgroundColor();

  const isPlurallApp = useIsPlurallApp();

  useSetStatusBarBackgroundColoriOS();

  return (
    <>
      <GlobalStyle
        backgroundColor={backgroundColor}
        isPlurallApp={isPlurallApp}
      />
      <Box width="100%">{children}</Box>
    </>
  );
}
