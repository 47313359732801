import * as React from "react";

import { makeModalContainer } from "../containers/Modal";

export type Props = {
  setModalOpen: (arg0: void) => void;
  setModalClosed: (arg0: void) => void;
  children: React.ReactNode;
};

class WithSetModalOpenOnMount extends React.PureComponent<Props> {
  componentDidMount() {
    this.props.setModalOpen();
  }

  componentWillUnmount() {
    this.props.setModalClosed();
  }

  render() {
    return this.props.children;
  }
}

export default makeModalContainer(WithSetModalOpenOnMount);
