import { useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";

import {
  EmailIdSourceParamKey,
  NotificationStateKeys,
  PushNotificationsSourceParamKey
} from "../consts";
import { getAnalyticsDataFromQueryParams } from "./getAnalyticsDataFromQueryParams";

export default function useGetNotificationAnalyticsFromQueryParams() {
  const history = useHistory();
  const { pathname, search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const { pushSource, notificationType, emailId } =
    getAnalyticsDataFromQueryParams(searchParams);

  const clearURLParams = () => {
    if (pushSource) searchParams.delete(PushNotificationsSourceParamKey);
    if (emailId) searchParams.delete(EmailIdSourceParamKey);
    history.replace(`${pathname}?${searchParams}`, {
      ...history.location.state,
      [NotificationStateKeys.PUSH_SOURCE]: pushSource,
      [NotificationStateKeys.TYPE]: notificationType,
      [NotificationStateKeys.EMAIL_ID]: emailId
    });
  };

  if (notificationType && (pushSource || emailId)) {
    clearURLParams();
  }

  return { pushSource, notificationType, emailId };
}
