import { Subscriptions } from "seneca-common/features/subscriptions/state/models/subscriptions";
import Subscription, {
  defaultSubscriptionValues
} from "seneca-common/features/subscriptions/state/models/subscriptions/subscription/Subscription";

export function getSubscription(
  subscriptions: Subscriptions,
  subscriptionId: string
) {
  return (
    subscriptions.get(subscriptionId) ||
    new Subscription(defaultSubscriptionValues)
  );
}
