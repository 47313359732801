import { useIntl } from "react-intl";

type Options = {
  separator?: string;
};
export default function useLocalisedDate(
  date: string,
  { separator }: Options = {}
) {
  const intl = useIntl();

  if (separator) {
    const formattedByParts = intl.formatDateToParts(date);
    const replaceSeparator = formattedByParts.map(({ type, value }) => {
      if (type === "literal") {
        return separator;
      }

      return value;
    });

    return replaceSeparator.join("");
  }

  return intl.formatDate(date);
}
