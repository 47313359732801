import { regionCodes } from "common/static/regions";
import { State } from "common/types/redux";

import { isPlurallApp } from "features/plurall";

import { getRegion as getRegionPreference } from "./preferences";
import { isUserManaged } from "./userInfo";

export default function getRegion(state: State) {
  if (isUserManaged(state)) {
    return regionCodes.GB;
  }

  if (isPlurallApp()) {
    return regionCodes.BR;
  }

  return getRegionPreference(state);
}
