import { ReactNode } from "react";

import PushNotificationsProvider from "services/firebase/messaging/PushNotificationsProvider";

import NotificationsAnalyticsProvider from "features/notifications/analytics/NotificationsAnalyticsProvider";

export default function NotificationsProvider({
  children
}: {
  children: ReactNode;
}) {
  return (
    <PushNotificationsProvider>
      <NotificationsAnalyticsProvider />
      {children}
    </PushNotificationsProvider>
  );
}
