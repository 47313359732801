import { Map } from "immutable";

import { isIn } from "seneca-common/utils/functions";

import SubscriptionProduct from "../../../models/state/subscription-products/subscription-product/SubscriptionProduct";
import { SubscriptionProducts } from "../../../models/state/subscription-products/SubscriptionProducts";
import { actionTypes, reducer } from "../../../reduxBundle";

export default function subscriptionProductsReducer(
  stored: SubscriptionProducts = Map<string, SubscriptionProduct>(),
  action: Record<string, any>
) {
  if (isIn(action.type, actionTypes)) {
    // $FlowFixMe
    return reducer(stored, action);
  }

  // can do non-standard stuff here in the future if we need to

  return stored;
}
