import { GoStudentLearningAppVariants } from "common/types/appVariant";

import {
  hasUserProvider,
  studienkreisProvider
} from "services/firebase/auth/ssoProvider";

export default function useGoStudentLearningAppVariant() {
  const hasStudienkreisProvider = hasUserProvider(
    studienkreisProvider.providerId
  );

  if (hasStudienkreisProvider) {
    return GoStudentLearningAppVariants.studienkreis;
  }

  return GoStudentLearningAppVariants.goStudent;
}
