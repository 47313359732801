import { combineReducers } from "redux-immutable";

import { makeCrudStateReducer } from "seneca-common/utils/apiStates";

import { feedbackCrudConfig } from "../consts";

const feedbackCrudStateReducer = makeCrudStateReducer(feedbackCrudConfig);

export default combineReducers({
  meta: feedbackCrudStateReducer
});
