import { useEffect } from "react";

import { regionCodes } from "common/static/regions";

import { isUserManaged } from "seneca-common/features/user/state";
import { useSelector } from "seneca-common/utils/hooks/selectors";

import { getLanguageFromRegion } from "features/regions/selectors";

export default function useSetLocalizeLanguage() {
  const isManaged = useSelector(isUserManaged);

  useEffect(() => {
    if (isManaged) {
      const language = getLanguageFromRegion(regionCodes.GB);
      window.Localize && window.Localize.setLanguage(language);
    }
  }, [isManaged]);
}
