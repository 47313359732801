import { pushEnrichedEventToDataLayerWithCleanUp } from "seneca-common/features/analytics";

import { FlattenedFlags } from "../types";

export const AB_TEST_NAMESPACE = "s/abTest";

export function logUserAssignedToVariant(payload: {
  variant: number | string | boolean;
  testId: string;
  id?: string;
}) {
  pushEnrichedEventToDataLayerWithCleanUp({
    event: `${AB_TEST_NAMESPACE}/assigned`,
    payload
  });
}

export function logABTestInteraction(payload: {
  variant: number | string | boolean;
  testId: string;
  action: string;
  id?: string;
}) {
  pushEnrichedEventToDataLayerWithCleanUp({
    event: `${AB_TEST_NAMESPACE}/interaction`,
    payload
  });
}

export function logABTestVariantsFallback(variants: FlattenedFlags) {
  Object.keys(variants).forEach(testId => {
    logUserAssignedToVariantDefault({
      testId,
      variant: variants[testId]
    });
  });
}

function logUserAssignedToVariantDefault(payload: {
  variant: number | string | boolean;
  testId: string;
  id?: string;
}) {
  pushEnrichedEventToDataLayerWithCleanUp({
    event: `${AB_TEST_NAMESPACE}/assignedDefault`,
    payload
  });
}
