import {
  receiveSubscription,
  updateSubscriptionActionFactory
} from "seneca-common/features/subscriptions/state";
import MissingArgumentsError from "seneca-common/utils/errors/MissingArgumentsError";

interface SubscriptionsClient {
  readonly retrySubscriptionPaymentById: (
    subscriptionId: string,
    arg0: {
      paymentMethodId: string;
      cardHolderEmail?: string;
    }
  ) => Promise<any[]>;
}

// For testing purposes we're able to inject a mock client
export const makeRetrySubscriptionPaymentFactory = (
  getClient: () => SubscriptionsClient
) => {
  return updateSubscriptionActionFactory({
    requestFunction: (id, options) =>
      getClient().retrySubscriptionPaymentById(id, options),
    storeAction: receiveSubscription
  });
};

export const makeRetrySubscriptionPayment = (
  getClient: () => SubscriptionsClient
) => {
  const retrySubscriptionPayment =
    makeRetrySubscriptionPaymentFactory(getClient);

  return (
    subscriptionId: string,
    stripePaymentMethodId: string,
    cardHolderEmail?: string
  ) => {
    const missingParams: string[] = [];
    !stripePaymentMethodId && missingParams.push("stripePaymentMethodId");
    !subscriptionId && missingParams.push("subscriptionId");

    if (missingParams.length > 0)
      throw new MissingArgumentsError(missingParams);

    // @ts-ignore
    return retrySubscriptionPayment(
      [
        subscriptionId,
        {
          paymentMethodId: stripePaymentMethodId,
          cardHolderEmail
        }
      ],
      subscriptionId
    );
  };
};
