export enum CustomTransactionName {
  "queryInCourseHP" = "query-in-course-hp",
  "completeSessionWSResponseDuration" = "complete-session-ws-response-duration",
  "ameliaImgUploadToStorage" = "amelia-img-upload-to-storage"
}

export const customTracingConfig: {
  [key: string]: {
    name: string;
    sampleRate: number;
  };
} = Object.freeze({
  [CustomTransactionName.queryInCourseHP]: {
    name: CustomTransactionName.queryInCourseHP,
    sampleRate: 0.1
  },
  [CustomTransactionName.completeSessionWSResponseDuration]: {
    name: CustomTransactionName.completeSessionWSResponseDuration,
    sampleRate: 0.002
  },
  [CustomTransactionName.ameliaImgUploadToStorage]: {
    name: CustomTransactionName.ameliaImgUploadToStorage,
    sampleRate: 1 // TODO: revisit this sample rate after 2024-05-01
  }
});
