import { matchPath } from "react-router-dom";

export function makeSignUpLink(basePath: string) {
  return `${basePath}/sign-up`;
}

export function makeSignInLink(basePath: string) {
  return `${basePath}/login`;
}

export function makePasswordResetLink(basePath: string) {
  return `${basePath}/reset-password`;
}

// Sign up
export function makeSignUpAccountTypeLink(linkToSignUp: string) {
  return `${linkToSignUp}/account-type`;
}

export function makeSignUpStudentDetailsLink(linkToSignUp: string) {
  return `${linkToSignUp}/student-details`;
}

export function makeSignUpTeacherDetailsLink(linkToSignUp: string) {
  return `${linkToSignUp}/teacher-details`;
}

export function makeSignUpUserDetailsLink(linkToSignUp: string) {
  return `${linkToSignUp}/user-details`;
}

export function makeSignUpLinks(linkToSignUp: string) {
  return {
    linkToSignUpAccountType: makeSignUpAccountTypeLink(linkToSignUp),
    linkToSignUpStudentDetails: makeSignUpStudentDetailsLink(linkToSignUp),
    linkToSignUpTeacherDetails: makeSignUpTeacherDetailsLink(linkToSignUp)
  };
}

// Reset password
export function makeResetPasswordSignInLink(linkToPasswordReset: string) {
  return `${linkToPasswordReset}/login`;
}

export function isInSignUpOrLogin(pathname: string) {
  return (
    matchPath(pathname, {
      path: makeSignInLink(""),
      exact: true
    }) ||
    matchPath(pathname, {
      path: makeSignUpLink(""),
      exact: false
    })
  );
}
