import { PushTopic } from "@seneca/notifications-service-types";

import { pushEnrichedEventToDataLayerWithCleanUp } from "seneca-common/features/analytics";
import { withLogAndSwallowError } from "seneca-common/utils/senecaAnalytics";

import { PushActivationAlertLocation } from "features/notifications/components/activation/types";

import { NotificationType } from "./consts";

export const makeEventName = (
  event: string,
  notificationType: NotificationType
) => {
  const eventName = `s/notifications/${notificationType}/${event}`;

  if (eventName.length >= 41) {
    throw new Error("GTM max event name length is 40 characters");
  }

  return eventName;
};

export const analyticsLogUserClickedOnPushNotification = withLogAndSwallowError(
  userClickedOnPushNotification
);

export const analyticsLogUserClickedOnEmailNotification =
  withLogAndSwallowError(userClickedOnEmailNotification);

export const analyticsLogUserClickedOnActivateNotifications =
  withLogAndSwallowError(userClickedOnActivateNotifications);

export const analyticsLogNotificationsPermissionRequestResult =
  withLogAndSwallowError(notificationsPermissionRequestResult);

function userClickedOnPushNotification(source: PushTopic) {
  pushEnrichedEventToDataLayerWithCleanUp({
    event: makeEventName("click", NotificationType.PUSH),
    payload: { source }
  });
}

function userClickedOnEmailNotification(emailId: string) {
  pushEnrichedEventToDataLayerWithCleanUp({
    event: makeEventName("click", NotificationType.EMAIL),
    payload: { emailId }
  });
}

function userClickedOnActivateNotifications(
  source: PushActivationAlertLocation
) {
  pushEnrichedEventToDataLayerWithCleanUp({
    event: makeEventName("activate/click", NotificationType.PUSH),
    payload: { source }
  });
}

function notificationsPermissionRequestResult({
  result,
  source
}: {
  result: "allowed" | "denied";
  source: PushActivationAlertLocation;
}) {
  pushEnrichedEventToDataLayerWithCleanUp({
    event: makeEventName(`permission/${result}`, NotificationType.PUSH),
    payload: { source }
  });
}
