import moment from "moment";

import {
  AllowedActionStatus,
  AllowedCallTypes,
  AllowedCallTypesLowerCase,
  AllowedCallTypesUpperCase,
  TIMESTAMP_FORMAT
} from "../const";

export const getNowString = (): string => moment().format(TIMESTAMP_FORMAT);

export const processCallType = (
  callType: AllowedCallTypes
): {
  callTypeCaps: AllowedCallTypes;
  callTypesLowerCase: AllowedCallTypesLowerCase;
  callTypeCapitalised: AllowedCallTypesUpperCase;
} => {
  return {
    callTypeCaps: AllowedCallTypes[callType],
    callTypesLowerCase: AllowedCallTypesLowerCase[callType],
    callTypeCapitalised: AllowedCallTypesUpperCase[callType]
  };
};

export const processName = (
  name: string
): {
  capsName: string;
  titleCaseName: string;
} => {
  const capsName = name.toUpperCase();
  const titleCaseName =
    name.slice(0, 1).toUpperCase() + name.slice(1, name.length);

  return {
    capsName,
    titleCaseName
  };
};

export const generateActionName = (
  callType: AllowedCallTypes,
  name: string,
  actionStatus: AllowedActionStatus
): string => {
  const { callTypesLowerCase } = processCallType(callType);
  const { titleCaseName } = processName(name);

  let actionStatusCapitalised =
    actionStatus.charAt(0).toUpperCase() + actionStatus.slice(1).toLowerCase();

  if (
    !actionStatus ||
    !actionStatusCapitalised ||
    !(AllowedActionStatus as any)[actionStatus.toUpperCase()]
  ) {
    actionStatusCapitalised = "Start";
  }

  return callTypesLowerCase + titleCaseName + actionStatusCapitalised;
};

export const processPath = (path: string): string => {
  if (!path.endsWith("/")) {
    return path + "/";
  }

  return path;
};
