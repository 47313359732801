import { useMutation, useQueryClient } from "@tanstack/react-query";

import {
  ContactType,
  PreferencesUpdate,
  PushType
} from "@seneca/notifications-service-types";

import { getClient } from "services";

import { PreferencesType } from "../types";
import getNotificationPreferenceTypeServiceName from "../utils/getNotificationPreferenceTypeServiceName";
import getPreferencesQueryKey from "../utils/getPreferencesQueryKey";

type Type<T> = T extends "push" ? PushType : ContactType;

export default function useUpdatePreferences<T extends PreferencesType>(
  type: T
) {
  const queryClient = useQueryClient();
  const queryKey = getPreferencesQueryKey(type);
  const updateNotificationPreferences = makeUpdateNotificationPreferences(type);

  return useMutation({
    mutationKey: [queryKey],
    mutationFn: updateNotificationPreferences,
    onSuccess: data => queryClient.setQueryData([queryKey], data)
  });
}

export function makeUpdateNotificationPreferences<T extends PreferencesType>(
  type: T
) {
  const typeName = getNotificationPreferenceTypeServiceName(type);

  return async (preferences: PreferencesUpdate<Type<T>>) => {
    const client = getClient("notificationsService");
    return client.updateMyNotificationPreferences<Type<T>>({
      type: typeName as any,
      preferences
    });
  };
}
