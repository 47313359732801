import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

import LastLocationContext from "./context";
import { LastLocation } from "./types";

type Props = RouteComponentProps & {
  children: React.ReactNode;
};

type State = {
  lastLocation: LastLocation;
  currentLocation: LastLocation;
};

class LastLocationProvider extends React.PureComponent<Props, State> {
  state = {
    lastLocation: null,
    currentLocation: this.props.location
  };

  static getDerivedStateFromProps(props: Props, state: State) {
    if (props.location !== state.currentLocation) {
      return {
        lastLocation: state.currentLocation,
        currentLocation: props.location
      };
    } else {
      return null;
    }
  }

  render() {
    return (
      <LastLocationContext.Provider value={this.state.lastLocation}>
        {this.props.children}
      </LastLocationContext.Provider>
    );
  }
}

export default withRouter(LastLocationProvider);
