import { Map, Record } from "immutable";

import "seneca-common/utils/apiStates";

import subscriptionsMetaInitialState from "seneca-common/features/subscriptions/state/models/subscriptions/meta";
import Payment from "seneca-common/features/subscriptions/state/models/subscriptions/payment";
import Subscription from "seneca-common/features/subscriptions/state/models/subscriptions/subscription/Subscription";
import { CrudBuilderState } from "seneca-common/utils/apiStates";

import { Subscriptions } from "./subscriptions";

export type SubscriptionsStateSliceType = {
  subscriptions: Subscriptions;
  payment: Payment;
  meta: CrudBuilderState;
};

export type OptionalSubscriptionsStateSliceType =
  Partial<SubscriptionsStateSliceType>;

export default class SubscriptionsStateSlice extends Record({
  subscriptions: Map<string, Subscription>(),
  payment: new Payment(),
  meta: subscriptionsMetaInitialState
}) {}
