import { LANGUAGE_CODES } from "../languages";

type RegionOption = {
  name: string;
  localName: string;
  languageCode: string;
  helpLinkCode: string;
  hidden?: boolean;
  authenticateWithPlurall?: boolean;
  showIntercom?: boolean;
  showPremiumAds?: boolean;
  showNewFeatures?: boolean;
  showShareToRemind?: boolean;
  collectTeacherRole?: boolean;
  collectUserYearGroup?: boolean;
  collectUserPhoneNumber?: boolean;
  collectUserSchool?: boolean;
  teacherResourcesDataNoTranslate?: boolean;
  locale: string;
  enableSchoolAnalytics?: boolean;
  enableWholeSchoolReport?: boolean;
  useCommaDecimalSeparator?: boolean;
  enableChatBot?: boolean;
  useDotMultiplicationSymbol?: true;
  showCramMode?: boolean;
};

export const regionCodes = {
  AT: "AT",
  AU: "AU",
  BR: "BR",
  CO: "CO",
  DE: "DE",
  ES: "ES",
  FR: "FR",
  GB: "GB",
  IN: "IN",
  IT: "IT",
  MX: "MX",
  US: "US",
  VN: "VN"
};

export type RegionCode = keyof typeof regionCodes;

const regionRedirects = {
  AR: "MX",
  BO: "MX",
  CL: "MX",
  EC: "MX",
  PY: "MX",
  PE: "MX",
  UY: "MX",
  VE: "MX"
};

export const regionCodesAndRedirects = { ...regionCodes, ...regionRedirects };

export const regions: Record<string, RegionOption> = {
  [regionCodes.GB]: {
    name: "United Kingdom",
    localName: "UK",
    languageCode: LANGUAGE_CODES.EN,
    helpLinkCode: "en",
    locale: "en-gb",
    showPremiumAds: true,
    showNewFeatures: true,
    showIntercom: true,
    collectTeacherRole: true,
    collectUserYearGroup: true,
    collectUserSchool: true,
    enableSchoolAnalytics: true,
    enableWholeSchoolReport: true,
    enableChatBot: true,
    showCramMode: true
  },
  [regionCodes.BR]: {
    name: "Brazil",
    localName: "Brasil",
    languageCode: LANGUAGE_CODES.PT_BR,
    helpLinkCode: "pt-BR",
    locale: "pt-br",
    authenticateWithPlurall: false,
    collectTeacherRole: true,
    collectUserYearGroup: false,
    collectUserPhoneNumber: true,
    teacherResourcesDataNoTranslate: true,
    collectUserSchool: true,
    enableSchoolAnalytics: true,
    useCommaDecimalSeparator: true
  },
  [regionCodes.DE]: {
    name: "Germany",
    localName: "Deutschland",
    languageCode: LANGUAGE_CODES.DE,
    helpLinkCode: "de",
    locale: "de",
    collectUserPhoneNumber: false,
    enableSchoolAnalytics: true,
    useCommaDecimalSeparator: true,
    enableChatBot: true,
    useDotMultiplicationSymbol: true,
    showCramMode: true
  },
  [regionCodes.AT]: {
    name: "Austria",
    localName: "Österreich",
    languageCode: LANGUAGE_CODES.AT,
    helpLinkCode: "de",
    locale: "de-at",
    collectUserPhoneNumber: false,
    enableSchoolAnalytics: true,
    useCommaDecimalSeparator: true,
    enableChatBot: true,
    useDotMultiplicationSymbol: true,
    showCramMode: true
  },
  [regionCodes.FR]: {
    name: "France",
    localName: "France",
    languageCode: LANGUAGE_CODES.FR,
    helpLinkCode: "fr",
    locale: "fr",
    collectUserYearGroup: false,
    useCommaDecimalSeparator: true,
    enableChatBot: true,
    showCramMode: true
  },
  [regionCodes.ES]: {
    name: "Spain",
    localName: "España",
    languageCode: LANGUAGE_CODES.ES,
    helpLinkCode: "es",
    locale: "es",
    collectUserPhoneNumber: false,
    enableSchoolAnalytics: true,
    useCommaDecimalSeparator: true,
    enableChatBot: true,
    showCramMode: true
  },
  [regionCodes.IT]: {
    name: "Italy",
    localName: "Italia",
    languageCode: LANGUAGE_CODES.IT,
    helpLinkCode: "en",
    locale: "it",
    collectUserPhoneNumber: false,
    enableSchoolAnalytics: true,
    useCommaDecimalSeparator: true,
    enableChatBot: true,
    showCramMode: true
  },
  [regionCodes.US]: {
    name: "USA",
    localName: "USA",
    languageCode: LANGUAGE_CODES.EN,
    helpLinkCode: "en",
    locale: "en-us",
    showNewFeatures: true,
    showIntercom: true,
    showShareToRemind: true,
    collectUserYearGroup: false,
    enableSchoolAnalytics: true
  },
  [regionCodes.MX]: {
    name: "Mexico",
    localName: "México",
    languageCode: LANGUAGE_CODES.ES_MX,
    helpLinkCode: "es",
    locale: "es-mx",
    teacherResourcesDataNoTranslate: true,
    enableSchoolAnalytics: true
  },
  [regionCodes.CO]: {
    name: "Colombia",
    localName: "Colombia",
    languageCode: LANGUAGE_CODES.ES_MX,
    // localize translations only work for es-MX at the moment, our plan doesn't cover another language code so will require further investigation
    helpLinkCode: "es",
    locale: "es-co",
    collectUserPhoneNumber: true,
    teacherResourcesDataNoTranslate: true,
    enableSchoolAnalytics: true,
    useCommaDecimalSeparator: true
  },
  [regionCodes.IN]: {
    name: "India",
    localName: "India",
    languageCode: LANGUAGE_CODES.EN,
    helpLinkCode: "en",
    locale: "en-in",
    showNewFeatures: true,
    showIntercom: true,
    collectUserPhoneNumber: true,
    enableSchoolAnalytics: true
  },
  [regionCodes.AU]: {
    name: "Australia",
    localName: "Australia",
    languageCode: LANGUAGE_CODES.EN,
    helpLinkCode: "en",
    locale: "en-au",
    showNewFeatures: true,
    showIntercom: true,
    collectUserPhoneNumber: true,
    enableSchoolAnalytics: true
  },
  [regionCodes.VN]: {
    name: "Vietnam",
    localName: "Việt Nam",
    languageCode: LANGUAGE_CODES.VI,
    helpLinkCode: "vi",
    locale: "vi",
    hidden: true,
    collectUserPhoneNumber: true,
    useCommaDecimalSeparator: true
  }
};

export type RegionDropdownOption = { label: string; value: string };

export const DEFAULT_REGION = regionCodes.GB;
export const DEFAULT_LOCALE = regions[DEFAULT_REGION].locale;

export const regionCodesArray: string[] = Object.keys(regionCodes);

export const allVisibleRegions = Object.keys(regions).filter(
  key => !regions[key].hidden
);

export const allRegionOptions = regionCodesArray
  .reduce((acc, regionCode) => {
    acc.push({
      label: regions[regionCode].name,
      value: regionCode
    });
    return acc;
  }, [] as RegionDropdownOption[])
  .sort((a, b) => a.label.localeCompare(b.label));

export const visibleRegionOptions = allRegionOptions.filter(
  region => !regions[region.value].hidden
);
