import { Map } from "immutable";

import {
  errorActionKey as makeErrorActionKey,
  resetActionKey as makeResetActionKey,
  startActionKey as makeStartActionKey,
  successActionKey as makeSuccessActionKey
} from "../actions/actionKeys";
import { makeActionTypes } from "../actions/actionTypes";
import { ActionCreatorConfig } from "../const";
import ApiState from "../models/ApiState";

export const makeIndividualApiStateReducer = (config: ActionCreatorConfig) => {
  const actionTypes = makeActionTypes(config);

  const startActionKey = makeStartActionKey(config);
  const successActionKey = makeSuccessActionKey(config);
  const errorActionKey = makeErrorActionKey(config);
  const resetActionKey = makeResetActionKey(config);

  return (apiState: ApiState = new ApiState(), action: Record<string, any>) => {
    switch (action.type) {
      case actionTypes[startActionKey]:
        return apiState.merge({
          started: true,
          error: false,
          errorMessage: undefined
        });

      case actionTypes[successActionKey]:
        return apiState.merge({
          success: true,
          error: false,
          started: false,
          last: action.payload.time,
          errorMessage: undefined
        });

      case actionTypes[errorActionKey]:
        const errorMessage = action.payload && action.payload.errorMessage;
        return apiState.merge({
          started: false,
          success: false,
          error: true,
          errorMessage: errorMessage
        });

      case actionTypes[resetActionKey]:
        return new ApiState();

      default:
        return apiState;
    }
  };
};

export const makeApiStateReducer = (config: ActionCreatorConfig) => {
  const { idFieldName } = config;

  const individualReducer = makeIndividualApiStateReducer(config);

  if (!idFieldName) return individualReducer;

  const actionTypes = Object.values(makeActionTypes(config));

  return (apiStates = Map<string, ApiState>(), action: Record<string, any>) => {
    const idsValue = action && action.payload && action.payload[idFieldName];

    if (idsValue && idFieldName && actionTypes.includes(action.type)) {
      const ids = Array.isArray(idsValue) ? idsValue : [idsValue];
      const newApiStates = apiStates.withMutations(apiStates => {
        ids.forEach((id: any) => {
          apiStates.update(id, apiState => individualReducer(apiState, action));
        });
      });
      return newApiStates;
    } else {
      return apiStates;
    }
  };
};
