const localStorageKey = "dev-tools";

const enabled = "dev-tools=true";
const disabled = "dev-tools=false";

export function shouldLoadDevTools() {
  // this allows you to explicitly disable it in development for example
  const explicitlyDisabled =
    window.location.search.includes(disabled) ||
    window.localStorage.getItem(localStorageKey) === "false";

  if (explicitlyDisabled) {
    return false;
  }

  const explicitlyEnabled =
    window.location.search.includes(enabled) ||
    window.localStorage.getItem(localStorageKey) === "true";

  return !!explicitlyEnabled;
}

export function persistDevToolsEnabled(searchParams: string) {
  if (searchParams.includes(enabled)) {
    window.localStorage.setItem(localStorageKey, true as unknown as string);
  }

  if (searchParams.includes(disabled)) {
    window.localStorage.setItem(localStorageKey, false as unknown as string);
  }
}
