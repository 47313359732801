import { makeActionsAndActionCreators } from "../../common/actions/actionCreator";
import { AllowedCallTypes, MakeActionCreator } from "../../common/const";

const makeUpdateStateActionsAndActionCreators = ({
  name,
  path = "seneca/",
  idFieldName
}: MakeActionCreator) => {
  const { actionTypes, actionCreators } = makeActionsAndActionCreators({
    name,
    path,
    idFieldName,
    callType: AllowedCallTypes.UPDATE
  });
  return {
    updateActionTypes: actionTypes,
    updateActionCreators: actionCreators
  };
};

export default makeUpdateStateActionsAndActionCreators;
