import { AllowedCallTypes, MakeActionCreator } from "../../common/const";
import { makeApiStateReducer } from "../../common/reducers/apiState";

const makeFetchAllStateReducer = ({
  name,
  path = "seneca/"
}: MakeActionCreator) => {
  return makeApiStateReducer({
    name,
    path,
    callType: AllowedCallTypes.FETCH_ALL
  });
};

export default makeFetchAllStateReducer;
