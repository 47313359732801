import { pushGlobalValuesToDataLayer } from "../../gtm";
import { SelectorsDetails } from "../types";
import pushSelectorOutputsToDataLayer from "./pushSelectorOutputsToDataLayer";

export default function pushGlobalSelectorOutputsToDataLayer(
  selectors: SelectorsDetails,
  lastSelectorOutputsCache: Record<string, any>
) {
  return pushSelectorOutputsToDataLayer({
    selectors,
    pushValuesToDataLayer: pushGlobalValuesToDataLayer,
    lastSelectorOutputsCache
  });
}
