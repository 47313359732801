import Product from "../../../../models/state/subscription-products/subscription-product/product/Product";
import ProductDescription from "../../../../models/state/subscription-products/subscription-product/productDescription/ProductDescription";
import SubscriptionProduct, {
  SUBSCRIPTION_PRODUCT_PAYMENT_FREQUENCIES
} from "../../../../models/state/subscription-products/subscription-product/SubscriptionProduct";
import * as productSelectors from "./product";
import * as productDescriptionSelectors from "./productDescription";

export const getSubscriptionProductId = (state: SubscriptionProduct) =>
  state.id!;

export const getDescription = (state: SubscriptionProduct) => state.description;

export const getPrice = (state: SubscriptionProduct) => state.price!;

export const isPromoted = (state: SubscriptionProduct) => !!state.promoted;

export const getAddonProductIds = (state: SubscriptionProduct) =>
  state.addonProductIds;

export const getCanBeUpgraded = (state: SubscriptionProduct) =>
  !!state.canBeUpgraded;

// Payment frequency
export const getPaymentFrequency = (state: SubscriptionProduct) =>
  state.paymentFrequency!;

export const subscriptionIsBilledAnually = (
  state: SubscriptionProduct
): boolean =>
  getPaymentFrequency(state) ===
  SUBSCRIPTION_PRODUCT_PAYMENT_FREQUENCIES.annually;

export const subscriptionIsBilledMonthly = (
  state: SubscriptionProduct
): boolean =>
  getPaymentFrequency(state) ===
  SUBSCRIPTION_PRODUCT_PAYMENT_FREQUENCIES.monthly;

export const subscriptionIsBilledSeasonal = (
  state: SubscriptionProduct
): boolean =>
  getPaymentFrequency(state) ===
  SUBSCRIPTION_PRODUCT_PAYMENT_FREQUENCIES.seasonal;

export const subscriptionMatchesPeriod = (
  state: SubscriptionProduct,
  period: string
): boolean => {
  return getPaymentFrequency(state) === period;
};

export const isAddOn = (state: SubscriptionProduct) => state.isAddon;

export const isMisBooster = (state: SubscriptionProduct) =>
  state.misSubscriptionBooster;

// Product
export const getProduct = (state: SubscriptionProduct) =>
  state.product as Product;

export const hasGuaranteedGrades = (state: SubscriptionProduct) =>
  productSelectors.hasGuaranteedGrades(getProduct(state));

export const hasSmartLearningMode = (state: SubscriptionProduct) =>
  productSelectors.hasSmartLearningMode(getProduct(state));

export const hasWrongAnswersMode = (state: SubscriptionProduct) =>
  productSelectors.hasWrongAnswersMode(getProduct(state));

export const hasQuizMode = (state: SubscriptionProduct) =>
  productSelectors.hasQuizMode(getProduct(state));

export const hasAiAssistant = (state: SubscriptionProduct) =>
  productSelectors.hasAiAssistant(getProduct(state));

export const hasTutoring = (state: SubscriptionProduct) =>
  productSelectors.hasTutoring(getProduct(state));

export const hasExamQuestions = (state: SubscriptionProduct) =>
  productSelectors.hasExamQuestions(getProduct(state));

export const hasParentPremiumFeatures = (state: SubscriptionProduct) =>
  productSelectors.hasParentPremiumFeatures(getProduct(state));

// Product Description
export const getProductDescription = (state: SubscriptionProduct) =>
  state.productDescription as ProductDescription;

export const getTierName = (state: SubscriptionProduct) =>
  productDescriptionSelectors.getTierName(getProductDescription(state));

export const getIconUrl = (state: SubscriptionProduct) =>
  productDescriptionSelectors.getIconUrl(getProductDescription(state));
