import { ToggleOption } from "@seneca/senekit/out/components/controls/ToggleButton/ToggleButtonOption";

import { validTeacherRoles } from "features/user/consts";

export const ADD_COURSE_DROPDOWN_TEXT = "+ Add course";
export const NAVIGATE_TO_ADD_COURSE_VALUE = "-1";

export const ADD_COURSE_DROPDOWN_OPTION = {
  label: ADD_COURSE_DROPDOWN_TEXT,
  value: NAVIGATE_TO_ADD_COURSE_VALUE
};

export const ENABLE_EQ_SUGGEST_FORM = false;

export const SENIOR_TEACHER_ROLES = validTeacherRoles.filter(
  teacher => !["classroom_teacher", "non_teaching_staff"].includes(teacher)
);

export enum ExpiryType {
  premium = "Seneca Premium",
  misSync = "School Sync"
}

export const SCHOOL_MANAGEMENT_ANALYTICS_NAMESPACE = "s/schoolManagement";

export const classManagedTypes = Object.freeze({
  ACTIVE: "ACTIVE",
  ARCHIVED: "ARCHIVED",
  HIDDEN: "HIDDEN"
});

export type ClassManagedType = keyof typeof classManagedTypes;

export const toggleOptionsDefault = [
  {
    label: "Active",
    value: classManagedTypes.ACTIVE
  },
  {
    label: "Archived",
    value: classManagedTypes.ARCHIVED
  }
] as ToggleOption<ClassManagedType>[];

export const toggleOptionsForMisSynced = [
  {
    label: "Active",
    value: classManagedTypes.ACTIVE
  },
  {
    label: "Hidden",
    value: classManagedTypes.HIDDEN
  },
  {
    label: "Archived",
    value: classManagedTypes.ARCHIVED
  }
] as ToggleOption<ClassManagedType>[];
