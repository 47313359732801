import { Location } from "history";

import { Link } from "common/types/routing";

export const addValueToLinkState = <StateT extends Object>(
  link: string | Location<any>,
  value: StateT
) => {
  if (typeof link === "string") {
    return {
      pathname: link,
      state: value
    } as Location;
  } else {
    return { ...link, state: { ...link.state, ...value } } as Location;
  }
};

export const makeAddValueToLinkState =
  <StateT extends Object>(value: StateT) =>
  (link: string | Location) =>
    addValueToLinkState(link, value);

export function getValueFromLinkState<V>(
  link: string | Location<any>,
  key: string
): V | null {
  if (typeof link === "object" && link.state && link.state[key] !== undefined) {
    return link.state[key];
  } else {
    return null;
  }
}

export function removeValueFromLinkState(
  link: string | Location<any>,
  key: string
) {
  if (typeof link === "object" && link.state && link.state[key] !== undefined) {
    const { [key]: removed, ...otherState } = link.state;
    return { ...link, state: { ...otherState } };
  } else {
    return link;
  }
}

export function makeLinkStateGetterAndSetter<V extends string | Location>(
  stateKey: string
) {
  return {
    set(link: string | Location, value: V): Location {
      // @ts-ignore
      return addValueToLinkState(link, {
        [stateKey]: value
      });
    },

    get(link: string | Location): V | null | undefined {
      return getValueFromLinkState(link, stateKey);
    },

    has(link: string | Location): boolean {
      return !!getValueFromLinkState(link, stateKey);
    },

    remove(link: string | Location): string | Location {
      return removeValueFromLinkState(link, stateKey);
    }
  };
}

export function openWindowSecurely(link: string) {
  const newWindow = window.open(link, "_blank");
  if (newWindow?.opener) newWindow.opener = null;
}

export function urlLink(link: string) {
  return `${window.location.origin}${link}`;
}

export function asLocation(link?: Link): Location | undefined {
  if (!link) return undefined;

  if (typeof link === "string") {
    return { pathname: link } as Location;
  }

  return link as Location;
}
