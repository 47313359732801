import * as React from "react";
import {
  FallbackProps,
  ErrorBoundary as ReactErrorBoundary
} from "react-error-boundary";
import { connect } from "react-redux";

import logError from "seneca-common/utils/sentry/logError";

/**
 * Note: You should prefer to use `ErrorBoundary` over `withErrorHandler`
 * or `makeErrorHandler`
 *  */

type Props = {
  showErrorComponent?: boolean;
};

type State = {
  error: boolean | null | undefined;
};

type Options<PropsT extends Record<string, any>> = {
  errorBoundaryName?: string;
  // used to send to Sentry
  errorLogger?: typeof logError;
  showErrorPageSelector?: (state: any, ownProps: PropsT) => boolean;
};

const RenderNull = () => null;

function withErrorHandler<PropsT extends Record<string, any>>(
  WrappedComponent: React.ComponentType<PropsT>,
  ErrorComponent: React.ComponentType<any> = RenderNull,
  {
    errorLogger = logError,
    showErrorPageSelector,
    errorBoundaryName: boundaryNameProvided
  }: Options<PropsT> = {}
) {
  const errorBoundaryName =
    boundaryNameProvided ||
    WrappedComponent.displayName ||
    WrappedComponent.name;

  class ErrorHandler extends React.PureComponent<Props & PropsT, State> {
    state = {
      error: null
    };

    componentDidCatch(error: Error, info: Record<string, any>) {
      this.setState({
        error: true
      });
      errorLogger(error, {
        extraInfo: info,
        message: `Error caught by the ${
          errorBoundaryName || "default"
        } error boundary`,
        fingerprint: errorBoundaryName ? [errorBoundaryName] : undefined
      });
    }

    render() {
      if (this.state.error || this.props.showErrorComponent) {
        return <ErrorComponent {...this.props} />;
      }

      const { showErrorComponent, ...otherProps } = this.props;
      // @ts-ignore
      return <WrappedComponent {...otherProps} />;
    }
  }

  // @ts-ignore
  ErrorHandler.displayName = `withErrorHandler(${
    errorBoundaryName || "Component"
  })`;

  function mapStateToProps(state: any, ownProps: PropsT) {
    return {
      showErrorComponent:
        showErrorPageSelector && showErrorPageSelector(state, ownProps)
    };
  }

  return showErrorPageSelector
    ? connect(mapStateToProps)(ErrorHandler as any)
    : ErrorHandler;
}

export const makeErrorHandler =
  <PropsT extends Record<string, any>>(
    ErrorComponent: React.ComponentType<any>,
    options?: Options<PropsT>
  ) =>
  (WrappedComponent: React.ComponentType<PropsT>) =>
    withErrorHandler(WrappedComponent, ErrorComponent, options);

export default withErrorHandler;

export function ErrorBoundary({
  errorBoundaryName,
  errorLogger = logError,
  children,
  fallbackRender
}: {
  errorBoundaryName?: string;
  errorLogger?: typeof logError;
  children: React.ReactElement;
  fallbackRender: (
    props: FallbackProps
  ) => React.ReactElement<unknown, string | React.FunctionComponent<{}>> | null;
}) {
  const handleError = (error: Error, info: { componentStack: string }) => {
    errorLogger(error, {
      extraInfo: info,
      message: `Error caught by the ${
        errorBoundaryName || "default"
      } error boundary`,
      fingerprint: errorBoundaryName ? [errorBoundaryName] : undefined
    });
  };

  return (
    <ReactErrorBoundary onError={handleError} fallbackRender={fallbackRender}>
      {children}
    </ReactErrorBoundary>
  );
}
