import { setDefaultOptions } from "date-fns";
import { useEffect } from "react";
import { useSelector } from "react-redux";

import logError from "seneca-common/utils/sentry/logError";

import { getLocale } from "features/regions/selectors";

export default function useSetDateFnsLocale() {
  const locale = useSelector(getLocale);

  useEffect(() => {
    setDateFnsLocale(locale);
  }, [locale]);
}

async function setDateFnsLocale(locale: string) {
  try {
    const getDateFnsLocale = webAppLocaleDateFnsMap[locale];
    if (!getDateFnsLocale) return;

    const localeDateFns = await getDateFnsLocale();

    setDefaultOptions({ locale: localeDateFns });
  } catch (error) {
    logError(error, { message: "Failed to set locale for date-fns" });
  }
}

const webAppLocaleDateFnsMap: Record<string, () => Promise<any>> = {
  "en-gb": async () =>
    await import(
      /* webpackMode: "lazy", webpackChunkName: "date-fns-en-GB" */ `date-fns/locale/en-GB/index.js`
    ),
  "pt-br": async () =>
    await import(
      /* webpackMode: "lazy", webpackChunkName: "date-fns-pt-BR" */ `date-fns/locale/pt-BR/index.js`
    ),
  "en-us": async () =>
    await import(
      /* webpackMode: "lazy", webpackChunkName: "date-fns-en-US" */ `date-fns/locale/en-US/index.js`
    ),
  "es-mx": async () =>
    await import(
      /* webpackMode: "lazy", webpackChunkName: "date-fns-es" */ `date-fns/locale/es/index.js`
    ),
  fr: async () =>
    await import(
      /* webpackMode: "lazy", webpackChunkName: "date-fns-fr" */ `date-fns/locale/fr/index.js`
    ),
  "en-in": async () =>
    await import(
      /* webpackMode: "lazy", webpackChunkName: "date-fns-en-IN" */ `date-fns/locale/en-IN/index.js`
    ),
  "en-au": async () =>
    await import(
      /* webpackMode: "lazy", webpackChunkName: "date-fns-en-AU" */ `date-fns/locale/en-AU/index.js`
    ),
  "es-co": async () =>
    await import(
      /* webpackMode: "lazy", webpackChunkName: "date-fns-es" */ `date-fns/locale/es/index.js`
    ),
  vi: async () =>
    await import(
      /* webpackMode: "lazy", webpackChunkName: "date-fns-vi" */ `date-fns/locale/vi/index.js`
    ),
  "de-at": async () =>
    await import(
      /* webpackMode: "lazy", webpackChunkName: "date-fns-de-AT" */ `date-fns/locale/de-AT/index.js`
    ),
  de: async () =>
    await import(
      /* webpackMode: "lazy", webpackChunkName: "date-fns-de" */ `date-fns/locale/de/index.js`
    ),
  es: async () =>
    await import(
      /* webpackMode: "lazy", webpackChunkName: "date-fns-es" */ `date-fns/locale/es/index.js`
    ),
  it: async () =>
    await import(
      /* webpackMode: "lazy", webpackChunkName: "date-fns-it" */ `date-fns/locale/it/index.js`
    )
};
