import { Client } from "@seneca/client-library";

import { AnyFeedback, FeedbackType } from "./types";

export default class UserFeedbackServiceClient extends Client {
  async giveFeedback(feedbackType: FeedbackType, feedback: AnyFeedback) {
    const url = `${this.url}/api/user-feedback/${feedbackType}`;
    const expectedResponses = [200, 201];
    return this.requestMaker.makeRequest(
      url,
      {
        method: "POST",
        body: JSON.stringify(feedback)
      },
      expectedResponses
    );
  }
}
