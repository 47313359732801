import sessionActionTypes from "seneca-common/features/session/action-types";

import metaActionTypes from "../action-types/meta";
import actionTypes from "../action-types/sessionUI";
import SessionUI from "../models/sessionUI/SessionUI";

export default function sessionUIReducer(
  sessionUI: SessionUI = new SessionUI(),
  action: any
) {
  switch (action.type) {
    case actionTypes.SET_SCROLLED_UP:
      return sessionUI.set("scrolledUp", true);

    case actionTypes.SET_SCROLLED_DOWN:
    case sessionActionTypes.NEW_SESSION:
      return sessionUI.set("scrolledUp", false);

    case metaActionTypes.QUIT_SESSION:
      return sessionUI.merge({
        scrolledUp: false
      });

    case actionTypes.SET_FOCUSED_QUESTION_ID:
      return sessionUI.set("focusedQuestionId", action.payload.questionId);

    default:
      return sessionUI;
  }
}
