import { fromJS } from "immutable";

import { ModuleRegistry } from "seneca-common/features/module/registry";

import actionTypes from "../action-types/sessionModule";
import SessionModule from "../models/SessionModule";

const moduleRegistryInstance = ModuleRegistry.getInstance();

export const makeSessionModuleReducerWithModuleRegistry =
  (moduleRegistry: ModuleRegistry) =>
  (sessionModule = new SessionModule(), action: any) => {
    const setScore = () => {
      const markAnswer = moduleRegistry.modules
        .getModule(sessionModule.moduleType!)
        .getMarkAnswer();

      return sessionModule.set(
        "score",
        (
          markAnswer(
            sessionModule.content ? sessionModule.content.toJS() : null,
            sessionModule.userAnswer ? sessionModule.userAnswer.toJS() : null
          ) as any
        ).score
      );
    };

    switch (action.type) {
      case actionTypes.NEW_SESSION_MODULE:
        return action.payload.sessionModule.set(
          "timeStarted",
          action.payload.timestamp
        );

      case actionTypes.UPDATE_ANSWER:
        return sessionModule.set("userAnswer", fromJS(action.payload.answer));

      case actionTypes.UPDATE_MODULE_STATE:
        const { state, updateState } = action.payload;
        const newState = state ?? updateState(sessionModule.toJS().moduleState);
        return sessionModule.set("moduleState", fromJS(newState) as any);

      case actionTypes.SUBMIT_SCORE:
        return setScore().set("submitted", true);

      case actionTypes.GIVE_UP:
        return setScore().set("gaveUp", true);

      case actionTypes.COMPLETE_MODULE:
        return sessionModule
          .set("completed", true)
          .set("timeFinished", action.payload.moduleStudiedEvent.timeFinished);

      default:
        return sessionModule;
    }
  };

export default makeSessionModuleReducerWithModuleRegistry(
  moduleRegistryInstance
);
