import { ComponentType } from "react";

import Navigator from "seneca-common/utils/builders/Navigator";

import Module from "../models/Module";
import ModuleComponents from "../models/ModuleComponents";
import ModuleComponentStaticInformation from "../models/ModuleComponentStaticInformation";
import ModuleExportTransformations from "../models/ModuleExportTransformations";
import ModuleForm from "../models/ModuleForm";
import ModuleLogic from "../models/ModuleLogic";
import ModuleStaticInformation from "../models/ModuleStaticInformation";
import ModuleRegistry from "../ModuleRegistry";
import ModuleComponentBuilder from "./ModuleComponentBuilder";
import ModuleComponentStaticInformationBuilder from "./ModuleComponentStaticInformationBuilder";
import ModuleExportTransformationsBuilder from "./ModuleExportTransformationsBuilder";
import ModuleFormBuilder from "./ModuleFormBuilder";
import ModuleLogicBuilder from "./ModuleLogicBuilder";
import ModuleStaticInformationBuilder from "./ModuleStaticInformationBuilder";

export default class ModuleBuilder<BackT, RootT> extends Navigator<
  BackT,
  RootT
> {
  _moduleName: string;

  _root: any;
  _logicBuilder: ModuleLogicBuilder<this, ModuleRegistry> | null | undefined;
  _staticBuilder: any;
  _staticInformationBuilder:
    | ModuleStaticInformationBuilder<this, ModuleRegistry>
    | null
    | undefined;
  _componentBuilder:
    | ModuleComponentBuilder<this, ModuleRegistry>
    | null
    | undefined;
  _formBuilder: ModuleFormBuilder<this, ModuleRegistry> | null | undefined;
  _formDecorator: any;
  _exportTransformationsBuilder:
    | ModuleExportTransformationsBuilder<this, ModuleRegistry>
    | null
    | undefined;
  _componentStaticInformationBuilder:
    | ModuleComponentStaticInformationBuilder<this, ModuleRegistry>
    | null
    | undefined;

  _logic: ModuleLogic | null | undefined;
  _staticInformation: ModuleStaticInformation | null | undefined;
  _components: ModuleComponents | null | undefined;
  _form: ModuleForm | null | undefined;
  _transformations: any;
  _exportTransformations: ModuleExportTransformations | null | undefined;
  _componentStaticInformation:
    | ModuleComponentStaticInformation
    | null
    | undefined;

  constructor(moduleName: string, back: any, root: any) {
    super(back, root);

    this._moduleName = moduleName;
    this._root = root;

    this._logicBuilder = null;
    this._staticBuilder = null;
    this._componentBuilder = null;
    this._exportTransformationsBuilder = null;
    this._formBuilder = null;
    this._formDecorator = null;
    this._componentStaticInformationBuilder = null;

    this._logic = null;
    this._staticInformation = null;
    this._components = null;
    this._exportTransformations = null;
    this._form = null;
    this._transformations = null;
    this._componentStaticInformation = null;
  }

  logic(): ModuleLogicBuilder<this, ModuleRegistry> {
    if (!this._logicBuilder) {
      this._logicBuilder = new ModuleLogicBuilder<this, ModuleRegistry>(
        this._moduleName,
        this,
        this._root
      );
    }

    return this._logicBuilder;
  }

  staticInformation(): ModuleStaticInformationBuilder<this, ModuleRegistry> {
    if (!this._staticInformationBuilder) {
      this._staticInformationBuilder = new ModuleStaticInformationBuilder<
        this,
        ModuleRegistry
      >(this._moduleName, this, this._root);
    }

    return this._staticInformationBuilder;
  }

  components(): ModuleComponentBuilder<this, ModuleRegistry> {
    if (!this._componentBuilder) {
      this._componentBuilder = new ModuleComponentBuilder<this, ModuleRegistry>(
        this._moduleName,
        this,
        this._root
      );
    }

    return this._componentBuilder;
  }

  exportTransformations(): ModuleExportTransformationsBuilder<
    this,
    ModuleRegistry
  > {
    if (!this._exportTransformationsBuilder) {
      this._exportTransformationsBuilder =
        new ModuleExportTransformationsBuilder<this, ModuleRegistry>(
          this._moduleName,
          this,
          this._root
        );
    }

    return this._exportTransformationsBuilder as any;
  }

  form(): ModuleFormBuilder<this, ModuleRegistry> {
    if (!this._formBuilder) {
      this._formBuilder = new ModuleFormBuilder<this, ModuleRegistry>(
        this._moduleName,
        this,
        this._root
      );
    }

    return this._formBuilder.registerFormDecorator(this._formDecorator);
  }

  registerFormDecorator(
    formDecorator?: (arg0: ComponentType<any>) => ComponentType<any>
  ) {
    this._formDecorator = formDecorator;
    return this;
  }

  componentStaticInformation(): ModuleComponentStaticInformationBuilder<
    this,
    ModuleRegistry
  > {
    if (!this._componentStaticInformationBuilder) {
      this._componentStaticInformationBuilder =
        new ModuleComponentStaticInformationBuilder<this, ModuleRegistry>(
          this._moduleName,
          this,
          this._root
        );
    }

    return this._componentStaticInformationBuilder;
  }

  build(): Module {
    if (this._logicBuilder) {
      this._logic = this._logicBuilder.build();
    }

    if (this._staticInformationBuilder) {
      this._staticInformation = this._staticInformationBuilder.build();
    }

    if (this._componentBuilder) {
      this._components = this._componentBuilder.build();
    }

    if (this._exportTransformationsBuilder) {
      this._exportTransformations = this._exportTransformationsBuilder.build();
    }

    if (this._formBuilder) {
      this._form = this._formBuilder.build();
    }

    if (this._componentStaticInformationBuilder) {
      this._componentStaticInformation =
        this._componentStaticInformationBuilder.build();
    }

    return new Module(this._moduleName, {
      logic: this._logic,
      staticInformation: this._staticInformation,
      components: this._components,
      form: this._form,
      exportTransformations: this._exportTransformations,
      componentStaticInformation: this._componentStaticInformation
    });
  }
}
