// TODO: don't have react in seneca-common. Find some way to absract away
import { ComponentType } from "react";

import {
  ModuleConnector,
  ModuleType as ModuleNames
} from "seneca-common/features/module/module-types/types";

export type ModuleComponentsType = {
  Main: ComponentType<any>;
  ControlBar: ComponentType<any>;
  moduleConnector: ModuleConnector;
};

export default class ModuleComponents {
  _moduleName: ModuleNames;

  Main: ComponentType<any>;
  ControlBar: ComponentType<any>;
  moduleConnector: ModuleConnector;

  constructor(
    moduleName: ModuleNames,
    { Main, ControlBar, moduleConnector }: ModuleComponentsType
  ) {
    this._moduleName = moduleName;

    this.Main = Main;
    this.ControlBar = ControlBar;
    this.moduleConnector = moduleConnector;
  }

  getMain(): ComponentType<any> {
    return this.Main;
  }

  getControlBar(): ComponentType<any> {
    return this.ControlBar;
  }

  getModuleConnector(): ModuleConnector {
    return this.moduleConnector;
  }
}
