import {
  createContext,
  ReactNode,
  useContext,
  useReducer,
  useRef
} from "react";
import { createPortal } from "react-dom";

import {
  initialTopbarState,
  topbarReducer,
  TopbarVisibilityState
} from "../hooks/useTopbarReducer";
import { TopbarDispatchContext } from "./TopbarDispatchProvider";

const TopbarContext = createContext<{
  ref?: {
    current: HTMLDivElement | null | undefined;
  };
  topbarsVisibility: TopbarVisibilityState;
}>({ topbarsVisibility: { ...initialTopbarState } });

export function TopbarPortal({ children }: { children: React.ReactNode }) {
  const { ref } = useContext(TopbarContext);

  return ref && ref.current && children
    ? createPortal(children, ref.current)
    : null;
}

export function useTopbarContext() {
  return useContext(TopbarContext);
}

type TopBarProviderProps = {
  children: ReactNode;
};

export default function TopBarProvider({ children }: TopBarProviderProps) {
  const ref = useRef<HTMLDivElement | null>(null);

  const [topbarsVisibility, dispatch] = useReducer(
    topbarReducer,
    initialTopbarState
  );

  return (
    <TopbarContext.Provider
      value={{
        ref,
        topbarsVisibility
      }}
    >
      <TopbarDispatchContext.Provider value={dispatch}>
        <div
          style={{
            display: "flex",
            flexDirection: "column-reverse"
          }}
        >
          {children}

          {/* Render top bar after children, so they appear "over" other elements: */}
          <div
            ref={ref}
            style={{
              position: "sticky",
              top: 0
            }}
            data-test="Topbar"
          />
        </div>
      </TopbarDispatchContext.Provider>
    </TopbarContext.Provider>
  );
}
