import { Record } from "immutable";

export type UpdateOnPaymentSuccessType = {
  subscriptionProductId?: string;
  proratedPrice?: number;
};

const defaultValues: UpdateOnPaymentSuccessType = {
  subscriptionProductId: undefined,
  proratedPrice: undefined
};

export default class UpdateOnPaymentSuccess extends Record(defaultValues) {}
