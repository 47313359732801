import { lazy, ReactNode, Suspense, useEffect, useState } from "react";

import loaderWithRetry from "seneca-common/utils/loaderWithRetry";

import {
  defaultContextValue,
  PushNotificationsContext,
  PushNotificationsContextState
} from "./context";

type Props = {
  children: ReactNode;
};

const NotificationsSubscriber = lazy(() =>
  loaderWithRetry(() => import("./NotificationsSubscriber"))
);

export default function PushNotificationsProvider({ children }: Props) {
  const [state, setState] =
    useState<PushNotificationsContextState>(defaultContextValue);

  useEffect(() => {
    const checkIsSupported = async () => {
      const { isSupported } = await import("firebase/messaging");
      const supported = await isSupported();
      setState({ ready: true, firebaseMessagingIsSupported: supported });
    };
    checkIsSupported();
  }, []);

  return (
    <PushNotificationsContext.Provider value={state}>
      <Suspense>
        <NotificationsSubscriber />
      </Suspense>
      {children}
    </PushNotificationsContext.Provider>
  );
}
