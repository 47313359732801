import RegisterReducerBuilder from "./RegisterReducerBuilder";
import { Reducer, ReducerRegister } from "./types";

type BuilderCallback = (arg0: RegisterReducerBuilder) => void;
type ChangeListener = (store: any, reducers?: ReducerRegister) => any;

export class ReducerRegistry {
  static instance = new ReducerRegistry();
  _emitChange: ChangeListener | null | undefined;
  _reducers: ReducerRegister;
  _initialised: Record<string, boolean>;
  store: any;

  constructor() {
    this._emitChange = null;
    this._reducers = {};
    this._initialised = {};
  }

  static getInstance() {
    return ReducerRegistry.instance;
  }

  initialise(
    initialisationIdentifier: string,
    builderCallback: BuilderCallback
  ) {
    if (!this._initialised[initialisationIdentifier]) {
      this._initialised[initialisationIdentifier] = true;

      const builder = new RegisterReducerBuilder(ReducerRegistry.getInstance());
      builderCallback(builder);
    }
  }

  getReducers(): ReducerRegister {
    return { ...this._reducers };
  }

  getReducer(name: string): Reducer | null | undefined {
    return this._reducers[name];
  }

  registerStore(store: any) {
    this.store = store;
    this.emitChange();
  }

  emitChange() {
    if (this.store && this._emitChange) {
      this._emitChange(this.store, this.getReducers());
    }
  }

  builderRegisterReducer(name: string, reducer: Reducer) {
    this._reducers = { ...this._reducers, [name]: reducer };
    this.emitChange();
  }

  builderRegisterMultipleReducers(reducers: ReducerRegister) {
    Object.keys(reducers).map(reducerName =>
      this.builderRegisterReducer(reducerName, reducers[reducerName])
    );
  }

  setChangeListener(listener: ChangeListener) {
    this._emitChange = listener;
  }

  register() {
    this._throwIncorrectUsageError("register()");
  }

  registerMultiple() {
    this._throwIncorrectUsageError("registerMultiple()");
  }

  _throwIncorrectUsageError(method: string) {
    throw new Error(
      `You shouldn't use ${method} to register reducers with the reducerRegistry directly, use the builder via reducerRegistry.initialise(...) instead.`
    );
  }
}

export default ReducerRegistry;
