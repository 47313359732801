import { ExtendableError } from "@seneca/client-library";

/**
 * Typed error for failed validation
 * @param {string} field the field that failed validation
 * @param {string} message the validation message
 */
export default class QuizSessionBadRequestError extends ExtendableError {
  field: string;

  constructor(field: string, message: string) {
    super(`${field} failed validation. ${message}`);

    this.field = field;
  }
}
