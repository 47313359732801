import ReducerRegistry from "seneca-common/utils/state/reducer-registry";

import { LearningMode, PATH_TO_STATE } from "./consts";
import reducer from "./reducers/classroom";

const reducerRegistry = ReducerRegistry.getInstance();
reducerRegistry.initialise(PATH_TO_STATE, builder => {
  builder.register(PATH_TO_STATE, reducer);
});

export { reducer, LearningMode };

export {
  getSidebarOpen,
  getLearningMode,
  getScrolledUp,
  getFocusedQuestionId,
  getShareTopicModalOpen,
  getCourseId,
  isSectionExpanded,
  getExpandedAssignmentId,
  isAssignmentExpanded,
  getActiveTab,
  getIsActiveTabSet,
  getAssignmentId,
  getSource,
  isAssignmentsMode,
  isWrongAnswersMode,
  isSmartLearningMode,
  isDefaultMode,
  getLastCourseSearchTerm
} from "./selectors";

export {
  setCourseId,
  openShareTopicModal,
  closeShareTopicModal,
  quitSession,
  setLastCourseSearchTerm
} from "./actions/meta";

export {
  expandAssignment,
  collapseAssignment,
  expandSection,
  expandSections,
  collapseSection,
  toggleSidebar,
  openSidebar,
  closeSidebar,
  closeSidebarIfOpen,
  setActiveTab
} from "./actions/sidebarUI";

export {
  setScrolledUp,
  setScrolledDown,
  setFocusedQuestionId
} from "./actions/sessionUI";

export { setLearningMode, setLearningModeMeta } from "./actions/learningMode";

export { default as Classroom } from "./models/Classroom";

export { modeToSessionTypeMap } from "./consts";
