import localforage from "localforage";

export const SelectedSchoolStorageKey = "user_selectedSchoolId";

export type SelectedSchoolStorage = {
  [userId: string]: string;
};

export async function getStoredSelectedSchools(): Promise<SelectedSchoolStorage> {
  const selectedSchools = await localforage.getItem<SelectedSchoolStorage>(
    SelectedSchoolStorageKey
  );
  return selectedSchools || {};
}

export async function getStoredSelectedSchoolId(
  userId: string
): Promise<string | undefined> {
  const selectedSchools = await getStoredSelectedSchools();
  return selectedSchools[userId];
}

export async function saveSelectedSchool({
  userId,
  schoolId
}: {
  userId: string;
  schoolId: string;
}) {
  const selectedSchools = await getStoredSelectedSchools();
  selectedSchools[userId] = schoolId;
  await localforage.setItem(SelectedSchoolStorageKey, selectedSchools);
}
