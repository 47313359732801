import { combineReducers } from "redux-immutable";

import { makeCrudStateReducer } from "seneca-common/utils/apiStates";

import {
  passwordResetCrudStateConfig,
  userInfoCrudStateConfig,
  userPreferenceCrudStateConfig
} from "../../consts";
import UserMeta from "../../models/meta";

export default combineReducers(
  {
    passwordReset: makeCrudStateReducer(passwordResetCrudStateConfig),
    userInfo: makeCrudStateReducer(userInfoCrudStateConfig),
    preferences: makeCrudStateReducer(userPreferenceCrudStateConfig)
  },
  () => new UserMeta() as any
);
