// TODO: don't have react in seneca-common. Find some way to abstract away
import { ComponentType } from "react";
import { reduxForm } from "redux-form/immutable";

import isValidFunction from "seneca-common/utils/functions/checks/isValidFunction";

// TODO: don't import from features...
import {
  ProcessContentSource,
  ValidateForm
} from "features/course-creation/module/module-types/types";

import ModuleForm from "../models/ModuleForm";
import ModuleBuilderNavigator from "./utils/ModuleBuilderNavigator";

export default class ModuleFormBuilder<
  BackT,
  RootT
> extends ModuleBuilderNavigator<BackT, RootT> {
  _moduleName: string;

  _formDecorator:
    | ((arg0: ComponentType<any>) => ComponentType<any>)
    | null
    | undefined;
  _Form: ComponentType<any> | null | undefined;
  _processContentSource: ProcessContentSource | null | undefined;
  _validateForm: ValidateForm | null | undefined;

  constructor(moduleName: string, back: any, root: any) {
    super(
      {
        moduleName,
        builderName: "ModuleFormBuilder"
      },
      back,
      root
    );
    this._moduleName = moduleName;

    this._formDecorator = null;
    this._Form = null;
    this._processContentSource = null;
    this._validateForm = null;
  }

  registerFormDecorator(
    formDecorator?: (arg0: ComponentType<any>) => ComponentType<any>
  ) {
    this._formDecorator = formDecorator;
    return this;
  }

  form(Form: ComponentType<any>) {
    this._Form = Form;
    return this;
  }

  processContentSource(processContentSource: ProcessContentSource) {
    this._processContentSource = processContentSource;
    return this;
  }

  validateForm(validateForm: ValidateForm) {
    this._validateForm = validateForm;
    return this;
  }

  build(): ModuleForm {
    !this._Form && this.throwMissingAttributeError("module form");
    !this._processContentSource &&
      this.throwMissingAttributeError("processContentSource");

    let validateForm;

    if (this._validateForm) {
      validateForm = (values: any) =>
        this._validateForm!(values.toJS ? values.toJS() : values);
    }

    const formComponent = isValidFunction(this._formDecorator)
      ? this._formDecorator(this._Form!)
      : this._Form;

    const reduxFormWrappedForm = reduxForm({
      form: this._moduleName,
      initialValues:
        formComponent!.defaultProps &&
        formComponent!.defaultProps.initialValues,
      validate: validateForm
    })(formComponent as any);

    return new ModuleForm(this._moduleName, {
      Form: reduxFormWrappedForm,
      processContentSource: this._processContentSource!
    });
  }
}
