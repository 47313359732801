import { linkToSession } from "../../links";

export function linkToEndSessionModal(courseId?: string, sectionId?: string) {
  return `${linkToSession(courseId, sectionId)}/end-session`;
}

export function linkToClassLeaderboardProgressModal(
  courseId?: string,
  sectionId?: string
) {
  return `${linkToEndSessionModal(courseId, sectionId)}/class-leaderboards`;
}

export function linkToAwardMemoryScreen(courseId?: string, sectionId?: string) {
  return `${linkToEndSessionModal(courseId, sectionId)}/store-your-memory`;
}

export function linkToViewNewMemoryScreen(
  courseId?: string,
  sectionId?: string
) {
  return `${linkToEndSessionModal(courseId, sectionId)}/view-memory`;
}

export function linkToAssignmentCompleteScreen(
  courseId?: string,
  sectionId?: string
) {
  return `${linkToEndSessionModal(courseId, sectionId)}/assignment-complete`;
}

export function linkToAssignmentProgress(
  courseId?: string,
  sectionId?: string
) {
  return `${linkToEndSessionModal(courseId, sectionId)}/assignment-progress`;
}

export function linkToWrongAnswersScreen(
  courseId?: string,
  sectionId?: string
) {
  return `${linkToEndSessionModal(courseId, sectionId)}/wrong-answers`;
}

function linkToDailyXpGoal(courseId?: string, sectionId?: string) {
  return `${linkToEndSessionModal(courseId, sectionId)}/daily-xp`;
}

export function linkToSetDailyXpGoal(courseId?: string, sectionId?: string) {
  return `${linkToDailyXpGoal(courseId, sectionId)}/set`;
}

export function linkToSelectDailyXpGoal(courseId?: string, sectionId?: string) {
  return `${linkToDailyXpGoal(courseId, sectionId)}/select`;
}

export function linkToSelectedDailyXpGoal(
  courseId?: string,
  sectionId?: string
) {
  return `${linkToSelectDailyXpGoal(courseId, sectionId)}/success`;
}

export function linkToDailyXpGoalProgress(
  courseId?: string,
  sectionId?: string
) {
  return `${linkToDailyXpGoal(courseId, sectionId)}/progress`;
}

function linkToChallengeYourFriend(courseId?: string, sectionId?: string) {
  return `${linkToEndSessionModal(courseId, sectionId)}/challenge`;
}

export function linkToShareChallenge(courseId?: string, sectionId?: string) {
  return `${linkToChallengeYourFriend(courseId, sectionId)}/share`;
}

export function linkToChallengeResult(courseId?: string, sectionId?: string) {
  return `${linkToChallengeYourFriend(courseId, sectionId)}/challengeResult`;
}

export function linkToShareChallengeResult(
  courseId?: string,
  sectionId?: string
) {
  return `${linkToChallengeYourFriend(courseId, sectionId)}/shareResult`;
}

export function linkToAmeliaAdScreen(courseId?: string, sectionId?: string) {
  return `${linkToEndSessionModal(courseId, sectionId)}/ask-amelia`;
}

export function linkToMiniMockAdScreen(courseId?: string, sectionId?: string) {
  return `${linkToEndSessionModal(courseId, sectionId)}/mini-mock`;
}

export function linkToExamQuestionsAdScreen(
  courseId?: string,
  sectionId?: string
) {
  return `${linkToEndSessionModal(courseId, sectionId)}/exam-questions`;
}

export function linkToPastPapersAdScreen(
  courseId?: string,
  sectionId?: string
) {
  return `${linkToEndSessionModal(courseId, sectionId)}/past-papers`;
}

export function linkToParentInviteScreen(
  courseId?: string,
  sectionId?: string
) {
  return `${linkToEndSessionModal(courseId, sectionId)}/parent-invite`;
}

export function linkToParentInviteAfterSessionsMilestone(
  courseId?: string,
  sectionId?: string
) {
  return `${linkToEndSessionModal(courseId, sectionId)}/sessions-milestone`;
}

export function linkToParentInviteConfirmationScreen(
  courseId?: string,
  sectionId?: string
) {
  return `${linkToEndSessionModal(
    courseId,
    sectionId
  )}/parent-invite-confirmation`;
}

export function linkToStudentSentimentScreen(
  courseId?: string,
  sectionId?: string
) {
  return `${linkToEndSessionModal(courseId, sectionId)}/student-sentiment`;
}
