import { History } from "history";
import Immutable, { fromJS } from "immutable";
import { applyMiddleware, compose, createStore } from "redux";
import { reducer as formReducer } from "redux-form/immutable";
import createSentryMiddleware from "redux-sentry-middleware";
import thunk from "redux-thunk";

import {
  analyticsReduxRegistry,
  createAnalytics as createAnalyticsV2
} from "seneca-common/features/analytics/redux";
import feedback from "seneca-common/features/feedback/reducers";
import getSentry, {
  addTracingIntegration
} from "seneca-common/features/Sentry";
import sessionMessages from "seneca-common/features/session/features/messages/reducers";
import sessions from "seneca-common/features/session/reducers/session";
import sessionModules from "seneca-common/features/session/reducers/sessionModules";
import { PATH_TO_SUBSCRIPTION_PRODUCTS_STATE } from "seneca-common/features/subscriptions/features/subscription-products/state";
import { reducer as teacherReducer } from "seneca-common/features/teachers/state";
import updateAppModalSlice from "seneca-common/features/update-app-modal/redux-navigation-workaround/state";
import {
  signInActionTypes,
  reducer as userReducer
} from "seneca-common/features/user/state";
import { createAnalytics } from "seneca-common/utils/senecaAnalytics";
import {
  actionTransformer,
  breadcrumbDataFromAction,
  filterBreadcrumbs,
  getTags,
  getUserContext
} from "seneca-common/utils/sentry";
import { awaitConditionMiddleware } from "seneca-common/utils/state/await-condition";
import ReducerRegistry from "seneca-common/utils/state/reducer-registry";
import { combineReducersAndPreserveInitialState } from "seneca-common/utils/state/store-utils";

import uiState from "features/ui-state/reducers";

const CYPRESS_MERGE_ACTION_TYPE = "seneca/cypress/merge";

const actionsWithHeavyPayload = [
  "seneca/studyStats/RECEIVE_SECTION_STUDY_STATS",
  "seneca/studyStats/RECEIVE_CONTENT_STUDY_STATS",
  "seneca/session-review/RECEIVE_SESSION_REVIEWS"
];

const actionSanitizer = (action: any) => {
  return actionsWithHeavyPayload.includes(action.type) && action.payload
    ? { ...action, payload: "<<LONG_BLOB>>" }
    : action;
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      serialize: {
        immutable: Immutable
      },
      actionSanitizer,
      maxAge: 25
    })
  : compose;

const appReducers = {
  user: userReducer,
  teacher: teacherReducer,
  sessions,
  sessionModules,
  sessionMessages,
  uiState,
  feedback,
  form: formReducer,
  [updateAppModalSlice.name]: updateAppModalSlice.reducer
};

const reducerRegistry = ReducerRegistry.getInstance();

const REDUCER_REGISTRY_INITIALISATION_IDENTIFIER = "store";
reducerRegistry.initialise(
  REDUCER_REGISTRY_INITIALISATION_IDENTIFIER,
  builder => {
    // Register all default reducers
    builder.registerMultiple(appReducers);
  }
);

const appReducer = combineReducersAndPreserveInitialState(
  reducerRegistry.getReducers()
);

export const makeRootReducer =
  (reducer: (arg0: any, arg1: Record<string, any>) => any) =>
  (state: any, action: Record<string, any>) => {
    if (action.type === signInActionTypes.SIGN_OUT_SUCCESS) {
      // keep subscription products as it's user agnostic
      const products = state.get(PATH_TO_SUBSCRIPTION_PRODUCTS_STATE);
      state = fromJS({ [PATH_TO_SUBSCRIPTION_PRODUCTS_STATE]: products });
    }

    if (action.type === signInActionTypes.RESET_STORE) {
      state = undefined;
    }

    if (action.type === CYPRESS_MERGE_ACTION_TYPE) {
      state = state.mergeDeep(action.payload);
    }

    return reducer(state, action);
  };

export const makeStore = (state?: any, history?: History) =>
  createStore(
    makeRootReducer(appReducer as any),
    state,
    composeEnhancers(
      applyMiddleware(
        thunk,
        awaitConditionMiddleware,
        createAnalytics, // TODO: remove this once we've migrated everything to v2
        createAnalyticsV2, // Uncomment to log all actions to console:
        // store => dispatch => action => console.log(action) || dispatch(action),
        createSentryMiddleware(getSentry(history) as any, {
          getUserContext,
          actionTransformer,
          breadcrumbDataFromAction,
          stateTransformer: () => "erased",
          filterBreadcrumbActions: filterBreadcrumbs,
          getTags
        })
      )
    )
  );

// Replace the store's reducer whenever a new reducer is registered.
reducerRegistry.setChangeListener((store: any, reducers: any) => {
  const newAppReducer = combineReducersAndPreserveInitialState(reducers);

  store.replaceReducer(makeRootReducer(newAppReducer as any));
});

function makeGetStore() {
  let store: any;
  let onStoreInit: any;
  const storePromise = new Promise(resolve => {
    onStoreInit = (store: any) => resolve(store);
  });

  return {
    getStore(history?: History) {
      if (!store) {
        if (!getSentry(history)) {
          throw new Error("Can't initialise store without Sentry");
        }
        if (history) {
          addTracingIntegration(history);
        }

        store = makeStore(undefined, history);
        onStoreInit(store);
        analyticsReduxRegistry.registerStore(store);

        reducerRegistry.registerStore(store);
      }

      return store;
    },

    getExistingStore() {
      if (!store) {
        throw new Error("Store hasn't been created yet!");
      }

      return store;
    },

    async waitForStore(): Promise<any> {
      return await storePromise;
    }
  };
}

const { getStore, getExistingStore, waitForStore } = makeGetStore();

export { getExistingStore, waitForStore };
export default getStore;
