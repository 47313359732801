import { matchPath } from "react-router-dom";

import { linkToHelpSectionArticle } from "common/links";

import { linkToParentDashboard } from "../../links";

export function linkToSettings() {
  return `${linkToParentDashboard()}/settings`;
}

export function linkToPersonalInformation() {
  return `${linkToSettings()}/personal-information`;
}

export function linkToNotificationsPreferences() {
  return `${linkToSettings()}/notifications`;
}

export function linkToRemoveChild(childId: string = ":childId") {
  return `${linkToPersonalInformation()}/child/${childId}/disconnect`;
}

export function linkToPasswordUpdate() {
  return `${linkToSettings()}/password`;
}

export function linkToPremiumSettings() {
  return `${linkToSettings()}/premium`;
}

export function linkToCancelSubscription(
  subscriptionId: string = ":subscriptionId"
) {
  return `${linkToPremiumSettings()}/cancel/${subscriptionId}`;
}

export function linkToRestartSubscription(
  subscriptionId: string = ":subscriptionId"
) {
  return `${linkToPremiumSettings()}/restart/${subscriptionId}`;
}

export function linkToUpdatePaymentDetails(
  subscriptionId: string = ":subscriptionId"
) {
  return `${linkToPremiumSettings()}/payment-details/${subscriptionId}`;
}

export function linkToTransferPremium(languageCode: string = "en") {
  return linkToHelpSectionArticle(languageCode, "2855334");
}

export function linkToChangeAccountType(languageCode: string = "en") {
  return linkToHelpSectionArticle(languageCode, "2864487");
}

export function isInParentSettings(pathname: string) {
  return matchPath(pathname, {
    path: linkToSettings(),
    exact: false
  });
}
