import { ActionCreatorConfig } from "../const";
import { getNowString, processCallType, processName } from "../utils";
import {
  errorActionKey,
  resetActionKey,
  startActionKey,
  successActionKey
} from "./actionKeys";
import { makeActionTypes } from "./actionTypes";

type ActionType = (
  arg0: any,
  arg1: any
) => {
  type: string;
  payload?: any | null | undefined;
};

const makeActionWithoutTimestamp = (
  type: string,
  idFieldName?: string
): ActionType => {
  if (idFieldName && idFieldName.length > 0) {
    return (ids: string | string[], payload: Record<string, any> = {}) => ({
      type,
      payload: { ...payload, [idFieldName]: ids }
    });
  } else {
    return (payload?: Record<string, any>) => {
      if (payload) {
        return {
          type,
          payload: { ...payload }
        };
      }

      return {
        type
      };
    };
  }
};

const makeErrorAction = (
  type: string,
  optionalIdFieldName?: string
): ActionType => {
  // Flow Flagged - set idFieldName to constant so optionalIdFieldName cant be set to undefined before using as an object key
  const idFieldName = optionalIdFieldName;

  if (idFieldName && idFieldName.length > 0) {
    return (
      id: string | string[],
      error:
        | string
        | {
            errorMessage: string;
          }
    ) => {
      if (error) {
        if (typeof error === "string") {
          error = {
            errorMessage: error
          };
        }

        return {
          type,
          payload: { ...error, [idFieldName]: id }
        };
      }

      return {
        type,
        payload: {
          [idFieldName]: id
        }
      };
    };
  } else {
    return (
      error:
        | string
        | {
            errorMessage: string;
          }
    ) => {
      if (error) {
        if (typeof error === "string") {
          error = {
            errorMessage: error
          };
        }

        return {
          type,
          payload: error
        };
      }

      return {
        type
      };
    };
  }
};

const makeActionWithTimestamp = (
  type: string,
  idFieldName?: string
): ActionType => {
  if (idFieldName && idFieldName.length > 0) {
    return (ids: string | string[], payload) => ({
      type,
      payload: { ...payload, [idFieldName]: ids, time: getNowString() }
    });
  } else {
    return payload => ({
      type,
      payload: { ...payload, time: getNowString() }
    });
  }
};

export const makeActionsAndActionCreators = (
  config: ActionCreatorConfig
): {
  actionTypes: Record<string, string>;
  actionCreators: Record<string, ActionType>;
} => {
  const { name, idFieldName, callType } = config;
  const { callTypesLowerCase } = processCallType(callType);
  const { titleCaseName } = processName(name);

  const actionTypes = makeActionTypes(config);

  const actionCreators = {
    [`${callTypesLowerCase}${titleCaseName}Start`]: makeActionWithoutTimestamp(
      actionTypes[startActionKey(config)],
      idFieldName
    ),
    [`${callTypesLowerCase}${titleCaseName}Success`]: makeActionWithTimestamp(
      actionTypes[successActionKey(config)],
      idFieldName
    ),
    [`${callTypesLowerCase}${titleCaseName}Error`]: makeErrorAction(
      actionTypes[errorActionKey(config)],
      idFieldName
    ),
    [`${callTypesLowerCase}${titleCaseName}Reset`]: makeActionWithoutTimestamp(
      actionTypes[resetActionKey(config)],
      idFieldName
    )
  };
  return {
    actionTypes,
    actionCreators
  };
};
