import { hashKey, QueryCache } from "@tanstack/react-query";

import { DATA_LAYER_SCOPES } from "seneca-common/features/analytics";
import { pushGlobalValuesToDataLayer } from "seneca-common/features/analytics/gtm";
import { fetchSelectedSchoolQueryOptions } from "seneca-common/features/schools/school-selection/queries";

const queryCache = new QueryCache();

const addSchoolInfoToDataLayer = (event: any) => {
  if (event.type === "updated" && event.action.type === "success") {
    if (
      event.query.queryHash ===
        hashKey(fetchSelectedSchoolQueryOptions().queryKey) &&
      !!event.action.data
    ) {
      pushGlobalValuesToDataLayer({
        [DATA_LAYER_SCOPES.SCHOOL]: {
          schoolId: event.action.data.schoolId,
          name: event.action.data.schoolInfo.name
        }
      });
    }
  }

  if (event.type === "removed") {
    if (
      event.query.queryHash ===
      hashKey(fetchSelectedSchoolQueryOptions().queryKey)
    ) {
      pushGlobalValuesToDataLayer({
        [DATA_LAYER_SCOPES.SCHOOL]: {
          schoolId: null,
          name: null
        }
      });
    }
  }
};

queryCache.subscribe(addSchoolInfoToDataLayer);

export default queryCache;
