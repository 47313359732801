import actionTypes from "../action-types";
import SessionMessage from "../models/SessionMessage";

const sessionMessage = (sessionMessage = new SessionMessage(), action: any) => {
  switch (action.type) {
    case actionTypes.COMPLETE_SESSION_MESSAGE:
      return sessionMessage.set("completed", true);
    default:
      return sessionMessage;
  }
};

export default sessionMessage;
