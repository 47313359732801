import { Record } from "immutable";

import { SessionOptions as SessionOptionsT } from "@seneca/stats-service-types";

export type { SessionOptionsT };

export const defaultSessionOptions: SessionOptionsT = {
  hasHardestQuestionContent: false
};

export default class SessionOptions extends Record(defaultSessionOptions) {}
