import { Record } from "immutable";

export type PaymentStateSlice = {
  error?: string;
  secureCustomerAuthenticationOngoing: boolean;
  paymentMethodCreationOngoing: boolean;
};

const defaultValues: PaymentStateSlice = {
  error: undefined,
  secureCustomerAuthenticationOngoing: false,
  paymentMethodCreationOngoing: false
};

export default class Payment extends Record(defaultValues) {}
