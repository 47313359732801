import { getExistingStore } from "store";

export default function populateCypressGlobals() {
  if (window.Cypress) {
    (async () => {
      // Ensures our firebase app is instantiated before any Cypress commands interact with firebase
      const { getFirebaseAuth } = await import("services/firebase/auth");
      getFirebaseAuth();
      const firebaseAuth = await import("firebase/auth");
      window.__firebaseAuth__ = firebaseAuth;
    })();
    window.__store__ = getExistingStore();
    window.__firebaseAuthMocks__ = {};
  }
}
