import { useSelector } from "react-redux";

/**
A convenience so you can do: 

const name = useSelectorWithArgsOnCondition(coursesHaveFetched, getCourseName, courseId)

instead of: 

const name = useSelector(s => coursesHaveFetched && getCourseName(s, courseId))

 */

export default function useSelectorOnCondition<
  ArgumentsT extends ReadonlyArray<any>,
  ReturnT
>(
  condition: any,
  selector: (...args: any) => ReturnT, // TODO: replace with SelectorWithArgs<ReturnT, ArgumentsT>
  ...selectorArgs: ArgumentsT
): ReturnT | null {
  return useSelector(s => (condition ? selector(s, ...selectorArgs) : null));
}
