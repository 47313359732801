let config: any;

export default function getConfig() {
  if (!config) {
    const { REACT_APP_USE_CONFIG, STORYBOOK_USE_CONFIG } = process.env;

    if (REACT_APP_USE_CONFIG === "dev" || STORYBOOK_USE_CONFIG === "dev") {
      // Require has to be `config.${REACT_APP_USE_CONFIG || STORYBOOK_USE_CONFIG}.json` (i.e string assembled by environment variables)
      // otherwise webpack will try and bundle config.dev.json
      config = require(
        `./config.${REACT_APP_USE_CONFIG || STORYBOOK_USE_CONFIG}.json`
      );
    } else {
      if (!window.env) throw new Error("No config found");
      config = window.env;
    }

    const googleTagManager = config.googleTagManager
      ? {
          id:
            config.googleTagManager.id ||
            process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID,
          auth:
            config.googleTagManager.auth ||
            process.env.REACT_APP_GOOGLE_TAG_MANAGER_AUTH,
          preview:
            config.googleTagManager.preview ||
            process.env.REACT_APP_GOOGLE_TAG_MANAGER_PREVIEW
        }
      : {};

    config = Object.freeze({
      ...config,
      googleTagManager
    });

    if (isPlurallApp(config.plurall.domain)) {
      config = Object.freeze({
        ...config,
        ...config.plurall,
        isPlurallApp: true
      });
    }

    if (isGoStudentLearningApp(config.goStudentLearning.domain)) {
      config = Object.freeze({
        ...config,
        ...config.goStudentLearning,
        isGoStudentLearningApp: true
      });
    }
  }

  return config;
}

export function useConfig() {
  try {
    return {
      config: getConfig(),
      configError: null
    };
  } catch (error) {
    return {
      config: null,
      configError: error
    };
  }
}

// importing from features/plurall messes up webpack
function isPlurallApp(domain: string) {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const featureFlagEnabled = urlSearchParams.get("isPlurallApp") === "true";

  const isPlurallDomain = window.location.origin.includes(domain);

  try {
    const item = localStorage.getItem("isPlurallApp");
    return (item && JSON.parse(item)) || isPlurallDomain || featureFlagEnabled;
  } catch (err) {
    return isPlurallDomain;
  }
}

function isGoStudentLearningApp(domain: string) {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const featureFlagEnabled =
    urlSearchParams.get("isGoStudentLearningApp") === "true";

  const isGoStudentLearningDomain = window.location.origin.includes(domain);

  try {
    const item = localStorage.getItem("isGoStudentLearningApp");
    return (
      (item && JSON.parse(item)) ||
      isGoStudentLearningDomain ||
      featureFlagEnabled
    );
  } catch (err) {
    return isGoStudentLearningDomain;
  }
}
