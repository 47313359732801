import { ThemeInMode, themes } from "@seneca/senekit";

import useAppVariant from "features/common/hooks/useAppVariant";
import { useDarkModeSettings } from "features/dark-mode/hooks/useDarkModeSettings";

import useSenecaTheme from "./useSenecaTheme";

export default function useGetThemeInMode(): ThemeInMode {
  const appVariant = useAppVariant();
  const senecaTheme = useSenecaTheme();
  const { darkModeEnabled } = useDarkModeSettings();

  const valuesByVariant = {
    seneca: senecaTheme,
    plurall: themes.plurall,
    goStudentLearning: themes.goStudentLearning
  };

  const theme = valuesByVariant[appVariant];
  const { colors, icons } = theme;

  return darkModeEnabled
    ? {
        ...colors.dark,
        icons
      }
    : {
        ...colors.light,
        icons
      };
}
