import {
  DefaultError,
  queryOptions,
  UseQueryOptions
} from "@tanstack/react-query";

import { queryClient } from "services";

import { defaultContext, fetchSelectedSchool } from "./fetchSelectedSchool";
import { SelectedSchool } from "./types";

export const SELECTED_SCHOOL_QUERY_KEY = [
  "schools",
  "school-selection",
  "fetchSelectedSchool"
];

export function fetchSelectedSchoolQueryOptions<DataT = SelectedSchool>(
  opts: Pick<
    UseQueryOptions<SelectedSchool | null, DefaultError, DataT>,
    "enabled" | "select"
  > & { includeParentInfo?: boolean } = {}
) {
  const { includeParentInfo, ...restOpts } = opts;

  return queryOptions({
    queryKey: [...SELECTED_SCHOOL_QUERY_KEY, { includeParentInfo }],
    queryFn: () =>
      fetchSelectedSchool({ includeParentInfo, context: defaultContext() }),
    staleTime: Infinity,
    ...restOpts
  });
}

export function getCachedSelectedSchool() {
  return queryClient.getQueryData(fetchSelectedSchoolQueryOptions().queryKey);
}
