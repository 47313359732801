import { ModuleType as ModuleNames } from "seneca-common/features/module/module-types/types";
import sortStringAscendingOrder from "seneca-common/utils/functions/sorting/sortStringAscendingOrder";

import ModuleType from "./Module";

type ModulesType = Record<ModuleNames, ModuleType>;

export default class Modules {
  _modules: ModulesType;

  constructor() {
    this._modules = {};
  }

  addOrUpdateModule(moduleName: ModuleNames, module: ModuleType) {
    this._modules[moduleName] = module;
  }

  hasModule(moduleName: ModuleNames): boolean {
    return !!this._modules[moduleName];
  }

  getModuleNames(): Array<ModuleNames> {
    return Object.keys(this._modules).sort(sortStringAscendingOrder);
  }

  getModules(): ModulesType {
    return { ...this._modules };
  }

  getModule(moduleName: ModuleNames): ModuleType {
    const moduleObject = this._modules[moduleName];

    if (!moduleObject) {
      throw new Error(
        `Unable to get module ${moduleName} because it has not been registered.`
      );
    }

    return moduleObject;
  }

  getReadOnlyModules(): Array<ModuleNames> {
    return this.getModuleNames().filter(moduleName =>
      this._modules[moduleName].isReadOnly()
    );
  }

  getTestOnlyModules(): Array<ModuleNames> {
    return this.getModuleNames().filter(moduleName =>
      this._modules[moduleName].isTestOnly()
    );
  }

  getMobileModules(): Array<ModuleNames> {
    return this.getModuleNames().filter(moduleName =>
      this._modules[moduleName].getComponentStaticInformation().isMobileModule()
    );
  }

  getDesktopOnlyModules(): Array<ModuleNames> {
    return this.getModuleNames().filter(
      moduleName =>
        !this._modules[moduleName]
          .getComponentStaticInformation()
          .isMobileModule()
    );
  }

  getMobileTestableModules(): Array<ModuleNames> {
    return this.getMobileModules().filter(
      moduleName => !this._modules[moduleName].isReadOnly()
    );
  }

  getCanBeTestedModules(): Array<ModuleNames> {
    return this.getModuleNames().filter(moduleName =>
      this._modules[moduleName].getCanBeTested()
    );
  }

  moduleHasBeenRegistered(moduleName: ModuleNames): boolean {
    return !!this._modules[moduleName];
  }

  validate() {
    const validationErrors = this.getModuleNames()
      .map(moduleName => this._modules[moduleName].validate())
      .filter(Boolean);

    if (validationErrors.length > 0) {
      throw new Error(
        `Modules registration error: \n${validationErrors.join("\n\n")}`
      );
    }
  }
}
