import {
  EmailAlreadyExistsError,
  FirebaseUserNotFoundError,
  ForbiddenUserOperationError,
  UserNotFoundError
} from "@seneca/authentication-service-client";

export const DEFAULT_MESSAGE = "An unknown error has occurred.";

export default function errorTranslator(error: Error): string {
  if (error instanceof EmailAlreadyExistsError)
    return "The email you entered is already in use, please try another one or login.";

  if (error instanceof UserNotFoundError)
    return "We've not seen that email before, please sign up!";

  if (error instanceof ForbiddenUserOperationError)
    return "You are not allowed to perform this operation";

  if (error instanceof FirebaseUserNotFoundError) return "User not found";

  return DEFAULT_MESSAGE;
}
