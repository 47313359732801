import { ModuleType as ModuleNames } from "seneca-common/features/module/module-types/types";
import Navigator from "seneca-common/utils/builders/Navigator";

type Options = {
  moduleName: ModuleNames;
  builderName: string;
};

class MissingAttributeError extends Error {
  constructor(attributeName: string, moduleName: string, builderName: string) {
    super(
      `You must provide a ${attributeName} before building the ${moduleName} ${builderName}`
    );
  }
}

export default class ModuleBuilderNavigator<BackT, RootT> extends Navigator<
  BackT,
  RootT
> {
  _moduleName: ModuleNames;
  _builderName: string;

  constructor({ moduleName, builderName }: Options, back: any, root: any) {
    super(back, root);
    this._moduleName = moduleName;
    this._builderName = builderName;
  }

  throwMissingAttributeError(attributeName: string) {
    throw new MissingAttributeError(
      attributeName,
      this._moduleName,
      this._builderName
    );
  }
}
