import SessionXp from "../../../../../models/SessionXp";

export function getNewXp(sessionXp?: SessionXp) {
  return sessionXp?.newXp;
}

export function getBonusMultiplier(sessionXp?: SessionXp) {
  return sessionXp?.bonusMultiplier;
}

export function getBreakdown(sessionXp?: SessionXp) {
  return sessionXp?.breakdown;
}
