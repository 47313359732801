export enum QuestionType {
  SIMPLE = "simple",
  MULTI_REASON = "multi-reason",
  MULTI_MODEL = "multi-model",
  CALCULATION = "calculation"
}

export enum QuestionDifficulty {
  HARD = "hard"
}

export type InputMeta = {
  placeholderText: string;
  prefixText: string;
};

export type ModelAnswer = {
  text: string;
  markPart: number;
};
