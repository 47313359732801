import { useIntl } from "react-intl";

import { Message, MessageValues } from "./types";

export default function useLocalisedMessage(
  message: Message,
  messageValues?: MessageValues
) {
  const formatMessage = useFormatMessage();
  return formatMessage(message, messageValues);
}

export function useFormatMessage() {
  const intl = useIntl();
  return intl.formatMessage;
}
