import {
  makePushEventToDataLayer,
  pushEnrichedEventToDataLayerWithCleanUp
} from "seneca-common/features/analytics";

import "seneca-common/features/subscriptions/state/analytics/register-data-layer-selectors";

import { PaymentMethod } from "features/subscriptions/analytics/consts";

import { ReceivedSubscriptionType } from "../types";

export const analyticsLogCreateSubscriptionSuccess = ({
  subscription,
  source,
  paymentMethod,
  yearGroup
}: {
  subscription: ReceivedSubscriptionType;
  source?: string;
  paymentMethod: PaymentMethod;
  yearGroup?: number | null;
}) => {
  const oldExpiryDate = subscription.expiryDate;
  pushEnrichedEventToDataLayerWithCleanUp({
    event: `seneca/subscription/create`,
    payload: {
      oldExpiryDate,
      source,
      paymentMethod,
      yearGroup
    }
  });
};

export const analyticsLogCancelSubscription = makePushEventToDataLayer(
  "seneca/subscription/cancel"
);

export const analyticsLogRestartSubscription = makePushEventToDataLayer(
  `seneca/subscription/restart`
);

export const analyticsLogUpdatePaymentMethod = makePushEventToDataLayer(
  "seneca/subscription/updatePaymentMethod"
);

export const analyticsLogReassignSubscription = (childId: string) => {
  pushEnrichedEventToDataLayerWithCleanUp({
    event: `seneca/subscription/reassign`,
    payload: {
      childId
    }
  });
};
