export default class ErrorTransformingObjectToImmutableError extends Error {
  jsObject: any;
  err: any;

  constructor(err: any, jsObject: any) {
    super(
      `An error was thrown when running the jsToImmutableTransform for an object: ${err}`
    );
    this.err = err;
    this.jsObject = jsObject;
  }
}
