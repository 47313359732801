import { Deferrable } from "./types";

export function defer(): Deferrable {
  let res: any, rej: any;

  const promise = new Promise((resolve, reject) => {
    res = resolve;
    rej = reject;
  });

  // @ts-ignore
  promise.resolve = res;
  // @ts-ignore
  promise.reject = rej;

  return promise as Deferrable;
}
