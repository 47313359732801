import ReducerRegistry from "seneca-common/utils/state/reducer-registry";

import { APP_STATE_PATH } from "./consts";
import appReducer from "./reducers";

const reducerRegistry = ReducerRegistry.getInstance();

reducerRegistry.initialise(APP_STATE_PATH, builder => {
  builder.register(APP_STATE_PATH, appReducer);
});

export {
  appVersionIsUpdating,
  dismissPrompts,
  newAppVersionIsAvailable,
  newPWAConfigReceived,
  setLastSeenPrompt,
  setUpdateToastHidden,
  showWarning
} from "./actions/pwa";
export {
  appVersionIsUpdating as getAppVersionIsUpdating,
  getLastSeenPrompt,
  getPWAStateSlice as getPWAState,
  newVersionOfTheAppIsAvailable as getNewVersionOfTheAppIsAvailable,
  shouldShowPrompt,
  shouldShowWarning
} from "./selectors";
