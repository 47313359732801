export const actionTypes = {
  SET_MODAL_OPEN: "seneca/uiState/SET_MODAL_OPEN",
  SET_MODAL_CLOSED: "seneca/uiState/SET_MODAL_CLOSED",
  SET_MOBILE_KEYBOARD_OPEN: "seneca/uiState/SET_MOBILE_KEYBOARD_OPEN",
  SET_MOBILE_KEYBOARD_CLOSED: "seneca/uiState/SET_MOBILE_KEYBOARD_CLOSED",
  SET_SMART_LEARNING_TRIAL_ACTIVE:
    "seneca/uiState/SET_SMART_LEARNING_TRIAL_ACTIVE",
  SET_SMART_LEARNING_TRIAL_INACTIVE:
    "seneca/uiState/SET_SMART_LEARNING_TRIAL_INACTIVE",
  SET_PLURALL_HELP_CENTRE_MODAL_OPEN:
    "seneca/uiState/SET_PLURALL_HELP_CENTRE_MODAL_OPEN",
  SET_PLURALL_HELP_CENTRE_MODAL_CLOSED:
    "seneca/uiState/SET_PLURALL_HELP_CENTRE_MODAL_CLOSED"
};

export const setModalOpen = () => ({
  type: actionTypes.SET_MODAL_OPEN
});

export const setModalClosed = () => ({
  type: actionTypes.SET_MODAL_CLOSED
});

export const setMobileKeyboardOpen = () => ({
  type: actionTypes.SET_MOBILE_KEYBOARD_OPEN
});

export const setMobileKeyboardClosed = () => ({
  type: actionTypes.SET_MOBILE_KEYBOARD_CLOSED
});

export const setPlurallHelpCentreModalOpen = () => ({
  type: actionTypes.SET_PLURALL_HELP_CENTRE_MODAL_OPEN
});

export const setPlurallHelpCentreModalClosed = () => ({
  type: actionTypes.SET_PLURALL_HELP_CENTRE_MODAL_CLOSED
});
