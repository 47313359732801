import { userType as userTypeClient } from "@seneca/user-service-client";

export const userServiceGender = {
  MALE: "male",
  FEMALE: "female",
  NON_BINARY: "non-binary",
  PREFER_NOT_TO_SAY: "prefer not to say"
};

export const userAccountType = {
  schoolteacher: userTypeClient.teacher,
  schoolstudent: userTypeClient.student,
  schoolparent: userTypeClient.parent
};

export const UserAccountTypeSettingsForm = [
  {
    label: "Student",
    value: userTypeClient.student
  },
  {
    label: "Teacher",
    value: userTypeClient.teacher
  },
  {
    label: "Parent",
    value: userTypeClient.parent
  }
];

export type TeacherRole = keyof typeof validTeacherRoleNames;

export const validTeacherRoleNames = Object.freeze({
  classroom_teacher: "Classroom Teacher",
  head_department: "Head of Department / Faculty",
  head_year: "Head of Year / Key Stage",
  senior_leader: "Senior Leader",
  headteacher: "Headteacher",
  non_teaching_staff: "Non-teaching staff"
});

export const validTeacherRoles = Object.keys(validTeacherRoleNames);

export const TeacherRoleOptions = validTeacherRoles.reduce(
  (dropdownValues, role) => {
    dropdownValues.push({
      label: (validTeacherRoleNames as any)[role],
      value: role as TeacherRole
    });
    return dropdownValues;
  },
  [] as Array<{
    label: string;
    value: TeacherRole;
  }>
);
const { senior_leader, headteacher } = validTeacherRoleNames;
export const SltTeacherRoles = Object.keys({ senior_leader, headteacher });
