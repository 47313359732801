export default async function hasNotificationsPermissions() {
  try {
    const permission = await navigator.permissions.query({
      name: "push",
      // @ts-ignore prop is supported https://developer.mozilla.org/en-US/docs/Web/API/Permissions/query
      userVisibleOnly: true
    });
    return permission.state === "granted";
  } catch {
    return false;
  }
}
