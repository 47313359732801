import { useSearchParams } from "react-router-dom-v5-compat";

import { IosSubscriptionFlow } from "../types";

export function useIosSubscriptionFlowQueryParams() {
  const [searchParams] = useSearchParams();

  const iosSubscriptionFlow = searchParams.get(
    "iosSubscriptionFlow"
  ) as IosSubscriptionFlow | null;

  const subscriptionId = searchParams.get("subscriptionId");
  const userId = searchParams.get("userId");
  const userType = searchParams.get("userType");

  return { iosSubscriptionFlow, subscriptionId, userId, userType };
}
