// flow
import { Record } from "immutable";

import LearningMode from "./learningMode/LearningMode";
import Meta from "./meta/Meta";
import SessionUI from "./sessionUI/SessionUI";
import SidebarUI from "./sidebarUI/SidebarUI";

type ClassroomType = {
  meta: Meta;
  learningMode: LearningMode;
  sessionUI: SessionUI;
  sidebarUI: SidebarUI;
};

export type OptionalClassroomType = Partial<ClassroomType>;

const defaultClassroom: ClassroomType = {
  meta: new Meta(),
  learningMode: new LearningMode(),
  sessionUI: new SessionUI(),
  sidebarUI: new SidebarUI()
};

export default class Classroom extends Record(defaultClassroom) {}
