import classNames from "classnames";
import * as React from "react";
import { Link } from "react-router-dom";
import { CSSTransition } from "react-transition-group";

import { Box, IconButton } from "@seneca/senekit";

import isValidFunction from "seneca-common/utils/functions/checks/isValidFunction";

import "./Modal.scss";

export type Props = {
  children: React.ReactNode;
  noOverlay?: boolean;
  overlay?: string;
  closeModal?: () => void;
  clickToExit?: boolean;
  fixed?: boolean;
  large?: boolean;
  noZIndex?: boolean;
  inTransitionWrapper?: boolean;
  overlayClassName?: string;
  open: boolean;
  setModalOpen?: (arg0: void) => void;
  setModalClosed?: (arg0: void) => void;
  overlayLink?: string;
  className?: string;
  showCloseIcon?: boolean;
};

const Fade = ({ children, open }: any) => (
  <CSSTransition
    in={open}
    classNames="Modal__fade"
    timeout={{
      enter: 400,
      exit: 250
    }}
    mountOnEnter
    unmountOnExit
  >
    {children}
  </CSSTransition>
);

class Modal extends React.PureComponent<Props> {
  static defaultProps = {
    fixed: true
  };

  componentDidMount() {
    if (this.props.open) {
      isValidFunction(this.props.setModalOpen) && this.props.setModalOpen();
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.open && !prevProps.open) {
      isValidFunction(this.props.setModalOpen) && this.props.setModalOpen();
    }

    if (!this.props.open && prevProps.open) {
      isValidFunction(this.props.setModalClosed) && this.props.setModalClosed();
    }
  }

  componentWillUnmount() {
    if (this.props.open && isValidFunction(this.props.setModalClosed)) {
      this.props.setModalClosed();
    }
  }

  renderOverlay = () => {
    const {
      open,
      closeModal,
      overlay,
      clickToExit,
      fixed,
      noZIndex,
      overlayLink,
      overlayClassName,
      noOverlay,
      inTransitionWrapper
    } = this.props;

    if (noOverlay) {
      return null;
    }

    let overlayElement = (
      <div
        className={classNames(overlayClassName, "Modal__overlay", {
          "Modal__overlay--cursor": clickToExit && !inTransitionWrapper,
          "Modal__overlay--noZIndex": noZIndex,
          "Modal__overlay--fixed": fixed,
          "Modal__overlay--inTransitionWrapper": inTransitionWrapper
        })}
        style={{
          background: overlay
        }}
        onClick={clickToExit ? closeModal : (null as any)}
      />
    );

    if (overlayLink && clickToExit) {
      overlayElement = (
        <Link to={overlayLink} replace>
          {overlayElement}
        </Link>
      );
    }

    return <Fade open={open}>{overlayElement}</Fade>;
  };

  render() {
    const {
      open,
      children,
      fixed,
      noZIndex,
      large,
      className,
      overlayLink,
      showCloseIcon
    } = this.props;
    return (
      <div>
        {this.renderOverlay()}
        <Fade open={open}>
          <div
            className={classNames("Modal__modal", className, {
              "Modal__modal--noZIndex": noZIndex,
              "Modal__modal--large": large,
              "Modal__modal--fixed": fixed
            })}
            data-test="Modal"
          >
            {showCloseIcon && overlayLink && (
              <Box position="absolute" top="0" right="0">
                <Link to={overlayLink} replace>
                  <IconButton
                    iconName="cross"
                    accessibilityLabel="Close modal"
                    size="medium"
                    onClick={() => {}}
                  />
                </Link>
              </Box>
            )}

            {children}
          </div>
        </Fade>
      </div>
    );
  }
}

export default Modal;
