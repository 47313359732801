import { Location } from "history";

import { textQueryParamInUrl } from "../consts";

export type Filters = Record<string, string[] | string>;

export function makeGetSearchTermFromLocation(textQueryParam: string) {
  return function getSearchTextFromLocation(location: any) {
    if (window.URLSearchParams) {
      const paramString = location.search;
      const searchParam = new URLSearchParams(paramString);
      return searchParam.get(textQueryParam) || "";
    } else {
      return "";
    }
  };
}

export function makeCreateFiltersAndTermSearchParams(textQueryParam: string) {
  return function createSearchParams(filters: Filters, text?: string): string {
    if (window.URLSearchParams) {
      const searchParams = Object.keys(filters).reduce(
        (queryParams, categoryName) => {
          const tagNames = filters[categoryName];
          const tags = Array.isArray(tagNames) ? tagNames : [tagNames];
          tags.forEach(tagName => queryParams.append(categoryName, tagName));
          return queryParams;
        },
        new URLSearchParams()
      );

      if (text) {
        searchParams.append(textQueryParam, text);
      }

      return searchParams.toString();
    } else {
      return "";
    }
  };
}

export function makeGetFiltersSearchParams(textQueryParam: string) {
  return function (location: Location) {
    if (window.URLSearchParams) {
      const paramString = location.search;
      const searchParams = new URLSearchParams(paramString);
      searchParams.delete(textQueryParam);
      return searchParams;
    } else {
      return "";
    }
  };
}

export const getSearchTextFromLocation =
  makeGetSearchTermFromLocation(textQueryParamInUrl);
export const createSearchParams =
  makeCreateFiltersAndTermSearchParams(textQueryParamInUrl);

export const createSearchParamsWithSemanticCategories =
  makeCreateFiltersAndTermSearchParams(textQueryParamInUrl);
export const getFiltersSearchParams =
  makeGetFiltersSearchParams(textQueryParamInUrl);
