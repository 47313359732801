import * as React from "react";
import { connect } from "react-redux";

import { toJS } from "seneca-common/utils/components/toJS";

import { setModalClosed, setModalOpen } from "features/ui-state/actions";

import Modal from "../components/Modal";

const mapStateToProps = (state: any, ownProps: any) => ownProps;

const mapDispatchToProps = (dispatch: any) => ({
  setModalOpen: () => dispatch(setModalOpen()),
  setModalClosed: () => dispatch(setModalClosed())
});

export type InjectedProps = {
  setModalOpen: () => void;
  setModalClosed: () => void;
};

export const makeModalContainer = (
  ModalComponent: React.ComponentType<any>,
  options?: Record<string, any>
) =>
  connect(
    mapStateToProps,
    mapDispatchToProps,
    null as any,
    options
  )(toJS(ModalComponent));

export default makeModalContainer(Modal);
