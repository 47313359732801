import { Location } from "history";

import { makeLinkStateGetterAndSetter } from "seneca-common/utils/routing";

import { linkToSession } from "../../links";

export const {
  set: addQuitSessionDestinationState,
  get: getQuitSessionDestination
} = makeLinkStateGetterAndSetter<string>("quitSessionDestination");

const QUIT_SESSION_APPENDAGE = "/quit";

export function linkToQuitSectionSession(
  courseId: string = ":courseId",
  sectionId: string = ":sectionId"
) {
  return `${linkToSession(courseId, sectionId)}${QUIT_SESSION_APPENDAGE}`;
}

export function getLinkToSectionSessionWithoutQuit(pathname: string) {
  if (pathname.endsWith(QUIT_SESSION_APPENDAGE)) {
    return pathname.replace(QUIT_SESSION_APPENDAGE, "");
  } else {
    return pathname;
  }
}

export function linkToQuitSectionSessionWithDestination(
  courseId?: string,
  sectionId?: string,
  // @ts-ignore optional-arg
  destination: string | Location
) {
  return addQuitSessionDestinationState(
    linkToQuitSectionSession(courseId, sectionId),
    destination as any
  );
}
