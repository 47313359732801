import {
  useForegroundVariantColor,
  useShadow,
  useTheme
} from "@seneca/senekit";
import { ForegroundVariant } from "@seneca/senekit/out/styles/colors/types";
import { ShadowSize } from "@seneca/senekit/out/styles/shadows/useShadow";

import StyledComponentsGlobalCSS from "./StyledComponentsGlobalCSS";

export default function GlobalCSS() {
  const shadows: {
    [key in ShadowSize]: string;
  } = {
    xsmall: useShadow("xsmall"),
    small: useShadow("small"),
    medium: useShadow("medium"),
    large: useShadow("large"),
    xlarge: useShadow("xlarge")
  };

  const foregroundVariantColors: {
    [key in ForegroundVariant]: string;
  } = {
    "1": useForegroundVariantColor("1"),
    "2": useForegroundVariantColor("2"),
    "3": useForegroundVariantColor("3")
  };

  const theme = useTheme();
  return (
    <StyledComponentsGlobalCSS
      theme={theme}
      shadows={shadows}
      foregroundVariantColors={foregroundVariantColors}
    />
  );
}
