// 'smartLearning' session type is used for analytics only
export const SESSION_TYPES = Object.freeze({
  adaptive: "adaptive",
  wrongAnswers: "wrongAnswers",
  smartLearning: "smartLearning",
  quiz: "quiz",
  multiSection: "multiSection"
});

export type SessionType = keyof typeof SESSION_TYPES;
