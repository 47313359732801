import { AllowedCallTypes, CrudConfig } from "../../common/const";
import { processCallType } from "../../common/utils";

export const callTypeExistsInList = (
  callType: AllowedCallTypes,
  callTypeList?: Array<AllowedCallTypes>
): boolean => {
  if (!callTypeList || callTypeList.length === 0) {
    return false;
  }

  return (
    callTypeList.filter(callTypeListEntry => {
      const { callTypeCaps: callTypeListEntryCaps } =
        processCallType(callTypeListEntry);
      return callTypeListEntryCaps === callType;
    }).length > 0
  );
};

export const consumerRequiresCallType = (
  callType: AllowedCallTypes,
  { callTypes }: CrudConfig
): boolean => {
  const noCallTypesSpecifiedInConfig = !callTypes || callTypes.length === 0;

  if (
    noCallTypesSpecifiedInConfig ||
    callTypeExistsInList(callType, callTypes)
  ) {
    return true;
  }

  return false;
};
