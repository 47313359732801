import { State } from "common/types/redux";

type Selector = (state: State, ...args: any[]) => any;

export default function withWaitForFetch<SelectorT extends Selector>(
  selector: SelectorT,
  hasDataFetched: (state: State) => boolean
): (arg0: any) => any {
  return function (state, ...args) {
    // @ts-ignore
    const fetched = hasDataFetched(state);

    if (!fetched) {
      return null;
    }

    // @ts-ignore
    return selector(state, ...args);
  };
}
