export type Selector<S, R> = (arg0: S, ...args: any) => R;

export type Selectors<S> = Record<string, Selector<S, any>>;

export type SelectorCreator<S, R> = (...args: any) => (arg0: S) => R;

export type SelectorCreators<S> = Record<string, SelectorCreator<S, any>>;

export const composeSelectors = <S, N>(
  stateSliceSelector: Selector<S, N>,
  selectors: Selectors<N>,
  argumentsMapper?: (...args: any) => any[]
): Selectors<S> =>
  // @ts-ignore
  makeComposedSelectorsFactory(selectors)(stateSliceSelector, argumentsMapper);

export const makeComposedSelectorsFactory =
  <S, N>(selectors: Selectors<N>) =>
  (
    stateSliceSelector: Selector<S, N>,
    argumentsMapper?: (...args: any) => any[]
  ): Selectors<S> =>
    Object.keys(selectors).reduce((composedSelectors: any, selectorName) => {
      composedSelectors[selectorName] = composeSelector(
        stateSliceSelector,
        selectors[selectorName],
        argumentsMapper
      );
      return composedSelectors;
    }, {});

export const composeSelector =
  <S, N, R>(
    stateSliceSelector: Selector<S, N>,
    selector: Selector<N, R>,
    argumentsMapper: (...args: any) => unknown[] = (...args: any) => args
  ): Selector<S, R> =>
  (state: S, ...args: any) =>
    selector(stateSliceSelector(state, ...args), ...argumentsMapper(...args));

export const composeSelectorCreators = <S, N>(
  stateSliceSelector: Selector<S, N>,
  selectorCreators: SelectorCreators<N>
): SelectorCreators<S> =>
  // @ts-ignore
  makeComposedSelectorCreatorsFactory(selectorCreators)(stateSliceSelector);

export const makeComposedSelectorCreatorsFactory =
  <S, N>(selectorCreators: SelectorCreators<N>) =>
  (stateSliceSelector: Selector<S, N>): SelectorCreators<S> =>
    Object.keys(selectorCreators).reduce((composedSelectors, selectorName) => {
      return {
        ...composedSelectors,
        [selectorName]: composeSelectorCreator(
          stateSliceSelector,
          selectorCreators[selectorName]
        )
      };
    }, {});

export const composeSelectorCreator =
  <S, N, R>(
    stateSliceSelector: Selector<S, N>,
    selectorCreator: SelectorCreator<N, R>,
    argumentsMapper: (...args: any) => unknown[] = (...args: any) => args
  ): SelectorCreator<S, R> =>
  (...args: any) => {
    const selector = selectorCreator(...args);
    return composeSelector(stateSliceSelector, selector, argumentsMapper);
  };

export const makeComposedSelectorFactory =
  <S>(selectors: Selectors<S>) =>
  (selectorName: string, stateSliceSelector: Selector<any, S>) =>
  (state: any, ...args: any) =>
    selectors[selectorName](stateSliceSelector(state, ...args), ...args);
