import { isServiceWorkerSupported } from "service-worker-registration/utils";

import { getFeatureItem } from "features/dev-tools/featureTogglesUtils";

import { publicUrl } from "./consts";
import registerServiceWorker from "./registerServiceWorker";
import { Config } from "./types";
import { debugLogString } from "./utils";

debugLogString("Register service worker file imported");

export default function registerServiceWorkerOnPageLoadIfNecessary(
  config: Config
) {
  debugLogString("Performing pre-registration service worker checks...");

  if (!shouldRegisterServiceWorker()) {
    debugLogString("Not registering service worker as some conditions not met");
    return;
  }

  // $FlowFixMe
  let previousController = navigator.serviceWorker.controller;

  // When the new service worker takes control (i.e. after the user has clicked update), reload the page so we start to use the new version of the app
  // $FlowFixMe
  navigator.serviceWorker.oncontrollerchange = () => {
    // NOTE: Only do this when a service worker controller exists, as this event will fire the first time the service worker is installed (ie. a first-time visitor)
    if (previousController) {
      window.location.reload();
    }

    // $FlowFixMe
    previousController = navigator.serviceWorker.controller;
  };

  debugLogString("Waiting for page load to finish...");
  window.addEventListener("load", () => {
    const swUrl = `${publicUrl}/service-worker.js`;
    registerServiceWorker(swUrl, config);
  });
}

function shouldRegisterServiceWorker() {
  const isSWSupported = isServiceWorkerSupported(); // The URL constructor is available in all browsers that support SW.

  const isEnabledServiceWorkerFlag =
    process.env.NODE_ENV === "development" &&
    getFeatureItem("enableServiceWorker");

  // Our service worker won't work if PUBLIC_URL is on a different origin
  // from what our page is served on. This might happen if a CDN is used to
  // serve assets; see https://github.com/facebook/create-react-app/issues/2374
  const isSameOrigin =
    new URL(publicUrl, window.location.href).origin === window.location.origin;

  const isProductionBuild = process.env.NODE_ENV === "production";

  return (
    isSWSupported &&
    ((isProductionBuild && isSameOrigin) || isEnabledServiceWorkerFlag)
  );
}
