import { LearningMode } from "../../../../consts";

export type { LearningMode };

export function isDefaultMode(mode: LearningMode) {
  return mode === LearningMode.default;
}

export function isSmartLearningMode(mode: LearningMode) {
  return mode === LearningMode.smartLearning;
}

export function isWrongAnswersMode(mode: LearningMode) {
  return mode === LearningMode.wrongAnswers;
}

export function isAssignmentsMode(mode: LearningMode) {
  return mode === LearningMode.assignments;
}

export function isQuizMode(mode: LearningMode) {
  return mode === LearningMode.quiz;
}
