import { matchPath } from "react-router-dom";

export function linkToParentDashboard() {
  return "/parent";
}

export function linkToParentWhatIsPremium() {
  return "/parent/what-is-premium";
}

export function isInParentPlatform(pathname: string) {
  return matchPath(pathname, {
    path: linkToParentDashboard(),
    exact: false
  });
}

export function isInParentPremium(pathname: string) {
  return matchPath(pathname, {
    path: linkToParentWhatIsPremium(),
    exact: false
  });
}
