import { useLocation } from "react-router-dom";

import { AndroidTWAVersionParamKey } from "features/pwa/mobile/android/utils/consts";

import { useGetIsAndroidTWA } from "./useAndroidTWA";

export function useSetAndroidTWAVersion() {
  const location = useLocation();
  const isAndroidTWA = useGetIsAndroidTWA();

  const params = new URLSearchParams(location.search);
  const hasVersion = params.has(AndroidTWAVersionParamKey);

  if (hasVersion && isAndroidTWA) {
    const version = params.get(AndroidTWAVersionParamKey)!;
    localStorage.setItem(AndroidTWAVersionParamKey, version);
  }
}

export function useGetAndroidTWAVersion() {
  try {
    return localStorage.getItem(AndroidTWAVersionParamKey);
  } catch (error) {
    return null;
  }
}
