import { LANGUAGE_CODES } from "common/static/languages";

import { isPlurallApp } from "features/plurall";

import messages_de_AT from "../../translations/de-at.json";
import messages_es from "../../translations/es.json";
import messages_fr from "../../translations/fr.json";
import messages_it from "../../translations/it.json";
import messages_pt_BR_plurall from "../../translations/pt-BR-plurall.json";
import messages_pt_BR from "../../translations/pt-BR.json";
import messages_vi from "../../translations/vi.json";

const dictionaries = {
  [LANGUAGE_CODES.PT_BR]: isPlurallApp()
    ? { ...messages_pt_BR, ...messages_pt_BR_plurall }
    : messages_pt_BR,
  [LANGUAGE_CODES.ES]: messages_es,
  [LANGUAGE_CODES.ES_CO]: messages_es,
  [LANGUAGE_CODES.ES_MX]: messages_es,
  [LANGUAGE_CODES.FR]: messages_fr,
  [LANGUAGE_CODES.VI]: messages_vi,
  [LANGUAGE_CODES.AT]: messages_de_AT,
  [LANGUAGE_CODES.DE]: messages_de_AT,
  [LANGUAGE_CODES.IT]: messages_it
};

export default dictionaries;
