import {
  UserPreferences,
  UserPreferencesType
} from "seneca-common/features/user/state";

import {
  isValidTypingOption,
  MAP_GRADIENT_OPTIONS,
  MapGradientOptions,
  TypingOptions,
  typingOptions
} from "../consts/preferences";

export function validateAndFormatMapGradient(
  input: string
): MapGradientOptions {
  return (
    (input && (MAP_GRADIENT_OPTIONS as any)[input]) ||
    MAP_GRADIENT_OPTIONS.SANGUINE
  );
}

export function validateAndFormatTypingOption(input: string): TypingOptions {
  const validPref = isValidTypingOption(input);

  return validPref ? input : typingOptions.ONE;
}

export function getNonNullPreferences(
  preferences: UserPreferences
): Partial<UserPreferencesType> {
  const nonNullPreferences = preferences
    .toSeq()
    .reduce((nonNullPreferences, preference, key) => {
      if (preference !== null && preference !== undefined) {
        return { ...nonNullPreferences, [key]: preference };
      } else {
        return nonNullPreferences;
      }
    }, {});
  return nonNullPreferences;
}
