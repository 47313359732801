import { ReceivedSubscriptionProductType } from "seneca-common/features/subscriptions/state/types";

import { actions } from "../reduxBundle";

export function receiveSubscriptionProduct(
  subscriptionProduct: ReceivedSubscriptionProductType
) {
  return actions.receiveOne(subscriptionProduct);
}

export function receiveSubscriptionProducts(
  subscriptionProducts: ReceivedSubscriptionProductType[]
) {
  return actions.receiveMany(subscriptionProducts);
}
