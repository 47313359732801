import logError from "seneca-common/utils/sentry/logError";

export default async function getPushSubscription({
  registration
}: {
  registration: ServiceWorkerRegistration | undefined;
}) {
  try {
    const subscription = await registration?.pushManager?.getSubscription();
    return subscription;
  } catch (error) {
    logError(error, {
      message: "Push notifications: unable to get push subscription."
    });
    return;
  }
}
