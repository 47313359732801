import { useState } from "react";
import { useDispatch } from "react-redux";

import { setUserHasSignedUpBefore } from "seneca-common/features/user/state";
import useEffectOnMount from "seneca-common/utils/hooks/useEffectOnMount";

import { getUserSignedUpBefore } from "../utils/signedUpBefore";

export default function useHasUserSignedUpBefore(): boolean {
  const [readFromStorage, setReadFromStorage] = useState(false);

  const dispatch = useDispatch();
  useEffectOnMount(() => {
    async function checkLocalStorage() {
      const signedUpBefore = await getUserSignedUpBefore();

      if (signedUpBefore) {
        dispatch(setUserHasSignedUpBefore());
      }

      setReadFromStorage(true);
    }

    checkLocalStorage();
  });

  return readFromStorage;
}
