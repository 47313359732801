import { Action } from "common/types/redux";

import userActionTypes from "seneca-common/features/user/state/action-types";

import actionTypes from "../action-types";
import { UserType } from "../model/UserType";

export default function userTypeReducer(
  state: UserType = UserType.free,
  action: Action<any>
): UserType {
  if (action.type === actionTypes.SET_TYPE) {
    return action.payload;
  }

  if (action.type === userActionTypes.SET_USER) {
    const managedBy = !!action.payload.user.managedBy;
    if (managedBy) {
      return UserType.school_free;
    } else return UserType.free;
  }
  if (action.type === actionTypes.UPDATE_TYPE) {
    const { misSubscription, b2cSubscription } = action.payload;

    const hasMISSubscription =
      misSubscription || state === UserType.school_premium;

    if (hasMISSubscription) {
      if (b2cSubscription) {
        return UserType.school_premium_b2c;
      } else {
        return UserType.school_premium;
      }
    }

    if (
      state === UserType.school_free ||
      state === UserType.school_premium_b2c ||
      state === UserType.school_free_b2c
    ) {
      if (b2cSubscription) {
        return UserType.school_free_b2c;
      }
    }

    if (state === UserType.free || state === UserType.b2c) {
      if (b2cSubscription) {
        return UserType.b2c;
      }
    }
  }
  return state;
}
