import { Dispatch, GetState } from "common/types";

import { getClient } from "services";
import { setRegionInSessionStorage } from "services/region";

import {
  getUserPreferences,
  isSignedIn,
  updateUserPersonalInformation,
  updateUserPreferences as updateUserPreferencesLocally,
  userPreferencesFetchActionFactory,
  userPreferencesUpdateActionFactory
} from "seneca-common/features/user/state";
import { debounce } from "seneca-common/utils/functions";

import { ThemeModePreference } from "features/dark-mode/consts";
import { setThemeModePreferenceInLocalStorage } from "features/dark-mode/utils";
import { getLanguageFromRegion } from "features/regions/selectors";

import { ThemePreference } from "../../../seneca-common/features/user/state/models/UserPreferences";
import {
  MapGradientOptions,
  PreferenceUpdateOperations,
  preferenceUpdateOperations,
  TypingOptions
} from "../consts/preferences";
import { pickDisplayNameError } from "../selectors/meta";
import { getNonNullPreferences } from "../utils/preferences";

const {
  HAS_PICKED_DISPLAY_NAME,
  MAP_GRADIENT,
  TYPING_PREFERENCE,
  MODULE_TOOLBAR_OPEN_BY_DEFAULT,
  REGION,
  NOTIFY_WHEN_DATA_REQUEST_READY,
  SOUND_EFFECTS_ENABLED,
  PREFERRED_THEME,
  PREFERRED_THEME_MODE,
  PREFERRED_STATS
} = preferenceUpdateOperations;

const getUserServiceClient = () => getClient("userService");

const updateUserPreferencesFromResponse = ({ preferences }: any) =>
  updateUserPreferencesLocally(preferences);

export const fetchUserPreferences = userPreferencesFetchActionFactory({
  requestFunction: () => getUserServiceClient().fetchMyPreferences(),
  storeAction: updateUserPreferencesFromResponse
});

const updatePreferences = userPreferencesUpdateActionFactory({
  requestFunction: preferences =>
    getUserServiceClient().replaceMyPreferences(preferences)
});

const updatePreferencesWithStoreUpdateFromResponse =
  userPreferencesUpdateActionFactory({
    requestFunction: preferences =>
      getUserServiceClient().replaceMyPreferences(preferences),
    storeAction: updateUserPreferencesFromResponse
  });

const persistUserPreferences =
  (preferences: Record<string, any>, idField: PreferenceUpdateOperations) =>
  async (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    const signedIn = isSignedIn(state);

    if (signedIn) {
      return dispatch(updatePreferences(preferences, idField));
    }
  };

export const persistChangedUserPreferences =
  () => async (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    const preferences = getUserPreferences(state);
    const nonNullPreferences = getNonNullPreferences(preferences);

    await dispatch(
      updatePreferencesWithStoreUpdateFromResponse(nonNullPreferences)
    );
  };

const updateAndPersistUserRegion =
  (preferences: Record<string, any>, idField: PreferenceUpdateOperations) =>
  (dispatch: Dispatch, getState: GetState) => {
    dispatch(updateUserPreferencesLocally(preferences, idField));
    const { region } = preferences;
    setRegionInSessionStorage(region);

    const language = getLanguageFromRegion(region);
    language && window.Localize.setLanguage(language);

    return dispatch(persistUserPreferences(preferences, idField));
  };

export const pickDisplayName =
  (displayName: string) => async (dispatch: Dispatch, getState: GetState) => {
    await dispatch(
      persistUserPreferences(
        {
          hasPickedDisplayName: true
        },
        HAS_PICKED_DISPLAY_NAME as any
      )
    );
    dispatch(
      updateUserPreferencesLocally(
        {
          hasPickedDisplayName: true
        },
        HAS_PICKED_DISPLAY_NAME
      )
    );

    if (!pickDisplayNameError(getState())) {
      dispatch(
        updateUserPersonalInformation({
          displayName
        })
      );
    }
  };

const makeUpdateAndPersistUserPreferencesWithDelay = (
  idField: PreferenceUpdateOperations,
  debounceDelay: number = 5000
) => {
  const persistChanges = debounce(
    (preferences, dispatch) =>
      dispatch(persistUserPreferences(preferences, idField)),
    debounceDelay,
    {
      leading: true
    }
  );
  return (preferences: Record<string, any>) =>
    (dispatch: Dispatch, getState: GetState) => {
      dispatch(updateUserPreferencesLocally(preferences, idField));
      persistChanges(preferences, dispatch);
    };
};

const updateAndPersistTypingPreferenceWithDelay =
  makeUpdateAndPersistUserPreferencesWithDelay(TYPING_PREFERENCE as any);

const updateAndPersistModuleToolbarOpenByDefaultWithDelay =
  makeUpdateAndPersistUserPreferencesWithDelay(
    MODULE_TOOLBAR_OPEN_BY_DEFAULT as any
  );

const updateAndPersistMapGradientWithDelay =
  makeUpdateAndPersistUserPreferencesWithDelay(MAP_GRADIENT as any);

export const setMapGradient = (gradient: MapGradientOptions) =>
  updateAndPersistMapGradientWithDelay({
    mapGradient: gradient
  });
export const setTypingPreference = (typingPreference: TypingOptions) =>
  updateAndPersistTypingPreferenceWithDelay({
    typingPreference
  });

export const setModuleToolbarOpenByDefault = (
  moduleToolbarOpenByDefault: boolean
) =>
  updateAndPersistModuleToolbarOpenByDefaultWithDelay({
    moduleToolbarOpenByDefault
  });

const updateAndPersistNotify = makeUpdateAndPersistUserPreferencesWithDelay(
  NOTIFY_WHEN_DATA_REQUEST_READY as any
);
export const setNotifyWhenDataRequestReady = (
  notifyWhenDataRequestReady: boolean
) =>
  updateAndPersistNotify({
    notifyWhenDataRequestReady
  });

export const setRegionPreference =
  (region: string) => (dispatch: Dispatch, getState: GetState) => {
    return dispatch(
      updateAndPersistUserRegion(
        {
          region
        },
        REGION as any
      )
    );
  };

export const setRegionPreferenceAndRefresh =
  (region: string) => async (dispatch: Dispatch, getState: GetState) => {
    await dispatch(setRegionPreference(region));
    window.location && window.location.reload();
  };

const updateAndPersistSoundEffectsEnabled =
  makeUpdateAndPersistUserPreferencesWithDelay(SOUND_EFFECTS_ENABLED as any);

export function setSoundEffectsEnabled(soundEffectsEnabled: boolean) {
  return updateAndPersistSoundEffectsEnabled({
    soundEffectsEnabled
  });
}

const updateAndPersistPreferredTheme =
  makeUpdateAndPersistUserPreferencesWithDelay(
    PREFERRED_THEME as PreferenceUpdateOperations
  );

export function setPreferredTheme(preferredTheme: ThemePreference) {
  return updateAndPersistPreferredTheme({
    preferredTheme
  });
}

const updateAndPersistPreferredThemeMode =
  makeUpdateAndPersistUserPreferencesWithDelay(PREFERRED_THEME_MODE as any);

export function setPreferredThemeMode(preferredThemeMode: string) {
  setThemeModePreferenceInLocalStorage(
    preferredThemeMode as ThemeModePreference
  );
  return updateAndPersistPreferredThemeMode({
    preferredThemeMode
  });
}

const updateAndPersistPreferredStats =
  makeUpdateAndPersistUserPreferencesWithDelay(PREFERRED_STATS as any);

export function setPreferredStats(preferredStats: string) {
  return updateAndPersistPreferredStats({
    preferredStats
  });
}
