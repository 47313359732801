import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useMemo,
  useState
} from "react";

const PromoContext = createContext<{
  showPromo: boolean;
  setShowPromo: Dispatch<SetStateAction<boolean>>;
}>({ showPromo: false, setShowPromo: () => {} });

export function usePromoContext() {
  return useContext(PromoContext);
}

export function PromoContextProvider({ children }: { children: ReactNode }) {
  const [showPromo, setShowPromo] = useState(true);
  const value = useMemo(() => ({ showPromo, setShowPromo }), [showPromo]);

  return (
    <PromoContext.Provider value={value}>{children}</PromoContext.Provider>
  );
}
