import { createContext, Dispatch, useContext } from "react";

import { TopbarAction } from "../hooks/useTopbarReducer";

export const TopbarDispatchContext = createContext<Dispatch<TopbarAction>>(
  () => {}
);

export function useTopbarDispatchContext() {
  return useContext(TopbarDispatchContext);
}
