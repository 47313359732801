import { actionTypes as baseActionTypes } from "seneca-common/features/subscriptions/state/reduxBundle";

import { baseSubscriptionsActionsPath } from "../consts";

const actionTypes = {
  RECEIVE_SUBSCRIPTION: baseActionTypes?.RECEIVE_ONE,
  RECEIVE_SUBSCRIPTIONS: baseActionTypes?.RECEIVE_MANY,
  SET_PAYMENT_ERROR: `${baseSubscriptionsActionsPath}/SET_PAYMENT_ERROR`,
  SET_SECURE_AUTHENTICATION_ONGOING: `${baseSubscriptionsActionsPath}/SET_SECURE_AUTHENTICATION_ONGOING`,
  SET_PAYMENT_METHOD_CREATION_ONGOING: `${baseSubscriptionsActionsPath}/SET_PAYMENT_METHOD_CREATION_ONGOING`
};

export default actionTypes;
