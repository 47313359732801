import { Map } from "immutable";

import { Subscriptions } from "seneca-common/features/subscriptions/state/models/subscriptions";
import Subscription from "seneca-common/features/subscriptions/state/models/subscriptions/subscription/Subscription";
import {
  actionTypes,
  reducer
} from "seneca-common/features/subscriptions/state/reduxBundle";
import { isIn } from "seneca-common/utils/functions";

export default function subscriptionsReducer(
  stored: Subscriptions = Map<string, Subscription>(),
  action: Record<string, any>
) {
  if (isIn(action.type, actionTypes)) {
    // @ts-ignore
    return reducer(stored, action);
  }

  return stored;
}
