import moment from "moment";

import { SessionXpBreakdown } from "seneca-common/features/session/models/SessionXp";
import { composeSelector } from "seneca-common/utils/selectors/compose-selectors";

import Session from "../../../../models/Session";
import { SESSION_ITEM_TYPES } from "../../../../models/SessionItem";
import { SESSION_TYPES } from "../../../../models/SessionType";
import * as sessionOptionsSelectors from "./options";
import * as sessionXpSelectors from "./xp";

export function getId(session?: Session) {
  return session?.id;
}

export function getSessionType(session?: Session) {
  return session?.sessionType;
}

export function getSessionOptions(session?: Session) {
  return session?.options;
}

export function getStartTime(session?: Session) {
  return session?.startTime;
}

export function getStartTimeUnix(session?: Session) {
  return moment(getStartTime(session)!).unix();
}

export function getEndTime(session?: Session) {
  return session?.endTime;
}

export function getEndTimeUnix(session?: Session) {
  return moment(getEndTime(session)!).unix();
}

export function getCompleted(session?: Session): boolean {
  return !!session?.completed;
}

export function getSessionScore(session?: Session): number | null | undefined {
  return session?.sessionScore;
}

export function getSessionSectionIds(session?: Session) {
  return session?.sectionIds;
}

export function getSessionItems(session?: Session) {
  return session?.items;
}

export function getSessionItemModules(session?: Session) {
  return getSessionItems(session)?.filter(
    (item: any) => item.type === SESSION_ITEM_TYPES.MODULE
  );
}

export function getNumberOfSessionModules(
  session?: Session
): number | undefined {
  return getSessionItemModules(session)?.size;
}

export function getSessionSectionId(session?: Session): string | undefined {
  return getSessionSectionIds(session)?.first();
}

export function isSessionForSection(
  session: Session,
  sectionId: string
): boolean {
  return getSessionSectionId(session) === sectionId;
}

export function getLastSessionItemId(session?: Session) {
  return getSessionItems(session)?.last()?.id;
}

export function getFocusedItemId(session?: Session) {
  return session?.focusedItemId;
}

export function isFocusedItem(session: Session, id: string): boolean {
  return getFocusedItemId(session) === id;
}

export function isWrongAnswersSession(session?: Session): boolean {
  return getSessionType(session) === SESSION_TYPES.wrongAnswers;
}

export function isQuizSession(session?: Session): boolean {
  return getSessionType(session) === SESSION_TYPES.quiz;
}

export function completesAssignment(session?: Session) {
  return session?.completesAssignment;
}

export function getXp(session?: Session) {
  return session?.xp;
}

export const getXpBonusMultiplier: (
  state: any,
  sessionId: string
) => number | undefined = composeSelector(
  getXp,
  sessionXpSelectors.getBonusMultiplier
);

export const getNewXp: (state: any, sessionId: string) => number | undefined =
  composeSelector(getXp, sessionXpSelectors.getNewXp);

export const getXpBreakdown: (
  state: any,
  sessionId: string
) => SessionXpBreakdown | undefined = composeSelector(
  getXp,
  sessionXpSelectors.getBreakdown
);

export const getHasHardestQuestionContent: (state: any) => boolean | undefined =
  composeSelector(
    getSessionOptions,
    sessionOptionsSelectors.getHasHardestQuestionContent
  );
