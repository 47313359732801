import { Record } from "immutable";

export type SessionMessageType = {
  messageType?: string;
  completed?: boolean;
};

export type OptionalSessionMessageType = Partial<SessionMessageType>;

const defaultSessionMessage: SessionMessageType = {
  messageType: undefined,
  completed: false
};

export default class SessionMessage extends Record(defaultSessionMessage) {}
