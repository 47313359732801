interface SubscriptionsClient {
  readonly fetchPaymentIntentClientSecretById: (id: string) => Promise<{
    secret: string;
  }>;
}

export function makeFetchPaymentIntentClientSecret(
  getClient: () => SubscriptionsClient
) {
  return async function fetchPaymentIntentClientSecret(id: string) {
    const response = await getClient().fetchPaymentIntentClientSecretById(id);
    return response.secret;
  };
}
