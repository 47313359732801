import classnames from "classnames";
import * as React from "react";

import { ReactComponent as Blob1 } from "common/assets/images/blobs/blob_1.svg";
import { ReactComponent as Blob2 } from "common/assets/images/blobs/blob_2.svg";
import { ReactComponent as Blob3 } from "common/assets/images/blobs/blob_3.svg";

import shuffle from "seneca-common/utils/functions/random/shuffle";

import Card, { Props as CardProps } from "../../Card";
import styles from "./CardWithBlobs.module.scss";

type Props = CardProps & {
  fixedBlobsForSmallCard?: boolean;
};

export default class CardWithBlobs extends React.PureComponent<Props> {
  shuffledPositionClasses: string[] = shuffle([
    styles.blobTopLeft,
    styles.blobBottomRight,
    styles.blobTopRight
  ]);

  render() {
    const { children, className, fixedBlobsForSmallCard, ...otherProps } =
      this.props;
    return (
      <Card
        {...otherProps}
        className={classnames(styles.card, className, {
          [styles.fixedBlobsForSmallCard]: "fixedBlobsForSmallCard"
        })}
      >
        <div className={styles.blobsWrapper}>
          <Blob1
            className={
              fixedBlobsForSmallCard
                ? styles.blobTopLeft
                : this.shuffledPositionClasses[0]
            }
          />
          <Blob2
            className={
              fixedBlobsForSmallCard
                ? styles.blobBottomRight
                : classnames(this.shuffledPositionClasses[1], styles.blob2)
            }
          />
          <Blob3
            className={
              fixedBlobsForSmallCard
                ? styles.blobTopRight
                : this.shuffledPositionClasses[2]
            }
          />
        </div>
        <div className={styles.contents}>{children}</div>
      </Card>
    );
  }
}
