import { PushTopic } from "@seneca/notifications-service-types";

const supportedAnalyticsSources: (PushTopic | `${PushTopic}-${string}`)[] = [
  "childCompletesAssignment",
  "childAssignmentSet",
  "childAssignmentDue",
  "parentWeeklyReport",
  "assignmentSet",
  "assignmentDue",
  "examReminder",
  "examReminder-2d",
  "examReminder-7d",
  "dailyRevisionReminder"
];

export default function isSupportedPushAnalyticsSource(
  source?: PushTopic | null
): source is PushTopic {
  if (!source) return false;

  return supportedAnalyticsSources.some(supported => supported === source);
}
