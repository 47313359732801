import { makeCrudStateActionFactories } from "seneca-common/utils/apiStates";

import actionTypes from "../action-types/passwordReset";
import { passwordResetCrudStateConfig } from "../consts";

export function updatePasswordResetEmail(email: string) {
  return {
    type: actionTypes.UPDATE_EMAIL,
    payload: {
      email
    }
  };
}

const { updateActionFactory: updatePasswordActionFactory } =
  makeCrudStateActionFactories(passwordResetCrudStateConfig);

export { updatePasswordActionFactory };
