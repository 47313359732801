import { Client } from "@seneca/client-library";

import { LeaderboardCategory } from "./types";

export default class SchoolLeaderboardsServiceClient extends Client {
  async fetchSchoolLeaderboard(
    schoolId: string,
    category: LeaderboardCategory
  ) {
    const url = `${this.url}/api/leaderboards/school?schoolId=${schoolId}&category=${category}`;
    const expectedResponses = [200];

    const response = await this.requestMaker.makeRequest<any>(
      url,
      { method: "GET" },
      expectedResponses
    );

    return response.json;
  }
}
