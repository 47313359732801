import ReducerRegistry from "seneca-common/utils/state/reducer-registry/ReducerRegistry";

import { PATH_TO_USER_TYPE_STATE } from "./consts";
import userTypeReducer from "./reducer";

export { PATH_TO_USER_TYPE_STATE } from "./consts";
export { getUserType } from "./selectors";

const reducerRegistry = ReducerRegistry.getInstance();
reducerRegistry.initialise(PATH_TO_USER_TYPE_STATE, builder => {
  builder.register(PATH_TO_USER_TYPE_STATE, userTypeReducer);
});

export { userTypeReducer };
