import { ActionTypes } from "../types";

/**
 * Generic:
 *  - JS = js object received from the client
 */

export default function makeActions<JS>(actionTypes: ActionTypes) {
  return {
    receiveOne: (item: JS) => ({
      type: actionTypes.RECEIVE_ONE,
      payload: item
    }),
    receiveMany: (items: JS[]) => ({
      type: actionTypes.RECEIVE_MANY,
      payload: items
    }),
    update: (updates: JS) => ({
      type: actionTypes.UPDATE,
      payload: updates
    }),
    delete: (id: string) => ({
      type: actionTypes.DELETE,
      payload: id
    })
  };
}
