import { Expose } from "@seneca/small-utils-library";

import { getClient } from "services/utils/ServiceClientRegistry";

export const SIGN_IN_VIA_EMAIL_LINK_STORAGE_KEY = "signInViaEmailLinkData";

type BaseSignInViaEmailData = {
  email: string;
  signInStarted?: boolean;
};

export type SignInViaEmailPreconnectedParentMeta = {
  givenName?: string;
  familyName?: string;
};

// Add other flow's meta types to this as a union type
export type SignInViaEmailLocalStorageMeta =
  SignInViaEmailPreconnectedParentMeta;

export type SignInViaEmailLocalStorageData<
  MetaT extends SignInViaEmailLocalStorageMeta
> = BaseSignInViaEmailData & MetaT;

export async function requestSignInViaEmailLink<
  MetaT extends SignInViaEmailLocalStorageMeta
>({
  email,
  callbackUrl,
  metadata
}: {
  email: string;
  callbackUrl: string;
  metadata?: MetaT;
}) {
  setSignInViaEmailLinkLocalStorageData({
    email,
    signInStarted: false,
    ...metadata
  });
  await getClient("authenticationService").sendSignInViaEmailLinkRequest({
    email,
    callbackUrl
  });
}

export function setSignInViaEmailLinkLocalStorageData<
  T extends SignInViaEmailLocalStorageMeta
>(data: SignInViaEmailLocalStorageData<T>) {
  localStorage.setItem(
    SIGN_IN_VIA_EMAIL_LINK_STORAGE_KEY,
    JSON.stringify({ ...data })
  );
}

export function getSignInViaEmailLinkLocalStorageData<
  T extends SignInViaEmailLocalStorageMeta
>() {
  try {
    const item = window.localStorage.getItem(
      SIGN_IN_VIA_EMAIL_LINK_STORAGE_KEY
    );
    return item
      ? (JSON.parse(item) as Expose<SignInViaEmailLocalStorageData<T>>)
      : null;
  } catch (err) {
    return null;
  }
}

export function getSignInViaEmailLinkLocalStorageDataByField<
  K extends keyof BaseSignInViaEmailData
>(field: K) {
  try {
    const item = window.localStorage.getItem(
      SIGN_IN_VIA_EMAIL_LINK_STORAGE_KEY
    );
    if (!item) {
      return null;
    }
    const parsedItem = JSON.parse(item) as BaseSignInViaEmailData;

    return parsedItem[field];
  } catch (err) {
    throw new Error("Error retrieving sign-in via email link data:");
  }
}

export function removeSignInViaEmailLinkLocalStorageFields<
  MetaT extends SignInViaEmailLocalStorageMeta
>(
  ...fieldsToRemove: (keyof SignInViaEmailLocalStorageData<MetaT>)[]
): SignInViaEmailLocalStorageData<MetaT> | null {
  try {
    const item = window.localStorage.getItem(
      SIGN_IN_VIA_EMAIL_LINK_STORAGE_KEY
    );
    if (!item) {
      throw new Error(
        "Sign in via link data could not be retrieved from local storage"
      );
    }

    const parsedItem = JSON.parse(item);

    const updatedItem = Object.fromEntries(
      Object.entries(parsedItem).filter(
        ([key]) =>
          !fieldsToRemove.includes(
            key as keyof SignInViaEmailLocalStorageData<MetaT>
          )
      )
    ) as SignInViaEmailLocalStorageData<MetaT>;

    setSignInViaEmailLinkLocalStorageData(updatedItem);

    return updatedItem;
  } catch (err) {
    throw err;
  }
}

export function removeSignInViaEmailLinkLocalStorage() {
  try {
    window.localStorage.removeItem(SIGN_IN_VIA_EMAIL_LINK_STORAGE_KEY);
  } catch (err) {
    throw err;
  }
}
