import { useEffect } from "react";

import { useBackgroundColor } from "@seneca/senekit";

import { useDarkModeSettings } from "features/dark-mode/hooks/useDarkModeSettings";
import setSafeAreaBackgroundColoriOS from "features/pwa/mobile/ios/utils/setSafeAreaBackgroundColoriOS";

export default function useSetStatusBarBackgroundColoriOS() {
  const color = useBackgroundColor("HEX");
  const { darkModeEnabled } = useDarkModeSettings();

  useEffect(() => {
    setSafeAreaBackgroundColoriOS({
      color,
      isDarkModeEnabled: darkModeEnabled ? "true" : "false"
    });
  }, [color, darkModeEnabled]);

  return color;
}
