import { useLocation } from "react-router-dom-v5-compat";

import { themes } from "@seneca/senekit";

import { hasLiveSubscription } from "seneca-common/features/subscriptions/state";
import { ThemePreference } from "seneca-common/features/user/state/models/UserPreferences";
import { useSelector } from "seneca-common/utils/hooks/selectors";

import { isInParentPlatform } from "features/parents/links";
import { getPreferredTheme } from "features/user/selectors";

export default function useSenecaTheme() {
  const userHasSubscription = useSelector(hasLiveSubscription);
  const preferredTheme = useSelector(getPreferredTheme);
  const { pathname } = useLocation();
  const inParentPlatform = isInParentPlatform(pathname);

  if (userHasSubscription) {
    if (inParentPlatform && !preferredTheme) {
      return themes.free;
    }

    if (!preferredTheme || preferredTheme === ThemePreference.PURPLE) {
      return themes.premium;
    }
  }

  return themes.free;
}
