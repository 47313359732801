import { useNavigate } from "react-router-dom-v5-compat";

import { Box, Button, Divider, Heading, Link, Stack } from "@seneca/senekit";

import CopyDiagnosisCode from "features/app/diagnosis/components/CopyDiagnosisCode";
import { DIAGNOSIS_CODE } from "features/app/diagnosis/utils";
import useUpdateApp from "features/app/hooks/useUpdateApp";
import Card from "features/common/components/Card";
import useHelpEmailByVariant from "features/common/hooks/useHelpEmailByVariant";
import { ROUTES } from "routing/routes";

import AppErrorWrapper from "./components/AppErrorWrapper";
import styles from "./GeneralAppError.module.scss";
import UpdateAvailableError from "./UpdateAvailableError";

export default function GeneralAppError({
  resetErrorBoundary
}: {
  resetErrorBoundary?: () => void;
}) {
  const navigate = useNavigate();
  const helpEmail = useHelpEmailByVariant();

  const { ready: newAppVersionIsAvailable } = useUpdateApp();

  if (newAppVersionIsAvailable)
    return <UpdateAvailableError resetErrorBoundary={resetErrorBoundary} />;

  return (
    <AppErrorWrapper>
      <Box marginTop="xlarge" paddingY="large">
        <Stack align="center" space="xxlarge">
          <Stack space="medium" align="center">
            <Heading level="4" weight="normal" align="center">
              Have you tried a different device?
              <br />
              If you're still experiencing issues, please get in touch at
              <br />
              <Link to={`mailto:${helpEmail}`}>{helpEmail}</Link>
            </Heading>
          </Stack>

          <CopyDiagnosisCode />

          <Divider includeOr />

          <Stack space="xlarge" align="center">
            <Heading level="5" weight="normal">
              Get more information
            </Heading>
            <Button
              width={["100%", "230px"]}
              onClick={() => {
                resetErrorBoundary?.();
                navigate(ROUTES.DIAGNOSIS.buildPath({}), {
                  state: { noUpdateRedirect: true }
                });
              }}
            >
              Diagnosis page
            </Button>
          </Stack>
        </Stack>
      </Box>
    </AppErrorWrapper>
  );
}

export function GeneralAppErrorCard() {
  return (
    <Card rounded>
      <GeneralAppError />
    </Card>
  );
}

/**
 * A simple version of the error page without bells and whistles to minimise
 * any possibilities of it throwing an error itself.
 */
export function SimpleAppError() {
  const helpEmail = useHelpEmailByVariant();

  return (
    <div className={styles.wrapper}>
      <h1>Oops, something went wrong!</h1>
      <p>Have you tried a different device?</p>
      <p>
        If you're still experiencing issues, please get in touch at:{" "}
        <a href={`mailto:${helpEmail}`}>{helpEmail}</a>
      </p>
      <p>
        Share this code and a description of what happened with customer support
        to help them identify what's going wrong:
      </p>
      <code className={styles.code} data-notranslate>
        {DIAGNOSIS_CODE}
      </code>
    </div>
  );
}
