import actionTypes from "../action-types/meta";

export function setCourseId(courseId: string) {
  return {
    type: actionTypes.SET_COURSE_ID,
    payload: {
      courseId
    }
  };
}

export function openShareTopicModal() {
  return {
    type: actionTypes.OPEN_SHARE_TOPIC_MODAL
  };
}

export function closeShareTopicModal() {
  return {
    type: actionTypes.CLOSE_SHARE_TOPIC_MODAL
  };
}

export function setLastCourseSearchTerm(term: string) {
  return {
    type: actionTypes.SET_COURSE_SEARCH_TERM,
    payload: {
      term: term
    }
  };
}

export function resetLastCourseSearchTerm() {
  return {
    type: actionTypes.SET_COURSE_SEARCH_TERM,
    payload: {
      term: ""
    }
  };
}

export function quitSession(
  courseId?: string | null | undefined,
  sectionId?: string | null | undefined
) {
  return {
    type: actionTypes.QUIT_SESSION,
    payload: {
      courseId,
      sectionId
    }
  };
}
