import getConfig from "config";

import { getDevOverride } from "features/feature-flags/utils";

// won't force a re-render if used
// useful for logic that doesn't live in a component
export default function isPlurallApp() {
  const { override } = getDevOverride("isPlurallApp");
  const config = getConfig();

  return !!override || (config && config.isPlurallApp);
}
