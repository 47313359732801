import { analyticsReduxRegistry } from "seneca-common/features/analytics";

import actionTypes from "../state/action-types";
import preferencesActionTypes from "../state/action-types/preferences";
import signInStateActionTypes from "../state/action-types/signInState";

analyticsReduxRegistry.registerEventGenerators([
  actionTypes.UPDATE_USER_PERSONAL_INFO,
  signInStateActionTypes.SIGN_IN_SUCCESS,
  {
    triggerReduxActionType: signInStateActionTypes.SIGN_IN_SUCCESS_AS_GUEST,
    gtmEventName: "seneca/authentication/signInAsGuest"
  },
  {
    triggerReduxActionType: preferencesActionTypes.SOUND_EFFECTS_ENABLED,
    gtmEventName: "seneca/user/toggleSoundEffects"
  },
  {
    triggerReduxActionType: preferencesActionTypes.PREFERRED_THEME_MODE,
    gtmEventName: "seneca/user/changePreferredThemeMode"
  },
  {
    triggerReduxActionType:
      preferencesActionTypes.MODULE_TOOLBAR_OPEN_BY_DEFAULT,
    gtmEventName: "seneca/user/changeToolbarDefaultOpen"
  }
]);
