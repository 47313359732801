import { makeCrudStateActionFactories } from "seneca-common/utils/apiStates";

import preferenceActionTypes from "../action-types/preferences";
import { userPreferenceCrudStateConfig } from "../consts";
import { UserPreferencesType } from "../models/UserPreferences";

export function setUserPreferences(preferences: Partial<UserPreferencesType>) {
  return {
    type: preferenceActionTypes.SET_USER_PREFERENCES,
    payload: preferences
  };
}

export const updateUserPreferences = (
  preferences: Partial<UserPreferencesType>,
  idField?: string
) => ({
  type:
    (preferenceActionTypes as any)[idField!] ||
    preferenceActionTypes.UPDATE_PREFERENCES,
  payload: preferences
});

const {
  fetchAllActionFactory: userPreferencesFetchActionFactory,
  updateActionFactory: userPreferencesUpdateActionFactory
} = makeCrudStateActionFactories(userPreferenceCrudStateConfig);

export {
  userPreferencesFetchActionFactory,
  userPreferencesUpdateActionFactory
};
