import { analyticsCategories } from "../consts";
import { logGTMTrackingEvent } from "../googleTagManager";

export const analyticsLogTryAsStudent = (dispatch: any, getState: any) => {
  logGTMTrackingEvent(
    {
      category: analyticsCategories.NAVIGATION,
      event: "seneca/navigation/TRY_AS_STUDENT"
    },
    getState()
  );
};
