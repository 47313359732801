import { composeSelectors } from "seneca-common/utils/selectors/compose-selectors";

import { PATH_TO_STATE } from "../consts";
import Classroom from "../models/Classroom";
import * as classroomSelectors from "./state";
import { ClassroomSelectors } from "./state/types";

type State = any;

function getClassroom(state: State): Classroom {
  return state.get(PATH_TO_STATE);
}

// @ts-ignore
export const {
  getScrolledUp,
  getFocusedQuestionId,
  getLearningMode,
  getSidebarOpen,
  getExpandedAssignmentId,
  isAssignmentExpanded,
  getExpandedSectionIds,
  isSectionExpanded,
  getCourseId,
  getShareTopicModalOpen,
  getActiveTab,
  getIsActiveTabSet,
  getAssignmentId,
  getSource,
  isAssignmentsMode,
  isWrongAnswersMode,
  isSmartLearningMode,
  isDefaultMode,
  getLastCourseSearchTerm
}: ClassroomSelectors<State> = composeSelectors(
  getClassroom,
  classroomSelectors
);
