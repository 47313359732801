import React, { ReactNode } from "react";
import { IntlProvider } from "react-intl";
import { useSelector } from "react-redux";

import { DEFAULT_LOCALE } from "common/static/regions";

import { getLocale, getUserLanguageCode } from "features/regions/selectors";

import dictionaries from "./dictionaries";
import { DictionaryLanguageCode } from "./types";
import useSetDateFnsLocale from "./useSetDateFnsLocale";
import useSetLocalizeLanguage from "./useSetLocalizeLanguage";

export default function LocalisationProvider({
  children
}: {
  children: ReactNode;
}) {
  const languageCode = useSelector(
    getUserLanguageCode
  ) as DictionaryLanguageCode;
  const locale = useSelector(getLocale);

  useSetDateFnsLocale();
  useSetLocalizeLanguage();

  return (
    <IntlProvider
      locale={locale}
      messages={dictionaries[languageCode]}
      defaultLocale={DEFAULT_LOCALE}
    >
      {children}
    </IntlProvider>
  );
}
