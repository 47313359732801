export function makeSignInPath(basePath: string = window.location.pathname) {
  return `${basePath}/login`;
}

// Reset password

export function makePasswordResetPath(
  basePath: string = window.location.pathname
) {
  return `${basePath}/reset-password`;
}

export function makePasswordResetSignInPath(
  basePath: string = window.location.pathname
) {
  return `${makePasswordResetPath(basePath)}/login`;
}

// Sign up
export function makeSignUpPath(basePath: string = window.location.pathname) {
  return `${basePath}/sign-up`;
}

export function makeSignUpUserDetailsPath(
  basePath: string = window.location.pathname
) {
  return `${makeSignUpPath(basePath)}/user-details`;
}

export function makeSignUpAccountTypePath(
  basePath: string = window.location.pathname
) {
  return `${makeSignUpPath(basePath)}/account-type`;
}

export function makeSignUpStudentDetailsPath(
  basePath: string = window.location.pathname
) {
  return `${makeSignUpPath(basePath)}/student-details`;
}

export function makeSignUpTeacherDetailsPath(
  basePath: string = window.location.pathname
) {
  return `${makeSignUpPath(basePath)}/teacher-details`;
}

export function makeSignUpConfirmPassword(
  basePath: string = window.location.pathname
) {
  return `${makeSignUpPath(basePath)}/confirm-password`;
}
