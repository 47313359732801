import { differentProviderErrorMessageHelpLink } from "common/links";
import { DEFAULT_REGION } from "common/static/regions";

import { getAppName } from "features/common/utils/functions/app";
import { linkToSignIn } from "features/sign-up/links";

import {
  AuthDomainConfigRequiredError,
  EmailAlreadyExistsError,
  GoStudentSubscriptionInvalid,
  InaccessibleEmailError,
  IncorrectPasswordError,
  InvalidEmailError,
  InvalidPasswordError,
  InvalidResetPasswordMethod,
  InvalidSignInMethod,
  LimitExceededError,
  NetworkRequestFailedError,
  OperationNotSupportedInThisEnvironmentError,
  PlurallAccountExistsWithDifferentCredential,
  PopupBlocked,
  PopupClosedByUser,
  PopupRequestCancelled,
  UnauthorizedDomainError,
  UserDeniedPermission,
  UserNotFoundError
} from "./errors";

export const DEFAULT_MESSAGE = "An unknown error has occurred.";

export type ErrorMessage = {
  message: string;
  link?: string;
  linkText?: string;
};

export default function errorTranslator(
  error: Error,
  locale = DEFAULT_REGION
): ErrorMessage {
  if (error instanceof EmailAlreadyExistsError) {
    return {
      message:
        "The email you entered is already in use, please try another one or log in.",
      link: linkToSignIn(),
      linkText: "Login"
    };
  }

  if (error instanceof IncorrectPasswordError) {
    return { message: "Oops, wrong password! Have another go." };
  }

  if (error instanceof InvalidPasswordError) {
    return {
      message: "Your password does not meet the requirements. Please try again."
    };
  }

  if (error instanceof UserNotFoundError) {
    return { message: "We've not seen that email before, please sign up!" };
  }

  if (error instanceof LimitExceededError) {
    return {
      message:
        "Too many incorrect password attempts, please try again in a second!"
    };
  }

  if (error instanceof NetworkRequestFailedError) {
    return { message: "Looks like there is no internet connection." };
  }

  if (error instanceof InvalidEmailError) {
    return { message: "Provided email is invalid. Try again." };
  }

  if (error instanceof InvalidSignInMethod) {
    const params = {
      link: differentProviderErrorMessageHelpLink(locale),
      linkText: "Need help?"
    };

    if (error.provider) {
      return {
        ...params,
        message: `Looks like your account was created using a different method. Try logging in with ${error.provider}.`
      };
    }

    return {
      ...params,
      message:
        "Looks like your account was created using a different method. Try another way of logging in."
    };
  }

  if (error instanceof InvalidResetPasswordMethod) {
    return {
      message: `Looks like your account was created with ${error.provider}. No need to reset your password. Try logging in instead.`
    };
  }

  if (error instanceof InaccessibleEmailError) {
    return { message: "To reset your email, please contact customer service." };
  }

  if (error instanceof PopupBlocked) {
    return {
      message: "The popup is blocked by your browser. Please unblock pop ups."
    };
  }

  if (error instanceof PopupClosedByUser) {
    return {
      message:
        "The popup has been closed before finalizing the connection, please try again."
    };
  }

  if (error instanceof PopupRequestCancelled) {
    return {
      message: "Multiple popups triggered. Please close unused pop ups."
    };
  }

  if (error instanceof UserDeniedPermission) {
    const appName = getAppName();
    return {
      message: `User denied access. Please grant permission to ${appName} to continue.`
    };
  }

  if (error instanceof AuthDomainConfigRequiredError) {
    return {
      message:
        "Oops.. Looks like is something wrong with authentication domain configuration."
    };
  }

  if (error instanceof OperationNotSupportedInThisEnvironmentError) {
    return {
      message:
        "The operation is not supported in the environment your application is running on"
    };
  }

  if (error instanceof UnauthorizedDomainError) {
    return { message: "The app domain is not authorized." };
  }

  if (error instanceof PlurallAccountExistsWithDifferentCredential) {
    return {
      message:
        "Looks like an account with this email already exists, please contact Plurall support for any help."
    };
  }

  if (error instanceof GoStudentSubscriptionInvalid) {
    return {
      message: "Your current plan does not allow you to access this resource.",
      link: "https://www.gostudent.org/chat/self-upgrade",
      linkText: "Upgrade subscription"
    };
  }

  return { message: DEFAULT_MESSAGE };
}

export function shouldLogError(error: Error): boolean {
  const expectedError = EXPECTED_ERRORS.some(
    errorType => error instanceof errorType
  );
  return !expectedError;
}

const EXPECTED_ERRORS = [
  EmailAlreadyExistsError,
  IncorrectPasswordError,
  UserNotFoundError,
  LimitExceededError,
  InvalidEmailError,
  InvalidSignInMethod,
  InvalidResetPasswordMethod,
  PopupClosedByUser,
  PopupRequestCancelled,
  UserDeniedPermission,
  PlurallAccountExistsWithDifferentCredential
];
