import { useMemo } from "react";
import { useParams } from "react-router-dom-v5-compat";

import { OutParams, Route } from "../common";

export function useTypedParams<
  TPath extends string,
  TPathTypes,
  TSearchTypes,
  THash extends string[],
  TStateParams
>(
  route: Route<TPath, TPathTypes, TSearchTypes, THash, TStateParams>
): OutParams<TPath, TPathTypes> {
  const params = useParams();
  return useMemo(() => route.getTypedParams(params), [route, params]);
}
