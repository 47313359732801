import {
  ModuleAssetParameters,
  ModuleType as ModuleNames,
  TransformModuleAssets
} from "seneca-common/features/module/module-types/types";

import { ModuleDeviceRequirements } from "features/module/types";

import ModuleComponentStaticInformation from "../models/ModuleComponentStaticInformation";
import ModuleBuilderNavigator from "./utils/ModuleBuilderNavigator";

export default class ModuleComponentStaticInformationBuilder<
  BackT,
  RootT
> extends ModuleBuilderNavigator<BackT, RootT> {
  _moduleName: ModuleNames;

  _deviceRequirements: ModuleDeviceRequirements | null | undefined;
  _transformModuleAssets: TransformModuleAssets<any> | null | undefined;
  _assetParameters: ModuleAssetParameters | null | undefined;

  constructor(moduleName: ModuleNames, back: any, root: any) {
    super(
      {
        moduleName,
        builderName: "ModuleComponentStaticInformationBuilder"
      },
      back,
      root
    );
    this._moduleName = moduleName;

    this._deviceRequirements = null;
    this._transformModuleAssets = null;
    this._assetParameters = null;
  }

  deviceRequirements(deviceRequirements: ModuleDeviceRequirements) {
    this._deviceRequirements = deviceRequirements;
    return this;
  }

  transformModuleAssets(transformModuleAssets: TransformModuleAssets<any>) {
    this._transformModuleAssets = transformModuleAssets;
    return this;
  }

  assetParameters(assetParameters: ModuleAssetParameters) {
    this._assetParameters = assetParameters;
    return this;
  }

  build(): ModuleComponentStaticInformation {
    !this._deviceRequirements &&
      this.throwMissingAttributeError("deviceRequirements");

    this._transformModuleAssets &&
      !this._assetParameters &&
      this.throwMissingAttributeError(
        "assetParameters (since transformModuleAssets has been provided)"
      );

    !this._transformModuleAssets &&
      this._assetParameters &&
      this.throwMissingAttributeError(
        "transformModuleAssets (since assetParameters has been provided)"
      );

    const supportsTransformAssets = !!(
      this._transformModuleAssets && this._assetParameters
    );

    let transformModuleAssets;

    if (supportsTransformAssets) {
      transformModuleAssets = (source: Record<string, any>) =>
        this._transformModuleAssets!(source, this._assetParameters!);
    }

    return new ModuleComponentStaticInformation(this._moduleName, {
      deviceRequirements: this._deviceRequirements!,
      transformModuleAssets
    });
  }
}
