import * as React from "react";

import propsToJS from "seneca-common/utils/functions/immutable/props-to-js";

export const toJS = <P extends Record<string, any>>(
  WrappedComponent: React.ComponentType<P>
): React.ComponentType<Partial<P>> => {
  const ImmutableToJSWrapper = (wrappedComponentProps: P) => {
    const propsJS = propsToJS(wrappedComponentProps);
    // @ts-ignore
    return <WrappedComponent {...propsJS} />;
  };

  ImmutableToJSWrapper.displayName = "ImmutableToJSWrapper";
  // @ts-ignore
  return ImmutableToJSWrapper;
};
