import { ComponentType } from "react";

import Navigator from "seneca-common/utils/builders/Navigator";

import ModuleBuilder from "./builders/ModuleBuilder";
import Modules from "./models/Modules";

export default class ModuleRegistry extends Navigator {
  static instance = new ModuleRegistry();
  _moduleBuilders: Record<string, ModuleBuilder<this, this>>;
  modules: Modules;
  _formDecorator:
    | ((arg0: ComponentType<any>) => ComponentType<any>)
    | null
    | undefined;

  constructor(back?: any, root?: any) {
    super(back, root);
    this._moduleBuilders = {};
    this.modules = new Modules();
  }

  static getInstance() {
    return ModuleRegistry.instance;
  }

  buildModule(moduleName: string): ModuleBuilder<this, this> {
    if (!this._moduleBuilders[moduleName]) {
      this._moduleBuilders[moduleName] = new ModuleBuilder<this, this>(
        moduleName,
        this,
        this.root
      );
    }

    return this._moduleBuilders[moduleName].registerFormDecorator(
      this._formDecorator as any
    );
  }

  registerFormDecorator(
    formDecorator: (arg0: ComponentType<any>) => ComponentType<any>
  ) {
    this._formDecorator = formDecorator;
    return this;
  }

  register() {
    Object.keys(this._moduleBuilders).forEach(moduleName => {
      this.modules.addOrUpdateModule(
        moduleName,
        this._moduleBuilders[moduleName].build()
      );
    });
  }
}
