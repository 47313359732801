import { Record } from "immutable";

export type PasswordResetStateType = {
  email?: string;
  resubmitCodeError?: string;
};

export type OptionalPasswordResetStateType = Partial<PasswordResetStateType>;

export default class PasswordResetState extends Record({
  email: null,
  resubmitCodeError: null
}) {}
