import logError from "seneca-common/utils/sentry/logError";

type AnalyticsFunction = (
  ...args: ReadonlyArray<any>
) => AnalyticsFunction | void;
// GOTCHA: This attempts to pull the function name from the 'func' provided, if you wrap an anonymous arrow function e.g withLogAndSwallowError(() => {}) then you need to explicitly provide a functionName

export default function withLogAndSwallowError<FuncT extends AnalyticsFunction>(
  func: FuncT,
  functionName?: string
): FuncT {
  // @ts-ignore -- no idea what to do to get this to work in here. But the most important this is that the flow types 'outside' are correct.
  return (...args: any[]) => {
    const _functionName = functionName || func.name;

    try {
      // @ts-ignore
      const output = func(...args);

      if (typeof output === "function") {
        return withLogAndSwallowError(output, _functionName);
      }

      return output;
    } catch (err: any) {
      logError(err, {
        message: `Error with analytics function ${_functionName}`,
        fingerprint: ["Analytics", _functionName]
      });
    }
  };
}

export const makeLogAndSwallowError =
  (functionName: string) =>
  <FuncT extends AnalyticsFunction>(func: FuncT) =>
    withLogAndSwallowError(func, functionName);
