import {
  CrudBuilderState,
  didError,
  didSucceed,
  getCreateSlice,
  getErrorMessage,
  getFetchAllSlice,
  getFetchSlice,
  getUpdateSlice,
  hasStarted
} from "seneca-common/utils/apiStates";

type State = CrudBuilderState;

// fetch
export function subscriptionsAreFetching(state: State): boolean {
  return hasStarted(getFetchAllSlice(state));
}

export function subscriptionsFetchSucceeded(state: State): boolean {
  return didSucceed(getFetchAllSlice(state));
}

export function subscriptionsFetchErrored(state: State): boolean {
  return didError(getFetchAllSlice(state));
}

export function subscriptionsFetchFinished(state: State) {
  return subscriptionsFetchSucceeded(state) || subscriptionsFetchErrored(state);
}

export function subscriptionsNeedsFetching(state: State) {
  return !(
    subscriptionsFetchSucceeded(state) || subscriptionsAreFetching(state)
  );
}

export function subscriptionIsFetching(
  state: State,
  subscriptionId: string
): boolean {
  return hasStarted(getFetchSlice(state), subscriptionId);
}

export function subscriptionFetchSucceeded(
  state: State,
  subscriptionId: string
): boolean {
  return didSucceed(getFetchSlice(state), subscriptionId);
}

export function subscriptionFetchErrored(
  state: State,
  subscriptionId: string
): boolean {
  return didError(getFetchSlice(state), subscriptionId);
}

export function subscriptionFetchFinished(
  state: State,
  subscriptionId: string
) {
  return (
    subscriptionFetchSucceeded(state, subscriptionId) ||
    subscriptionFetchErrored(state, subscriptionId)
  );
}

// update
export function subscriptionIsUpdating(
  state: State,
  subscriptionId: string
): boolean {
  return hasStarted(getUpdateSlice(state), subscriptionId);
}

export function subscriptionUpdateSucceeded(
  state: State,
  subscriptionId: string
): boolean {
  return didSucceed(getUpdateSlice(state), subscriptionId);
}

export function subscriptionUpdateErrored(
  state: State,
  subscriptionId: string
): boolean {
  return didError(getUpdateSlice(state), subscriptionId);
}

export function subscriptionUpdateFinished(
  state: State,
  subscriptionId: string
) {
  return (
    subscriptionUpdateSucceeded(state, subscriptionId) ||
    subscriptionUpdateErrored(state, subscriptionId)
  );
}

export function subscriptionUpdateErrorMessage(
  state: State,
  subscriptionId: string
) {
  return getErrorMessage(getUpdateSlice(state), subscriptionId);
}

// create
export function subscriptionIsBeingCreated(
  state: State,
  subscriptionId: string
): boolean {
  return hasStarted(getCreateSlice(state), subscriptionId);
}

export function createSubscriptionSucceeded(
  state: State,
  subscriptionId: string
): boolean {
  return didSucceed(getCreateSlice(state), subscriptionId);
}

export function createSubscriptionErrored(
  state: State,
  subscriptionId: string
): boolean {
  return didError(getCreateSlice(state), subscriptionId);
}

export function createSubscriptionErrorMessage(
  state: State,
  subscriptionId: string
) {
  return getErrorMessage(getCreateSlice(state), subscriptionId);
}

export function createSubscriptionFinished(
  state: State,
  subscriptionId: string
) {
  return (
    createSubscriptionSucceeded(state, subscriptionId) ||
    createSubscriptionErrored(state, subscriptionId)
  );
}
