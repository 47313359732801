import { AllowedCallTypes, CrudConfig } from "seneca-common/utils/apiStates";

export const PATH_TO_SUBSCRIPTION_PRODUCTS_STATE = "subscription-products";
export const FEATURE_NAME = PATH_TO_SUBSCRIPTION_PRODUCTS_STATE;

export const baseActionsPath = `seneca/${PATH_TO_SUBSCRIPTION_PRODUCTS_STATE}`;

export const subscriptionProductsCrudStateConfig: CrudConfig = {
  name: "subscriptionProducts",
  path: `${baseActionsPath}/meta`,
  callTypes: [AllowedCallTypes.FETCH_ALL]
};
