import Product from "../../../../../models/state/subscription-products/subscription-product/product/Product";

export const hasExamQuestions = (state: Product): boolean =>
  !!state.examQuestions;

export const hasGuaranteedGrades = (state: Product) => !!state.guaranteedGrades;

export const hasSmartLearningMode = (state: Product) =>
  !!state.smartLearningMode;

export const hasWrongAnswersMode = (state: Product) => !!state.wrongAnswersMode;

export const hasQuizMode = (state: Product) => !!state.quizMode;

export const hasAiAssistant = (state: Product) => !!state.aiAssistant;

export const hasTutoring = (state: Product) => !!state.tutoring;

export const hasParentPremiumFeatures = (state: Product) =>
  !!state.parentPremiumFeatures;
