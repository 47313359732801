const actionTypes = {
  RESET_PASSWORDS_SUCCESS:
    "seneca/teacher/passwordsReset/RESET_PASSWORDS_SUCCESS",
  RESET_PASSWORDS_FAILURE:
    "seneca/teacher/passwordsReset/RESET_PASSWORDS_FAILURE",
  SUCCESS_RESET: "seneca/teacher/passwordsReset/SUCCESS_RESET",
  RESET_PASSWORDS_UPDATE_DATA:
    "seneca/teacher/passwordsReset/RESET_PASSWORDS_UPDATE_DATA"
};

export default actionTypes;
