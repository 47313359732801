import { useLocation } from "react-router-dom";

import { AndroidTWAParamKey } from "features/pwa/mobile/android/utils/consts";
import isAndroidTWA from "features/pwa/mobile/android/utils/isAndroidTWA";

export function useSetAndroidTWA() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  if (params.has(AndroidTWAParamKey)) {
    localStorage.setItem(AndroidTWAParamKey, "true");
  }
}

export function useGetIsAndroidTWA() {
  try {
    const location = useLocation();
    const params = new URLSearchParams(location.search);

    return isAndroidTWA() || params.has(AndroidTWAParamKey);
  } catch (error) {
    return false;
  }
}
