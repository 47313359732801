import { Dispatch } from "common/types";

import { queryClient } from "services";
import { signOut as signOutFirebase } from "services/firebase/auth";
import { unsubscribeFromPushNotifications } from "services/firebase/messaging/unsubscribeFromPushNotifications";

import {
  signOut as signOutAction,
  signOutError,
  signOutStart
} from "seneca-common/features/user/state";
import { analyticsLogSignOut } from "seneca-common/utils/senecaAnalytics";
import logError from "seneca-common/utils/sentry/logError";

import { resetNumSessionsCompletedAllTime } from "features/classroom/pages/session/components/Session/sessionsCompletedCounter";
import { getRegionFromSessionStorageOrIpAndCache } from "features/regions/selectors";
import { clearHasSkippedCompleteTeacherAccount } from "features/teachers/pages/complete-teacher-account/utils/skippedCompleteTeacherAccount";

import { signInAsGuest } from "./utils";

export function signOut() {
  return async function (dispatch: Dispatch) {
    try {
      dispatch(signOutStart());

      await unsubscribeFromPushNotifications();
      await signOutFirebase();

      resetNumSessionsCompletedAllTime();

      analyticsLogSignOut();
    } catch (error: any) {
      logError(error);
      dispatch(signOutError());
    }

    dispatch(signOutAction());
    queryClient.clear();
    const region = await getRegionFromSessionStorageOrIpAndCache();
    clearHasSkippedCompleteTeacherAccount();
    dispatch(signInAsGuest(region));
  };
}
