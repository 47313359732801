// for local testing uncomment lines
// import {  useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { appVersionUpdate } from "service-worker-registration";

import {
  getAppVersionIsUpdating,
  getNewVersionOfTheAppIsAvailable
} from "../state";

export default function useUpdateApp() {
  const ready = useSelector(getNewVersionOfTheAppIsAvailable);
  const updating = useSelector(getAppVersionIsUpdating);

  return {
    update: appVersionUpdate.trigger,
    ready,
    updating
  };
}
// let readyOutOfScope = false
// export default function useUpdateApp() {
//   const ready = readyOutOfScope;
//   const [a, inc] = useState(0);
//   const updating = useSelector(getAppVersionIsUpdating);

//   useEffect(() => {
//     setTimeout(() => {
//       readyOutOfScope = true;
//       inc(s => s + 1);
//     }, 5000);
//   }, []);

//   const update = () => {};

//   return { update, ready, updating };
// }
