import { composeSelectors } from "seneca-common/utils/selectors/compose-selectors";

import { APP_STATE_PATH } from "../consts";
import AppStateSlice from "../models/AppStateSlice";
import PWAStateSlice from "../models/state/pwa/PWAStateSlice";
import * as stateSelectors from "./state";
import * as pwaSelectors from "./state/pwa";

function getAppStateSlice(state: any): AppStateSlice {
  return state.get(APP_STATE_PATH);
}

export function getPWAStateSlice(state: any): PWAStateSlice {
  return stateSelectors.getPWAStateSlice(getAppStateSlice(state));
}

export const {
  newVersionOfTheAppIsAvailable,
  appVersionIsUpdating,
  shouldShowPrompt,
  shouldShowWarning,
  getLastSeenPrompt
} = composeSelectors<any, PWAStateSlice>(getPWAStateSlice, pwaSelectors);
