import { Subscriptions } from "seneca-common/features/subscriptions/state/models/subscriptions";
import Payment from "seneca-common/features/subscriptions/state/models/subscriptions/payment";
import SubscriptionsStateSlice from "seneca-common/features/subscriptions/state/models/SubscriptionsStateSlice";

export function getSubscriptionsSlice(
  state: SubscriptionsStateSlice
): Subscriptions {
  return state.subscriptions;
}

export function getPaymentSlice(state: SubscriptionsStateSlice): Payment {
  return state.payment;
}

export function getMetaSlice(state: SubscriptionsStateSlice) {
  return state.meta;
}
