import * as Sentry from "@sentry/react";
import { History } from "history";
import { matchPath } from "react-router-dom";

import { routesConfig } from "./routesConfig";

export function getBrowserTracingIntegration(history: History) {
  // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
  return new Sentry.BrowserTracing({
    routingInstrumentation: Sentry.reactRouterV5Instrumentation(
      history,
      routesConfig,
      matchPath
    )
  });
}
