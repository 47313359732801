import ApiState from "../models/ApiState";

export function hasStarted(state: ApiState): boolean {
  return state.started;
}

export function didSucceed(state: ApiState): boolean {
  return state.success;
}

export function didError(state: ApiState): boolean {
  return state.error;
}

export function getErrorMessage(state: ApiState): string | null | undefined {
  return state.errorMessage;
}

export function getLastFetchedTime(state: ApiState) {
  return state.last;
}

export function hasFinished(state: ApiState): boolean {
  return didSucceed(state) || didError(state);
}

export function notSucceededOrStarted(state: ApiState): boolean {
  return !(didSucceed(state) || hasStarted(state));
}
