import type { UserInfo } from "firebase/auth";
import { Record } from "immutable";

import { TeacherRoleType, UserAccountType } from "common/types/user";

export type UserType = {
  type: UserAccountType | null | undefined;
  nickname: string | null | undefined;
  displayName: string | null | undefined;
  userId: string | null;
  email: string | null | undefined;
  givenName: string | null | undefined;
  familyName: string | null | undefined;
  school: string | null | undefined;
  birthdate: string | null | undefined;
  gender: string | null | undefined;
  guardianEmail: string | null | undefined;
  learningDifficulties: boolean | null | undefined;
  phoneNumber: string | null | undefined;
  yearGroup: number | null | undefined;
  teacherRole: TeacherRoleType | null | undefined;
  managedBy?: string | null;
  externalSchoolId?: string | null;
  providerData?: UserInfo[] | null;
  avatarUrl?: string | null;
  recoveryEmail?: string | null;
  recoveryEmailConfirmed?: boolean | null;
  lastVisitTime?: string | null;
  teacherSubjects?: string[] | null;
};

export type OptionalUserType = Partial<UserType>;

const defaultUser = {
  type: null,
  nickname: null,
  displayName: null,
  userId: null,
  email: null,
  givenName: null,
  familyName: null,
  school: null,
  birthdate: null,
  gender: null,
  guardianEmail: null,
  learningDifficulties: false,
  phoneNumber: null,
  yearGroup: null,
  teacherRole: null,
  managedBy: null,
  externalSchoolId: null,
  providerData: null,
  avatarUrl: null,
  recoveryEmail: null,
  recoveryEmailConfirmed: null,
  lastVisitTime: null,
  teacherSubjects: null
};

export default class User extends Record<UserType>(defaultUser) {}
