import {
  EmailNotificationsPreferencesQueryKey,
  PushNotificationsPreferencesQueryKey
} from "../hooks/consts";
import { PreferencesType } from "../types";

export default function getPreferencesQueryKey(type: PreferencesType) {
  return type === "push"
    ? PushNotificationsPreferencesQueryKey
    : EmailNotificationsPreferencesQueryKey;
}
