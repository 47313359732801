import { ActionCreatorConfig } from "../const";
import { processCallType, processName, processPath } from "../utils";
import {
  errorActionKey,
  resetActionKey,
  startActionKey,
  successActionKey
} from "./actionKeys";

export const makeActionTypes = (config: ActionCreatorConfig) => {
  const { name, path, callType } = config;

  const processedPath = processPath(path);
  const { callTypeCaps } = processCallType(callType);
  const { capsName } = processName(name);

  return {
    [startActionKey(config)]:
      `${processedPath}${callTypeCaps}_${capsName}_START`,
    [successActionKey(config)]:
      `${processedPath}${callTypeCaps}_${capsName}_SUCCESS`,
    [errorActionKey(config)]:
      `${processedPath}${callTypeCaps}_${capsName}_ERROR`,
    [resetActionKey(config)]:
      `${processedPath}${callTypeCaps}_${capsName}_RESET`
  };
};
