import { Dispatch } from "common/types";

import { Condition } from "./types";
import { defer } from "./utils";

export const AWAIT_CONDITION = "seneca/utils/awaitCondition";

export function awaitCondition(condition: Condition) {
  return (dispatch: Dispatch) => {
    const promise = defer();
    dispatch({
      type: AWAIT_CONDITION,
      payload: {
        condition,
        promise
      }
    });
    return promise;
  };
}
