import {
  restartMySubscription,
  updateSubscriptionActionFactory
} from "seneca-common/features/subscriptions/state";

import { ReceivedSubscriptionType } from "../types";

interface SubscriptionsClient {
  readonly restartSubscriptionById: (
    id: string
  ) => Promise<ReceivedSubscriptionType>;
}

export const makeRestartSubscription = (
  getClient: () => SubscriptionsClient
) => {
  return updateSubscriptionActionFactory({
    requestFunction: (id: string) => getClient().restartSubscriptionById(id),
    storeAction: restartMySubscription
  });
};
