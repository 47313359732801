import { Record } from "immutable";

type SessionUIType = {
  scrolledUp: boolean;
  focusedQuestionId?: string;
};

export type OptionalSessionUIType = Partial<SessionUIType>;

const defaultSessionUI: SessionUIType = {
  scrolledUp: false,
  focusedQuestionId: undefined
};

export default class SessionUI extends Record(defaultSessionUI) {}
