import React, {
  lazy,
  ReactComponentElement,
  Suspense,
  useCallback,
  useContext,
  useMemo,
  useState
} from "react";

import useEffectOnMount from "seneca-common/utils/hooks/useEffectOnMount";
import loaderWithRetry from "seneca-common/utils/loaderWithRetry";

type LocalStorageContext = {
  ready: boolean;
  blocked: boolean;
};

const LocalStorageBlockedContext = React.createContext<LocalStorageContext>({
  ready: false,
  blocked: true
});

export const useLocalStorageBlocked = () =>
  useContext(LocalStorageBlockedContext);

export const LOCAL_STORAGE_TEST_KEY = "seneca_test";

type Props = {
  children: ReactComponentElement<any, any> | ReactComponentElement<any, any>[];
};

const LocalStorageBlockedModal = lazy(() =>
  loaderWithRetry(
    () =>
      import(
        /* webpackChunkName: "local-storage-blocked-modal" */ "./LocalStorageBlockedModal"
      )
  )
);

export default function LocalStorageBlockedProvider({ children }: Props) {
  const [localStorageBlocked, setLocalStorageBlocked] = useState(true);
  const [checkFinished, setCheckFinished] = useState(false);
  const [modalDismissed, setModalDismissed] = useState(false);

  useEffectOnMount(() => {
    try {
      window.localStorage.setItem(LOCAL_STORAGE_TEST_KEY, "test");
      window.localStorage.removeItem(LOCAL_STORAGE_TEST_KEY);
      setLocalStorageBlocked(false);
    } catch (err: any) {
    } finally {
      setCheckFinished(true);
    }
  });

  const modalOpen = checkFinished && localStorageBlocked && !modalDismissed;

  const onCloseModalClick = useCallback(() => setModalDismissed(true), []);

  const localStorageContext = useMemo(
    () => ({ blocked: localStorageBlocked, ready: checkFinished }),
    [localStorageBlocked, checkFinished]
  );

  return (
    <>
      {modalOpen && (
        <Suspense>
          <LocalStorageBlockedModal onCloseClick={onCloseModalClick} />
        </Suspense>
      )}
      <LocalStorageBlockedContext.Provider value={localStorageContext}>
        {children}
      </LocalStorageBlockedContext.Provider>
    </>
  );
}
