import { Dispatch, GetState } from "common/types";

import { getClient } from "services";
import {
  changePassword as changeFirebasePassword,
  sendPasswordResetEmail as sendPasswordResetEmailFirebase
} from "services/firebase/auth";

import {
  getPasswordResetEmail,
  updatePasswordActionFactory,
  updatePasswordResetEmail
} from "seneca-common/features/user/state";

const forgotPasswordAction = updatePasswordActionFactory({
  requestFunction: email => sendPasswordResetEmailFirebase(email)
});

export async function changePassword(
  currentPassword: string,
  newPassword: string
) {
  return changeFirebasePassword(currentPassword, newPassword);
}

export function sendPasswordResetEmail(email: string) {
  return async function (dispatch: Dispatch) {
    await sendPasswordResetEmailFirebase(email);
    dispatch(updatePasswordResetEmail(email));
  };
}

export function resendPasswordResetEmail() {
  return async function (dispatch: Dispatch, getState: GetState) {
    const email = getPasswordResetEmail(getState());
    dispatch(forgotPasswordAction(email, email));
  };
}

export async function resetStudentPassword(
  classId: string,
  userId: string,
  newPassword: string
) {
  return getClient("authenticationService").resetStudentPassword({
    classId,
    userId,
    newPassword
  });
}

export async function resetStudentPasswords({
  schoolId,
  userIds,
  newPassword
}: {
  schoolId: string;
  userIds: string[];
  newPassword: string;
}) {
  const batchResetPasswords = (userIds: string[]) =>
    getClient("schoolsService").batchResetUserPasswords({
      schoolId,
      userIds,
      newPassword
    });

  return batchRequest(batchResetPasswords, userIds);
}

const BATCH_SIZE = 250;

async function batchRequest(
  request: (items: any[]) => Promise<any>,
  items: any[]
) {
  if (items.length <= BATCH_SIZE) {
    return request(items);
  }

  const numberOfBatches = Math.ceil(items.length / BATCH_SIZE);

  for (let i = 0; i < numberOfBatches; i++) {
    await request(items.slice(i * BATCH_SIZE, i * BATCH_SIZE + BATCH_SIZE));
  }
}
