import { UserAccountType } from "common/types/user";

import { makeAddValueToLinkState } from "seneca-common/utils/routing";

import { linkToDashboard } from "features/dashboard/links";
import { linkToParentWhatIsPremiumPage } from "features/parents/routes/parent/routes/what-is-premium/links";
import { SUBSCRIPTION_JOURNEY_SOURCES } from "features/subscriptions/analytics/consts";
import { Options as SubscriptionLinkOptions } from "features/subscriptions/features/what-is-premium";
import { userAccountType } from "features/user/consts";
import { ROUTES } from "routing/routes";

// Use this when routing users to the what is premium page
export function makeLinkToWhatIsPremium(options: SubscriptionLinkOptions) {
  return makeAddValueToLinkState(options)(linkToStudentWhatIsPremiumPage());
}

// Only use this inside the routing structure
export function linkToStudentWhatIsPremiumPage() {
  return `${linkToDashboard()}/what-is-premium`;
}

export { SUBSCRIPTION_JOURNEY_SOURCES };

export function getLinkToWhatIsPremiumForAccountType({
  accountType,
  options
}: {
  accountType: UserAccountType | undefined | null;
  options: SubscriptionLinkOptions;
}) {
  switch (accountType) {
    case userAccountType.schoolteacher:
      return makeAddValueToLinkState(options)(
        ROUTES.TEACHER.WHAT_IS_PREMIUM.buildPath({})
      );

    case userAccountType.schoolparent:
      return makeAddValueToLinkState(options)(linkToParentWhatIsPremiumPage());

    default:
      return makeAddValueToLinkState(options)(linkToStudentWhatIsPremiumPage());
  }
}
