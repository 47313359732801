import { CrudBuilderState } from "seneca-common/utils/apiStates";

import { SubscriptionProducts } from "../../models/state/subscription-products/SubscriptionProducts";
import SubscriptionProductsStateSlice from "../../models/SubscriptionProductsStateSlice";

export const getSubscriptionProductsSlice = (
  state: SubscriptionProductsStateSlice
): SubscriptionProducts => state.subscriptionProducts;

export function getMetaSlice(
  state: SubscriptionProductsStateSlice
): CrudBuilderState {
  return state.meta as unknown as CrudBuilderState;
}
