export default function setSafeAreaBackgroundColoriOS({
  color,
  isDarkModeEnabled
}: {
  color: string;
  isDarkModeEnabled: string;
}) {
  if (window?.webkit?.messageHandlers?.setSafeAreaBackgroundColoriOS) {
    window.webkit.messageHandlers.setSafeAreaBackgroundColoriOS.postMessage({
      color,
      isDarkModeEnabled
    });
  }
}
