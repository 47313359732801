import { Map } from "immutable";

import ApiStateDoesNotExistError from "../errors/ApiStateDoesNotExistError";
import ApiState from "../models/ApiState";
import * as selectors from "./apiState";

type States = Map<string, ApiState> | ApiState;

export function hasStarted(states: States, entityId?: string): boolean {
  return mapToAppropriateSelector(states, entityId, selectors.hasStarted);
}

export function didSucceed(states: States, entityId?: string): boolean {
  return mapToAppropriateSelector(states, entityId, selectors.didSucceed);
}

export function didError(states: States, entityId?: string): boolean {
  return mapToAppropriateSelector(states, entityId, selectors.didError);
}

export function hasFinished(states: States, entityId?: string): boolean {
  return mapToAppropriateSelector(states, entityId, selectors.hasFinished);
}

export function notSucceededOrStarted(
  states: States,
  entityId?: string
): boolean {
  return mapToAppropriateSelector(
    states,
    entityId,
    selectors.notSucceededOrStarted,
    {
      missingEntityReturnValue: true
    }
  );
}

export function getErrorMessage(states: States, entityId?: string) {
  return mapToAppropriateSelector(states, entityId, selectors.getErrorMessage, {
    throwMissingEntityError: true
  });
}

export function getErrorMessagePermissive(states: States, entityId?: string) {
  return mapToAppropriateSelector(states, entityId, selectors.getErrorMessage);
}

export function getLastFetchedTime(states: States, entityId?: string) {
  return mapToAppropriateSelector(
    states,
    entityId,
    selectors.getLastFetchedTime,
    {
      throwMissingEntityError: true
    }
  ) as string | undefined;
}

type Selector<T> = (arg0: ApiState) => T;

function mapToAppropriateSelector<T>(
  states: States,
  entityId?: string,
  // @ts-ignore
  selector: Selector<T>,
  {
    throwMissingEntityError = false,
    missingEntityReturnValue = false
  }: {
    throwMissingEntityError?: boolean;
    missingEntityReturnValue?: boolean;
  } = {}
) {
  if (!entityId) {
    return selector(states as ApiState);
  }

  const state = (states as Map<string, ApiState>).get(entityId);

  if (!state) {
    if (throwMissingEntityError) throw new ApiStateDoesNotExistError(entityId);
    return missingEntityReturnValue;
  }

  return selector(state);
}
