import {
  AppVariants,
  GoStudentLearningAppVariants
} from "common/types/appVariant";

import { isPlurallApp } from "features/plurall";
import { linkToPageNotFoundError } from "features/plurall/links";

import { regionCodes, regions } from "../static/regions";

// Social Media Links
const instagram = "https://www.instagram.com/senecalearning/";
const facebook = "https://www.facebook.com/senecalearning/";
const twitter = "https://twitter.com/SenecaLearn";
const youtube = "https://www.youtube.com/channel/UC6MESCv3-w_lKQiAWZf-s8A";
const pinterest = "https://www.pinterest.co.uk/senecalearning/";

export const socialMediaLinks = {
  instagram,
  youtube,
  facebook,
  twitter,
  pinterest
};

export const helpEmailByVariant = {
  [AppVariants.seneca]: "learnmore@seneca.io",
  [AppVariants.plurall]: "learnmore@seneca.io",
  [AppVariants.goStudentLearning]: {
    [GoStudentLearningAppVariants.goStudent]: "gostudent@seneca.io",
    [GoStudentLearningAppVariants.studienkreis]: "studienkreis@seneca.io"
  }
};

export function linkToMailToPremium() {
  return "mailto:premium@seneca.io";
}

export function linkToMailToPremiumSchools() {
  return "mailto:premiumschools@seneca.io";
}

export const linkToGoStudentCalendar = "https://bit.ly/3Ccfnxc";
export const linkToCalendly = (
  medium: string,
  campaign: string,
  content: string
) =>
  `https://calendly.com/alex-1859/seneca-expert?utm_source=webapp&utm_medium=${medium}&utm_campaign=${campaign}&utm_content=${content}`;

export function linkToDemoAirtableForm() {
  return "https://airtable.com/shrCOwCVzQRS6mgQ8";
}

export const tournamentWinnerGoogleForm =
  "https://docs.google.com/forms/d/e/1FAIpQLSdBm6HY1NZ8juzCv4HV6Z-iKF62jsphPUYQHXHhxV0tATjmNw/viewform";

export const linkToHelpSection = (
  locale: string = "en",
  { enableForPlurall = false }: { enableForPlurall?: boolean } = {}
) =>
  !enableForPlurall && isPlurallApp()
    ? `${window.location.origin}${linkToPageNotFoundError()}`
    : `https://help.senecalearning.com/${locale}`;

export const linkToHelpSectionArticle = (
  locale: string = "en",
  article: string,
  config: { enableForPlurall?: boolean } = {}
) => `${linkToHelpSection(locale, config)}/articles/${article}`;

export const makeLinkToHelpSectionArticle =
  (article: string, config: { enableForPlurall?: boolean } = {}) =>
  (locale: string) =>
    linkToHelpSectionArticle(locale, article, config);

export const linkToPrintableInviteCards = (
  locale: string,
  teacherName: string,
  classId: string,
  nameOfClass: string
) =>
  `https://your-school.senecalearning.com/#/print-class-instructions/${locale}/${teacherName}/${classId}/${nameOfClass}`;

type HelperArticleLinkParameters = {
  article: string;
  source?: string;
  medium?: string;
  campaign?: string;
  term?: string;
  content?: string;
  regionCode?: string;
};

/**
 * Util to build help article link. Ensure article does exist for region you're building the link for.
 * @returns Help article link
 */
export const linkToHelpArticle = (params: HelperArticleLinkParameters) => {
  const {
    article,
    source,
    medium,
    campaign,
    term,
    content,
    regionCode = regionCodes.GB
  } = params;

  const locale = (regions[regionCode] ?? regions[regionCodes.GB]).helpLinkCode;

  const link = `https://help.senecalearning.com/${locale}/articles/${article}`;

  const queryParams = [
    { source: source },
    { medium: medium },
    { campaign: campaign },
    { term: term },
    { content: content }
  ];

  if (source) {
    return (
      link +
      "?" +
      queryParams
        .map(item => {
          const entries = Object.entries(item);
          const [key, value] = entries[0];

          return value ? `utm_${key}=${value}` : "";
        })
        .filter(item => item)
        .join("&")
    );
  } else {
    return link;
  }
};

export const AcceptableUsePolicyArticleByRegion = {
  [regionCodes.GB]: "8794632-acceptable-use-policy",
  [regionCodes.ES]: "9504769-politica-de-uso-aceptable",
  [regionCodes.IT]: "9504763-politica-di-utilizzo-accettabile",
  [regionCodes.FR]: "9504772-politique-d-utilisation-acceptable",
  [regionCodes.DE]: "9504775-richtlinie-zur-akzeptablen-nutzung",
  [regionCodes.AT]: "9504775-richtlinie-zur-akzeptablen-nutzung"
};

export function linkToAcceptableUsePolicyArticle(regionCodeArg: string) {
  const article = AcceptableUsePolicyArticleByRegion[regionCodeArg];
  return linkToHelpArticle({
    article: article ?? AcceptableUsePolicyArticleByRegion[regionCodes.GB],
    regionCode: article ? regionCodeArg : regionCodes.GB
  });
}

export const premiumFaqsLink = makeLinkToHelpSectionArticle("2663301");

export const linkToHelpSectionCollection = (
  collection: string,
  locale: string = "en"
) => `${linkToHelpSection(locale)}/collections/${collection}`;

export const linkToMISIntegrationHelpSection = () =>
  linkToHelpSectionCollection("3117371");

export const linkToSettingMultiClassAssignmentsHelpSection = (locale: string) =>
  linkToHelpSectionArticle(locale, "8340213");

export const linkToPremiumHelpSection = () =>
  linkToHelpSectionCollection("2699107");

export const teacherAddDeleteStudentsHelpLink = (locale: string) =>
  makeLinkToHelpSectionArticle(
    getArticleNumberForTeacherAddDeleteStudentsHelpLink(locale)
  )(locale);

const getArticleNumberForTeacherAddDeleteStudentsHelpLink = (
  locale: string
) => {
  switch (locale) {
    case "pt-BR":
      return "3379119";

    default:
      return "2482967";
  }
};

export const differentProviderErrorMessageHelpLink =
  makeLinkToHelpSectionArticle("5057733");

export const termsAndConditionsLink = (locale: string) => {
  switch (locale) {
    case regions.BR.helpLinkCode: {
      return "https://senecalearning.com/pt-BR/termos-e-condicoes";
    }

    case regions.DE.helpLinkCode:
    case regions.AT.helpLinkCode:
      return "https://senecalearning.com/de-AT/AGB";

    default:
      return "https://senecalearning.com/en-GB/terms-and-conditions";
  }
};

export const termsAndConditionsGoStudentLink =
  "https://www.gostudent.org/en/T&Cs";

export const linkToStaticSiteTeacherPage = (region: string) => {
  switch (region) {
    case "BR":
      return "https://senecalearning.com/pt-BR/professores";

    case "CO":
    case "MX":
      return "https://senecalearning.com/es-MX/docentes";

    case "US":
      return "https://senecalearning.com/en-US/teachers";

    default:
      return "https://senecalearning.com/en-GB/teachers";
  }
};

export const linkToHealthResourcesArticle =
  makeLinkToHelpSectionArticle("4245191");

export const linkToWhyIsWrongAnswersLocked = makeLinkToHelpSectionArticle(
  "3582557",
  { enableForPlurall: true }
);

export const linkToWhyIsQuizModeLocked = makeLinkToHelpSectionArticle(
  "6039781",
  { enableForPlurall: true }
);

export const linkToTransferPremiumForm = (parentEmail?: string | null) => {
  const TRANSFER_PREMIUM_FORM =
    "https://docs.google.com/forms/d/e/1FAIpQLSeZT3Op0wNWDA90l2Duz3F-xeuhVKcWXZJqIe_b0Z0Hk1aVOg/viewform";

  return parentEmail
    ? `${TRANSFER_PREMIUM_FORM}?usp=pp_url&entry.1505850047=${parentEmail}&entry.843439797=&entry.876228073=${parentEmail}`
    : `${TRANSFER_PREMIUM_FORM}?usp=sf_link`;
};

export const LINK_TO_GET_STARTED_WITH_TUTORING_FORM =
  "https://docs.google.com/forms/d/e/1FAIpQLSfUHzdGx484iDKLlThA8b7wXKZtWE83bu9SqtwTT-40ZwP-Lw/viewform";

export const LINK_TO_PREMIUM_INFO = "https://bit.ly/Seneca-Premium-Info";

export function linkToDiscordActivationDemo() {
  return "https://help.senecalearning.com/en/articles/4771842-getting-started-on-discord-with-seneca-tutoring";
}

export function linkToIntercomTutoring() {
  return "https://www.senecalearningtutoring.com/tutor";
}

export const linkToUnblockSchoolNetworkArticle =
  makeLinkToHelpSectionArticle("2498808");
