import { Action } from "common/types";

import propsToJS from "seneca-common/utils/functions/immutable/props-to-js";
import logError from "seneca-common/utils/sentry/logError";

import { AnalyticsEventsRegister } from "./AnalyticsEventsRegister";
import { analyticsCategories, AnalyticsCategoriesTypes } from "./consts";
import { logGTMTrackingEvent } from "./googleTagManager";
import getUserData from "./payload";

const sendAnalyticEvent = (payload: any, category: any, actionType: any) => {
  const analyticsCategoryExists =
    Object.values(analyticsCategories).filter((e: any) => e === category)
      .length > 0;

  if (!analyticsCategoryExists) {
    category = analyticsCategories.OTHER;
  }

  logGTMTrackingEvent({
    payload,
    category,
    event: actionType
  });
};

export const makeAnalyticsEvent = (
  category: AnalyticsCategoriesTypes,
  payload?: any
) => ({
  category,
  payload
});

export const createAnalytics =
  (store: any) => (next: any) => (action: Action<any>) => {
    if (!AnalyticsEventsRegister.hasEventGenerator(action.type)) {
      return next(action);
    }

    try {
      const state = store.getState();
      const makeAnalyticsEvent = AnalyticsEventsRegister.getEventGenerator(
        action.type
      );
      const analyticsEvent = makeAnalyticsEvent(action, state);
      const injectedPayload = getUserData(state);
      const jsActionPayload = propsToJS(action.payload) || {};

      sendAnalyticEvent(
        { ...injectedPayload, ...jsActionPayload, ...analyticsEvent.payload },
        analyticsEvent.category,
        action.type
      );
    } catch (error: any) {
      logError(error, {
        message: "Error sending analytics event"
      });
    } finally {
      return next(action);
    }
  };
