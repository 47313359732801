import { combineReducers } from "redux-immutable";

import Classroom from "../models/Classroom";
import learningMode from "./learningMode";
import meta from "./meta";
import sessionUI from "./sessionUI";
import sidebarUI from "./sidebarUI";

export default combineReducers(
  {
    meta,
    learningMode,
    sessionUI,
    sidebarUI
  },
  () => new Classroom() as any
);
