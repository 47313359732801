import {
  Box,
  Button,
  Column,
  Columns,
  Icon,
  Text,
  TextButton,
  useBreakpoint,
  useTheme
} from "@seneca/senekit";

import useEffectOnMount from "seneca-common/utils/hooks/useEffectOnMount";

import { analyticsLogPromptToUseAppBannerViewedInSession } from "features/app/pwa/analytics";
import { TopbarPortal } from "features/common/top-bars";
import { getMobileDeviceType } from "features/common/utils/functions/mobile";

import { PromptProps } from "../types";
import {
  getBannerHasBeenViewedInSession,
  setBannerHasBeenViewedInSession
} from "../utils";

const CTA_TEXT = "Use app";

export function PromptToUseAppBanner({
  handleDismiss,
  handleCtaClick
}: PromptProps) {
  const theme = useTheme();

  const { isMobileOnly } = useBreakpoint();
  const deviceType = getMobileDeviceType();

  useEffectOnMount(() => {
    if (!getBannerHasBeenViewedInSession()) {
      setBannerHasBeenViewedInSession();
      analyticsLogPromptToUseAppBannerViewedInSession();
    }
  });

  return (
    <TopbarPortal>
      <Box
        backgroundColor={theme["primary-high-surface"]}
        width="100%"
        paddingX="20"
        paddingY="12"
      >
        <Columns alignY="center" space="xlarge">
          <Column width="content">
            <Box
              component="button"
              onClick={handleDismiss}
              border="none"
              padding="none"
              backgroundColor={theme["primary-high-surface"]}
            >
              <Icon
                iconName="cross"
                accessibilityLabel="Close banner"
                tone="primary"
                onHighEmphasisSurface
              />
            </Box>
          </Column>
          <Column width="content">
            <Box
              width="60px"
              height="60px"
              backgroundColor={theme.foreground}
              borderRadius="large"
              padding="small"
            >
              <Icon
                iconName="seneca"
                accessibilityLabel={null}
                tone="primary"
                size="fill"
              />
            </Box>
          </Column>
          <Column width="content">
            <Text onHighEmphasisSurface>
              <div style={{ whiteSpace: "nowrap" }}>{`Seneca for ${
                deviceType === "iOS" ? "iOS" : "Android"
              }`}</div>
            </Text>
          </Column>
          <Column alignX="flex-end">
            {isMobileOnly ? (
              <TextButton
                onClick={handleCtaClick}
                onHighEmphasisSurface
                underline
              >
                {CTA_TEXT}
              </TextButton>
            ) : (
              <Button onClick={handleCtaClick} fill="filledTonal">
                {CTA_TEXT}
              </Button>
            )}
          </Column>
        </Columns>
      </Box>
    </TopbarPortal>
  );
}
