import { Record } from "immutable";

import { ThemeModePreference } from "features/dark-mode/consts";

export enum StatsPreference {
  KNOWLEDGE_SCORE = "knowledge-score",
  XP = "xp"
}

export enum ThemePreference {
  BLUE = "BLUE",
  PURPLE = "PURPLE"
}

export type UserPreferencesType = {
  hasPickedDisplayName: boolean | null;
  mapGradient: string | null | undefined;
  typingPreference: string | null | undefined;
  moduleToolbarOpenByDefault: boolean;
  ageGroup: string | null | undefined;
  smartLearningEnabled: boolean | null;
  region: string | null | undefined;
  notifyWhenDataRequestReady?: boolean | null;
  soundEffectsEnabled?: boolean | null;
  preferredTheme?: ThemePreference | null;
  preferredThemeMode?: ThemeModePreference | null;
  preferredStats?: StatsPreference | null;
};

export type OptionalUserPreferences = Partial<UserPreferencesType>;

export const defaultUserPreferenceValues: UserPreferencesType = {
  hasPickedDisplayName: null,
  mapGradient: null,
  typingPreference: null,
  moduleToolbarOpenByDefault: true,
  ageGroup: null,
  smartLearningEnabled: null,
  region: null,
  notifyWhenDataRequestReady: null,
  soundEffectsEnabled: null,
  preferredTheme: null,
  preferredThemeMode: null,
  preferredStats: null
};

export const recognisedUserPreferenceFields: string[] = Object.keys(
  defaultUserPreferenceValues
);

export default class UserPreferences extends Record(
  defaultUserPreferenceValues
) {}
