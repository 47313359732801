import { AllowedCallTypes, MakeActionCreator } from "../../common/const";
import { makeApiStateReducer } from "../../common/reducers/apiState";

const makeDeleteStateReducer = ({
  name,
  path = "seneca/",
  idFieldName
}: MakeActionCreator) => {
  return makeApiStateReducer({
    name,
    path,
    idFieldName,
    callType: AllowedCallTypes.DELETE
  });
};

export default makeDeleteStateReducer;
