import actionTypes from "../action-types/meta";
import Meta from "../models/meta/Meta";

export default function metaReducer(meta: Meta = new Meta(), action: any) {
  switch (action.type) {
    case actionTypes.SET_COURSE_ID:
      return meta.set("courseId", action.payload.courseId);

    case actionTypes.OPEN_SHARE_TOPIC_MODAL:
      return meta.set("shareTopicModalOpen", true);

    case actionTypes.CLOSE_SHARE_TOPIC_MODAL:
      return meta.set("shareTopicModalOpen", false);

    case actionTypes.QUIT_SESSION:
      return meta.set("shareTopicModalOpen", false);

    case actionTypes.SET_COURSE_SEARCH_TERM: {
      if (meta.lastCourseSearchTerm !== action.payload.term) {
        return meta.set("lastCourseSearchTerm", action.payload.term);
      }
      return meta;
    }

    default:
      return meta;
  }
}
