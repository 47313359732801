import { State } from "common/types/redux";

import { pushLastActionPayloadToDataLayer } from "seneca-common/features/analytics/gtm";

import getUserData from "./payload";

type GTMEventType = {
  category: string;
  event: string;
  payload?: Record<string, any>;
};

export const logGTMTrackingEvent = (event: GTMEventType, state?: State) => {
  event.payload = event.payload || {};

  if (state) {
    event.payload = { ...getUserData(state), ...event.payload };
  }

  pushLastActionPayloadToDataLayer(event.payload);

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(event);

  pushLastActionPayloadToDataLayer(undefined);
};
