import { pushEnrichedEventToDataLayerWithCleanUp } from "seneca-common/features/analytics";
import { withLogAndSwallowError } from "seneca-common/utils/senecaAnalytics";

import {
  AllPreferences,
  PreferenceOptions,
  PreferencesType
} from "features/notifications/types";

export const makeEventName = (event: string) => {
  const eventName = `s/preferences/${event}`;
  const errorMessage = "GTM max event name length is 40 characters";

  if (eventName.length >= 41) throw new Error(errorMessage);
  return eventName;
};

export const analyticsLogPreferenceChange =
  withLogAndSwallowError(logPreferenceChange);

export const analyticsLogPreferencesChangeOnSignUp = withLogAndSwallowError(
  logPreferencesChangeOn("signup")
);

export const analyticsLogPreferenceChangeOnMarketingModal =
  withLogAndSwallowError(logPreferencesChangeOn("marketing-modal"));

function logPreferenceChange({
  field,
  checked,
  type
}: {
  field: keyof PreferenceOptions;
  checked: boolean;
  type: PreferencesType;
}) {
  pushEnrichedEventToDataLayerWithCleanUp({
    event: makeEventName(`change`),
    payload: {
      checked,
      fieldName: field,
      preferenceType: `${type}-notification`
    }
  });
}

export function logAnalytics(preferences: AllPreferences) {
  const { keys } = Object;

  const preferenceTypes = keys(preferences) as PreferencesType[];

  preferenceTypes.forEach(prefType => {
    const fields = keys(preferences[prefType]) as (keyof PreferenceOptions)[];

    fields.forEach(field => {
      const payload = {
        field,
        type: prefType,
        checked: !!preferences[prefType][field]
      };

      logPreferenceChange(payload);
    });
  });
}

function logPreferencesChangeOn(event: "signup" | "marketing-modal") {
  return function ({
    field,
    checked
  }: {
    field: keyof PreferenceOptions;
    checked: boolean;
  }) {
    pushEnrichedEventToDataLayerWithCleanUp({
      event: makeEventName(`change/${event}`),
      payload: {
        checked,
        fieldName: field,
        preferenceType: "email/push"
      }
    });
  };
}
