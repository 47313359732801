import { AppVariants } from "common/types/appVariant";

import isGoStudentLearningApp from "features/goStudentLearning/utils/isGoStudentLearningApp";
import { isPlurallApp } from "features/plurall";

export const appNames = {
  seneca: "Seneca",
  plurall: "Adapta",
  goStudentLearning: "GoStudent Learning"
};

export function getAppVersion() {
  return process.env.REACT_APP_VERSION;
}

export function getAppName() {
  const appVariant = getAppVariant();
  return appNames[appVariant];
}

export function getAppVariant() {
  if (isPlurallApp()) return AppVariants.plurall;
  if (isGoStudentLearningApp()) return AppVariants.goStudentLearning;
  return AppVariants.seneca;
}
