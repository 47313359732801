import actionTypes from "../action-types/preferences";
import UserPreferences, {
  recognisedUserPreferenceFields
} from "../models/UserPreferences";

export default function preferencesReducer(
  preferences: UserPreferences = new UserPreferences(),
  action: Record<string, any>
): UserPreferences {
  switch (action.type) {
    case actionTypes.SET_USER_PREFERENCES:
      return new UserPreferences(action.payload);

    case actionTypes.UPDATE_PREFERENCES:
      return updatePreferences(preferences, action);

    case actionTypes.MAP_GRADIENT:
      return preferences.set("mapGradient", action.payload.mapGradient);

    case actionTypes.TYPING_PREFERENCE:
      return preferences.set(
        "typingPreference",
        action.payload.typingPreference
      );

    case actionTypes.MODULE_TOOLBAR_OPEN_BY_DEFAULT:
      return preferences.set(
        "moduleToolbarOpenByDefault",
        action.payload.moduleToolbarOpenByDefault
      );

    case actionTypes.SOUND_EFFECTS_ENABLED:
      return preferences.set(
        "soundEffectsEnabled",
        action.payload.soundEffectsEnabled
      );

    case actionTypes.PREFERRED_THEME_MODE:
      return preferences.set(
        "preferredThemeMode",
        action.payload.preferredThemeMode
      );

    default:
      return preferences;
  }
}

function updatePreferences(
  preferences: UserPreferences = new UserPreferences(),
  action: Record<string, any>
): UserPreferences {
  const newPreferences = action.payload;
  const recognisedPreferences = Object.entries(newPreferences).reduce(
    (acc, [key, value]) =>
      recognisedUserPreferenceFields.includes(key)
        ? { ...acc, [key]: value }
        : acc,
    {}
  );
  return preferences.merge(recognisedPreferences);
}
