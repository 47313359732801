import ProductDescription, {
  SUBSCRIPTION_TIERS
} from "seneca-common/features/subscriptions/features/subscription-products/state/models/state/subscription-products/subscription-product/productDescription/ProductDescription";

export const getTierName = (
  state: ProductDescription
): SUBSCRIPTION_TIERS | undefined => state.tierName;

export const getIconUrl = (state: ProductDescription): string | undefined =>
  state.iconUrl;
