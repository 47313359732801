export default class MissingArgumentsError extends Error {
  argumentNames: string[];
  explanation: string | null | undefined;

  constructor(
    argumentNames: string[],
    explanation?: string | null | undefined
  ) {
    const argumentNamesCopy = argumentNames ? [...argumentNames] : [];
    let argumentString;

    if (argumentNamesCopy.length <= 1) {
      argumentString = argumentNamesCopy[0];
    } else {
      const lastArgument = argumentNamesCopy.pop();
      argumentString = `${argumentNamesCopy.join(", ")} and ${lastArgument}`;
    }

    super(`Missing ${argumentString} arguments. ${explanation || ""}`);

    this.argumentNames = argumentNames;
    this.explanation = explanation;
  }
}
