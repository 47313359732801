import { Client } from "@seneca/client-library";

import { LiveFeedEvent } from "features/parents/pages/monitoring-overview/components/LiveFeed/types";

const DEFAULT_LIVE_FEED_LIMIT = 25;

export default class LiveFeedServiceClient extends Client {
  async fetchFeedEvents({
    userId,
    limit = DEFAULT_LIVE_FEED_LIMIT
  }: {
    userId: string;
    limit?: number;
  }) {
    let url = `${this.url}/api/live-feed-events/${userId}?limit=${limit}`;
    const expectedResponses = [200, 403];

    const items: LiveFeedEvent[] = [];
    let lastKey: string | undefined = undefined;

    do {
      if (lastKey) {
        url += `&lastKey=${encodeURIComponent(lastKey)}`;
      }

      const response = await this.requestMaker.makeRequest<{
        items: LiveFeedEvent[];
        lastKey?: string;
      }>(url, { method: "GET" }, expectedResponses);

      items.push(...response.json!.items);
      lastKey = response.json!.lastKey;
    } while (lastKey);

    return { items };
  }
}
