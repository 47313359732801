import { isServiceWorkerSupported } from "service-worker-registration/utils";

import { logMessage } from "seneca-common/utils/sentry/logError";

import {
  appVersionIsUpdating,
  newAppVersionIsAvailable,
  receivePwaConfig
} from "features/app/pwa/actions";
import { analyticsLogNewServiceWorkerInstalled } from "features/app/pwa/analytics";

import { waitForStore } from "../store";
import registerServiceWorkerOnPageLoadIfNecessary from "./registerServiceWorkerOnPageLoadIfNecessary";
import { Config, PwaConfig } from "./types";

const appVersionUpdate = {
  trigger: () =>
    logMessage(
      "Tried to trigger app version update but update is not yet available"
    )
};

async function handleNewServiceWorkerReady({ activateNewServiceWorker }: any) {
  const triggerAppVersionUpdate = async () => {
    const store = await waitForStore();
    store.dispatch(appVersionIsUpdating(Date.now()));
    activateNewServiceWorker();
  };

  appVersionUpdate.trigger = triggerAppVersionUpdate;

  analyticsLogNewServiceWorkerInstalled();
  const store = await waitForStore();
  store.dispatch(newAppVersionIsAvailable());
}

// Uncomment to simulate new version ready:
// handleNewServiceWorkerReady({
//   activateNewServiceWorker: () => {
//     console.log("UPDATING APP");
//   }
// });

async function handleNewPwaConfigReceived(pwaConfig: PwaConfig) {
  const store = await waitForStore();
  store.dispatch(receivePwaConfig(pwaConfig));
}

const defaultConfig = {
  onNewServiceWorkerReadyButWaiting: handleNewServiceWorkerReady,
  onNewPwaConfigReceived: handleNewPwaConfigReceived
};

const registerServiceWorker = (config: Config = defaultConfig) =>
  registerServiceWorkerOnPageLoadIfNecessary(config);

function unregisterServiceWorker() {
  if (isServiceWorkerSupported()) {
    navigator.serviceWorker.ready.then(registration => {
      registration.unregister();
    });
  }
}

export { registerServiceWorker, unregisterServiceWorker, appVersionUpdate };
