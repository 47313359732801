import { useLocation, useNavigate } from "react-router-dom-v5-compat";

import { Box, Button, Heading, Stack } from "@seneca/senekit";

import { ROUTES } from "routing/routes";

import AppErrorWrapper from "./components/AppErrorWrapper";

export default function UpdateAvailableError({
  resetErrorBoundary
}: {
  resetErrorBoundary?: () => void;
}) {
  const { pathname, state } = useLocation();

  const navigate = useNavigate();

  const navigateToUpdate = () => {
    resetErrorBoundary?.();
    navigate(ROUTES.UPDATE.buildPath({}), {
      state: { redirectPostUpdate: pathname, redirectState: { ...state } }
    });
  };

  return (
    <AppErrorWrapper>
      <Box marginTop="xlarge">
        <Stack align="center" space="xlarge">
          <Heading level="4" weight="normal" align="center">
            There's a new version available that might fix this:
          </Heading>
          <Button width={["100%", "200px"]} onClick={navigateToUpdate}>
            Update
          </Button>
        </Stack>
      </Box>
    </AppErrorWrapper>
  );
}
