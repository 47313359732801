import { Dispatch, GetState } from "common/types";

import { signUp as signUpFirebase } from "services/firebase/auth";
import { pushProviderToGlobalDataLayer } from "services/firebase/auth/analytics/events";
import { getUserLinkedProviders } from "services/firebase/auth/ssoProvider";

import {
  getRegion,
  signInError,
  signInStart,
  signUpSuccess
} from "seneca-common/features/user/state";
import {
  analyticsLogSignUp,
  analyticsReferredUserSignUp
} from "seneca-common/utils/senecaAnalytics";
import logError, { logMessage } from "seneca-common/utils/sentry/logError";

import { makeUpdateNotificationPreferences } from "features/notifications/hooks/useUpdatePreferences";
import { analyticsLogPreferencesChangeOnSignUp } from "features/settings/features/notifications/analytics";
import { userAccountType } from "features/user";

import { setRegionPreference } from "../../actions/preferences";
import { setUserAsSignedUpBefore } from "../../utils/signedUpBefore";
import { saveUserInfo } from "../userInfo";
import { reconcileData } from "./utils";

type Props = {
  userDetails: Record<string, any>;
  password: string;
  referralCode?: string;
  isSubscribedToMarketingEmails?: boolean;
};

export function signUp({
  userDetails,
  password,
  referralCode,
  isSubscribedToMarketingEmails
}: Props) {
  const updateEmailPreferences = makeUpdateNotificationPreferences("email");
  const updatePushPreferences = makeUpdateNotificationPreferences("push");

  return async function (dispatch: Dispatch, getState: GetState) {
    try {
      pushProviderToGlobalDataLayer("password");
      dispatch(signInStart());

      const region = getRegion(getState()); // region needed so we can save it after sign up

      await signUpFirebase(userDetails.email, password);

      try {
        await dispatch(
          saveUserInfo({
            ...userDetails,
            providerData: getUserLinkedProviders()
          })
        );
      } catch (err: any) {
        // log both message and error so we can see top level grouping
        // and full error stack
        logMessage("created user with missing user info record", {
          extraInfo: {
            errorMessage: err.message
          }
        });
        logError(err);
      }

      const isParent = userDetails.type === userAccountType.schoolparent;
      const parentMarketing = !!isSubscribedToMarketingEmails;

      if (isParent) {
        updateEmailPreferences({ parentMarketing });
        updatePushPreferences({ parentMarketing });

        analyticsLogPreferencesChangeOnSignUp({
          field: "parentMarketing",
          checked: parentMarketing
        });
      }

      await dispatch(reconcileData());

      setUserAsSignedUpBefore();

      dispatch(signUpSuccess());

      region && dispatch(setRegionPreference(region));

      analyticsLogSignUp(getState());
      referralCode && analyticsReferredUserSignUp(referralCode); // probably still relied upon
    } catch (error: any) {
      dispatch(signInError());
      throw error;
    }
  };
}
