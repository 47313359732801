type EventGenerators = Record<string, (action?: any, state?: any) => any>;

class AnalyticsEventsRegister {
  static instance: AnalyticsEventsRegister = new AnalyticsEventsRegister();
  eventGenerators: EventGenerators = {};

  static getInstance() {
    return AnalyticsEventsRegister.instance;
  }

  static getEventGenerators() {
    return AnalyticsEventsRegister.getInstance()._getEventGenerators();
  }

  static addEventGenerators(generators: EventGenerators) {
    return AnalyticsEventsRegister.getInstance()._addEventGenerators(
      generators
    );
  }

  static hasEventGenerator(key: string) {
    return AnalyticsEventsRegister.getInstance()._hasEventGenerator(key);
  }

  static getEventGenerator(key: string) {
    return AnalyticsEventsRegister.getInstance()._getEventGenerator(key);
  }

  _getEventGenerators() {
    return this.eventGenerators;
  }

  _addEventGenerators(generators: EventGenerators) {
    this.eventGenerators = { ...this.eventGenerators, ...generators };
  }

  _hasEventGenerator(key: string) {
    return Object.keys(this.eventGenerators).includes(key);
  }

  _getEventGenerator(key: string) {
    return this.eventGenerators[key];
  }
}

export { AnalyticsEventsRegister };
