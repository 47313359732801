import logError from "seneca-common/utils/sentry/logError";

import { OnNewPwaConfigReceived } from "./types";
import { debugLog, debugLogString } from "./utils";

let count = 0;

const oneHour = 60 * 60 * 1000;

type Config = {
  onNewPwaConfigReceived: OnNewPwaConfigReceived;
};

export default function pollForNewServiceWorkerOnTheServer(
  registration: ServiceWorkerRegistration,
  config: Config
) {
  try {
    const pollTimeoutMS =
      (window.env &&
        window.env.pwa &&
        window.env.pwa.pollForNewServiceWorkerTimeoutMS) ||
      oneHour;

    debugLogString(
      `Setting timeout to check for new service worker at ${new Date(
        new Date().valueOf() + pollTimeoutMS
      ).toLocaleTimeString()} (in ${pollTimeoutMS} ms)`
    );
    setTimeout(async () => {
      await checkForNewServiceWorker(registration, config);
      pollForNewServiceWorkerOnTheServer(registration, config);
    }, pollTimeoutMS);
  } catch (err: any) {
    logError(err, {
      message: "Error whilst polling for a new service worker on the server",
      fingerprint: ["service-worker", "polling"]
    });
  }
}

async function checkForNewServiceWorker(
  registration: ServiceWorkerRegistration,
  config: Config
) {
  try {
    count++;
    debugLogString(`Starting check for new service worker loop ${count}...`);

    // Fetch the config in case the version of the app this user is on has been marked a faulty etc.
    debugLogString("Attempting to fetch new config");
    await fetchNewPWAConfig(config);

    debugLogString(
      "Checking if a new service worker has been published on the server..."
    );

    // Temp logging to try and help debug: https://github.com/SenecaLabs/senecaWeb/issues/6881
    try {
      console.log(
        "Service worker: active state: ",
        registration.active && registration.active.state
      );
      console.log(
        "Service worker: installing state: ",
        registration.installing && registration.installing.state
      );
      console.log(
        "Service worker: waiting state: ",
        registration.waiting && registration.waiting.state
      );
    } catch (e: any) {}

    registration.active && (await registration.update());
  } catch (err: any) {
    debugLogString(`Error during check for new service worker loop ${count}:`);
    debugLog(err);
    throw err;
  }
}

async function fetchNewPWAConfig(config: Config) {
  try {
    if (!navigator.onLine) return;

    const output = await fetch("/env.js");
    const body = await output.text();

    const pwaConfig = getPWAConfigFromEnvJSString(body);

    debugLogString("Fetched new PWA config, merging to window config");
    debugLog(pwaConfig);

    if (window.env && window.env.pwa) {
      window.env.pwa = { ...window.env.pwa, ...pwaConfig };
      debugLogString("New window PWA config:");
      debugLog(window.env.pwa);
      debugLogString("Updating PWA config in the redux store...");
      config.onNewPwaConfigReceived(window.env.pwa);
    } else {
      debugLogString(
        "No config found on the window, not merging fetched PWA config"
      );
    }
  } catch (err: any) {
    debugLogString("Error fetching new PWA config:");
    debugLog(err);
    throw err;
  }
}

function getPWAConfigFromEnvJSString(envJSContents: string) {
  try {
    const configString = envJSContents.replace("window.env = ", "");
    const config = JSON.parse(configString);

    return config.pwa;
  } catch (err: any) {
    logError(err, {
      message: "Error whilst attempting to parse new PWA config",
      extraInfo: {
        envJSContents
      },
      fingerprint: ["service-worker", "polling", "pwa-config"]
    });
    throw err;
  }
}
