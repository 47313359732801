const actionTypes = {
  SET_USER_PREFERENCES: "seneca/user/preferences/SET_USER_PREFERENCES",
  UPDATE_PREFERENCES: "seneca/user/preferences/UPDATE_PREFERENCES",
  MAP_GRADIENT: "seneca/user/preferences/MAP_GRADIENT",
  TYPING_PREFERENCE: "seneca/user/preferences/TYPING_PREFERENCE",
  MODULE_TOOLBAR_OPEN_BY_DEFAULT:
    "seneca/user/preferences/MODULE_TOOLBAR_OPEN_BY_DEFAULT",
  SOUND_EFFECTS_ENABLED: "seneca/user/preferences/SOUND_EFFECTS_ENABLED",
  PREFERRED_THEME_MODE: "seneca/user/preferences/PREFERRED_THEME_MODE"
};

export default actionTypes;
