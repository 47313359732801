import { makeCrudStateSelectors } from "seneca-common/utils/apiStates";

import { userPreferenceCrudStateConfig } from "../consts";
import { getUserMetaSlice } from "./userInfo";

const getUpdatePreferencesMetaState = (state: any) =>
  getUserMetaSlice(state).preferences;

const updatePreferencesMetaSelectors = makeCrudStateSelectors(
  getUpdatePreferencesMetaState,
  userPreferenceCrudStateConfig
);

export const getUpdatePreferencesStarted = (
  state: any,
  fieldName: string
): boolean =>
  updatePreferencesMetaSelectors.update.getStarted(state, fieldName);

export const getUpdatePreferencesError = (
  state: any,
  fieldName: string
): boolean => updatePreferencesMetaSelectors.update.getError(state, fieldName);
