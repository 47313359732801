import { validateEmailStrictly } from "seneca-common/utils/functions/validationFunctions";

export async function getFirebaseEmail(email: string): Promise<string> {
  return validateEmailStrictly(email) ? await transformEmail(email) : email;
}

export async function transformEmail(email: string) {
  const encoder = new TextEncoder();
  const data = encoder.encode(email);
  const digest = await globalThis.crypto.subtle.digest("SHA-256", data);
  const hashArray = Array.from(new Uint8Array(digest));
  const hashHex = hashArray.map(b => b.toString(16).padStart(2, "0")).join("");
  return `${hashHex}@seneca.com`;
}
