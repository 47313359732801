import { List } from "immutable";

import SidebarUI from "../../../models/sidebarUI/SidebarUI";

export function getSidebarOpen(state: SidebarUI): boolean {
  return state.open;
}

export function getExpandedAssignmentId(state: SidebarUI) {
  return state.expandedAssignmentId;
}

export function isAssignmentExpanded(
  state: SidebarUI,
  assignmentId: string
): boolean {
  return getExpandedAssignmentId(state) === assignmentId;
}

export function getExpandedSectionIds(state: SidebarUI): List<string> {
  return state.expandedSectionIds;
}

export function isSectionExpanded(
  state: SidebarUI,
  sectionId: string
): boolean {
  return getExpandedSectionIds(state).includes(sectionId);
}

export function getActiveTab(state: SidebarUI) {
  return state.activeTab;
}

export function getIsActiveTabSet(state: SidebarUI) {
  return state.activeTab !== undefined;
}
