import getConfig from "config";

import logError from "seneca-common/utils/sentry/logError";

export function getShowWarningForVersionsFromConfig() {
  return getPWAConfig("showWarningForVersions") || [];
}

export function getForceUpdateForVersionsFromConfig() {
  return getPWAConfig("forceUpdateForVersions") || [];
}

const dayInMS = 1000 * 60 * 60 * 24;

export function getToastVisibilityTimeout(): number {
  return getPWAConfig("toastVisibilityTimeoutMS") || dayInMS;
}

function getPWAConfig(keyName: string) {
  try {
    return getConfig().pwa[keyName];
  } catch (err: any) {
    logError(err, {
      message: "Error attempting to pull pwa values from config",
      extraInfo: {
        keyName
      },
      fingerprint: ["config", "pwa"]
    });
  }
}
