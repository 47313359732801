export const analyticsCategories = {
  USER: "User",
  SESSION: "Session",
  MODAL: "Modal",
  OTHER: "Other",
  REFERRAL: "Referral",
  NAVIGATION: "Navigation",
  COURSE: "Course",
  COURSE_ENROLLMENT: "Course enrollment",
  CLASS_ENROLLMENT: "Class enrollment",
  CLASS: "Class",
  ASSIGNMENT: "Assignment",
  MEMORY: "Memory",
  FEEDBACK: "Feedback",
  SUBSCRIPTION: "Subscription",
  SMART_LEARNING: "Smart Learning",
  COURSE_SEARCH: "Course Search",
  EXAM_QUESTIONS: "Exam Questions"
} as const;

export type AnalyticsCategoriesTypes =
  (typeof analyticsCategories)[keyof typeof analyticsCategories];
