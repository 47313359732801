import { Action } from "common/types/redux";

import propsToJS from "seneca-common/utils/functions/immutable/props-to-js";

import { isUnsafe, recursivelyFilterFieldsForSentry } from "./utils";

export default function actionTransformer(
  originalAction: Action<any> | null | undefined
) {
  if (!originalAction) return null;

  if (!originalAction.payload) {
    return {
      type: originalAction.type
    };
  }

  const originalActionJS = propsToJS(originalAction);

  if (looksLikeReduxFormAction(originalActionJS)) {
    return filterReduxFormPayloadsIfNecessary(originalActionJS);
  }

  return recursivelyFilterFieldsForSentry(originalActionJS);
}

function filterReduxFormPayloadsIfNecessary(action: Record<string, any>) {
  const { field } = action.meta;

  if (isUnsafe(field)) {
    return { ...action, payload: "***" };
  }

  return action;
}

const looksLikeReduxFormAction = (action: Record<string, any>): boolean =>
  action.hasOwnProperty("meta") &&
  action.meta.hasOwnProperty("form") &&
  action.meta.hasOwnProperty("field");
