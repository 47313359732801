import { List } from "immutable";

import Subscription, {
  subscriptionPaymentStatuses,
  subscriptionStatuses
} from "seneca-common/features/subscriptions/state/models/subscriptions/subscription/Subscription";
import UpdateOnPaymentSuccess from "seneca-common/features/subscriptions/state/models/subscriptions/subscription/UpdateOnPaymentSuccess";
import * as updateOnPaymentSuccessSelectors from "seneca-common/features/subscriptions/state/selectors/state/subscriptions/subscription/update-on-payment-success";
import { composeSelector } from "seneca-common/utils/selectors/compose-selectors";

export const getSubscriptionId = (state: Subscription): string | undefined =>
  state.subscriptionId;

export const getProductId = (state: Subscription) =>
  state.subscriptionProductId!;

// Status
export const getStatus = (state: Subscription) => state.status;

export const getPaymentStatus = (state: Subscription) => state.paymentStatus;

export const paymentSucceeded = (state: Subscription): boolean =>
  getPaymentStatus(state) === subscriptionPaymentStatuses.succeeded;

export const paymentFailed = (state: Subscription): boolean =>
  (getPaymentStatus(state) &&
    getPaymentStatus(state) === subscriptionPaymentStatuses.failed) ||
  getStatus(state) === subscriptionStatuses.paymentFailed;

export const paymentRequiresAuthentication = (state: Subscription): boolean =>
  (getPaymentStatus(state) &&
    getPaymentStatus(state) === subscriptionPaymentStatuses.requiresAction) ||
  getStatus(state) === subscriptionStatuses.paymentRequiresAction;

export const isActive = (state: Subscription): boolean =>
  getStatus(state) === subscriptionStatuses.active;

export const isManaged = (state: Subscription): boolean => !!state.managedBy;

export const isCancelled = (state: Subscription): boolean =>
  getStatus(state) === subscriptionStatuses.cancelled;

export const canBeRestarted = (state: Subscription) => state.canBeReactivated;

export const hasStripeSubscription = (state: Subscription): boolean =>
  !!state.stripeSubscriptionId;

export const getAddonProductIds = (state: Subscription): List<string> =>
  state.addonProductIds!;

// Expiry status
export const getExpiryDate = (state: Subscription) => state.expiryDate;

export const hasCardDetails = (state: Subscription): boolean => !!state.card;

export const getCardBrand = (state: Subscription) => state.card!.brand;

export const getCardLast4Digits = (state: Subscription): string =>
  state.card!.last4;

// Purchase dates
export const getInitialPurchaseDate = (state: Subscription) =>
  state.initialPurchaseDate;

export const getRenewalPurchaseDate = (state: Subscription) =>
  state.renewalPurchaseDate;

// isActive flag returned from the back end is calculated from expiryDate < now
export const hasExpired = (state: Subscription): boolean =>
  !paymentFailed(state) &&
  !paymentRequiresAuthentication(state) &&
  !state.isActive;

export const isLive = (state: Subscription): boolean =>
  (isActive(state) || isCancelled(state)) && !hasExpired(state);

export function requiresAction(state: Subscription) {
  return paymentFailed(state) || paymentRequiresAuthentication(state);
}

// Upgrades

export function getUpdateOnPaymentSuccess(
  state: Subscription
): UpdateOnPaymentSuccess {
  // @ts-ignore
  return state.updateOnPaymentSuccess;
}

export function getIsUpgrading(state: Subscription): boolean {
  return !!getUpdateOnPaymentSuccess(state);
}

// @ts-ignore
export const getUpgradeSubscriptionProductId: (arg0: Subscription) => string =
  composeSelector(
    getUpdateOnPaymentSuccess,
    updateOnPaymentSuccessSelectors.getSubscriptionProductId
  );

// @ts-ignore
export const getUpgradeProratedPrice: (arg0: Subscription) => number =
  composeSelector(
    getUpdateOnPaymentSuccess,
    updateOnPaymentSuccessSelectors.getProratedPrice
  );

// Transferring

export function canBeTransferred(state: Subscription): boolean {
  return isLive(state) && !isManaged(state);
}
