export const examQuestionsAnalyticsNamespace = "s/exam-questions";
export const markingAnalyticsNamespace = "s/marking";

export const CONFIDENCE_THRESHOLD = 0.5;

export enum NeedsMarkingReason {
  FLAG = "flagged",
  SELF_MARK = "selfMarked",
  LOW_CONFIDENCE = "lowConfidence"
}

export type MarkEvaluation = "perfect" | "good" | "ok" | "bad" | "unknown";
export type TimeEvaluation = "good" | "bad";
