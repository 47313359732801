import {
  linkWithPopup as _linkWithPopup,
  reauthenticateWithPopup as _reauthenticateWithPopup,
  signInWithPopup as _signInWithPopup,
  unlink as _unlink
} from "firebase/auth";

export const signInWithPopup = makeMockable(
  _signInWithPopup,
  "signInWithPopup"
);

export const linkWithPopup = makeMockable(_linkWithPopup, "linkWithPopup");

export const unlink = makeMockable(_unlink, "unlink");

export const reauthenticateWithPopup = makeMockable(
  _reauthenticateWithPopup,
  "reauthenticateWithPopup"
);

function makeMockable<F extends (...args: any[]) => any>(
  fn: F,
  fnName: string
) {
  return (...args: Parameters<F>): ReturnType<F> => {
    if (window.Cypress && window.__firebaseAuthMocks__[fnName]) {
      return window.__firebaseAuthMocks__[fnName](...args);
    }
    return fn(...args);
  };
}
