import React, { useMemo } from "react";
import {
  Navigate,
  useLocation,
  useSearchParams
} from "react-router-dom-v5-compat";

import { ROUTES } from "routing/routes";

import useIsAppOutdated, { MIN_VERSION_PARAM_KEY } from "./useIsAppOutdated";

export default function MinVersionParamHandler({
  children
}: {
  children: React.ReactElement;
}) {
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();

  const { isOutdated } = useIsAppOutdated();

  const redirectParams = useMemo(() => {
    const originalParams = new URLSearchParams(searchParams);
    originalParams.delete(MIN_VERSION_PARAM_KEY);
    return originalParams.toString();
  }, [searchParams]);

  const navigateUrlWithParams = `${pathname}?${redirectParams}`;

  if (isOutdated) {
    return (
      <Navigate
        to={ROUTES.UPDATE.buildPath({})}
        state={{ redirectPostUpdate: navigateUrlWithParams }}
        replace
      />
    );
  }

  if (searchParams.has(MIN_VERSION_PARAM_KEY)) {
    return <Navigate to={navigateUrlWithParams} replace />;
  }

  return children;
}
