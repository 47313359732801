import TeacherStateSlice from "../models/TeacherStateSlice";

export function getTeacherSlice(state: any): TeacherStateSlice {
  return state.get("teacher");
}

export function getPasswordResets(state: any) {
  return getTeacherSlice(state).resetPasswords;
}

export function getPasswordResetsSuccess(state: any) {
  return getPasswordResets(state).success;
}

export function getNewPassword(state: any) {
  return getPasswordResets(state).newPassword;
}
