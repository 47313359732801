import { Record } from "immutable";

export enum SESSION_ITEM_TYPES {
  MODULE = "MODULE",
  MESSAGE = "MESSAGE"
}

export type SessionItemType = {
  type?: keyof typeof SESSION_ITEM_TYPES;
  id?: string;
};

const defaultSessionItem: SessionItemType = {
  type: undefined,
  id: undefined
};

export default class SessionItem extends Record(defaultSessionItem) {}
