import { queryClient } from "services";
import { FEATURE_FLAGS_KEY } from "services/featureFlagsService";

import getAllFeatureFlagsWithOverrides from "./getAllFeatureFlags";
import { FlagsByType } from "./types";

export function getAllFeatureFlags() {
  const flags = queryClient.getQueryData<FlagsByType>([FEATURE_FLAGS_KEY]);
  return getAllFeatureFlagsWithOverrides(flags);
}

export function getFeatureFlag(flagId: string) {
  const {
    static: staticFlags,
    abTest: abTests,
    release: releaseFlags
  } = getAllFeatureFlags();
  const flags = { ...staticFlags, ...abTests, ...releaseFlags };

  return flags[flagId];
}
