import { useSelector } from "react-redux";

import { Selector } from "./types";

/**
A convenience so you can do: 

const courseId = useSelectorOnCondition(coursesHaveFetched, getCourseId)

instead of: 

const courseId = useSelector(s => coursesHaveFetched && getCourseId(s))

 */

export default function useSelectorOnCondition<ReturnT>(
  condition: any,
  selector: Selector<ReturnT>
): ReturnT | null {
  return useSelector(s => (condition ? selector(s) : null));
}
