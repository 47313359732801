import { addValueToLinkState } from "seneca-common/utils/routing";

import { LearningMode } from "features/classroom/state";
import { ExamQuestionsSource } from "features/exam-questions/analytics";

import { linkToCourseExamQuestionsHomepage } from "../course-exam-questions-homepage/links";

export function linkToCourseExamQuestionsSession(courseId?: string) {
  return `${linkToCourseExamQuestionsHomepage(courseId)}/session`;
}

// use this whenever linking to a course Exam Questions session

export const makeLinkToCourseExamQuestionsSessionWithState = ({
  courseId,
  selectedQuestionIds,
  source,
  sessionId,
  isRetrySession,
  assignmentId
}: {
  courseId: string;
  selectedQuestionIds: string[];
  source: ExamQuestionsSource;
  sessionId?: string;
  isRetrySession?: boolean;
  assignmentId?: string;
}) =>
  addValueToLinkState(linkToCourseExamQuestionsSession(courseId), {
    selectedQuestionIds,
    source,
    sessionId,
    isRetrySession,
    assignmentId
  });

export const makeLinkToAssignmentExamQuestionsSessionWithState = ({
  courseId,
  selectedQuestionIds,
  sessionId,
  assignmentId
}: {
  courseId: string;
  selectedQuestionIds: string[];
  sessionId?: string;
  assignmentId: string;
}) => {
  const linkWithState = addValueToLinkState(
    linkToCourseExamQuestionsSession(courseId),
    {
      selectedQuestionIds,
      source: ExamQuestionsSource.partOfAssignment,
      sessionId,
      assignmentId
    }
  );
  const params = new URLSearchParams();
  params.append("mode", LearningMode.assignments);
  params.append("assignmentId", assignmentId);
  linkWithState.search = params.toString();
  return linkWithState;
};
