import getConfig from "config";
import { getToken, Messaging } from "firebase/messaging";

export default async function getMessagingToken({
  registration,
  messaging
}: {
  registration: ServiceWorkerRegistration | undefined;
  messaging: Messaging | undefined;
}) {
  if (!registration || !messaging) return;

  const config = getConfig();

  const pushToken = await getToken(messaging, {
    vapidKey: config?.firebaseMessaging.vapidKey,
    serviceWorkerRegistration: registration
  });

  return pushToken;
}
