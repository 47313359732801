import { Record } from "immutable";

import { LearningMode as LearningModeT } from "../../consts";

type LearningModeType = {
  mode: LearningModeT;
  source: string | null | undefined;
  assignmentId: string | null | undefined;
};

export type OptionalLearningModeType = Partial<LearningModeType>;

const defaultLearningMode: LearningModeType = {
  mode: LearningModeT.default as any,
  source: undefined,
  assignmentId: undefined
};

export default class LearningMode extends Record(defaultLearningMode) {}
