import { Dispatch, GetState } from "common/types";

import { getClient } from "services";
import errorHandler from "services/userService/errorHandler";

import {
  setUser,
  updateUserPersonalInformation,
  userInfoCreateActionFactory,
  userInfoFetchActionFactory,
  userInfoUpdateActionFactory,
  UserType
} from "seneca-common/features/user/state";

import { userAccountType } from "../consts";
import { userInfoUpdateOperations } from "../consts/userInfo";

const getUserServiceClient = () => getClient("userService");

export const fetchUserInfo = userInfoFetchActionFactory({
  requestFunction: () => getUserServiceClient().fetchMyInfo(),
  storeAction: setUser,
  errorHandler
});

export const saveUserInfo = userInfoCreateActionFactory({
  requestFunction: userDetails =>
    getUserServiceClient().saveMyInfo(userDetails),
  storeAction: setUser,
  errorHandler
});

const clientUpdateInfoFunction = userInfoUpdateActionFactory({
  requestFunction: preferences =>
    getUserServiceClient().updateAttributes(preferences)
});

export const optimisticallyUpdateAttributes =
  (
    attributes: Partial<UserType>,
    updateId: string = generateUpdateId(attributes)
  ) =>
  async (dispatch: Dispatch, getState: GetState) => {
    dispatch(updateUserPersonalInformation(attributes));
    return await dispatch(clientUpdateInfoFunction(attributes, updateId));
  };

export const updateAttributes =
  (
    attributes: Partial<UserType>,
    updateId: string = generateUpdateId(attributes)
  ) =>
  async (dispatch: Dispatch, getState: GetState) => {
    const output = await dispatch(
      clientUpdateInfoFunction(attributes, updateId)
    );

    if (!(output instanceof Error)) {
      dispatch(updateUserPersonalInformation(attributes));
    }

    return output;
  };

export const setAccountTypeToSchoolTeacher = () => (dispatch: Dispatch) =>
  dispatch(
    updateAttributes(
      {
        type: userAccountType.schoolteacher as any
      },
      userInfoUpdateOperations.ACCOUNT_TYPE
    )
  );

export const setAccountTypeToSchoolStudent = () => (dispatch: Dispatch) =>
  dispatch(
    updateAttributes(
      {
        type: userAccountType.schoolstudent as any
      },
      userInfoUpdateOperations.ACCOUNT_TYPE
    )
  );

export const setAccountTypeToSchoolParent = () => (dispatch: Dispatch) =>
  dispatch(
    updateAttributes(
      {
        type: userAccountType.schoolparent as any
      },
      userInfoUpdateOperations.ACCOUNT_TYPE
    )
  );

export const generateNewDisplayName = async () => {
  return await getClient("userService").createNewDisplayName();
};

function generateUpdateId(attributes: Partial<UserType>): string {
  return Object.keys(attributes).join("-");
}
