import { useEffect, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";

import { Box, IconButton, useTheme } from "@seneca/senekit";

export default function CopyWithTooltip({
  copyValue,
  accessibilityLabel = "Copy"
}: {
  copyValue: string;
  accessibilityLabel?: string;
}) {
  const [clickedCopyButton, setClickedCopyButton] = useState(false);

  const {
    "neutral-high-surface": tooltipBackground,
    "contentOn-neutral-high-surface": tooltipColor
  } = useTheme();

  useEffect(() => {
    if (clickedCopyButton) {
      const timeoutId = setTimeout(() => setClickedCopyButton(false), 600);
      return () => clearTimeout(timeoutId);
    }
  }, [clickedCopyButton]);

  return (
    <Box position="relative" width="100%">
      {clickedCopyButton && (
        <Box
          backgroundColor={tooltipBackground}
          color={tooltipColor}
          padding="large"
          borderRadius="small"
          position="absolute"
          right="-10px"
          top="-42px"
        >
          Copied
        </Box>
      )}
      <CopyToClipboard text={copyValue}>
        <IconButton
          onClick={() => setClickedCopyButton(true)}
          accessibilityLabel={accessibilityLabel}
          iconName="copy"
          blend
        />
      </CopyToClipboard>
    </Box>
  );
}
