import { hasLiveSubscription } from "seneca-common/features/subscriptions/state";
import {
  getRegion,
  getUserAccountType,
  getUserId,
  isUserManaged
} from "seneca-common/features/user/state";

import { recursivelyFilterFieldsForSentry } from "./utils";

export default function getUserContext(state: any) {
  return recursivelyFilterFieldsForSentry({
    id: getUserId(state),
    region: getRegion(state),
    accountType: getUserAccountType(state),
    premium: hasLiveSubscription(state),
    isUserManaged: isUserManaged(state)
  });
}
