import { LearningMode } from "features/classroom/state";
import { withLearningModeQueryParams } from "features/classroom/utils";
import { makeLinkToStartCourseScreen } from "features/dashboard/pages/courses-page/links";

import { linkToClassroomSection } from "../../links";

export function linkToSectionHomepage(courseId?: string, sectionId?: string) {
  return `${linkToClassroomSection(courseId, sectionId)}/section-overview`;
}

export const linkToSectionHomepageWithLearningMode = (
  courseId: string,
  sectionId: string,
  mode: LearningMode | string
) =>
  withLearningModeQueryParams(
    linkToSectionHomepage(courseId, sectionId),
    mode as LearningMode
  );

export function linkToSectionHomepageEnrollCourse(
  courseId?: string,
  sectionId?: string,
  courseIdToEnroll?: string
) {
  return `${makeLinkToStartCourseScreen(
    linkToSectionHomepage(courseId, sectionId),
    courseIdToEnroll
  )}`;
}
