import { useConfig } from "config";

import { useFeatureFlag } from "features/feature-flags";

/**
 * Note: In most cases, you don't want to use this hook because it leads to unmaintainable code due to multiple app variants (Seneca, Plurall, GoStudentLearning).
 * Instead, create your own hook using `useAppVariant` hook (check existing usages of that hook for examples).
 */
export default function useIsGoStudentLearningApp() {
  const { config } = useConfig();
  const isGoStudentLearningApp = (config &&
    config.isGoStudentLearningApp) as boolean;

  return !!useFeatureFlag("isGoStudentLearningApp") || isGoStudentLearningApp;
}
