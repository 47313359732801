import Payment from "seneca-common/features/subscriptions/state/models/subscriptions/payment";

import actionTypes from "../../../action-types";

export default function paymentReducer(
  stored: Payment = new Payment(),
  action: Record<string, any>
) {
  if (action.type === actionTypes.SET_PAYMENT_ERROR) {
    return stored.set("error", action.payload);
  }

  if (action.type === actionTypes.SET_SECURE_AUTHENTICATION_ONGOING) {
    return stored.set("secureCustomerAuthenticationOngoing", action.payload);
  }

  if (action.type === actionTypes.SET_PAYMENT_METHOD_CREATION_ONGOING) {
    return stored.set("paymentMethodCreationOngoing", action.payload);
  }

  return stored;
}
