import { queryClient } from "services";

import { DATA_LAYER_SCOPES } from "seneca-common/features/analytics";
import { pushGlobalValuesToDataLayer } from "seneca-common/features/analytics/gtm";
import logError from "seneca-common/utils/sentry/logError";

import { fetchSelectedSchoolQueryOptions } from "../school-selection/queries";
import { searchSchools } from "./client";

export type SchoolOption =
  | {
      schoolId: string;
      name: string;
      localAuthority?: string;
      town?: string;
      postcode?: string;
      multiAcadamyTrust?: boolean;
      cantFindOption?: false;
    }
  | {
      cantFindOption: true;
    };

export async function fetchSchoolsDropdownOptions(
  searchTerm: string
): Promise<SchoolOption[]> {
  try {
    if (searchTerm.length < 3) return [];
    const schools = await searchSchools(searchTerm);
    return [
      ...(schools?.map(
        ({ schoolId, name, town, localAuthority, postcode }) => ({
          schoolId,
          name,
          town,
          localAuthority,
          postcode
        })
      ) ?? []),
      {
        cantFindOption: true
      }
    ];
  } catch (e: any) {
    logError(e, { message: "Error fetching schools options" });
  }

  return [];
}

export async function fetchMySchoolInfoAndPushValuesToDataLayer() {
  const data = await queryClient.fetchQuery(fetchSelectedSchoolQueryOptions());

  if (data) {
    pushGlobalValuesToDataLayer({
      [DATA_LAYER_SCOPES.SCHOOL]: {
        schoolId: data.schoolId,
        name: data.schoolInfo?.name
      }
    });
  }
}
