import { isServiceWorkerSupported } from "service-worker-registration/utils";

import { getPushSubscription } from "services/firebase/messaging/utils";

import logError from "seneca-common/utils/sentry/logError";

export async function unsubscribeFromPushNotifications() {
  try {
    if (!isServiceWorkerSupported()) return;

    const registration = await navigator.serviceWorker.getRegistration();

    if (!registration || registration?.installing) return;

    const subscription = await getPushSubscription({ registration });

    await subscription?.unsubscribe();
  } catch (error) {
    logError(error, {
      message: "Push notifications: unsubscribe error."
    });
  }
}
