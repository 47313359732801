import { makeActionsAndActionCreators } from "../../common/actions/actionCreator";
import { AllowedCallTypes, MakeActionCreator } from "../../common/const";

const makeFetchAllStateActionsAndActionCreators = ({
  name,
  path = "seneca/"
}: MakeActionCreator) => {
  const { actionTypes, actionCreators } = makeActionsAndActionCreators({
    name,
    path,
    callType: AllowedCallTypes.FETCH_ALL
  });
  return {
    fetchAllActionTypes: actionTypes,
    fetchAllActionCreators: actionCreators
  };
};

export default makeFetchAllStateActionsAndActionCreators;
