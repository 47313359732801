import { Map, Record } from "immutable";

import { AllowedCallTypes, CrudConfig } from "../../common/const";
import ApiState from "../../common/models/ApiState";
import { processCallType } from "../../common/utils";
import { consumerRequiresCallType } from "../utils";

type ReducerState = ApiState | Map<string, ApiState>;

type CrudBuilderStateType = {
  create?: ReducerState;
  delete?: ReducerState;
  fetch?: ReducerState;
  fetchAll?: ReducerState;
  update?: ReducerState;
};

export type CrudBuilderState = Record<{
  create?: ReducerState;
  delete?: ReducerState;
  fetch?: ReducerState;
  fetchAll?: ReducerState;
  update?: ReducerState;
}>;

export class CrudStateRecord extends Record<CrudBuilderStateType>({
  create: undefined,
  delete: undefined,
  fetch: undefined,
  fetchAll: undefined,
  update: undefined
}) {}

export const makeCrudBuilderInitialState = (config: CrudConfig) => {
  let returnState: any = {};

  let defaultState = new ApiState();

  if (config.idFieldName) {
    // @ts-ignore
    defaultState = new Map();
  }

  const { FETCH_ALL, FETCH, CREATE, UPDATE, DELETE } = AllowedCallTypes;

  if (consumerRequiresCallType(FETCH_ALL, config)) {
    returnState.fetchAll = new ApiState();
  }

  [FETCH, CREATE, UPDATE, DELETE].map(addCallTypeIfNecessary);

  function addCallTypeIfNecessary(callType: AllowedCallTypes) {
    if (consumerRequiresCallType(callType, config)) {
      const { callTypesLowerCase: stateKey } = processCallType(callType);
      returnState[stateKey] = defaultState;
    }
  }

  return Map(returnState);
};
