import { useQuery } from "@tanstack/react-query";

import { getClient } from "services";
import { FEATURE_FLAGS_KEY } from "services/featureFlagsService";

export default function useFetchFeatureFlags({
  enabled
}: {
  enabled: boolean;
}) {
  return useQuery({
    queryKey: [FEATURE_FLAGS_KEY],
    queryFn: async () => getClient("featureFlagsService").fetchFeatureFlags(),
    enabled,
    refetchOnWindowFocus: false,
    retry: false,
    refetchOnMount: false,
    refetchInterval: false
  });
}
