import {
  analyticsReduxRegistry,
  DATA_LAYER_SCOPES
} from "seneca-common/features/analytics";
import {
  getLiveManagedSubscriptionId,
  getLiveStripeSubscriptionId,
  getSubscriptionExpiryDate,
  getSubscriptionInitialPurchaseDate,
  getSubscriptionProductId,
  getSubscriptionRenewalPurchaseDate,
  getSubscriptionStatus,
  hasLiveSubscription
} from "seneca-common/features/subscriptions/state/selectors";
import { isSignedIn } from "seneca-common/features/user/state";

import {
  getMySubscriptionTierName,
  getSubscriptionPrice,
  subscriptionProductPaymentFrequency
} from "../../features/subscription-products/selectors/subscriptionSubscriptionProduct";
import { subscriptionsFetchSucceeded } from "../selectors/state/subscriptions/meta";

analyticsReduxRegistry.registerGlobalSelectorsToDataLayerScope(
  DATA_LAYER_SCOPES.SUBSCRIPTION,
  {
    isPremiumUser: withFetchState(hasLiveSubscription),
    subscriptionProductId: withFetchState(
      getSubscriptionProductId,
      subscriptionsFetchSucceeded
    ),
    status: withFetchState(getSubscriptionStatus, subscriptionsFetchSucceeded),
    paymentFrequency: withFetchState(
      subscriptionProductPaymentFrequency,
      subscriptionsFetchSucceeded
    ),
    price: withFetchState(getSubscriptionPrice, subscriptionsFetchSucceeded),
    tierName: withFetchState(
      getMySubscriptionTierName,
      subscriptionsFetchSucceeded
    ),
    expiryDate: withFetchState(
      getSubscriptionExpiryDate,
      subscriptionsFetchSucceeded
    ),
    initialPurchaseDate: withFetchState(
      getSubscriptionInitialPurchaseDate,
      subscriptionsFetchSucceeded
    ),
    renewalPurchaseDate: withFetchState(
      getSubscriptionRenewalPurchaseDate,
      subscriptionsFetchSucceeded
    )
  }
);

analyticsReduxRegistry.registerUserPropertiesSelectorsToDataLayerScope({
  isPremiumUser: withFetchState(hasLiveSubscription)
});

type State = any;
type Selector = (arg0: State, ...args: any[]) => any;

function withFetchState(
  selector: Selector,
  isFetched?: Selector | null | undefined
) {
  return function (state: State) {
    if (!isSignedIn(state)) {
      // see analytics README, null values in configs
      return false;
    }

    if (isFetched && !isFetched(state)) {
      return null;
    }

    const subscriptionId =
      getLiveStripeSubscriptionId(state) || getLiveManagedSubscriptionId(state);

    return !!subscriptionId ? selector(state, subscriptionId) : false;
  };
}
