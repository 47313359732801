import { NoValidSubscriptionExistsError } from "@seneca/subscriptions-service-client";

import { throttledLogError } from "seneca-common/utils/apiStates";

import defaultErrorTranslator from "./errorTranslator";

type ErrorTranslator = (error: Error) => string;

export default function makeErrorHandler(
  errorTranslator: ErrorTranslator = defaultErrorTranslator
) {
  return function subscriptionServiceErrorHandler(
    error: Error,
    {
      errorAction,
      successAction
    }: {
      successAction: (arg0?: any) => void;
      errorAction: (arg0?: any) => void;
    }
  ): void {
    if (error instanceof NoValidSubscriptionExistsError) {
      return successAction();
    }

    errorAction(errorTranslator(error));
    throttledLogError(error);
  };
}
