import { List } from "immutable";

import { buildReduxMapBundle } from "seneca-common/utils/state/build-redux-bundles";

import { FEATURE_NAME } from "./consts";
import Product from "./models/state/subscription-products/subscription-product/product/Product";
import ProductDescription from "./models/state/subscription-products/subscription-product/productDescription/ProductDescription";
import SubscriptionProduct, {
  SubscriptionProductType
} from "./models/state/subscription-products/subscription-product/SubscriptionProduct";

export const { actionTypes, actions, reducer } = buildReduxMapBundle({
  featureName: FEATURE_NAME,
  getIdFromJsObject: (subscriptionProduct: SubscriptionProductType) =>
    subscriptionProduct.id!,
  jsToImmutableTransform: (subscriptionProduct: SubscriptionProductType) =>
    new SubscriptionProduct({
      ...subscriptionProduct,
      product: new Product(subscriptionProduct.product),
      productDescription: new ProductDescription(
        subscriptionProduct.productDescription
      ),
      addonProductIds: List(subscriptionProduct.addonProductIds)
    })
});
