export default function getMessageFromError(
  error?: Error
): string | null | undefined {
  return (
    (error &&
      Object.prototype.hasOwnProperty.call(error, "message") &&
      typeof error.message === "string" &&
      error.message) ||
    undefined
  );
}
