import { makeCrudStateSelectors } from "seneca-common/utils/apiStates";

import { passwordResetCrudStateConfig } from "../consts";
import PasswordResetState from "../models/PasswordResetState";
import { getUserMetaSlice, getUserSlice } from "./userInfo";

export function getPasswordResetSlice(state: any): PasswordResetState {
  return getUserSlice(state).passwordReset;
}

export function getPasswordResetEmail(state: any) {
  return getPasswordResetSlice(state).email;
}

export function getPasswordResetMetaState(state: any) {
  return getUserMetaSlice(state).passwordReset;
}

const passwordResetMetaSelectors = makeCrudStateSelectors(
  getPasswordResetMetaState,
  passwordResetCrudStateConfig
);

export function getResubmitCodeError(
  state: any,
  email: string
): string | null | undefined {
  return passwordResetMetaSelectors.update.getErrorMessage(state);
}

export function getResubmitCodePending(state: any, email: string): boolean {
  return passwordResetMetaSelectors.update.getStarted(state, email);
}

export function getResubmitCodeSuccess(state: any, email: string): boolean {
  return passwordResetMetaSelectors.update.getSuccess(state, email);
}
