import * as Sentry from "@sentry/react";
import { History } from "history";

import {
  DIAGNOSIS_CODE,
  DIAGNOSIS_CODE_SENTRY_KEY
} from "features/app/diagnosis/utils";

import { getBrowserTracingIntegration } from "./performance/browserTracing/browserTracingIntegration";
import getSentryConfig from "./sentryConfig";

export const fingerprintTypes = {
  default: "{{ default }}",
  transaction: "{{ transaction }}",
  function: "{{ function }}",
  type: "{{ type }}",
  module: "{{ module }}",
  package: "{{ package }}"
};

let initialised = false;
export default function getSentry(history?: History) {
  if (!initialised && process.env.CI !== "true") {
    const sentryConfig = getSentryConfig(history);

    if (!sentryConfig) {
      throw new Error("Can't initialise Sentry without Sentry config");
    }
    initialised = true;

    Sentry.init(sentryConfig);
    Sentry.setTag(DIAGNOSIS_CODE_SENTRY_KEY, DIAGNOSIS_CODE);
  }

  return Sentry;
}

export function addTracingIntegration(history: History) {
  const client = Sentry.getCurrentHub().getClient<Sentry.BrowserClient>();
  const browserTracingIntegration = getBrowserTracingIntegration(history);

  const integrationAlreadyAdded = !!client?.getIntegrationById(
    browserTracingIntegration.name
  );

  if (client && !integrationAlreadyAdded) {
    client.addIntegration(browserTracingIntegration);
  }
}

type Options = {
  category: string;
  message: string;
};

export function addBreadcrumb({ category, message }: Options) {
  getSentry().addBreadcrumb({
    level: "info",
    category,
    message
  });
}
