import flatten from "flat";
import sizeof from "object-sizeof";

import actionTransformer from "./actionTransformer";
import { MAX_BREADCRUMB_SIZE_BYTES } from "./consts";

export default function breadcrumbDataFromAction(
  action: Record<string, any> | null | undefined
) {
  if (!action) return null;

  // @ts-ignore
  const safeAction = actionTransformer(action);

  const flatAction = flatten(safeAction);

  const size = sizeof(flatAction);

  if (size > MAX_BREADCRUMB_SIZE_BYTES) {
    return {
      type: action.type,
      WARNING: `Action data exceeds ${MAX_BREADCRUMB_SIZE_BYTES} bytes so not sent`
    };
  }

  return flatAction;
}
