import { addValueToLinkState } from "seneca-common/utils/routing";

import { linkToChild } from "../../links";

export function linkToAssignmentSummaryRoute(
  assignmentId: string = ":assignmentId",
  userId: string = ":userId"
) {
  return `${linkToChild(userId)}/assignment/${assignmentId}`;
}

export const isInternalLinkStateKey = "isInternal";

export function makeLinkToAssignmentSummaryPage(
  assignmentId: string,
  userId: string
) {
  return addValueToLinkState(
    linkToAssignmentSummaryRoute(assignmentId, userId),
    { [isInternalLinkStateKey]: true }
  );
}
