import { AllowedCallTypes, CrudConfig } from "seneca-common/utils/apiStates";

export const passwordResetCrudStateConfig: CrudConfig = {
  name: "startResetPassword",
  path: "seneca/user/passwordReset",
  idFieldName: "email",
  callTypes: [AllowedCallTypes.UPDATE]
};

export const userInfoCrudStateConfig: CrudConfig = {
  name: "userInfo",
  path: "seneca/user/userInfo/",
  idFieldName: "userInfoId",
  callTypes: [
    AllowedCallTypes.FETCH_ALL,
    AllowedCallTypes.CREATE,
    AllowedCallTypes.UPDATE
  ]
};

export const userPreferenceCrudStateConfig: CrudConfig = {
  name: "userPreferences",
  path: "seneca/user/userPreferences/",
  idFieldName: "preference",
  callTypes: [AllowedCallTypes.FETCH_ALL, AllowedCallTypes.UPDATE]
};

export const PATH_TO_USER_STATE = "user";
