import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { PushTopic } from "@seneca/notifications-service-types";
import { Expose } from "@seneca/small-utils-library";

import { getUserId } from "seneca-common/features/user/state";

import { analyticsLogUserClickedOnPushNotification } from "../";
import { NotificationType } from "../consts";
import isSupportedPushAnalyticsSource from "../utils/isSupportedPushAnalyticsSource";

type Props = Expose<{
  pushSource?: PushTopic | null;
  notificationType: NotificationType | null;
}>;

export default function usePushNotificationsAnalytics(props: Props) {
  const userId = useSelector(getUserId);
  const { source } = useGetCachedProps(props);
  const [hasLogged, setHasLogged] = useState(false);

  useEffect(() => {
    if (userId && source && !hasLogged) {
      analyticsLogUserClickedOnPushNotification(source);
      setHasLogged(true);
    }
  }, [source, userId, hasLogged]);
}

function useGetCachedProps({ pushSource, notificationType }: Props) {
  const isPush = notificationType === NotificationType.PUSH;
  const isSupportedSource = isSupportedPushAnalyticsSource(pushSource);

  const [source, setSource] = useState<PushTopic>();

  useEffect(() => {
    if (isPush && isSupportedSource) setSource(pushSource);
  }, [isPush, isSupportedSource, pushSource]);

  return { source };
}
