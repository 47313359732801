import { Record } from "immutable";

import PWAStateSlice from "./state/pwa/PWAStateSlice";

export type AppStateSliceType = {
  pwa: PWAStateSlice;
};

export default class AppStateSlice extends Record({
  pwa: new PWAStateSlice()
}) {}
