import { makeActionsAndActionCreators } from "../../common/actions/actionCreator";
import { AllowedCallTypes, MakeActionCreator } from "../../common/const";

const makeDeleteStateActionsAndActionCreators = ({
  name,
  path = "seneca/",
  idFieldName
}: MakeActionCreator) => {
  const { actionTypes, actionCreators } = makeActionsAndActionCreators({
    name,
    path,
    idFieldName,
    callType: AllowedCallTypes.DELETE
  });
  return {
    deleteActionTypes: actionTypes,
    deleteActionCreators: actionCreators
  };
};

export default makeDeleteStateActionsAndActionCreators;
