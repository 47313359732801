import { Record } from "immutable";

import ResetPasswordsState from "./resetPasswordsState";

export type TeacherStateSliceType = {
  resetPasswordsState: ResetPasswordsState;
};

export type OptionalTeacherStateSliceType = Partial<TeacherStateSliceType>;

export default class TeacherStateSlice extends Record({
  resetPasswords: new ResetPasswordsState()
}) {}
