import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import useEffectOnMount from "seneca-common/utils/hooks/useEffectOnMount";

import { getPreferredThemeMode, setPreferredThemeMode } from "features/user";

import { PREFERS_DARK_MODE, ThemeModePreference } from "../consts";
import {
  setThemeModePreferenceInLocalStorage,
  themeModePreferenceSetInLocalStorage
} from "../utils";

export function useDarkModeSettings() {
  const dispatch = useDispatch();

  const preferredThemeMode = useSelector(getPreferredThemeMode);

  const [userDevicePrefersDarkMode, setUserDevicePrefersDarkMode] = useState(
    window.matchMedia(PREFERS_DARK_MODE).matches
  );

  const darkModeEnabled = useMemo(() => {
    return (
      preferredThemeMode === ThemeModePreference.DARK ||
      (preferredThemeMode === ThemeModePreference.SYSTEM &&
        userDevicePrefersDarkMode)
    );
  }, [preferredThemeMode, userDevicePrefersDarkMode]);

  useEffectOnMount(() => {
    const darkModeEventListener = (e: MediaQueryListEvent) => {
      setUserDevicePrefersDarkMode(e.matches);
    };
    try {
      window
        .matchMedia(PREFERS_DARK_MODE)
        .addEventListener("change", darkModeEventListener);

      return () => {
        window
          .matchMedia(PREFERS_DARK_MODE)
          .removeEventListener("change", darkModeEventListener);
      };
    } catch (e) {
      // Fallback for Safari
      window?.matchMedia(PREFERS_DARK_MODE)?.addListener(darkModeEventListener);

      return () => {
        window
          ?.matchMedia(PREFERS_DARK_MODE)
          ?.removeListener(darkModeEventListener);
      };
    }
  });

  useEffect(() => {
    if (preferredThemeMode && !themeModePreferenceSetInLocalStorage()) {
      setThemeModePreferenceInLocalStorage(preferredThemeMode);
    }
  }, [preferredThemeMode]);

  const toggleDarkMode = () => {
    dispatch(
      setPreferredThemeMode(
        darkModeEnabled ? ThemeModePreference.LIGHT : ThemeModePreference.DARK
      )
    );
  };

  return { darkModeEnabled, toggleDarkMode };
}
