import { Record } from "immutable";

export type ProductType = {
  allPremiumCourses: boolean | undefined;
  numberOfPremiumCourses: number | null | undefined;
  examQuestions: boolean | undefined;
  guaranteedGrades: boolean | undefined;
  smartLearningMode: boolean | undefined;
  wrongAnswersMode: boolean | undefined;
  aiAssistant?: boolean;
  tutoring: boolean | undefined;
  quizMode: boolean | undefined;
  parentPremiumFeatures: boolean | undefined;
};

export type OptionalProductType = Partial<ProductType>;

const defaultValues: ProductType = {
  allPremiumCourses: undefined,
  numberOfPremiumCourses: undefined,
  examQuestions: undefined,
  guaranteedGrades: undefined,
  smartLearningMode: undefined,
  wrongAnswersMode: undefined,
  aiAssistant: undefined,
  tutoring: undefined,
  quizMode: undefined,
  parentPremiumFeatures: undefined
};

export default class Product extends Record(defaultValues) {}
