import { useSearchParams } from "react-router-dom-v5-compat";

import { logMessage } from "seneca-common/utils/sentry/logError";

import { getAppVersion } from "features/common/utils/functions/app";

import isCurrentVersionLessThan from "./utils";

export const MIN_VERSION_PARAM_KEY = "min-version";

export default function useIsAppOutdated({
  errorCallback = () => logMessage("Unable to compare version numbers")
}: {
  errorCallback?: () => void;
} = {}) {
  const [searchParams] = useSearchParams();

  const minVersion = searchParams.get(MIN_VERSION_PARAM_KEY);
  const currentVersion = getAppVersion();

  const isOutdated = isCurrentVersionLessThan({
    currentVersion,
    versionToCompare: minVersion,
    errorCallback
  });

  return { isOutdated };
}
