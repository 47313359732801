import { useSelector } from "react-redux";

import { getRegion } from "features/regions/selectors";

import { isNativeAppiOS } from "../utils/isNativeAppiOS";

export default function useSendRegionMessage() {
  const region = useSelector(getRegion) ?? "GB";

  if (isNativeAppiOS()) {
    window?.webkit?.messageHandlers?.region?.postMessage(region);
  }
}
