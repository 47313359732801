import { Dispatch, GetState } from "common/types";

import { resumeSession as resumeFirebaseSession } from "services/firebase/auth";
import { pushProviderToGlobalDataLayer } from "services/firebase/auth/analytics/events";
import { NoUserSessionError } from "services/firebase/auth/errors";
import { ProviderSSO } from "services/firebase/auth/types";

import { fetchMySchoolInfoAndPushValuesToDataLayer } from "seneca-common/features/schools/service/utils";
import { getRegion } from "seneca-common/features/user/state";
import {
  signInStart,
  signInSuccess
} from "seneca-common/features/user/state/actions/signInState";
import logError, { logMessage } from "seneca-common/utils/sentry/logError";

import { getRegionFromSessionStorageOrIpAndCache } from "features/regions/selectors";

import { setRegionPreference } from "../../actions/preferences";
import { fetchUserPreferences } from "../preferences";
import { fetchUserInfo } from "../userInfo";
import { signInAsGuest } from "./utils";

type Options = {
  onSessionError: () => void;
};

export function resumeSession({ onSessionError }: Options) {
  return async function (dispatch: Dispatch) {
    dispatch(signInStart());

    try {
      const user = await resumeFirebaseSession();
      dispatch(signInSuccess(user?.uid));
      await dispatch(fetchUserInformation());

      pushProviderToGlobalDataLayer(
        user?.providerData?.[0]?.providerId as ProviderSSO
      );
    } catch (error: any) {
      if (error instanceof NoUserSessionError) {
        // This just means no one was signed in. Do nothing
      } else {
        logMessage("Unexpected error when resuming session via Firebase", {
          errorMessage: error.message,
          extraInfo: {
            error
          }
        });

        onSessionError();
      }

      const region = await getRegionFromSessionStorageOrIpAndCache();
      dispatch(signInAsGuest(region));
    }
  };
}

function fetchUserInformation() {
  return async function (dispatch: Dispatch, getState: GetState) {
    try {
      await Promise.all([
        dispatch(fetchUserInfo()),
        dispatch(fetchUserPreferences()),
        fetchMySchoolInfoAndPushValuesToDataLayer()
      ]);

      const persistedRegion = getRegion(getState());

      if (!persistedRegion) {
        const region = await getRegionFromSessionStorageOrIpAndCache();
        dispatch(setRegionPreference(region));
      }
    } catch (error: any) {
      logError(error, {
        message: "Error fetching user information after resuming session"
      });
    }
  };
}
