import { composeSelectors } from "seneca-common/utils/selectors/compose-selectors";

import Classroom from "../../models/Classroom";
import * as learningModeSelectors from "./learningMode";
import { LearningModeSelectors } from "./learningMode/types";
import * as metaSelectors from "./meta";
import { MetaSelectors } from "./meta/types";
import * as sessionUISelectors from "./sessionUI";
import { SessionUISelectors } from "./sessionUI/types";
import * as sidebarUISelectors from "./sidebarUI";
import { SidebarUISelectors } from "./sidebarUI/types";

function getSessionUIState(state: Classroom) {
  return state.sessionUI;
}

// @ts-ignore
export const {
  getScrolledUp,
  getFocusedQuestionId
}: SessionUISelectors<Classroom> = composeSelectors(
  getSessionUIState,
  sessionUISelectors
);

function getLearningModeState(state: Classroom) {
  return state.learningMode;
}

// @ts-ignore
export const {
  getLearningMode,
  getAssignmentId,
  getSource,
  isAssignmentsMode,
  isWrongAnswersMode,
  isSmartLearningMode,
  isDefaultMode
}: LearningModeSelectors<Classroom> = composeSelectors(
  getLearningModeState,
  learningModeSelectors
);

function getSidebarUIState(state: Classroom) {
  return state.sidebarUI;
}

// @ts-ignore
export const {
  getSidebarOpen,
  getExpandedAssignmentId,
  isAssignmentExpanded,
  getExpandedSectionIds,
  isSectionExpanded,
  getActiveTab,
  getIsActiveTabSet
}: SidebarUISelectors<Classroom> = composeSelectors(
  getSidebarUIState,
  sidebarUISelectors
);

function getMetaState(state: Classroom) {
  return state.meta;
}

// @ts-ignore
export const {
  getCourseId,
  getShareTopicModalOpen,
  getLastCourseSearchTerm
}: MetaSelectors<Classroom> = composeSelectors(getMetaState, metaSelectors);
