import classNames from "classnames";
import * as React from "react";

import { Box, useShadow, useTheme } from "@seneca/senekit";
import {
  Overflow,
  ResponsiveDimension,
  ResponsiveProp,
  Styles
} from "@seneca/senekit/out/types";

import "./Card.scss";

export type Props = {
  clickable?: boolean;
  height?: ResponsiveProp<string>;
  className?: string;
  onClick?: () => void;
  rounded?: boolean;
  children: React.ReactNode;
  fullWidth?: boolean;
  growOnHover?: boolean;
  dataTest?: string;
  allowTooltips?: boolean;
  overflow?: Overflow;
  padding?: ResponsiveDimension;
  backgroundColor?: string;
  hoverStyles?: Styles;
  selected?: boolean;
};

export default function Card(props: Props) {
  const {
    clickable,
    className,
    onClick,
    rounded,
    children,
    fullWidth,
    growOnHover = true,
    dataTest = "Card",
    allowTooltips,
    height,
    padding,
    overflow,
    selected = false,
    ...restProps
  } = props;

  const theme = useTheme();
  const shadow = useShadow("small");

  const handleClick = () => {
    if (clickable && onClick) onClick();
  };

  const cardClass = classNames(
    {
      Card__outer: true,
      "Card__outer--clickable": clickable,
      "Card__outer--rounded": rounded,
      "Card__outer--fullWidth": fullWidth,
      "Card__outer--growOnHover": growOnHover,
      "Card__outer--noTooltip": !allowTooltips
    },
    className
  );

  return (
    <Box
      height={height}
      className={cardClass}
      onClick={handleClick}
      dataTest={dataTest}
      backgroundColor={theme.foreground}
      boxShadow={shadow}
      padding={padding}
      overflow={overflow}
      borderWidth={selected ? "xxsmall" : "none"}
      borderStyle={selected ? "solid" : "none"}
      borderColor={selected ? theme["primary-high-content"] : "none"}
      {...restProps}
    >
      {children}
    </Box>
  );
}
