import { matchPath } from "react-router-dom";

import { LearningMode } from "features/classroom/state";
import { withLearningModeQueryParams } from "features/classroom/utils/withLearningModeQueryParams";
import { makeLinkToStartCourseScreen } from "features/dashboard/pages/courses-page/links";

import { linkToClassroomCourse } from "../../links";

export function linkToCourseHomepage(courseId?: string) {
  return linkToClassroomCourse(courseId);
}

export function linkToCourseHomepageWithLearningMode(
  courseId: string,
  mode: LearningMode | string
) {
  return withLearningModeQueryParams(
    linkToCourseHomepage(courseId),
    mode as LearningMode
  );
}

export function linkToInviteToCourseLeaderboard(
  courseId: string = ":courseId"
) {
  return `${linkToCourseHomepage(courseId)}/leaderboard-invite`;
}

const courseHomePagePaths = [
  linkToCourseHomepage(),
  linkToInviteToCourseLeaderboard()
];

export const atCourseHomePage = (pathname: string): boolean =>
  courseHomePagePaths.some(path =>
    matchPath(pathname, {
      path,
      exact: true
    })
  );

export function linkToCourseHomepageStartCourse(
  courseId?: string,
  courseIdToStart?: string
) {
  return `${makeLinkToStartCourseScreen(
    linkToCourseHomepage(courseId),
    courseIdToStart
  )}`;
}
