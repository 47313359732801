import { isServiceWorkerSupported } from "service-worker-registration/utils";

import logError from "seneca-common/utils/sentry/logError";

export default async function getServiceWorkerRegistration() {
  try {
    const isSWSupported = isServiceWorkerSupported();
    if (!isSWSupported) return;

    const swRegistration = await navigator.serviceWorker.getRegistration();
    if (!swRegistration) return;

    const registration = await navigator.serviceWorker.ready;
    if (!registration.active) return;

    return registration;
  } catch (error) {
    logError(error, {
      message: "Push notifications: unable to get service worker registration."
    });
    return;
  }
}
