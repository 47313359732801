import ApiState from "../models/ApiState";

export const TIMESTAMP_FORMAT = "YYYY-MM-DDTHH:mm:ss.SSSZ";

export enum AllowedCallTypes {
  FETCH_ALL = "FETCH_ALL",
  FETCH = "FETCH",
  CREATE = "CREATE",
  UPDATE = "UPDATE",
  DELETE = "DELETE"
}

export enum AllowedCallTypesLowerCase {
  FETCH_ALL = "fetchAll",
  FETCH = "fetch",
  CREATE = "create",
  UPDATE = "update",
  DELETE = "delete"
}

export enum AllowedCallTypesUpperCase {
  FETCH_ALL = "FetchAll",
  FETCH = "Fetch",
  CREATE = "Create",
  UPDATE = "Update",
  DELETE = "Delete"
}

export enum AllowedActionStatus {
  START = "START",
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
  RESET = "RESET"
}

export type CrudConfig = {
  name: string;
  path?: string;
  idFieldName?: string;
  callTypes?: Array<AllowedCallTypes>;
};

export type MakeActionCreator = {
  name: string;
  path?: string;
  idFieldName?: string;
};

export type ActionCreatorConfig = {
  name: string;
  path: string;
  idFieldName?: string;
  callType: AllowedCallTypes;
};

export type CallTypeApiState = ApiState | Map<string, ApiState>;

export type ApiStates = Map<AllowedCallTypes, CallTypeApiState>;
