import { isImmutable } from "immutable";
import { combineReducers } from "redux-immutable";

import { ReducerRegister } from "../reducer-registry/types";

// Preserve initial state for not-yet-loaded reducers
const combineReducersAndPreserveInitialState = (
  reducers: ReducerRegister,
  initialState?: Record<string, any> | null | undefined
) => {
  if (!initialState) {
    return combineReducers(reducers);
  }

  const stateObject = isImmutable(initialState)
    ? initialState.toJS()
    : initialState;

  const withStatePreservingReducers = Object.keys(stateObject).reduce(
    (combinedReducers, stateKey) => {
      combinedReducers[stateKey] = combinedReducers[stateKey] || preserveState;
      return combinedReducers;
    },
    reducers
  );

  return combineReducers(withStatePreservingReducers);
};

const preserveState = (state = null) => state;

export default combineReducersAndPreserveInitialState;
