import { Reducer, ReducerRegister } from "./types";

type EnhancementWrapper = (arg0: Reducer) => Reducer;

export default class RegisterReducerBuilder {
  registry: any;

  constructor(registry: any) {
    this.registry = registry;
  }

  register(name: string, reducer: Reducer) {
    this.registry.builderRegisterReducer(name, reducer);
    return this;
  }

  registerMultiple(reducers: ReducerRegister) {
    this.registry.builderRegisterMultipleReducers(reducers);
    return this;
  }

  enhance(
    nameOfReducerToEnhance: string,
    reducerToEnhance: Reducer,
    enhancementWrapper: EnhancementWrapper
  ) {
    const registeredReducer = this.registry.getReducer(nameOfReducerToEnhance);

    let wrappedReducer: Reducer | null | undefined;

    if (!registeredReducer) {
      wrappedReducer = enhancementWrapper(reducerToEnhance);
    } else {
      wrappedReducer = enhancementWrapper(registeredReducer);
    }

    this.registry.builderRegisterReducer(
      nameOfReducerToEnhance,
      wrappedReducer
    );
    return this;
  }
}
