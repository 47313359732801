import { makeAddValueToLinkState } from "seneca-common/utils/routing";

import { linkToParentDashboard } from "features/parents/routes/parent/links";
import { SUBSCRIPTION_JOURNEY_SOURCES } from "features/subscriptions/analytics/consts";
import { Options as SubscriptionLinkOptions } from "features/subscriptions/features/what-is-premium";

type Options = SubscriptionLinkOptions & {
  childHasSubscription?: boolean;
};

export function makeLinkToParentWhatIsPremium(options: Options) {
  return makeAddValueToLinkState(options)(linkToParentWhatIsPremiumPage());
}

export function linkToParentWhatIsPremiumPage(params?: string) {
  return `${linkToParentDashboard()}/what-is-premium${params ? params : ""}`;
}

export { SUBSCRIPTION_JOURNEY_SOURCES };
