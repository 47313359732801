import { createGlobalStyle } from "styled-components";

import { ThemeInMode } from "@seneca/senekit";
import { ForegroundVariant } from "@seneca/senekit/out/styles/colors/types";
import { ShadowSize } from "@seneca/senekit/out/styles/shadows/useShadow";

const hexToRgb = (hex: string) => {
  return `${parseInt(hex.slice(1, 3), 16)},
        ${parseInt(hex.slice(3, 5), 16)},
        ${parseInt(hex.slice(5, 7), 16)}`;
};

type Props = {
  theme: ThemeInMode;
  shadows: {
    [key in ShadowSize]: string;
  };
  foregroundVariantColors: {
    [key in ForegroundVariant]: string;
  };
};

export default createGlobalStyle<Props>`
   :root {
      ${props =>
        Object.entries(props.theme).reduce(
          (acc, [colorName, hexCode]) =>
            typeof hexCode === "string"
              ? acc +
                `--${colorName}: ${hexCode};\n` +
                `--${colorName}-rgb: ${hexToRgb(hexCode)};\n`
              : acc,
          ""
        ) +
        Object.entries(props.shadows).reduce(
          (acc, [size, boxShadow]) => acc + `--shadow-${size}: ${boxShadow};\n`,
          ""
        ) +
        Object.entries(props.foregroundVariantColors).reduce(
          (acc, [variant, hexCode]) =>
            acc + `--foreground-variant-${variant}: ${hexCode};\n`,
          ""
        )}
     }
  `;
