import { Map } from "immutable";

import { State } from "common/types/redux";

import { AllowedCallTypes, CrudConfig } from "../../common/const";
import ApiState from "../../common/models/ApiState";
import { processCallType } from "../../common/utils";

const makeCrudStateSelectors = (
  getCrudSliceSelector: (arg0: any) => Map<string, any>,
  { idFieldName }: CrudConfig
) => {
  const fetchAllSelectors = makeActionTypeSelectors(
    AllowedCallTypes.FETCH_ALL,
    getCrudSliceSelector,
    undefined
  );

  const fetchSelectors = makeActionTypeSelectors(
    AllowedCallTypes.FETCH,
    getCrudSliceSelector,
    idFieldName
  );

  const createSelectors = makeActionTypeSelectors(
    AllowedCallTypes.CREATE,
    getCrudSliceSelector,
    idFieldName
  );

  const updateSelectors = makeActionTypeSelectors(
    AllowedCallTypes.UPDATE,
    getCrudSliceSelector,
    idFieldName
  );

  const deleteSelectors = makeActionTypeSelectors(
    AllowedCallTypes.DELETE,
    getCrudSliceSelector,
    idFieldName
  );

  return {
    fetchAll: fetchAllSelectors,
    fetch: fetchSelectors,
    create: createSelectors,
    update: updateSelectors,
    delete: deleteSelectors
  };
};

// TODO: add support for ids
const makeActionTypeSelectors = (
  crudAction: AllowedCallTypes,
  getCrudSliceSelector: any,
  idFieldName?: string
) => {
  const { callTypesLowerCase } = processCallType(crudAction);

  const getCrudActionSlice = (state: State): ApiState =>
    getCrudSliceSelector(state).get(callTypesLowerCase);

  // @ts-ignore
  const getProperty = (state: State, id?: string, property: any): any =>
    !idFieldName
      ? getCrudActionSlice(state).get(property)
      : getCrudActionSlice(state).getIn([id, property]);

  const getStarted = (state: State, id?: string): boolean =>
    getProperty(state, id, "started");

  const getSuccess = (state: State, id?: string): boolean =>
    getProperty(state, id, "success");

  const getLast = (state: State, id?: string): string | null | undefined =>
    getProperty(state, id, "last");

  const getError = (state: State, id?: string): boolean =>
    getProperty(state, id, "error");

  const getErrorMessage = (
    state: State,
    id?: string
  ): string | null | undefined => getProperty(state, id, "errorMessage");

  return {
    getStarted,
    getSuccess,
    getLast,
    getError,
    getErrorMessage
  };
};

export default makeCrudStateSelectors;
