import { QueryClientProvider } from "@tanstack/react-query";
import { History } from "history";
import { Provider } from "react-redux";
import getStore from "store";

import { queryClient } from "services";

import { FlagsProvider } from "features/feature-flags";

export default function AppStateProviders({
  children,
  history
}: {
  children: React.ReactElement;
  history: History;
}) {
  const store = getStore(history);
  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <FlagsProvider>{children}</FlagsProvider>
      </QueryClientProvider>
    </Provider>
  );
}
