import getStore from "store";

import { DEFAULT_REGION } from "common/static/regions";

import { getRegion } from "seneca-common/features/user/state";
import logError from "seneca-common/utils/sentry/logError";

export const REGION_SESSION_STORAGE_KEY = "seneca.region";

/**
 * This function is to be used only during app initialisation.
 *
 * You might want to retrieve the current user's region from the Redux store instead by using `useSelector(getRegion)`.
 */
export const getUserRegion = () =>
  getRegion(getStore().getState()) || DEFAULT_REGION;

export const setRegionInSessionStorage = (region: string) => {
  try {
    window.sessionStorage &&
      window.sessionStorage.setItem(REGION_SESSION_STORAGE_KEY, region);
  } catch (err: any) {
    logError(err, {
      message: "Could not set region in sessionStorage"
    });
  }
};

export const getRegionFromSessionStorage = () => {
  try {
    return (
      window.sessionStorage &&
      window.sessionStorage.getItem(REGION_SESSION_STORAGE_KEY)
    );
  } catch (err: any) {
    logError(err, {
      message: "Could not get region from sessionStorage"
    });
  }

  return null;
};
