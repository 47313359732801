export function initialiseLocalize() {
  window.Localize.on("setLanguage", function (data: any) {
    // Persist the currently selected language choice
    localStorage.setItem("lz_currentLanguage", data.to);
  });
  window.Localize.initialize({
    key: "nm7XvraFvnEjr",
    rememberLanguage: true,
    disableWidget: true,
    blockedIds: ["aria-context", "aria-selection-event"] // ignore react-select screen-reader <p> tags
  });
}
