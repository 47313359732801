import localforage from "localforage";

import logError from "seneca-common/utils/sentry/logError";

const SIGNED_UP_BEFORE_KEY = "signedUpBefore";

export async function setUserAsSignedUpBefore() {
  try {
    await localforage.ready();
    return await localforage.setItem(SIGNED_UP_BEFORE_KEY, true);
  } catch (e: any) {
    logError(e);
  }
}

export async function getUserSignedUpBefore() {
  try {
    await localforage.ready();
    return await localforage.getItem(SIGNED_UP_BEFORE_KEY);
  } catch (e: any) {
    logError(e);
    return false;
  }
}
