import ReducerRegistry from "seneca-common/utils/state/reducer-registry";

import { PATH_TO_SUBSCRIPTION_PRODUCTS_STATE } from "./consts";
import subscriptionProductsReducer from "./reducers";

const reducerRegistry = ReducerRegistry.getInstance();
reducerRegistry.initialise(PATH_TO_SUBSCRIPTION_PRODUCTS_STATE, builder => {
  builder.register(
    PATH_TO_SUBSCRIPTION_PRODUCTS_STATE,
    subscriptionProductsReducer
  );
});

export { subscriptionProductsReducer, PATH_TO_SUBSCRIPTION_PRODUCTS_STATE };

// actions
export {
  receiveSubscriptionProduct,
  receiveSubscriptionProducts
} from "./actions";
export { fetchAllSubscriptionProductsActionFactory } from "./actions/meta";

// selectors
export {
  // subscription products
  getSubscriptionProducts,
  getSubscriptionProduct,
  getSortedAnnualSubscriptionProductIds,
  getSortedMonthlySubscriptionProductIds,
  subscriptionProductExists,
  getSubscriptionTierName,
  makeGetSortedPromotedPeriodSubscriptionProductIds, // subscription-product
  getSubscriptionProductDescription,
  getSubscriptionProductIsAddOn,
  getSubscriptionProductIsMisBooster,
  getSubscriptionProductPrice,
  getSubscriptionProductIconUrl,
  subscriptionProductHasExamQuestions,
  subscriptionProductHasGuaranteedGrades,
  subscriptionProductHasSmartLearningMode,
  subscriptionProductHasWrongAnswersMode,
  subscriptionProductHasQuizMode,
  subscriptionProductHasAiAssistant,
  subscriptionProductHasTutoring,
  subscriptionProductHasParentPremiumFeatures,
  getSubscriptionProductCanBeUpgraded, // payment-frequency
  getSubscriptionProductPaymentFrequency,
  subscriptionProductIsBilledAnually,
  subscriptionProductIsBilledMonthly,
  subscriptionProductPaymentFrequency,
  getSameTierProductAnnualPrice,
  getSameTierProductSeasonalPrice,
  getTotalPriceByProductIds
} from "./selectors";

export {
  subscriptionProductsAreFetching,
  subscriptionProductsFetchSucceeded,
  subscriptionProductsFetchErrored,
  subscriptionProductsFetchFinished,
  subscriptionProductsNeedFetching
} from "./selectors/meta";

// models
export { SUBSCRIPTION_PRODUCT_PAYMENT_FREQUENCIES } from "./models/state/subscription-products/subscription-product/SubscriptionProduct";
export { SUBSCRIPTION_TIERS } from "./models/state/subscription-products/subscription-product/productDescription/ProductDescription";
export { default as SubscriptionProductsStateSlice } from "./models/SubscriptionProductsStateSlice";
export { default as Product } from "./models/state/subscription-products/subscription-product/product/Product";
export { default as SubscriptionProduct } from "./models/state/subscription-products/subscription-product/SubscriptionProduct";
export type {
  SubscriptionProductType,
  SubscriptionProductPaymentFrequencies
} from "./models/state/subscription-products/subscription-product/SubscriptionProduct";
