import { ReactComponentElement } from "react";
import { Helmet } from "react-helmet";

import favicon from "common/assets/images/favicon/favicon.png";
import icon192 from "common/assets/images/favicon/icon-192.png";
import goStudentLearningFavicon from "common/assets/images/logo/goStudentLearning-logo.svg";
import plurallFavicon from "common/assets/images/logo/plurall-smile.svg";

import useAppVariant from "features/common/hooks/useAppVariant";
import usePlurallConfiguration from "features/plurall/hooks/usePlurallConfiguration";
import useSetStatusBarBackgroundColoriOS from "features/pwa/mobile/ios/hooks/useSetStatusBarBackgroundColoriOS";

export default function AppHeadProvider({
  children
}: {
  children: ReactComponentElement<any, any> | ReactComponentElement<any, any>[];
}) {
  const appVariant = useAppVariant();

  const valuesByVariant = {
    plurall: <PlurallWrapper>{children}</PlurallWrapper>,
    goStudentLearning: (
      <GoStudentLearningWrapper>{children}</GoStudentLearningWrapper>
    ),
    seneca: (
      <Wrapper
        title="Seneca - Learn 2x Faster"
        favicon={favicon}
        icon={icon192}
      >
        {children}
      </Wrapper>
    )
  };

  return valuesByVariant[appVariant];
}

function PlurallWrapper({
  children
}: {
  children: ReactComponentElement<any, any> | ReactComponentElement<any, any>[];
}) {
  usePlurallConfiguration();

  return (
    <Wrapper
      title="Plurall Adapta - Learn 2x Faster"
      favicon={plurallFavicon}
      icon={plurallFavicon}
    >
      {children}
    </Wrapper>
  );
}

function GoStudentLearningWrapper({
  children
}: {
  children: ReactComponentElement<any, any> | ReactComponentElement<any, any>[];
}) {
  return (
    <Wrapper
      title="GoStudent Learning - Learn 2x Faster"
      favicon={goStudentLearningFavicon}
      icon={goStudentLearningFavicon}
    >
      {children}
    </Wrapper>
  );
}

function Wrapper({
  children,
  title,
  favicon,
  icon
}: {
  children: ReactComponentElement<any, any> | ReactComponentElement<any, any>[];
  title: string;
  favicon: string;
  icon: string;
}) {
  const backgroundColor = useSetStatusBarBackgroundColoriOS();

  return (
    <>
      <Helmet>
        <link rel="shortcut icon" href={favicon} />
        <link rel="icon" type="image/png" sizes="192x192" href={icon} />
        <link rel="apple-touch-icon" sizes="57x57" href={icon} />
        <link rel="apple-touch-icon" sizes="72x72" href={icon} />
        <link rel="apple-touch-icon" sizes="114x114" href={icon} />
        <link rel="apple-touch-icon" sizes="144x144" href={icon} />
        <meta name="theme-color" content={backgroundColor} />
        <meta name="msapplication-navbutton-color" content={backgroundColor} />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta
          name="apple-mobile-web-app-status-bar-style"
          content={backgroundColor}
        />
        <meta name="viewport" content="initial-scale=1, viewport-fit=cover" />
        <title>{title}</title>
      </Helmet>
      {children}
    </>
  );
}
