import { detect } from "detect-browser";

import logError from "seneca-common/utils/sentry/logError";

import { browserNames } from "./const";
import { BrowserType } from "./types";

const getBrowser = () => detect() as BrowserType;

const getBrowserName = () => {
  const browser = getBrowser();
  return browser ? browser.name : undefined;
};

const getBrowserVersion = () => {
  const browser = getBrowser();
  return browser ? browser.version : undefined;
};

const getBrowserDetails = (): string => {
  return `${getBrowserName()}:${getBrowserVersion()}`;
};

const isSafari = (): boolean => getBrowserName() === browserNames.SAFARI;

const isSafariIos = (): boolean => getBrowserName() === browserNames.IOS;

const isChrome = (): boolean => getBrowserName() === browserNames.CHROME;

const isChromeIOS = (): boolean => getBrowserName() === browserNames.CHROME_IOS;

const isEdge = (): boolean => getBrowserName() === browserNames.EDGE;

const isFirefox = (): boolean => getBrowserName() === browserNames.FIREFOX;

const isAndroid = (): boolean => getBrowserName() === browserNames.ANDROID;

const isIE = (): boolean => getBrowserName() === browserNames.IE;

const isOpera = (): boolean => getBrowserName() === browserNames.OPERA;

// Arc identifies itself as Chrome, so we need to look for an Arc-specific CSS variable
const isArc = (): boolean =>
  !!getComputedStyle(document.documentElement).getPropertyValue(
    "--arc-palette-background"
  );

const getMajorVersionFromString = (version: string): number =>
  Number(version.split(".")[0]);

const browserVersionLessThan = (version: string): boolean => {
  try {
    return (
      getMajorVersionFromString(getBrowserVersion()!) <
      getMajorVersionFromString(version)
    );
  } catch (error: any) {
    logError(error, {
      message: "Error checking browser version"
    });
    return true;
  }
};

export {
  browserVersionLessThan,
  getBrowserDetails,
  isAndroid,
  isArc,
  isChrome,
  isChromeIOS,
  isEdge,
  isFirefox,
  isIE,
  isOpera,
  isSafari,
  isSafariIos
};
