type Args = {
  versionToCompare: string | null;
  currentVersion: string | undefined;
  errorCallback?: () => void;
};

export default function isCurrentVersionLessThan({
  currentVersion,
  versionToCompare,
  errorCallback
}: Args): boolean {
  if (!versionToCompare || !currentVersion) return false;

  const minVersionParts = versionToCompare.split(".").map(Number);
  const currentVersionParts = currentVersion.split(".").map(Number);

  if (minVersionParts.some(isNaN) || currentVersionParts.some(isNaN)) {
    errorCallback?.();
    return false;
  }

  for (let i = 0; i < minVersionParts.length; i++) {
    if (minVersionParts[i] > currentVersionParts[i]) {
      return true;
    } else if (minVersionParts[i] < currentVersionParts[i]) {
      return false;
    }
  }

  return false;
}
