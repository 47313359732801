import { makeLinkToWhatIsPremium } from "features/dashboard/pages/what-is-premium-page/routes/links";
import {
  makeLinkToPasswordReset,
  makeLinkToSignIn,
  makeLinkToSignUp
} from "features/sign-up/global-sign-up-modal/links";
import { SUBSCRIPTION_JOURNEY_SOURCES } from "features/subscriptions/analytics/consts";
import { Options as SubscriptionLinkOptions } from "features/subscriptions/features/what-is-premium";

import { linkToSession } from "./links";

// Sign up
export function linkToSessionSignUp(courseId: string, sectionId: string) {
  return makeLinkToSignUp(linkToSession(courseId, sectionId));
}

export function linkToSessionSignIn(courseId: string, sectionId: string) {
  return makeLinkToSignIn(linkToSession(courseId, sectionId));
}

export function linkToSessionPasswordReset(
  courseId: string,
  sectionId: string
) {
  return makeLinkToPasswordReset(linkToSession(courseId, sectionId));
}

// Subscription
export function linkToSessionCreateSubscriptionJourney(
  courseId: string,
  sectionId: string,
  options: SubscriptionLinkOptions = {
    source: SUBSCRIPTION_JOURNEY_SOURCES.SESSION
  }
) {
  return makeLinkToWhatIsPremium(options);
}
