export default function loaderWithRetry<T>(
  lazyComponent: () => Promise<T>,
  attemptsLeft = 5,
  interval = 1000
) {
  return new Promise<T>((resolve, reject) => {
    lazyComponent()
      .then(resolve)
      .catch(error => {
        setTimeout(() => {
          if (attemptsLeft === 1) {
            reject(error);
            return;
          }
          loaderWithRetry(lazyComponent, attemptsLeft - 1, interval).then(
            resolve,
            reject
          );
        }, interval);
      });
  });
}
