import actionTypes from "seneca-common/features/subscriptions/state/action-types";
import {
  OptionalSubscriptionType,
  subscriptionStatuses
} from "seneca-common/features/subscriptions/state/models/subscriptions/subscription/Subscription";

import { actions } from "../reduxBundle";
import { ReceivedSubscriptionsType, ReceivedSubscriptionType } from "../types";

export const receiveSubscriptions = (response: ReceivedSubscriptionsType) =>
  actions.receiveMany(response.items);

export const receiveSubscription = (subscription: ReceivedSubscriptionType) =>
  actions.receiveOne(subscription);

export const updateSubscription = (updates: OptionalSubscriptionType) =>
  actions.update(updates);

export const deleteSubscription = (subscriptionId: string) =>
  actions.delete(subscriptionId);

export const cancelSubscription = (_response: any, subscriptionId: string) => {
  return updateSubscription({
    subscriptionId,
    status: subscriptionStatuses.cancelled
  });
};

export const restartMySubscription = (subscription: ReceivedSubscriptionType) =>
  updateSubscription({
    subscriptionId: subscription.subscriptionId,
    status: subscription.status
  });

export const createSubscription = receiveSubscription;

export const setPaymentError = (error?: string | null) => {
  return {
    type: actionTypes.SET_PAYMENT_ERROR,
    payload: error
  };
};

export const resetPaymentError = () => setPaymentError(null);

export const secureAuthenticationProcessingStarted = () => ({
  type: actionTypes.SET_SECURE_AUTHENTICATION_ONGOING,
  payload: true
});

export const secureAuthenticationProcessingFinished = () => ({
  type: actionTypes.SET_SECURE_AUTHENTICATION_ONGOING,
  payload: false
});

export const createPaymentMethodStarted = () => ({
  type: actionTypes.SET_PAYMENT_METHOD_CREATION_ONGOING,
  payload: true
});

export const createPaymentMethodFinished = () => ({
  type: actionTypes.SET_PAYMENT_METHOD_CREATION_ONGOING,
  payload: false
});
