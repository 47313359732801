import {
  CrudBuilderState,
  didError,
  didSucceed,
  getFetchAllSlice as getFetchAllSliceBase,
  hasStarted
} from "seneca-common/utils/apiStates";

type State = CrudBuilderState;

// fetch
function getFetchAllState(state: State) {
  return getFetchAllSliceBase(state);
}

export function subscriptionProductsAreFetching(state: State): boolean {
  return hasStarted(getFetchAllState(state));
}

export function subscriptionProductsFetchSucceeded(state: State): boolean {
  return didSucceed(getFetchAllState(state));
}

export function subscriptionProductsFetchErrored(state: State): boolean {
  return didError(getFetchAllState(state));
}

export function subscriptionProductsFetchFinished(state: State) {
  return (
    subscriptionProductsFetchSucceeded(state) ||
    subscriptionProductsFetchErrored(state)
  );
}
export function subscriptionProductsNeedFetching(state: State) {
  return !(
    subscriptionProductsFetchSucceeded(state) ||
    subscriptionProductsAreFetching(state)
  );
}
