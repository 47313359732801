import { commonSession } from "seneca-common/features/common/action-types";

const actionTypes = {
  NEW_SESSION_MODULE: "seneca/sessionModule/NEW_SESSION_MODULE",
  UPDATE_ANSWER: "seneca/sessionModule/UPDATE_ANSWER",
  SUBMIT_SCORE: "seneca/sessionModule/SUBMIT_SCORE",
  GIVE_UP: "seneca/sessionModule/GIVE_UP",
  UPDATE_MODULE_STATE: "seneca/sessionModule/UPDATE_MODULE_STATE",
  COMPLETE_MODULE: commonSession.COMPLETE_MODULE
};

export default actionTypes;
