import actionTypes from "../action-types/sidebarUI";
import SidebarUI from "../models/sidebarUI/SidebarUI";

export default function sidebarUIReducer(
  sidebarUI: SidebarUI = new SidebarUI(),
  action: any
) {
  switch (action.type) {
    case actionTypes.OPEN_SIDEBAR:
      return sidebarUI.set("open", true);

    case actionTypes.CLOSE_SIDEBAR:
      return sidebarUI.set("open", false);

    case actionTypes.EXPAND_ASSIGNMENT:
      return sidebarUI.set("expandedAssignmentId", action.payload.assignmentId);

    case actionTypes.COLLAPSE_ASSIGNMENT:
      return sidebarUI.set("expandedAssignmentId", undefined);

    case actionTypes.EXPAND_SECTION:
      return sidebarUI.update("expandedSectionIds", (ids: any) => {
        const id = action.payload.sectionId;

        if (!ids.includes(id)) {
          ids = ids.push(id);
        }

        return ids;
      });

    case actionTypes.EXPAND_SECTIONS: {
      const { sectionIds } = action.payload;

      return sidebarUI.update("expandedSectionIds", (ids: any) => {
        sectionIds.forEach((id: any) => {
          if (!ids.includes(id)) {
            ids = ids.push(id);
          }
        });

        return ids;
      });
    }

    case actionTypes.COLLAPSE_SECTION:
      return sidebarUI.update("expandedSectionIds", (ids: any) => {
        ids = ids.remove(ids.indexOf(action.payload.sectionId));
        return ids;
      });

    case actionTypes.SET_ACTIVE_TAB:
      return sidebarUI.set("activeTab", action.payload.id);

    default:
      return sidebarUI;
  }
}
