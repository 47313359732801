export default class MissingArgumentError extends Error {
  argumentName: string;
  explanation: string | null | undefined;

  constructor(argumentName: string, explanation?: string | null | undefined) {
    super(
      `Missing '${argumentName}' argument. ${explanation ? explanation : ""}`
    );

    this.argumentName = argumentName;
    this.explanation = explanation;
  }
}
