import { isImmutable } from "immutable";

export function propsToJS(props?: Record<string, any>) {
  if (!props) return undefined;

  if (isImmutable(props)) {
    return props.toJS();
  }

  return Object.entries(props).reduce(
    (newProps: any, [propName, propValue]) => {
      newProps[propName] = isImmutable(propValue)
        ? propValue.toJS()
        : propValue;
      return newProps;
    },
    {}
  );
}
